import React, { useEffect, useState } from 'react'
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io"
import { useHistory } from 'react-router-dom'
import { FaqsParticipation, FaqsGoal, FaqsTournaments, HowWork } from "../../utils/faqConstant"
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { useSelector } from 'react-redux'
import FaqFooter from './FaqFooter'
import { getFaqHelper } from '../../utils/helpers'
const SideMenuFaq = (props) => {
    const { faqSettingAction, user } = props
    const history = useHistory()
    const faqSettingData = useSelector(state => state?.faqSettingReducer?.faqSetting)
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'
    const [reading, setReading] = useState('')
    const [readingGoals, setReadingGoals] = useState('')
    const [readingTournaments, setReadingTournaments] = useState('')

    useEffect(() => {
        getFaqHelper(faqSettingAction)
    }, [])
    return (
        <div className='faq-all-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
            >
                <div>
                    <div className='height-35'>
                        <p className='screen-heading' style={{ color: 'white' }}>{selectedLanguage?.title_HowItWorks}</p>
                    </div>
                </div>

                <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                    {faqSettingData?.howIsWorkArray?.map((v, i) => {
                        return (
                            <>
                                {user?.userType === 'club' && v?.isClub &&
                                    <div
                                        key={1 + i}
                                        style={{
                                            padding: '20px',
                                            width: '100%',
                                            marginTop: '20px',
                                            borderRadius: '8px',
                                            paddingTop: '30px',
                                            boxShadow: 'white 0px 2px 5px -4px',
                                            borderRadius: '8px',
                                            background: '#000000a3'
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <p style={{ fontWeight: '600', fontSize: 16, width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                        </div>
                                        <div>
                                            <br />
                                            <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                        </div>
                                    </div>}
                                {user?.userType === 'player' && !v?.isClub &&
                                    <div
                                        key={1 + i}
                                        style={{
                                            padding: '20px',
                                            width: '100%',
                                            marginTop: '20px',
                                            borderRadius: '8px',
                                            paddingTop: '30px',
                                            boxShadow: 'white 0px 2px 5px -4px',
                                            borderRadius: '8px',
                                            background: '#000000a3'
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <p style={{ fontWeight: '600', fontSize: 16, width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                        </div>
                                        <div>
                                            <br />
                                            <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                        </div>
                                    </div>
                                }
                            </>
                        )
                    })
                    }
                </div>

                <div className='div-flex-back-name' style={{ marginTop: '35px' }}>
                    <div className='height-35'>
                        <p className='screen-heading' style={{ color: 'white' }}>{`FAQ's`}</p>
                    </div>
                </div>

                {faqSettingData?.FaqArrayGrourp?.Participation?.length &&

                    <div>
                        <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                            <p className='screen-heading' style={{ color: 'white', textAlign: 'center', }}>{selectedLanguage?.title_Participation}</p>
                        </div>
                        <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                            {faqSettingData?.FaqArrayGrourp?.Participation?.map((v, i) => {
                                return (
                                    <>
                                        {user?.userType === 'club' && v?.isClub &&
                                            <div
                                                key={1 + i}
                                                style={{
                                                    padding: '20px',
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    boxShadow: 'white 0px 2px 5px -4px',
                                                    borderRadius: '8px',
                                                    background: '#000000a3',
                                                    paddingTop: '30px'
                                                }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>Q:{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                                    {reading !== v?.titleEn ?
                                                        <IoIosArrowDown color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReading(v?.titleEn)
                                                        }} /> :
                                                        <IoIosArrowUp color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReading('')
                                                        }} />}
                                                </div>
                                                {reading === v?.titleEn ?
                                                    <div>
                                                        <br />
                                                        <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>A:{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                                    </div> : null}
                                            </div>
                                        }
                                        {user?.userType === 'player' && !v?.isClub &&
                                            <div
                                                key={1 + i}
                                                style={{
                                                    padding: '20px',
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    boxShadow: 'white 0px 2px 5px -4px',
                                                    borderRadius: '8px',
                                                    background: '#000000a3',
                                                    paddingTop: '30px'
                                                }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>Q:{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                                    {reading !== v?.titleEn ?
                                                        <IoIosArrowDown color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReading(v?.titleEn)
                                                        }} /> :
                                                        <IoIosArrowUp color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReading('')
                                                        }} />}
                                                </div>
                                                {reading === v?.titleEn ?
                                                    <div>
                                                        <br />
                                                        <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>A:{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                                    </div> : null}
                                            </div>
                                        }
                                    </>
                                )
                            })
                            }
                        </div>
                    </div>
                }

                {faqSettingData?.FaqArrayGrourp?.Goal?.length &&
                    <div>
                        <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                            <p className='screen-heading' style={{ color: 'white', textAlign: 'center', }}>{selectedLanguage?.title_Goal}</p>
                        </div>
                        <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                            {faqSettingData?.FaqArrayGrourp?.Goal?.map((v, i) => {
                                return (
                                    <>
                                        {user?.userType === 'club' && v?.isClub &&
                                            <div
                                                key={1 + i}
                                                style={{
                                                    padding: '20px',
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    boxShadow: 'white 0px 2px 5px -4px',
                                                    borderRadius: '8px',
                                                    background: '#000000a3',
                                                    paddingTop: '30px'
                                                }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>Q:{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                                    {readingGoals !== v?.titleEn ?
                                                        <IoIosArrowDown color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReadingGoals(v?.titleEn)
                                                        }} /> :
                                                        <IoIosArrowUp color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReadingGoals('')
                                                        }} />}
                                                </div>
                                                {readingGoals === v?.titleEn ?
                                                    <div>
                                                        <br />
                                                        <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>A:{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                                    </div> : null}
                                            </div>
                                        }
                                        {user?.userType === 'player' && !v?.isClub &&
                                            <div
                                                key={1 + i}
                                                style={{
                                                    padding: '20px',
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    boxShadow: 'white 0px 2px 5px -4px',
                                                    borderRadius: '8px',
                                                    background: '#000000a3',
                                                    paddingTop: '30px'
                                                }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>Q:{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                                    {readingGoals !== v?.titleEn ?
                                                        <IoIosArrowDown color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReadingGoals(v?.titleEn)
                                                        }} /> :
                                                        <IoIosArrowUp color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReadingGoals('')
                                                        }} />}
                                                </div>
                                                {readingGoals === v?.titleEn ?
                                                    <div>
                                                        <br />
                                                        <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>A:{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                                    </div> : null}
                                            </div>
                                        }
                                    </>
                                )
                            })
                            }
                        </div>
                    </div>
                }
                {faqSettingData?.FaqArrayGrourp?.Tournaments?.length &&
                    <div>
                        <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                            <p className='screen-heading' style={{ color: 'white', textAlign: 'center', }}>{selectedLanguage?.title_Tournaments}</p>
                        </div>
                        <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                            {faqSettingData?.FaqArrayGrourp?.Tournaments?.map((v, i) => {
                                return (
                                    <>
                                        {user?.userType === 'club' && v?.isClub &&
                                            <div
                                                key={1 + i}
                                                style={{
                                                    padding: '20px',
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    boxShadow: 'white 0px 2px 5px -4px',
                                                    borderRadius: '8px',
                                                    background: '#000000a3',
                                                    paddingTop: '30px'
                                                }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>Q:{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                                    {readingTournaments !== v?.titleEn ?
                                                        <IoIosArrowDown color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReadingTournaments(v?.titleEn)
                                                        }} /> :
                                                        <IoIosArrowUp color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReadingTournaments('')
                                                        }} />}
                                                </div>
                                                {readingTournaments === v?.titleEn ?
                                                    <div>
                                                        <br />
                                                        <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>A:{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                                    </div> : null}
                                            </div>
                                        }
                                        {user?.userType === 'player' && !v?.isClub &&
                                            <div
                                                key={1 + i}
                                                style={{
                                                    padding: '20px',
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    boxShadow: 'white 0px 2px 5px -4px',
                                                    borderRadius: '8px',
                                                    background: '#000000a3',
                                                    paddingTop: '30px'
                                                }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>Q:{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                                    {readingTournaments !== v?.titleEn ?
                                                        <IoIosArrowDown color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReadingTournaments(v?.titleEn)
                                                        }} /> :
                                                        <IoIosArrowUp color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                            setReadingTournaments('')
                                                        }} />}
                                                </div>
                                                {readingTournaments === v?.titleEn ?
                                                    <div>
                                                        <br />
                                                        <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>A:{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                                    </div> : null}
                                            </div>
                                        }
                                    </>
                                )
                            })
                            }
                        </div>
                    </div>
                }
                <div style={{ marginTop: '10px' }} />
                <FaqFooter />
            </div>
        </div >
    )
}

export default SideMenuFaq