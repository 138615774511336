import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GET, POST } from '../../../utils/apis'
import { Button, Popconfirm, Table } from 'antd'
import { convertRoundNumber, convertTitle, createInvoicePdf, errorMessage, getUserData, successMessage } from '../../../utils/helpers'
import axios from 'axios'
import moment from 'moment'
import ViewCard from "../../Cards/ViewCard"
import NoData from '../../../assets/noDataImae.png'
import { addPlayerInvoice } from '../../../Redux/actions/playerInvoiceAction'


const PlayerInvoices = (props) => {
    const { selectedLanguage, isEnglish, user, getUser } = props
    const dispatch = useDispatch()
    const invoices = useSelector(state => state?.playerInvoiceReducer?.playerInvoice)
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})

    console.log(invoices, '=invoices=invoices')


    useEffect(() => {
        getUser()
        getInvoices()
    }, [])

    const getInvoices = () => {
        axios.get(`${GET?.GET_PLAYER_INVOICES}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    dispatch(addPlayerInvoice(data.data))
                }
                else {
                    console.log(data, 'else')
                }
            })
            .catch((e) => {
                console.log(e?.message, 'catch==')
            })
    }

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    let columns = [
        {
            title: selectedLanguage?.text_Name,
            dataIndex: 'subscriptionName',
            key: 'subscriptionName',
            sorter: true,
        },
        {
            title: selectedLanguage?.text_TotalAmount,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            sorter: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>$ {e}</p>
        },
        {
            title: selectedLanguage?.text_SubscriptionDate,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).format('DD-MM-YYYY')}</p>
        },
        {
            title: selectedLanguage?.text_ViewInvoice,
            key: 'invoice',
            render: (e) => <a
                onClick={() => { createInvoicePdf(e, user, 'view', viewInvoice) }}
            >{selectedLanguage?.text_View}</a>
        },
        {
            title: selectedLanguage?.text_DownloadInvoice,
            key: 'download',
            render: (e) => <a
                onClick={() => { createInvoicePdf(e, user, 'download', viewInvoice) }}
            >{selectedLanguage?.text_Download}</a>
        },

    ]

    return (
        <div className='claim-p-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }}
            >

                <p
                    style={{
                        marginTop: '10px',
                    }}
                    className='admin-home-title-white'>
                    {selectedLanguage?.text_MyInvoices}
                </p>
                {/* <div style={{ marginTop: '20px' }}>
                    <Table
                        dataSource={user?.invoiceHistory || []}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 700 }}
                    />
                </div> */}

                <div style={{ marginTop: '20px' }}>
                    <div
                        className='sidebar-scroll-class-black'
                        style={{
                            overflow: 'scroll',
                            width: '98%',
                            margin: '0px auto'
                        }}
                    >
                        <div style={{
                            marginTop: '10px',
                            marginBottom: '5px',
                            // width: '100%',
                            width: '1000px',
                        }}>
                            <div style={{
                                width: '100%',
                                height: '60px',
                                borderTopRightRadius: '4px',
                                borderTopLeftRadius: '4px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '5px',
                                alignItems: 'center',
                            }}>
                                {[
                                    { title: selectedLanguage?.text_Name, },
                                    { title: selectedLanguage?.text_TotalAmount, },
                                    { title: selectedLanguage?.text_SubscriptionDate, },
                                    { title: selectedLanguage?.text_ViewInvoice, },
                                    { title: selectedLanguage?.text_DownloadInvoice, },
                                ]?.map((v, i) => {
                                    return (
                                        <p className='tableValue'
                                            style={{
                                                margin: '0px',
                                                color: 'white',
                                                fontWeight: 'bolder',
                                                fontSize: '20px',
                                                width: '290px',
                                                marginLeft: '10px',
                                                marginRight: '10px'
                                            }}
                                        >{v?.title}</p>
                                    )
                                })}
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    borderBottomRightRadius: '4px',
                                    borderBottomLeftRadius: '4px',
                                    justifyContent: 'space-between',
                                    padding: '5px',
                                    alignItems: 'center',
                                }}>
                                {invoices?.length ?
                                    invoices?.map((v, i) => {
                                        return (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '50px',
                                                    borderTopRightRadius: '4px',
                                                    borderTopLeftRadius: '4px',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    padding: '5px',
                                                    alignItems: 'center',
                                                    margin: '0px',
                                                    padding: '0px'
                                                }}>
                                                <p className='tableValue table-p'>
                                                    {v?.subscriptionName}
                                                </p>
                                                <p className='tableValue table-p'>
                                                    €{convertRoundNumber(v?.totalAmount) || 0}
                                                </p>
                                                <p className='tableValue table-p'>
                                                    {moment(v?.created).format('DD-MM-YYYY')}
                                                </p>
                                                <p className='tableValue table-p'>
                                                    <a onClick={() => { createInvoicePdf(v, user, 'view', viewInvoice) }}
                                                    >{selectedLanguage?.text_View}</a>
                                                </p>
                                                <p className='tableValue table-p'>
                                                    <a onClick={() => { createInvoicePdf(v, user, 'download', viewInvoice) }}
                                                    >{selectedLanguage?.text_Download}</a>
                                                </p>
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{ height: '160px', textAlign: 'center' }}>
                                        <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice} /> : null}

            </div>
        </div>
    )
}

export default PlayerInvoices