import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successNotification, errorMessage } from '../../utils/helpers'
import { allPaths } from '../../utils/constants'
import { LoginFooter, LoginHeader } from '../../Components'
import axios from 'axios'
import { AUTH } from '../../utils/apis'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'

const Login = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'

    const [loading, setLoading] = useState(false)
    const path = history?.location?.pathname
    const onFinish = (value) => {
        value.userType = path === allPaths?.BU_LOGIN ? 'club' : 'player'
        setLoading(true)
        axios.post(AUTH.LOGIN, value)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(selectedLanguage?.message_SuccessfullyLoggedIn)
                    dispatch(loginUser(data.user))
                    return setTimeout(() => {
                        history?.replace(allPaths?.HOME)
                    }, 300)
                }
                errorMessage(isEnglish ? data.message : data.messageNl)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }

    useEffect(() => {
        dispatch(removeUser())
    }, [])
    return (
        <div className='login-main-screen helper-main'>
            <LoginHeader />
            <div className='auth-screen-main-div' style={{ marginTop: '20px', }}>
                <p className='heading head-center' style={{ color: 'white' }}>{selectedLanguage?.title_Login}</p>
                <p style={{ textAlign: 'center', color: 'white' }}>{selectedLanguage?.subTitle_WelcomeBack}</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='email'
                                label={<p className='my-form-label'>{selectedLanguage?.label_Email}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourEmail
                                    },
                                    {
                                        type: 'email',
                                        message: selectedLanguage?.error_EmailIsNotValid
                                    },
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_PleaseEnterYourEmail}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='password'
                                label={<p className='my-form-label'>{selectedLanguage?.label_Password}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourPassword
                                    },
                                    {
                                        min: 8,
                                        message: selectedLanguage?.error_PasswordMustBeAtLeastEightCharacters
                                    }
                                ]}
                            >
                                <Input.Password
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.PlaceHolder_PleaseEnterYourPasswordHere}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {path === allPaths?.BU_LOGIN ? (
                        <Form.Item>
                            {/* <Link to='/login/bu'> */}
                            <Button
                                className='login_button'
                                htmlType='submit'
                                loading={loading}
                            >
                                {/* {selectedLanguage?.BusinessLogin} */}
                                Club Login
                            </Button>
                            {/* </Link> */}
                        </Form.Item>
                    ) : (
                        <Form.Item>
                            <Button
                                className='login_button'
                                htmlType='submit'
                                loading={loading}
                            >
                                Player Login
                                {/* {selectedLanguage?.Login} */}
                            </Button>
                        </Form.Item>
                    )}

                    <center>
                        {/* <p>{selectedLanguage?.OR}</p> */}
                        <p>Or</p>
                    </center>

                    {path === allPaths?.BU_LOGIN ? (
                        <Form.Item>
                            <Link to='/login'>
                                <Button
                                    className='login_button'
                                    htmlType='submit'
                                >
                                    {/* {selectedLanguage?.LoginAsGuest} */}
                                    Login as Player
                                </Button>
                            </Link>
                        </Form.Item>
                    ) : (
                        <Form.Item>
                            <Link to={allPaths?.BU_LOGIN}>
                                <Button
                                    className='login_button'
                                    htmlType='submit'
                                >
                                    {/* {selectedLanguage?.BusinessLogin} */}
                                    Login as Club
                                </Button>
                            </Link>
                        </Form.Item>
                    )}
                </Form>
                <div style={{ textAlign: 'center' }}>
                    <p>
                        {selectedLanguage?.DonTaveAnAccount}&nbsp;
                        {path === allPaths?.BU_LOGIN ?
                         <span>
                            <Link to={allPaths?.BU_SIGNUP}>{'Club Signup'}</Link>
                            <br />
                            <Link to={allPaths?.FORGOT_PASSOWRD}>{selectedLanguage?.minTitle_ForgotPassword}</Link>
                        </span> :
                         <span>
                            <Link to={allPaths.SIGNUP}>{selectedLanguage?.minTitle_SignUp}</Link>
                            <br />
                            <Link to={allPaths?.FORGOT_PASSOWRD}>{selectedLanguage?.minTitle_ForgotPassword}</Link>
                        </span>}
                    </p>
                </div >
            </div>
            <LoginFooter />
        </div>
    )
}


export default Login