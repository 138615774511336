import React, { useEffect, useState } from 'react'
import { SideMenu } from '../'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import HeaderMenuLayout from './HeaderMenuLayout'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { addAllGames, addAllPromoter, adminTournament, allAdminClubs, allClubsUsers,allCompetitions, allPlayersUsers } from '../../Redux/actions/generalActions'
import { addAllGameSettings, addAllGameSuggestions, addAllPlayer, addAllPlayerContacts, addAllUserTournaments, addUserRanks, addUserProRanks, addAllTournaments, addAllTournamentMatches, addAllSentMatches, addAllReceiveMatches, addAllMatchResults, addWalletTransaction, addCompetitionRanks, addAllSentCompetitionMatches, addAllReceiveCompetitionMatches, addAllCompetitionMatches, addSubscribedCompetitions, addAllCourts, addAllCoupleRequests, addAllCouples, addAllOpponents, addAllMembers, addMySubscriptions, addMyMatches, addClubInfo, addMyCompetitions } from '../../Redux/actions/userAction'
import axios from 'axios'
import { GET } from '../../utils/apis'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { AddCashbackServiceFee, AddCompetitionSubServiceFee, AddDollerTournamnetOrganizer, AddMatchReqSendFee, AddSubscriptionFeeDollerTournamnet } from '../../Redux/actions/pointSettingAction'
import { allPaths } from '../../utils/constants'
import { get1_1_MatchData, getAllTournaments, getCompetitionMatches, getFaqHelper, getGeneralData, getPointSetting, getTournamentMatchData, getUserData } from '../../utils/helpers'
import { AddFaqSetting } from '../../Redux/actions/faqSettingActions'


const MenuLayout = ({ component: Component, ...props }) => {
    const history = useHistory()
    const location = useLocation();
    const CurrnetPath = location?.pathname
    const isAdminScreen = CurrnetPath?.includes('admin')
    const dispatch = useDispatch()
    const pointsSettingData = useSelector(state => state?.pointSettingReducer)
    const user = useSelector(state => state.authReducer.user)
    const MenuNone = useSelector(state => state.menuNoneReducer.menuNone)
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const selectedLanguageUser = user?.userType === 'player' && user?.languageSetting === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'
    const [mobile, setMobile] = useState(false)


    useEffect(() => {
        // CurrnetPath !== '/' && CurrnetPath !== '/tournament/participation' && CurrnetPath !== '/competition/result' && isAdminScreen /* && user?.userType === "player" && dispatch(removeUser()) */
        // CurrnetPath !== '/' && CurrnetPath !== '/tournament/participation' && CurrnetPath !== '/competition/result' && !isAdminScreen /* && user?.userType !== "player" && dispatch(removeUser()) */
    }, [CurrnetPath])

    const setWindowWidth = () => {
        window.innerWidth <= 700 ? setMobile(true) : setMobile(false)
    }
    useEffect(() => {
        window.addEventListener('resize', setWindowWidth)
        setWindowWidth()
    }, [])
    useEffect(() => {
        user?._id && getUserData(user?._id, userActions)
        user?._id && getAllTournaments(user?._id, userActions)
        user?._id && getTournamentMatchData(user?._id, userActions)
        user?._id && get1_1_MatchData(user?._id, userActions)
        user?._id && getPointSetting(pointSettingAction)
        user?._id && getFaqHelper(faqSettingAction)
        user?._id && getCompetitionMatches(user?._id, userActions)
        getGeneralData(generalActions)
    }, [])


    if (!user) {
        return (
            <Redirect to='/home' />
        )
    }

    const getUser = () => {
        axios.get(`${GET.USER_BY_ID}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(loginUser(data.user))
                }
                else {
                    history?.replace(allPaths?.LOGIN)
                    dispatch(removeUser())
                }
            })
            .catch((e) => {
                dispatch(removeUser())
            })
    }

    const faqSettingAction = {
        AddFaqSetting: (e) => dispatch(AddFaqSetting(e)),
    }

    const generalActions = {
        addAllGames: (e) => dispatch(addAllGames(e)),
        addAllPromoter: (e) => dispatch(addAllPromoter(e)),
        adminTournament: (e) => dispatch(adminTournament(e)),
        allCompetitions: (e) => dispatch(allCompetitions(e)),
        allPlayersUsers: (e) => dispatch(allPlayersUsers(e)),
        allClubsUsers: (e) => dispatch(allClubsUsers(e)),
        allAdminClubs: (e) => dispatch(allAdminClubs(e))
    }

    const userActions = {
        addAllGameSettings: (e) => dispatch(addAllGameSettings(e)),
        addAllPlayerContacts: (e) => dispatch(addAllPlayerContacts(e)),
        addAllPlayer: (e) => dispatch(addAllPlayer(e)),
        addAllGameSuggestions: (e) => dispatch(addAllGameSuggestions(e)),
        addAllUserTournaments: (e) => dispatch(addAllUserTournaments(e)),
        addUserRanks: (e) => dispatch(addUserRanks(e)),
        addAllTournaments: (e) => dispatch(addAllTournaments(e)),
        addAllTournamentMatches: (e) => dispatch(addAllTournamentMatches(e)),
        addAllSentMatches: (e) => dispatch(addAllSentMatches(e)),
        addAllReceiveMatches: (e) => dispatch(addAllReceiveMatches(e)),
        addAllMatchResults: (e) => dispatch(addAllMatchResults(e)),
        addWalletTransaction: (e) => dispatch(addWalletTransaction(e)),
        addUserProRanks: (e) => dispatch(addUserProRanks(e)),
        addCompetitionRanks: (e) => dispatch(addCompetitionRanks(e)),
        addAllSentCompetitionMatches: (e) => dispatch(addAllSentCompetitionMatches(e)),
        addAllReceiveCompetitionMatches: (e) => dispatch(addAllReceiveCompetitionMatches(e)),
        addAllCompetitionMatches: (e) => dispatch(addAllCompetitionMatches(e)),
        addSubscribedCompetitions: (e) => dispatch(addSubscribedCompetitions(e)),
        addAllCourts: (e) => dispatch(addAllCourts(e)),
        addAllCoupleRequests: (e) => dispatch(addAllCoupleRequests(e)),
        addAllCouples: (e) => dispatch(addAllCouples(e)),
        addAllOpponents: (e) => dispatch(addAllOpponents(e)),
        addAllMembers: (e) => dispatch(addAllMembers(e)),
        addMySubscriptions: (e) => dispatch(addMySubscriptions(e)),
        addMyMatches: (e) => dispatch(addMyMatches(e)),
        addClubInfo: (e) => dispatch(addClubInfo(e)),
        addMyCompetitions: (e) => dispatch(addMyCompetitions(e))
    }

    const pointSettingAction = {
        AddMatchReqSendFee: (e) => dispatch(AddMatchReqSendFee(e)),
        AddSubscriptionFeeDollerTournamnet: (e) => dispatch(AddSubscriptionFeeDollerTournamnet(e)),
        AddDollerTournamnetOrganizer: (e) => dispatch(AddDollerTournamnetOrganizer(e)),
        AddCashbackServiceFee: (e) => dispatch(AddCashbackServiceFee(e)),
        AddCompetitionSubServiceFee: (e) => dispatch(AddCompetitionSubServiceFee(e))

    }



    return (
        <div className='helper-main'>
            <div className='menu-flex'>
                <div>
                    <SideMenu {...props} user={user} isEnglish={isEnglish} MenuNone={MenuNone} />
                </div>
                <div
                    style={{
                        minWidth: '20%',
                        position: 'relative',
                        height: `calc(100vh)`,
                        width: '100%',
                        paddingBottom: '50px',
                        marginLeft: mobile ? 0 : window.innerWidth <= 700 ? 0 : 251
                    }}
                >
                    <div style={{
                        background: 'green',
                        height: '62px',
                    }}>
                        <HeaderMenuLayout MenuNone={MenuNone} user={user} />
                    </div>
                    <div
                        className='sidebar-scroll-class-black'
                        style={{
                            height: `calc(100vh - 68px)`,
                            maxHeight: `calc(100vh - 68px)`,
                            overflow: 'scroll',
                            marginBottom: '10px',
                            marginTop: '4px'
                        }}>
                        <Component
                            {...props}
                            user={user}
                            getUser={getUser}
                            MenuNone={MenuNone}
                            isEnglish={isEnglish}
                            userActions={userActions}
                            generalActions={generalActions}
                            selectedLanguage={user?.userType === 'player' ? selectedLanguageUser : selectedLanguage}
                            faqSettingAction={faqSettingAction}
                            pointsSettingData={pointsSettingData}
                            pointSettingAction={pointSettingAction}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export { MenuLayout }