import { Button, Input, Popconfirm, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import AdminAddGame from './AddGame'
import { ADMIN } from '../../../utils/apis'
import { convertTitle, errorMessage, getGeneralData, successMessage } from '../../../utils/helpers'
import axios from 'axios'
import { useSelector } from 'react-redux'

const AdminGame = (props) => {
    const { selectedLanguage, isEnglish ,generalActions} = props
    const games = useSelector(state => state.generalReducer.games)
    const [game, setGame] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [edit, setEdit] = useState({})

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    const deleteGame = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = { _id: id }
        axios.post(ADMIN.DELETE_GAME, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getGeneralData(generalActions)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }


    const columns = [
        {
            title: selectedLanguage?.title_ID,
            dataIndex: '_id',
            key: 'id',
            render: (e) => {
                let Number = e.substr(e.length - 5)
                return (
                    <p className='p_button_style'>{Number}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Name,
            dataIndex: 'name',
            key: 'name',
            render: (e) => {
                return (
                    <p>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_gameType,
            dataIndex: 'gameType',
            key: 'gameType',
            render: (e) => {
                return (
                    <p>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Description,
            dataIndex: 'description',
            key: 'description',
            render: (e) => {
                return (
                    <p>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_PlayingTimeAvgmin,
            dataIndex: 'playingTime',
            key: 'playingTime',
            render: (e) => {
                return (
                    <p style={{ width: '200px' }}>{e}{' '}{selectedLanguage?.Text_Min}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_StartingPoint,
            render: (e) => {
                return (
                    <p
                        onClick={() => {
                            setEdit(e)
                            setGame(true)
                        }}
                        className='p-input-style'> {e?.startingPoint}</p >
                )
            }
        },
        {
            title: '',
            render: (e) => {
                return (
                    <span style={{ display: 'flex', }}>
                        <Button
                            onClick={() => {
                                setEdit(e)
                                setGame(true)
                            }}
                            className='addproduct-text '
                            style={{ height: '30px', width: '90px', marginRight: '2px' }}
                        >{selectedLanguage?.buttonText_Edit}</Button>
                        <Popconfirm
                            title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.name} `)} ${selectedLanguage?.textGame} ?`}
                            okText={selectedLanguage?.textDelete}
                            cancelText={selectedLanguage?.textCancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => {
                                deleteGame(e?._id)
                            }}
                        >
                            <Button
                                loading={deleteLoading[e?._id]}
                                className='addproduct-text '
                                style={{ height: '30px', width: '90px', marginLeft: '2px' }}
                            >{selectedLanguage?.buttonText_Delete}</Button>
                        </Popconfirm>
                    </span>
                )
            }
        },
    ]

    const goBack = () => {
        setGame(false)
        setEdit({})
    }


    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px', }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {game && <span
                        onClick={() => {
                            goBack()
                        }}
                        style={{ marginTop: '2px', cursor: 'pointer' }}>
                        <IoIosArrowBack size={28} />
                    </span>}
                    <p className='admin-home-title'>{game ?
                        edit?._id ? selectedLanguage?.text_EditGame : selectedLanguage?.title_AddGame
                        : selectedLanguage?.title_GameSetting}</p>
                </div>
                <div>
                    {game ?
                        <AdminAddGame isHide={true} edit={edit} {...props} />
                        :
                        <>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button
                                    className='addproduct-text '
                                    style={{ height: '40px', width: '130px', marginTop: '10px' }}
                                    onClick={() => {
                                        setGame(true)
                                    }}
                                >
                                    {selectedLanguage?.buttonText_addGame}
                                </button>
                            </div>
                            <div style={{ marginTop: '20px' }} >
                                <Table
                                    dataSource={games || []}
                                    columns={columns}
                                    pagination={false}
                                    scroll={{ x: 800 }}
                                />
                            </div>
                        </>}
                </div>

            </div>
        </div>
    )
}

export default AdminGame