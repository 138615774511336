import { CheckCircleFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Image, Popconfirm, Select, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN, GET, POST } from '../../../utils/apis'
import { convertTitle, errorMessage, getGeneralData, successNotification } from '../../../utils/helpers'
import { AiFillDelete } from 'react-icons/ai'

const { Option } = Select


const AdminCourts = (props) => {
    const { user, selectedLanguage, generalActions, isEnglish } = props
    const allClubs = useSelector(state => state.generalReducer.allClubsUsers)
    const [loading, setLoading] = useState(false)
    const [approveloading, setApproveloading] = useState({})
    const [deleteloading, setDeleteLoading] = useState({})
    const [allCourts, setallcourts] = useState([])
    const [selectedClub, setSelectedclub] = useState(allClubs?.[0]?._id || null)

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    const getProducts = () => {
        setLoading(true)
        axios.get(`${GET?.GET_COURTS}/${selectedClub}`)
            .then((res) => {
                const { data } = res
                setallcourts(data?.data || [])
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }



    useEffect(() => {
        getProducts()
    }, [selectedClub])



    const approveProduct = (_id) => {
        setApproveloading({ [_id]: true })
        let obj = {
            _id,
        }
        axios.post(ADMIN?.APPROVE_COURT, obj)
            .then((res) => {
                const { data } = res
                setApproveloading({})
                getProducts()
                if (data?.success) {
                    successNotification(isEnglish ? data?.message : data?.messageNl)
                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setApproveloading({})
                console.log('e', e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    const deleteCourt = (_id) => {
        setDeleteLoading({ [_id]: true })
        let obj = {
            _id,
        }
        axios.post(ADMIN?.DELETE_COURT, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                getProducts()
                if (data?.success) {
                    successNotification(isEnglish ? data?.message : data?.messageNl)
                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                console.log('e', e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    let columns = [
        {
            title: selectedLanguage?.CourtImage,
            key: 'image',
            align: 'center',
            render: (e) =>
                <Image src={e?.imageUrl} style={{ marginRight: 10, width: 'auto', height: 60 }} shape='square' />
        },
        {
            title: selectedLanguage?.CourtName,
            dataIndex: 'name',
            sorter: (a, b) => a?.name?.length - b?.name?.length,
        },
        {
            title: selectedLanguage?.Description,
            dataIndex: 'description',
            sorter: (a, b) => a?.description?.length - b?.description?.length,
        },
        {
            title: selectedLanguage?.Total,
            dataIndex: ['reservation', 'settings'],
            key: 'soldProduct',
            align: 'center',
            sorter: (a, b) => a?.soldProduct - b?.soldProduct,
            render: (e) => e?.reduce((sum, { soldProduct }) => sum + soldProduct, 0) || '-'
        },
        {
            title: selectedLanguage?.label_promote,
            dataIndex: 'promote',
            sorter: (a, b) => a?.promote - b?.promote,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>,
        },
        {
            title: selectedLanguage?.Private,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.privateOne || e?.privateAll ? 'True' : 'False'}</p>,
        },
        {
            title: selectedLanguage?.Created,
            dataIndex: 'created',
            render: (e) => moment(e).format('YYYY-MM-DD')
        },

        {
            title: selectedLanguage?.Status,
            width: 115,
            align: 'center',
            render: (e) => <> {e?.isApproved ? <CheckCircleFilled style={{ color: 'green' }} /> :
                <>{approveloading[e?._id] ? <Spin /> :
                    <Button onClick={() => approveProduct(e?._id)}>{selectedLanguage?.Approve}</Button>
                }</>
            }</>,
            sorter: (a, b) => a?.isApproved - b?.isApproved,
        },
        {
            title: selectedLanguage?.Action,
            width: 115,
            align: 'center',
            render: (e) =>
                <>
                    <Popconfirm
                        title={selectedLanguage?.areYouSureToDeleteCourt}
                        okText={selectedLanguage?.textDelete}
                        cancelText={selectedLanguage?.textCancel}
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => {
                            deleteCourt(e?._id)
                        }}
                    >
                        {deleteloading[e?._id] ? <Spin /> : <AiFillDelete size={20} color='#EC671B' style={{ cursor: 'pointer' }} />}
                    </Popconfirm>
                </>,
        },
    ]
    console.log('allCourts', allCourts)
    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <p className='admin-home-title'>{selectedLanguage?.text_Club_Courts}</p>
                <div className='flex-column menu-main'>
                    <div>
                        <div className='flex-mobile' style={{ display: 'flex', marginTop: '15px' }}>
                            <Select
                                showSearch
                                showArrow
                                allowClear={false}
                                defaultValue={selectedClub}
                                onChange={(e) => setSelectedclub(e)}
                                className='form-input'
                                placeholder={selectedLanguage?.txt_SelectUser}
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {allClubs?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.name)} ${v?.email}`}</Option>)}
                            </Select>
                        </div>

                        <div className='user-table' style={{ marginTop: '20px' }}>
                            <Table
                                columns={columns}
                                dataSource={allCourts}
                                loading={loading}
                                pagination={{ hideOnSinglePage: true }}
                                scroll={{ x: 1000 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminCourts

