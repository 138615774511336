import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { successMessage, errorMessage } from '../../utils/helpers'
import { removeUser } from '../../Redux/actions/authActions'
import { AUTH } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { LoginFooter, LoginHeader } from '../../Components'
import axios from 'axios'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'

const ForgotPassword = (props) => {
    const { history } = props
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState('Search')

    const onFinish = (values) => {
        setLoading(true)
        setBtnText('Searching...')

        values.userType = 'player'

        axios.post(AUTH.FORGOT_PASSWORD, values)
            .then((result) => {
                const { data } = result
                setLoading(false)
                setBtnText('Search')
                if (data.success) {
                    return successMessage(isEnglish ? data.message : data.messageNl)
                }
                else {
                    errorMessage(isEnglish ? data.message : data.messageNl)
                }
            })
            .catch((err) => {
                setLoading(false)
                setBtnText('Search')
                errorMessage(isEnglish ? err.message : err.messageNl)
            })
    }

    useEffect(() => {
        dispatch(removeUser())
    }, [])

    return (
        <div className='forgot-password-main'>
            <LoginHeader />
            <div className='auth-screen-main-div' style={{ marginTop: '20px', }}>
                <p className='heading head-center' style={{ color: 'white' }}>
                    {selectedLanguage?.title_ResetPassword}
                </p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <div className='form-input-single-input'>
                        <Form.Item
                            name='email'
                            label={<p className='my-form-label'>{selectedLanguage?.label_Email}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_PleaseEnterYourEmail,
                                },
                                {
                                    type: 'email',
                                    message: selectedLanguage?.error_EmailIsNotValid,
                                },
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.placeHolder_PleaseEnterYourEmail}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button
                            style={{
                                padding: '0px 20px 0px 20px',
                                background: 'rgba(92, 93, 93, 1)',
                                color: 'white',
                                border: 'none',
                                height: '50px',
                                marginTop: '10px',
                                borderRadius: 6,
                                width: '100%'
                            }}
                            htmlType='submit'
                            loading={loading}
                        >
                            {btnText === 'Search' ? selectedLanguage?.ButtonText_Search : selectedLanguage?.ButtonText_Searching}
                        </Button>
                        <br />
                        <br />
                        <div style={{ textAlign: 'center', marginTop: '20px', color: 'white' }}>
                            {selectedLanguage?.minTitle_AlreadyHaveAnAccount} <Link className='linkStyle' to={allPaths?.LOGIN}>{selectedLanguage?.minTitle_Login}</Link>
                            <br />
                            {selectedLanguage?.minTitle_DontHaveAnAccount} <Link className='linkStyle' to={allPaths?.SIGNUP}>{selectedLanguage?.minTitle_SignUp}</Link>
                        </div>
                    </Form.Item>
                </Form>
            </div>
            <LoginFooter />
        </div>
    )
}

export default ForgotPassword