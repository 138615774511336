import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AddGameSuggestion, AdminAddGame, AdminClaim, AdminCoupons, AdminGame, AdminHome, AdminLogin, AdminProfile, AdminSelectTournaments, CreateMatchRequest, CreatePlayerTournament, CreateTournament, ForgotPassword, GameSuggestion, Home, InviteFriend, Login, MatchRequestScreen, MatchResult, PlayerClaim, PlayerContact, PlayerDetail, PlayerGameSetting, PlayerGameSuggestion, PlayerProRankingPiont, PlayerProfile, PlayerRankingPiont, PlayerTournamentSelect, PointSetting, Promoter, PromotingForm, Signup, TournamentMatchResults, UpdatePassword, VerifyEmail, SubscriptionScreen, PlayerInvoices, AdminAllInvoices, PlayerCashback, AdminCashback, BtwInvoice, AddFaq, AdminFaq, MainHome, WalletTransactions, CreateCompetitionMatchRequest, CompetitionSelect, CreateCompetition, CompetetionMatches, AdminCompetitionSelect, CompetitionMatchesResult, PlayerCompetitionRank, CompetitionSubInvoives, AdminCompetitionSubInvoices, ClubSignup, Products, AdminClubs, AdminPlayers, PlayerPrivateCourt, AdminCourts, RegisteredClubs, AdminReview, ClubCourtReview, PlayerCouple, Members, CompetitionRequest, SubscribedCompetition, Competitionstatus, ClubInfo, CompetitionMatches } from '../Screens'
import { CompetitionResult, Faq, MenuLayout, SideMenuFaq, TournamentDetail } from '../Components'
import allPaths from './paths'
import { Result, Button } from 'antd'
import { getFaqHelper } from '../utils/helpers'
import { AddFaqSetting } from '../Redux/actions/faqSettingActions'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
const Page404 = (props) => {
    const { history } = props
    return (
        <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            extra={<Button
                type='primary'
                className='form-button'
                onClick={() => history.push('/')}
            >Back to home</Button>}
        />
    )
}

const Routes = () => {
    const dispatch = useDispatch()
    const faqSettingAction = {
        AddFaqSetting: (e) => dispatch(AddFaqSetting(e)),
    }

    useEffect(() => {
        getFaqHelper(faqSettingAction)
    }, [])

    return (
        <Router>
            <Switch>
                <MenuLayout path={allPaths?.BU_PRODUCT} exact component={Products} />
                <Route path={allPaths?.MAIN_HOME} exact component={MainHome} />
                <Route path={allPaths?.LOGIN} exact component={Login} />
                <Route path={allPaths?.BU_LOGIN} exact component={Login} />
                <Route path={allPaths?.SIGNUP} exact component={Signup} />
                <Route path={allPaths?.BU_SIGNUP} exact component={ClubSignup} />
                <Route path={allPaths?.FORGOT_PASSOWRD} exact component={ForgotPassword} />
                <Route path={allPaths?.ADMIN_LOGIN} exact component={AdminLogin} />
                <Route path={allPaths?.FAQ} exact component={Faq} />
                <Route path={`${allPaths?.VERIFY_EMAIL}/:token`} exact component={VerifyEmail} />
                <Route path={`${allPaths?.UPDATE_PASSWORD}/:token`} exact component={UpdatePassword} />
                <Route path={`${allPaths?.PROMOTING_FORM}`} exact component={PromotingForm} />

                {/* both */}
                <MenuLayout path={allPaths?.SIDE_MENU_FAQ} exact component={SideMenuFaq} />
                <MenuLayout path={allPaths?.TOURNAMENT_PARTICIPATION} exact component={TournamentDetail} />
                <MenuLayout path={allPaths?.COMPETITION_RESULT} exact component={CompetitionResult} />
                {/* Admin Routes */}
                <MenuLayout path={allPaths?.HOME} exact component={Home} />
                <MenuLayout path={allPaths?.ADMIN_PROFILE} exact component={AdminProfile} />
                <MenuLayout path={allPaths?.ADMIN_CREATE_GAME} exact component={AdminAddGame} />
                <MenuLayout path={allPaths?.ADMIN_TOURNAMENT_SELECT} exact component={AdminSelectTournaments} />
                <MenuLayout path={allPaths?.ADMIN_GAMES} exact component={AdminGame} />
                <MenuLayout path={allPaths?.ADMIN_CREATE_TOURNAMENT} exact component={CreateTournament} />
                <MenuLayout path={allPaths?.ADMIN_CREATE_COMPETITION} exact component={CreateCompetition} />
                <MenuLayout path={allPaths?.ADMIN_CLAIM_ARGUES} exact component={AdminClaim} />
                <MenuLayout path={allPaths?.ADMIN_GAME_SUGGESTION} exact component={GameSuggestion} />
                <MenuLayout path={allPaths?.PLAYER_CREATE_SUGGESTION} exact component={PlayerGameSuggestion} />
                <MenuLayout path={allPaths?.ADMIN_PROMOTER_SETTINGS} exact component={Promoter} />
                <MenuLayout path={allPaths?.ADMIN_POINT_SETTINGS} exact component={PointSetting} />
                <MenuLayout path={allPaths?.ADMIN_PLAYER_DETAIL} exact component={PlayerDetail} />
                <MenuLayout path={allPaths?.ADMIN_COUPONS} exact component={AdminCoupons} />
                <MenuLayout path={allPaths?.ADMIN_PLAYER_INVOICES} exact component={AdminAllInvoices} />
                <MenuLayout path={allPaths?.ADMIN_CASHBACK} exact component={AdminCashback} />
                <MenuLayout path={allPaths?.ADMIN_BTW_INVOICES} exact component={BtwInvoice} />
                <MenuLayout path={allPaths?.ADMIN_ADD_FAQ} exact component={AddFaq} />
                <MenuLayout path={allPaths?.ADMIN__FAQ} exact component={AdminFaq} />
                <MenuLayout path={allPaths?.ADMIN_COMPETITION_SELECT} exact component={AdminCompetitionSelect} />
                <MenuLayout path={allPaths?.ADMIN_COMPETITION_SUB_INVOICES} exact component={AdminCompetitionSubInvoices} />
                <MenuLayout path={allPaths?.REGISTERED_CLUBS} exact component={RegisteredClubs} />
                <MenuLayout path={allPaths?.ADMIN_CLUBS} exact component={AdminClubs} />
                <MenuLayout path={allPaths?.ADMIN_PLAYER} exact component={AdminPlayers} />
                <MenuLayout path={allPaths?.ADMIN_COURTS} exact component={AdminCourts} />
                <MenuLayout path={allPaths?.ADMIN_COURTS_REVIEW} exact component={AdminReview} />
                <MenuLayout path={allPaths?.ADMIN_COMPETITION_REQUEST} exact component={CompetitionRequest} />
                <MenuLayout path={allPaths?.ADMIN_COMPETITION_Matches} exact component={CompetitionMatches} />
                {/* Player Routes */}
                <MenuLayout path={allPaths?.PLAYER_PROFILE} exact component={PlayerProfile} />
                <MenuLayout path={allPaths?.PLAYER_GAME_SETTING} exact component={PlayerGameSetting} />
                <MenuLayout path={allPaths?.PLAYER_CONTACT} exact component={PlayerContact} />
                <MenuLayout path={allPaths?.PLAYER_CREATE_TOURNAMENT} exact component={CreatePlayerTournament} />
                <MenuLayout path={allPaths?.PLAYER_SELECT_TOURNAMENT} exact component={PlayerTournamentSelect} />
                <MenuLayout path={allPaths?.PLAYER_RANKING_POINT} exact component={PlayerRankingPiont} />
                <MenuLayout path={allPaths?.PLAYER_CREATE_MATCH_REQ} exact component={CreateMatchRequest} />
                <MenuLayout path={allPaths?.PLAYER_MATCH_REQUESTS} exact component={MatchRequestScreen} />
                <MenuLayout path={allPaths?.PLAYER_MATCH_RESULT} exact component={MatchResult} />
                <MenuLayout path={allPaths?.PLAYER_TOURNAMENT_MATCHES} exact component={TournamentMatchResults} />
                <MenuLayout path={allPaths?.PLAYER_CLAIM} exact component={PlayerClaim} />
                <MenuLayout path={allPaths?.PLAYER_INVITE_FRIEND} exact component={InviteFriend} />
                <MenuLayout path={allPaths?.PLAYER__PRO_RANKING_POINT} exact component={PlayerProRankingPiont} />
                <MenuLayout path={allPaths?.PLAYER_SUBSCRIPTION} exact component={SubscriptionScreen} />
                <MenuLayout path={allPaths?.PLAYER_INVOIVE} exact component={PlayerInvoices} />
                <MenuLayout path={allPaths?.PLAYER_CASHBACK} exact component={PlayerCashback} />
                <MenuLayout path={allPaths?.PLAYER_WALLET_TRANSACTION} exact component={WalletTransactions} />
                <MenuLayout path={allPaths?.PLAYER_CREATE_COMPETITION_MATCH} exact component={CreateCompetitionMatchRequest} />
                <MenuLayout path={allPaths?.COMPETITION_SELECT} exact component={CompetitionSelect} />
                <MenuLayout path={allPaths?.COMPETITION_MATCHES} exact component={CompetetionMatches} />
                <MenuLayout path={allPaths?.COMPETITION_MATCHES_RESULTS} exact component={CompetitionMatchesResult} />
                <MenuLayout path={allPaths?.COMPETITION_RANKING_POINT} exact component={PlayerCompetitionRank} />
                <MenuLayout path={allPaths?.COMPETITION_SUB_INVOICE} exact component={CompetitionSubInvoives} />
                <MenuLayout path={allPaths?.PLAYER_PRIVATE_COURTS} exact component={PlayerPrivateCourt} />
                <MenuLayout path={allPaths?.PLAYER_COURT_REVIEW} exact component={ClubCourtReview} />
                <MenuLayout path={allPaths?.PLAYER_COUPLE} exact component={PlayerCouple} />
                <MenuLayout path={allPaths?.MEMBERS} exact component={Members} />
                <MenuLayout path={allPaths?.SUBSCRIBED_COMPETITION} exact component={SubscribedCompetition} />
                <MenuLayout path={allPaths?.COMPETITION_STATUS} exact component={Competitionstatus} />
                <MenuLayout path={allPaths?.CLUB_INFO} exact component={ClubInfo} />

                <Route path='/:page404' exact component={Page404} />
                <Route path='/player/:page404' exact component={Page404} />
                <Route path='/admin/:page404' exact component={Page404} />
            </Switch >
        </Router >
    )
}

export {
    Routes,
    Page404
}