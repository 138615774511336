import React, { useState } from 'react'
import { Button, Form, Input, InputNumber, Select, } from 'antd'
import { errorMessage, getGeneralData, successMessage } from '../../../utils/helpers'
import axios from 'axios'
import { ADMIN } from '../../../utils/apis'


const AdminAddGame = (props) => {

    const { Option } = Select
    const { selectedLanguage, isEnglish, edit, isHide, generalActions } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    let gameTypes = [
        { name: selectedLanguage?.text_OneByOneMatchGame, value: '1-1 Match Game' },
        { name: selectedLanguage?.text_TeamMatchGame, value: 'Team Match Game' },
    ]
    const onFinish = (values) => {
        setLoading(true)
        values._id = edit?._id
        values.name = values.name.trim()
        values.description = values.description.trim()
        values.playingTime = values.playingTime.trim()
        axios.post(edit?._id ? ADMIN.EDIT_GAME : ADMIN.ADD_NEW_GAME, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    getGeneralData(generalActions)
                    successMessage(isEnglish ? data.message : data.messageNl)
                   (!edit?._id ? null : form.resetFields())
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }


    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <div>
                    {isHide ? null :
                        <p className='admin-home-title'>
                            {selectedLanguage?.title_AddNewGame}
                        </p>}
                    <div style={{ marginTop: '20px' }}>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='name'
                                        initialValue={edit?.name}
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.title_Name}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_PleaseInputYourName
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_InputYourNameHere}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='gameType'
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_selectGameType}</p>}
                                        initialValue={edit?.gameType}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_selectGameType
                                            }
                                        ]}
                                    >
                                        <Select
                                            className='form-input-new-style select-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_selectGameType}>
                                            {gameTypes?.length ?
                                                gameTypes?.map((v, i) => {
                                                    return (
                                                        <Option value={v?.value}>{`${v?.name}`}</Option>
                                                    )
                                                })
                                                : null}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='description'
                                        initialValue={edit?.description}
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.title_Description}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_Description
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_Description}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='playingTime'
                                        initialValue={edit?.playingTime}
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.title_Playingtimeavg}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_Playingtimeavg
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_Playingtimeavg}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.Item
                                        name='startingPoint'
                                        initialValue={edit?.startingPoint}
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.title_StartingPoint}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_StartingPoint
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            style={{ width: '100%' }}
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_StartingPoint}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                <Form.Item>
                                    <Button
                                        className='addproduct-text '
                                        style={{ height: '45px', width: '120px', marginTop: '10px' }}
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        {edit?._id ? selectedLanguage?.buttonText_updateGame : selectedLanguage?.buttonText_addGame}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AdminAddGame