import { Button, Popconfirm, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { POST } from '../../../utils/apis'
import { convertTitle, errorMessage, getUserData, successMessage } from '../../../utils/helpers'
import AddContact from './AddContact'
import NoData from '../../../assets/noDataImae.png'


const PlayerContact = (props) => {
    const { selectedLanguage, isEnglish, user, userActions } = props
    const playerContacts = useSelector(state => state?.userReducer?.playerContacts)
    const [visible, setVisible] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})

    useEffect(() => {
        getUserData(user?._id, userActions)
    }, [])

    const deleteConatact = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = { _id: id }
        axios.post(POST.DELETE_CONTACT, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getUserData(user?._id, userActions)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const handleOk = () => {
        getUserData(user?._id, userActions)
        setVisible(false)
    }

    // const columns = [
    //     {
    //         title: selectedLanguage?.label_Email,
    //         dataIndex: 'contactId',
    //         key: 'email',
    //         render: (e) => {
    //             return (
    //                 <p>{e?.email}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_Name,
    //         dataIndex: 'contactId',
    //         key: 'name',
    //         render: (e) => {
    //             return (
    //                 <p>{e?.name}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.label_Country,
    //         dataIndex: 'contactId',
    //         key: 'country',
    //         render: (e) => {
    //             return (
    //                 <p>{e?.country}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_Action,
    //         key: 'action',
    //         render: (e) => {
    //             return (
    //                 <Popconfirm
    //                     title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.contactId?.name} `)} ${selectedLanguage?.text_confirm_Contact} ?`}
    //                     okText={selectedLanguage?.textDelete}
    //                     cancelText={selectedLanguage?.textCancel}
    //                     okButtonProps={{
    //                         type: 'primary',
    //                         className: 'form-button'
    //                     }}
    //                     cancelButtonProps={{ style: { borderRadius: 4 } }}
    //                     onConfirm={() => {
    //                         deleteConatact(e?._id)
    //                     }}
    //                 >
    //                     <Button
    //                         loading={deleteLoading[e?._id]}
    //                         className='addproduct-text' style={{ width: '80px' }}>
    //                         {selectedLanguage?.buttonText_Delete}
    //                     </Button>
    //                 </Popconfirm>
    //             )
    //         }
    //     }
    // ]
    

    return (
        <div className='contact-p-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }}
            >
                <p
                    style={{
                        marginTop: '10px',
                    }}
                    className='admin-home-title-white'>
                    {selectedLanguage?.title_Contacts}</p>
                <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    <Button
                        style={{
                            padding: '0px 20px 0px 20px',
                            background: 'rgba(92, 93, 93, 1)',
                            color: 'white',
                            border: 'none',
                            height: '45px',
                            marginTop: '10px',
                            borderRadius: 6
                        }}
                        onClick={() => setVisible(true)}>{selectedLanguage?.title_AddnewContact}</Button>
                </span>

                {/* <Table
                    dataSource={
                        playerContacts?.sort((a, b) => {
                            if (a?.contactId?.email === undefined || a?.contactId?.email === null) {
                                return 1
                            }
                            if (b?.contactId?.email === undefined || b?.contactId?.email === null) {
                                return -1
                            }
                            return a?.contactId?.email.localeCompare(b?.contactId?.email);
                        })
                    }
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 400 }}
                /> */}
                <div style={{ marginTop: '20px' }}>
                    <div
                        className='sidebar-scroll-class-black'
                        style={{
                            overflow: 'scroll',
                            width: '98%',
                            margin: '0px auto'
                        }}
                    >
                        <div style={{
                            marginTop: '10px',
                            marginBottom: '5px',
                            width: '900px',
                        }}>
                            <div style={{
                                width: '100%',
                                height: '60px',
                                borderTopRightRadius: '4px',
                                borderTopLeftRadius: '4px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '5px',
                                alignItems: 'center',
                            }}>
                                {[
                                    {
                                        title: selectedLanguage?.label_Email,
                                        width: 190
                                    },
                                    {
                                        title: selectedLanguage?.title_Name,
                                        width: 150
                                    },
                                    {
                                        title: selectedLanguage?.label_Country,
                                        width: 100
                                    },
                                    {
                                        title: selectedLanguage?.title_Action,
                                        width: 100
                                    },
                                ]?.map((v, i) => {
                                    return (
                                        <p className='tableValue'
                                            style={{
                                                margin: '0px',
                                                color: 'white',
                                                fontWeight: 'bolder',
                                                fontSize: '20px',
                                                width: v?.width,
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                            }}
                                        >{v?.title}</p>
                                    )
                                })}
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    borderBottomRightRadius: '4px',
                                    borderBottomLeftRadius: '4px',
                                    justifyContent: 'space-between',
                                    padding: '5px',
                                    alignItems: 'center',
                                }}>
                                {
                                    playerContacts?.sort((a, b) => {
                                        if (a?.contactId?.email === undefined || a?.contactId?.email === null) {
                                            return 1
                                        }
                                        if (b?.contactId?.email === undefined || b?.contactId?.email === null) {
                                            return -1
                                        }
                                        return a?.contactId?.email.localeCompare(b?.contactId?.email);
                                    })?.length ?
                                        playerContacts?.sort((a, b) => {
                                            if (a?.contactId?.email === undefined || a?.contactId?.email === null) {
                                                return 1
                                            }
                                            if (b?.contactId?.email === undefined || b?.contactId?.email === null) {
                                                return -1
                                            }
                                            return a?.contactId?.email.localeCompare(b?.contactId?.email);
                                        })?.map((v, i) => {
                                            return (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '50px',
                                                        borderTopRightRadius: '4px',
                                                        borderTopLeftRadius: '4px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        padding: '5px',
                                                        alignItems: 'center',
                                                        margin: '0px',
                                                        padding: '0px'
                                                    }}>
                                                    <p className='tableValue table-p'
                                                        style={{ width: '190px' }}>
                                                        {v?.contactId?.email}
                                                    </p>
                                                    <p className='tableValue table-p'
                                                        style={{ width: '150px' }}>
                                                        {v?.contactId?.name}
                                                    </p>
                                                    <p className='tableValue table-p'
                                                        style={{ width: '100px' }}>
                                                        {v?.contactId?.country}
                                                    </p>
                                                    <p className='tableValue table-p'
                                                        style={{ width: '100px' }}>
                                                        <Popconfirm
                                                            title={`${selectedLanguage?.textDelete} ${convertTitle(`${v?.contactId?.name} `)} ${selectedLanguage?.text_confirm_Contact} ?`}
                                                            okText={selectedLanguage?.textDelete}
                                                            cancelText={selectedLanguage?.textCancel}
                                                            okButtonProps={{
                                                                type: 'primary',
                                                                className: 'form-button'
                                                            }}
                                                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                            onConfirm={() => {
                                                                deleteConatact(v?._id)
                                                            }}
                                                        >
                                                            <Button
                                                                loading={deleteLoading[v?._id]}
                                                                style={{
                                                                    padding: '0px 20px 0px 20px',
                                                                    background: 'rgba(92, 93, 93, 1)',
                                                                    color: 'white',
                                                                    border: 'none',
                                                                    height: '45px',
                                                                    marginTop: '10px',
                                                                    borderRadius: 6
                                                                }}>
                                                                {selectedLanguage?.buttonText_Delete}
                                                            </Button>
                                                        </Popconfirm>
                                                    </p>
                                                </div>
                                            )
                                        })
                                        :
                                        <div style={{ height: '160px', textAlign: 'center' }}>
                                            <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {
                visible &&
                <AddContact playerContact={playerContacts} visible={visible} handleOk={handleOk} {...props} />

            }
        </div>
    )
}

export default PlayerContact