import React, { useEffect, useState } from 'react'
import NoData from '../../../assets/noDataImae.png'
import { Button, Image, Input, InputNumber, Modal, Select } from 'antd'
import { BTW_TEX, convertRoundNumber, convertRoundNumber4, errorMessage, getCoupleObj, getGeneralData, getUserData, infoMessage, isWithin7Days, successMessage } from "../../../utils/helpers"
import { useSelector } from 'react-redux'
import axios from 'axios'
import { POST } from '../../../utils/apis'
import moment from 'moment'
import { GrContactInfo } from 'react-icons/gr'
import { useHistory } from 'react-router-dom'
import { allPaths } from '../../../utils/constants'

const CompetitionSelect = (props) => {
    const { selectedLanguage, user, generalActions, isEnglish, getUser, userActions } = props
    const { Option } = Select
    const myCompetitions = useSelector(state => state.userReducer.myCompetitions)
    const AdminServiceFee = useSelector(state => state?.pointSettingReducer)
    const mySubscriptions = useSelector(state => state.userReducer.mySubscriptions)
    const allCouples = useSelector(state => state?.userReducer?.allCouples)
    const history = useHistory()
    const tournamentServiceFee = Number(AdminServiceFee?.competitionSubServiceFee) || 0
    const tournamentServiceBTW = convertRoundNumber((tournamentServiceFee * BTW_TEX))
    const tournamentServiceFeeWithBTW = convertRoundNumber(tournamentServiceFee + (tournamentServiceFee * BTW_TEX))
    const [loading, setLoading] = useState({})
    const [competitionData, setCompetitionData] = useState({})
    const [coupouncode, setCoupounCode] = useState('')
    const [couple, setCouple] = useState('')
    const [showInput, setShowInput] = useState(false)
    const [selectedCouple, setSelectedCouple] = useState({})
    const [myRanking, setMyRanking] = useState(null)
    const [partnerRanking, setPartnerRanking] = useState(null)
    const [avgRanking, setAvgRanking] = useState(null)

    useEffect(() => {
        getUserData(user?._id, userActions)
    }, [])

    useEffect(() => {
        const obj = getCoupleObj(allCouples, couple)
        setSelectedCouple(obj)
        setMyRanking(obj?.userId?.actualRanking)
        setPartnerRanking(obj?.partnerId?.actualRanking)
    }, [couple])

    useEffect(() => {
        let sum = (myRanking + partnerRanking)
        let avg = sum / 2
        setAvgRanking((avg).toFixed(4))
    }, [myRanking, partnerRanking])

    // console.log('myCompetitions', myCompetitions)
    // console.log('couple', couple)
    // console.log('competitionData', competitionData)
    // console.log('allCouples', allCouples)
    function handlePrivateCodeChange(event) {
        const { value } = event.target;
        const trimmedValue = value.trim()
        setCoupounCode(trimmedValue);
    }
    const handleCancelSubscribeModal = () => {
        setCompetitionData({})
        resetCouple()

    }

    const resetCouple = () => {
        setAvgRanking(null)
        setPartnerRanking(null)
        setMyRanking(null)
        setSelectedCouple({})
        setCouple('')
    }
    const handleOKSubscribeModal = () => {
        showInput ?
            !coupouncode ?
                errorMessage(selectedLanguage?.text__PleaseEnterCouponcode) :
                CompetitionSubscription(competitionData) :
            CompetitionSubscription(competitionData)
    }

    const CompetitionSubscription = (values) => {
        if (values?.playerNumbers === 4 && !myRanking || myRanking === 0) {
            return errorMessage(selectedLanguage?.txt_pleaseEnterYourRankingFirst)
        }
        if (values?.playerNumbers === 4 && !partnerRanking || partnerRanking === 0) {
            return errorMessage(selectedLanguage?.txt_pleaseEnterYourParRankingFirst)
        }

        setCompetitionData({})
        setLoading({ [values?._id]: true })
        if (user?.walletBalance > values?.participationMoney) {
            let obj = {
                competitionId: values?._id,
                userId: user?._id,
                avgRanking: (values?.playerNumbers === 2 ? user?.actualRanking : convertRoundNumber4(avgRanking))
            }
            showInput && (obj.couponCode = coupouncode)
            values?.playerNumbers === 4 && (obj.coupleId = couple)
            values?.playerNumbers === 4 && (obj.captianRanking = +myRanking)
            values?.playerNumbers === 4 && (obj.partnerRanking = +partnerRanking)

            axios.post(POST?.COMPETITION_SUBSCRIPTION, obj)
                .then((res) => {
                    const { data } = res
                    setLoading({})
                    if (data?.success) {
                        successMessage(isEnglish ? data?.message : data?.messageNl)
                        getUserData(user?._id, userActions)
                        getGeneralData(generalActions)
                        getUser()
                        resetCouple()
                    }
                    else {
                        errorMessage(isEnglish ? data?.message : data?.messageNl)
                        resetCouple()
                    }
                })
                .catch((e) => {
                    resetCouple()
                    setLoading({})
                    errorMessage(isEnglish ? e?.message : e?.messageNl)
                })
        }
        else {
            errorMessage(selectedLanguage?.text_walletBalanceIsZeroOrLessThenParticipationFee)
            setLoading({})
        }
    }

    useEffect(() => {
        getUser()
        getGeneralData(generalActions)
    }, [])

    return (
        <div className='match-req-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
            >

                <p className='admin-home-title-white'>
                    {selectedLanguage?.text_CompetitionSelect}
                </p>

                <div
                    className='sidebar-scroll-class-black'
                    style={{
                        overflow: 'scroll',
                        width: '98%',
                        margin: '0px auto'
                    }}
                >
                    <div style={{
                        marginTop: '10px',
                        marginBottom: '5px',
                        width: '1500px',
                    }}>
                        <div style={{
                            width: '100%',
                            height: '60px',
                            borderTopRightRadius: '4px',
                            borderTopLeftRadius: '4px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '5px',
                            alignItems: 'center',
                        }}>
                            {[
                                { title: selectedLanguage?.text_Image },
                                { title: selectedLanguage?.text_Name },
                                { title: selectedLanguage?.text_StartDate },
                                { title: selectedLanguage?.text_EndDate },
                                { title: selectedLanguage?.text_Level },
                                { title: selectedLanguage?.text_NumOfPlayers },
                                { title: selectedLanguage?.text_MaximumMatches },
                                { title: selectedLanguage?.title_Fee },
                                { title: selectedLanguage?.text_Result },
                                { title: selectedLanguage?.title_Action },
                            ]?.map((v, i) => {
                                return (
                                    <p className='tableValue'
                                        style={{
                                            margin: '0px',
                                            color: 'white',
                                            fontWeight: 'bolder',
                                            fontSize: '20px',
                                            width: '250px',
                                            marginLeft: '10px',
                                            marginRight: '10px'
                                        }}
                                    >{v?.title}</p>
                                )
                            })}
                        </div>
                        <div
                            style={{
                                width: '100%',
                                borderBottomRightRadius: '4px',
                                borderBottomLeftRadius: '4px',
                                justifyContent: 'space-between',
                                padding: '5px',
                                alignItems: 'center',
                            }}>
                            {myCompetitions?.length ?
                                myCompetitions?.map((v, i) => {
                                    // let isSubscribed = v?.competitionSubscribers?.some(item => item.userId?._id === user?._id)
                                    let isSubscribed = mySubscriptions.some(item => item?.userId === user?._id && v?._id === item?.competitionId)
                                    const isStarted = isWithin7Days(v?.competitionDuration?.date2)
                                    return (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '50px',
                                                borderTopRightRadius: '4px',
                                                borderTopLeftRadius: '4px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                padding: '5px',
                                                alignItems: 'center',
                                                margin: '0px',
                                                padding: '0px',
                                                marginTop: '10px'
                                            }}>
                                            <p className='tableValue table-p'>
                                                <Image src={v?.imageObj?.url} style={{ marginRight: 10, width: 86, height: 50 }} />
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.competitionName}
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.competitionDuration?.date1}
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.competitionDuration?.date2}
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.level}
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.playerNumbers}
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.maximumMatches}
                                            </p>
                                            <p className='tableValue table-p'>
                                                € {v?.participationMoney}
                                            </p>
                                            <p className='tableValue table-p'>
                                                <GrContactInfo
                                                    color='white'
                                                    size={30}
                                                    style={{
                                                        background: 'white',
                                                        borderRadius: '100px',
                                                        padding: '4px',
                                                        cursor: 'pointer'
                                                    }}

                                                    onClick={() => {
                                                        v?.isCompleted ?
                                                            history?.push({ pathname: allPaths?.COMPETITION_RESULT, state: v }) :
                                                            errorMessage(selectedLanguage?.text_competitionIsNotCompeletedYet)
                                                    }} />
                                            </p>
                                            <p className='tableValue table-p'>
                                                {isSubscribed || isStarted ?
                                                    <button
                                                        style={{
                                                            height: '35px',
                                                            width: '140px',
                                                            marginTop: '10px',
                                                            background: '#ec671b6e',
                                                            border: 'none',
                                                            cursor: 'not-allowed'
                                                        }}
                                                    >
                                                        {isSubscribed ? selectedLanguage?.text_subscribed : selectedLanguage?.txt_AlreadyStarted}
                                                    </button>
                                                    :
                                                    v?.maximumPlayer === v?.competitionSubscribers ?
                                                        <p style={{
                                                            background: '#00aeff',
                                                            padding: '3px 4px',
                                                            color: 'white',
                                                            borderRadius: '4px',
                                                            width: '100px',
                                                            textAlign: 'center'
                                                        }}>{selectedLanguage?.buttonText_Fullybocked}</p>
                                                        : <Button
                                                            className='addproduct-text '
                                                            style={{ height: '35px', width: 140, marginTop: 10 }}
                                                            loading={loading[v?._id]}
                                                            onClick={() => { setCompetitionData(v) }}
                                                        >
                                                            {selectedLanguage?.btn_Subscribe}
                                                        </Button>}
                                            </p>
                                        </div>
                                    )
                                })
                                :
                                <div style={{ height: '160px', textAlign: 'center' }}>
                                    <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
            {/* confirm subscribe */}
            <Modal
                open={competitionData?._id}
                footer={null}
                className='pop-info-modal'
                style={{ top: '10px' }}
            >
                <div>
                    <p className='p-question'>{selectedLanguage?.txt_AreYouSureYouWanttoSubscribeCompetition}</p>
                    <p className='p-opt'>{selectedLanguage?.text_IfYouSubscribePointsWillBeDeductFromYourAccount}</p>
                    <p className='p-opt'>{selectedLanguage?.text_Fee}:€ {`${competitionData?.participationMoney}`}</p>
                    <p className='p-opt'>{selectedLanguage?.text_AdminserviceFee}: € {tournamentServiceFee || 0}</p>
                    <p className='p-opt'>{selectedLanguage?.text_tmntyoneBTW}:€ {tournamentServiceBTW}</p>
                    <p className='p-opt'>{selectedLanguage?.text_AdminserviceFeeBTW}:€ {tournamentServiceFeeWithBTW}</p>
                    <p className='p-opt'>{selectedLanguage?.text_TotalinclBTW}:€ {(competitionData?.participationMoney) + (+tournamentServiceFeeWithBTW)}</p>
                </div>

                {competitionData?.playerNumbers === 4 &&
                    <div>
                        <p style={{
                            fontWeight: 'bold',
                            margin: '0px',
                            marginTop: '10px',
                            color: '#ec671b',
                            cursor: 'pointer'
                        }}>{selectedLanguage?.txt_SelectPartnerCouple} </p>
                        <Select
                            placeholder={selectedLanguage?.txt_SelectCouplehere}
                            className='form-input-new-style '
                            onChange={(e) => { setCouple(e) }}
                            allowClear
                            style={{ width: '100%', marginBottom: 20, marginTop: 5 }}
                        >{allCouples?.length && allCouples?.filter((v) => v?.userId?._id === user?._id)?.map((v, i) => {
                            return (
                                <Option value={v?._id}>{v?.coupleName}</Option>
                            )
                        })
                            }</Select>
                    </div>}
                {selectedCouple?._id ?
                    <div >
                        <div >
                            <p>{`${selectedLanguage?.TheSelectedCoupleis} "${selectedCouple?.coupleName}".${selectedLanguage?.txt_Youandyoupartner} "${selectedCouple?.partnerId?.name || null}"  are couple`}</p>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <p className='my-form-label' style={{ color: '#ec671b' }}>{selectedLanguage?.txt_YourRanking}</p>
                                    <InputNumber
                                        precision={4}
                                        step={0.0001}
                                        value={myRanking}
                                        onChange={(e) => setMyRanking(e)}
                                        style={{ width: '100%' }}
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.txt_PleaseEnterYourRanking}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <p className='my-form-label' style={{ color: '#ec671b' }}>{selectedLanguage?.txt_YourPartnerRanking}</p>
                                    <InputNumber
                                        precision={4}
                                        step={0.0001}
                                        value={partnerRanking}
                                        onChange={(e) => setPartnerRanking(e)}
                                        style={{ width: '100%' }}
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.txt_pleaseYourPartnerRanking}
                                    />
                                </div>
                            </div>
                            <p>{`${selectedLanguage?.txt_TheAvgRankingOfCouple} "${avgRanking || 0}"`}</p>
                        </div>
                    </div>
                    : null}

                {!showInput ?
                    <p
                        onClick={() => {
                            setShowInput(true)
                        }}
                        style={{
                            fontWeight: 'bold',
                            margin: '0px',
                            marginTop: '10px',
                            color: '#ec671b',
                            cursor: 'pointer'
                        }}>{selectedLanguage?.txt_DoYouHaveACouponCode}</p>
                    : <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    margin: '0px',
                                    marginTop: '10px'
                                }}
                            >{selectedLanguage?.text_Entercouponcode}</p>
                            <p
                                onClick={() => {
                                    setShowInput(false)
                                }}
                                style={{
                                    fontWeight: 'bold',
                                    margin: '0px',
                                    marginTop: '10px',
                                    color: '#ec671b',
                                    cursor: 'pointer'
                                }}
                            >{selectedLanguage?.txt_Donhavecoupon}</p>
                        </div>
                        <Input
                            value={coupouncode}
                            onChange={handlePrivateCodeChange}
                            className='form-input-new-style'
                            placeholder={'###-###-###'}
                        />
                    </div>}

                <div style={{ display: 'flex', marginTop: 10 }}>
                    <button style={{
                        width: '50%',
                        textAlign: ' center',
                        border: 'none',
                        backgroundColor: ' white',
                        color: '#ec671b',
                        height: '40px',
                        border: '1px solid #ec671b',
                        cursor: 'pointer'
                    }} onClick={handleCancelSubscribeModal} >{selectedLanguage?.text_Cancel}</button>
                    <button className='confirm-btn' onClick={handleOKSubscribeModal} >{selectedLanguage?.btn_Subscribe}</button>
                </div>
            </Modal >
        </div >
    )
}

export default CompetitionSelect