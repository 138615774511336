import React, { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Upload } from 'antd'
import { convertTitle, errorMessage, getTournamentMatchData, successMessage, warningMessage } from '../../../utils/helpers'
import { POST } from '../../../utils/apis'
import axios from 'axios'

const ClaimMatchModal = (props) => {
    const { selectedLanguage, isEnglish, onCancel, user, data, visible, handleOk, userActions } = props
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    console.log('data', data)

    const onFinish = (values) => {

        values.file = fileList
        values.userId = user?._id
        // values.winner = data?.playerNumbers === 2 ? (user?._id !== data?.senderId?._id ? [data?.opponents?.[0]?._id] : [data?.senderId?._id]) : ((user?._id !== data?.senderId?._id || user?._id !== data?.partner?._id) ? data?.opponents?.map((v) => v?._id) : [data?.senderId?._id, data?.partner?._id])
        values.winner = data?.playerNumbers === 2 ? (user?._id === data?.senderId?._id ? [data?.senderId?._id] : [data?.opponents?.[0]?._id]) : ((user?._id === data?.senderId?._id || user?._id === data?.partner?._id) ? [data?.senderId?._id, data?.partner?._id] : data?.opponents?.map((v) => v?._id))

        if (!values._id && !values?.file?.length) {
            return warningMessage(selectedLanguage?.PleaseUploadImage)
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        formData.append('_id', data?._id)
        formData.append('userId', values?.userId)
        formData.append('score', values?.score)
        formData.append('winner', JSON.stringify(values?.winner))
        axios.post(POST.SUBMIT_WIN_PROOF, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(data?.message)
                    getTournamentMatchData(user?._id, userActions)
                    onCancel()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
                console.log('e', e)
            })
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <Modal
            open={visible}
            onOk={handleOk}
            onCancel={handleOk}
            footer={null}>
            <div style={{ marginTop: 10 }}>
                <span style={{ textAlign: 'center' }}>
                    <p className='admin-home-title' style={{ fontSize: '18px' }}>{
                        selectedLanguage?.title_AddProveOfMatchThatYouWin
                    }</p>
                </span>
            </div>
            <div className='border-line' />
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: 15 }}>{selectedLanguage?.text_GameDetail}</p>
                <p className='p-none-margin'><p className='p-none-margin-bolder'>{'Game Type'}</p>: {convertTitle(data?.gameType)}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{'Number of Players'} </p>: {data?.playerNumbers}</p>
            </div>
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: 15 }}>{selectedLanguage?.text_MatchDetail}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.text_MatchDateTime} </p>: {data?.matchDate} | {data?.matchTime}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.text_MessagefromSender} </p>: {data?.message}</p>
            </div>
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: 15 }}>{'Team A'}</p>
                {
                    data?.playerNumbers === 4 ?
                        <>
                            <p className='p-none-margin'> <p className='p-none-margin-bolder'>{'Couple Name'} </p>: {data?.couple?.coupleName} </p>
                            <p className='p-none-margin'> <p className='p-none-margin-bolder'>{'Players'} </p>: {data?.senderId?.email} & {data?.partner?.email}</p>
                        </>
                        :
                        <p className='p-none-margin'> <p className='p-none-margin-bolder'>{'Player'} </p>: {data?.senderId?.email}</p>
                }
            </div>
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: 15 }}>{'Team B'}</p>
                {
                    data?.playerNumbers === 4 ?
                        <>
                            <p className='p-none-margin'> <p className='p-none-margin-bolder'>{'Couple Name'} </p>: {data?.opponentCouple?.coupleName} </p>
                            <p className='p-none-margin'> <p className='p-none-margin-bolder'>{'Players'} </p>: {data?.opponents?.[0]?.email} & {data?.opponents?.[1]?.email}</p>
                        </>
                        :
                        <p className='p-none-margin'> <p className='p-none-margin-bolder'>{'Players'} </p>: {data?.opponents?.[0]?.email}</p>
                }
            </div>
            <div className='border-line' />
            <div style={{ marginTop: 20 }}>
                <span style={{ textAlign: 'center' }}>
                    <p className='admin-home-title' style={{ color: '#ec671b', fontSize: '18px' }}>{
                        selectedLanguage?.text_AddProveofwinning
                    }</p>
                </span>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                        <Form.Item
                            name='file'
                            label={selectedLanguage?.text_winningproof}
                        >
                            <Upload
                                name='file'
                                multiple={false}
                                beforeUpload={() => false}
                                accept='image/*'
                                onChange={normFile}
                                fileList={fileList}
                            >
                                <Button icon={<UploadOutlined />}>{selectedLanguage?.text_clicktouploadproof}</Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                            <Form.Item
                                name='score'
                                label={<p className='my-form-label' style={{ color: '#ec671b' }}>{'Score'}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter score here...'
                                    },
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={'Enter Score here"eg=6-6"'}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                        <Form.Item>
                            <Button
                                className='addproduct-text'
                                style={{ height: '45px', width: 180, marginTop: 10 }}
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.text_SendtoAdmin}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}

export default ClaimMatchModal