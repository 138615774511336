import { Button, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { convertRoundNumber, convertRoundNumber4, errorMessage, successMessage } from "../../../utils/helpers";
import axios from "axios";
import { ADMIN } from "../../../utils/apis";

const UserRankingModal = (props) => {
    const { user, onCancel, selectedLanguage, data, isEnglish, getData } = props
    const [captianRanking, setCaptianRanking] = useState(null)
    const [loading, setLoading] = useState(false)
    let oldCaptionRank = data?.userId?.actualRanking
    let isNothingChanged = (oldCaptionRank === captianRanking)
    console.log(data, '1313')

    useEffect(() => {
        setCaptianRanking(data?.userId?.actualRanking)
    }, [])


    let updatePoint = () => {
        let obj = {
            _id: data?._id,
            userId: data?.userId?._id,
            userRanking: +captianRanking,
        }
        setLoading(true)
        console.log(obj, '=obj')
        axios.post(ADMIN?.UPDATE_COMPETETION_SUB_USER_RANK, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getData(data?.data?.competitionId?._id)
                    onCancel()
                    setLoading(false)
                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                    setLoading(false)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    return (
        <Modal
            open={true}
            onCancel={onCancel}
            footer={null}
            className='pop-info-modal'
            style={{ top: '10px' }}
        >
            <div>
                <p className='p-question'>{selectedLanguage?.txt_User_Ranking_Setting}</p>
                <p className='p-opt'>{selectedLanguage?.txt_updateRankingOfThePlayer}</p>
                <div style={{ marginTop: '15px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ width: '100%' }}>
                            <p className='my-form-label' style={{ color: '#ec671b' }}>{selectedLanguage?.txt_UserActualRanking}</p>
                            <InputNumber
                                precision={4}
                                step={0.0001}
                                min={0.0001}
                                value={captianRanking}
                                onChange={(e) => setCaptianRanking(e)}
                                style={{ width: '100%' }}
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.txt_enter_ranking_here}
                            />
                        </div>
                    </div>


                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <button style={{
                            width: '50%',
                            textAlign: ' center',
                            border: 'none',
                            backgroundColor: ' white',
                            color: '#ec671b',
                            height: '40px',
                            border: '1px solid #ec671b',
                            cursor: 'pointer'
                        }}
                            onClick={onCancel}
                        >{isNothingChanged ? selectedLanguage?.button_txt_Close : selectedLanguage?.text_Cancel}</button>
                        <Button
                            loading={loading}
                            disabled={isNothingChanged}
                            style={{
                                width: '50%',
                                textAlign: ' center',
                                border: 'none',
                                backgroundColor: '#ec671b',
                                color: 'white',
                                height: '40px',
                                border: '1px solid #ec671b',
                                cursor: isNothingChanged ? 'not-allowed' : 'pointer',
                                opacity: isNothingChanged ? 0.5 : 1
                            }}
                            onClick={updatePoint}
                        >{selectedLanguage?.txt_UpdateRanks}</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default UserRankingModal