import { Button, DatePicker, Form, Input } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import voucher_codes from 'voucher-code-generator'
import { ADMIN } from '../../../utils/apis'
import { errorMessage, getGeneralData, successMessage } from "../../../utils/helpers"
const { RangePicker } = DatePicker;

const AddAdminClub = (props) => {
    const { generalActions, selectedLanguage, isEnglish, user, edit, editSuccess, onCancel } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const onFinish = (values) => {

        setLoading(true)

        let ROUTE = edit?._id ? ADMIN.EDIT_ADMIN_CLUBS : ADMIN.ADD_ADMIN_CLUBS

        if (edit?._id) {
            values._id = edit?._id
        }
        axios.post(ROUTE, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    getGeneralData(generalActions)

                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    form.resetFields()
                    onCancel()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            }).catch((e) => {
                setLoading(false)
                console.log(e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const generateCoupon = () => {
        let GenerateCoupon = voucher_codes.generate({
            count: 1,
            pattern: '###-###-###',
        })
        form.setFieldValue('code', GenerateCoupon[0])
    }
    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <div>
                    <p className='admin-home-title'>
                        {edit?._id ? selectedLanguage?.txt_UpdateClub : selectedLanguage?.txt_AddClub}
                    </p>
                    <div style={{ marginTop: '20px' }}>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='name'
                                        hasFeedback
                                        // initialValue={edit?.tournamentName}
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.txt_AddClub}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.txt_PleaseEnterClubName
                                            }
                                        ]}
                                        initialValue={edit?.name}
                                    >
                                        <Input

                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.EnterClubName}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='email'
                                        hasFeedback
                                        initialValue={edit?.email}
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.ClubEmail}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.PleaseEnterClubEmail
                                            },
                                            {
                                                type: 'email',
                                                message: selectedLanguage?.error_EmailIsNotValid
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.EnterClubEmail}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <>
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input '>
                                        <Form.Item
                                            name='code'
                                            label={<p className='my-form-label'>{selectedLanguage?.PrivateCode}</p>}
                                            initialValue={edit?.code}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.PleaseGenerateYourPrivateCode
                                                },
                                            ]}
                                        >
                                            <Input
                                                disabled
                                                className='form-input-new-style'
                                                placeholder='###-###-###'
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input '>
                                        {!edit?._id && <Button
                                            style={{ height: '40px', width: '160px', marginTop: 35 }}
                                            className='addproduct-text'
                                            onClick={generateCoupon}

                                        >
                                            {selectedLanguage?.Generate}
                                        </Button>}
                                    </div>
                                </div>
                            </>
                            <Form.Item>
                                <Button
                                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    htmlType='submit'
                                    loading={loading}
                                >
                                    {edit?._id ? selectedLanguage?.Update : selectedLanguage?.Add}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    style={{ height: '40px', width: '160px', marginTop: 5 }}
                                    onClick={onCancel}
                                >
                                    {selectedLanguage?.textCancel}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAdminClub