import React from "react";
import { LoginFooter, LoginHeader } from "../../Components";
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import soldierImg from "../../assets/Main-home.png"
import playersFootbal from "../../assets/home2.png"


const MainHome = () => {
    const history = useHistory()
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'

    return (
        <div className="helper-main" style={{ background: 'black' }}>
            <LoginHeader />
            <div className="margin-div"/>
            <div className="home-1st-div">
                <div className="txt-div-home">
                    <p className='admin-home-title white-label font-math'>{selectedLanguage?.text_BattleintournamentsandsinglematchestowinPrizes}</p>
                    <p className='admin-home-title white-label font-math'>{selectedLanguage?.text_OrganiseYourOwnPrivateOrPublicTournaments}</p>
                    <div className="home-button-div">
                        <button className="login-s-button" onClick={() => { history.push('/login') }}>
                            Login
                        </button>
                        <button className='login-s-button' onClick={() => { history.push('/register') }}>
                            Register
                        </button>
                    </div>
                </div>
                <div className="home-img-div">
                    <img src={playersFootbal} className="home-soldier-img" />
                </div>
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img className="playersFootbal-img" src={playersFootbal} />
            </div> */}
            <LoginFooter home={true} />
        </div>
    )
}



export default MainHome