import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { GET, POST } from '../../../utils/apis'
import { Button, Image, Input, Modal, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import { convertTitle, errorMessage, successMessage, myTournametSetting, infoMessage, isWithinDisabledPeriod, isDisabledSubscribe, adjustDataForRank, getUserData, getAllTournaments, convertNumber, } from '../../../utils/helpers'
import CreatePlayerTournament from './CreateTournaments'
import { FaInfoCircle } from "react-icons/fa"
import TournamentGraph from './TournamentGraph'
import { useSelector } from 'react-redux'
import DollerTournamentSubscribeModal from './DollerTournamentSubscribe'
import io from 'socket.io-client'
import { BsInfoCircleFill } from 'react-icons/bs'
import { allPaths } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import NoData from '../../../assets/noDataImae.png'


const PlayerTournamentSelect = (props) => {
    const { selectedLanguage, isEnglish, user, userActions, getUser } = props
    const history = useHistory()
    const [tournament, setTournament] = useState([])
    const [currentSubscribeData, setCurrentSubscribeData] = useState({})
    const [currentPromotedSubscribeData, setCurrentPromotedSubscribeData] = useState({})
    const [currentWithdrawalData, setCurrentWithdrawalData] = useState({})
    const [loadingPromoted, setLoadingPromoted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [PrivateLoading, setPrivateLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [editdata, setEditData] = useState({})
    const [subscribeLoading, setSubscribeLoading] = useState({})
    const [withdrawalLoading, setWithdrawalLoading] = useState({})
    const [PrivateTournamentData, setPrivateTournamentData] = useState({})
    const [dollerTournamentData, setDollerTournamentData] = useState({})
    const [privateCodeValue, setPrivateCodeValue] = useState('')
    const [CouponCodeValue, setCouponCodeValue] = useState('')
    const [showCouponInput, setShowCouponInput] = useState(false)
    const [viewTournament, setviewTournament] = useState({})
    const userRanks = useSelector(state => state.userReducer.userRanks)
    const tournaments = useSelector(state => state.userReducer.tournaments)
    console.log('tournament', tournaments)
    useEffect(() => {
        getUserData(user?._id, userActions)
        // getAllTournament()
        // getAllTournaments(user?._id, userActions)
        getSocketInfo()
    }, [])
    const getData = () => {
        getUserData(user?._id, userActions)
    }


    let origin = `https://www.padeldater.com`
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    // console.log('origin', origin)
    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('tournamentResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            getAllTournaments(user?._id, userActions)

        })
    }

    const editSuccess = () => {
        setEditData({})
    }


    const deleteTournament = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = {
            _id: id,
            userId: user?._id
        }

        axios.post(POST?.DELETE_TOURNAMENT, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getData()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const handleOKSubscribeModal = () => {
        tournamentSubscribe(currentSubscribeData)
        setCurrentSubscribeData({})
    }
    const handleCancelSubscribeModal = () => {
        setCurrentSubscribeData({})
    }
    const handleOKWithdrawalModal = () => {
        tournamentWithdrawal(currentWithdrawalData)
        setCurrentWithdrawalData({})
    }
    const handleCancelWithdrawalModal = () => {
        setCurrentWithdrawalData({})
    }
    const handleOKPromotedSubModal = () => {
        if (showCouponInput) {
            if (!CouponCodeValue) {
                return errorMessage(selectedLanguage?.text_pleaseentercouponcode)
            }
            else {
                tournamentSubscribeWithCoupon(currentPromotedSubscribeData)
            }
        }
        else {
            tournamentSubscribe(currentPromotedSubscribeData)
            setCurrentPromotedSubscribeData({})
        }
    }
    const handleCancelPromotedSubModal = () => {
        setCurrentPromotedSubscribeData({})
    }


    let tournamentSubscribe = (data) => {
        console.log(data, "=data")
        let gameId = data?.gameId?._id
        let participationFee = data?.participationFee
        let rankingNeeded = data?.rankingNeeded
        let tournamentId = data?._id
        let isFreeInvitedUser = data?.invitesContacts?.includes(user?._id)
        let isPrivate = data?.isPrivate
        let isPrivateCodeFound = data?.privateCode
        let gameIdExist = userRanks?.find((v) => v?.gameId?._id?.toString() === gameId)?._id

        if (!gameIdExist) {
            return errorMessage(`${selectedLanguage?.text_Add} ${data?.gameId?.name} ${selectedLanguage?.text_inyourgamesettings}!`)
        }
        if (isPrivate && isPrivateCodeFound) {
            setPrivateTournamentData(data)
            return true
        }

        setSubscribeLoading({ [data?._id]: true })

        let obj = {
            userId: user?._id,
            tournamentId: tournamentId,
            pointDeduct: isFreeInvitedUser ? participationFee : participationFee + participationFee,
            gameId,
            participationFee,
            rankingNeeded,
            isInvited: isFreeInvitedUser

        }
        axios.post(POST?.TOURNAMENT_PARTICIPATE, obj)
            .then((res) => {
                const { data } = res
                setSubscribeLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getData()

                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                    console.log(data?.message,"=data?.message")
                }
            })
            .catch((e) => {
                setSubscribeLoading({})
                console.log(e?.message,"=e?.message")
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }


    let handlePrivateSubscription = (data) => {
        let gameId = data?.gameId?._id
        let participationFee = data?.participationFee
        let rankingNeeded = data?.rankingNeeded
        let tournamentId = data?._id
        let isFreeInvitedUser = data?.invitesContacts?.includes(user?._id)
        setPrivateLoading(true)
        if (privateCodeValue === data?.privateCode) {

            let obj = {
                userId: user?._id,
                tournamentId: tournamentId,
                pointDeduct: isFreeInvitedUser ? participationFee : participationFee + participationFee,
                gameId,
                participationFee,
                rankingNeeded,
                CouponCodeValue: CouponCodeValue,
                isInvited: isFreeInvitedUser

            }
            axios.post(POST?.TOURNAMENT_PARTICIPATE, obj)
                .then((res) => {
                    const { data } = res
                    setPrivateLoading(false)
                    if (data?.success) {
                        successMessage(isEnglish ? data?.message : data?.messageNl)
                        handleClose()
                        getData()

                    }
                    else {
                        errorMessage(isEnglish ? data?.message : data?.messageNl)
                    }
                })
                .catch((e) => {
                    setPrivateLoading(false)
                    errorMessage(isEnglish ? e?.message : e?.messageNl)
                })
        }
        else {
            setTimeout(() => {
                setPrivateLoading(false)
                errorMessage(selectedLanguage?.error_ThePrivateCodeisIncorrectorWrong)
            }, 1500);
        }

    }

    const tournamentWithdrawal = (e) => {
        const participationObj = e?.tournamentParticipate?.find(obj => obj.userId._id === user?._id);
        console.log(participationObj, "==obj")
        setWithdrawalLoading({ [e?._id]: true })
        axios.post(POST.CANCEL_PARTICIPATE, participationObj)
            .then((res) => {
                const { data } = res
                setWithdrawalLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getUser()
                    getData()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setWithdrawalLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    let tournamentSubscribeWithCoupon = (data) => {
        let gameId = data?.gameId?._id
        let participationFee = data?.participationFee
        let rankingNeeded = data?.rankingNeeded
        let tournamentId = data?._id
        let isFreeInvitedUser = data?.invitesContacts?.includes(user?._id)
        let isPrivate = data?.isPrivate
        let isPrivateCodeFound = data?.privateCode
        if (isPrivate && isPrivateCodeFound) {
            setPrivateTournamentData(data)
            return true
        }

        setLoadingPromoted(true)

        let obj = {
            userId: user?._id,
            tournamentId: tournamentId,
            pointDeduct: isFreeInvitedUser ? participationFee : participationFee + participationFee,
            gameId,
            participationFee,
            rankingNeeded,
            CouponCodeValue: CouponCodeValue,
            isInvited: isFreeInvitedUser
        }
        axios.post(POST?.TOURNAMENT_PARTICIPATE_WITH_COUPON, obj)
            .then((res) => {
                const { data } = res
                setLoadingPromoted(false)
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    setCurrentPromotedSubscribeData({})
                    getData()

                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoadingPromoted(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    // const columns = [
    //     {
    //         title: selectedLanguage?.title_Game,
    //         dataIndex: 'gameId',
    //         key: 'gameName',
    //         render: (e) => {
    //             return (<p style={{ width: '100px' }}>{e?.name || '----'}</p>)
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_GameType,
    //         dataIndex: 'gameId',
    //         key: 'gameType',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '160px' }}>{e?.gameType || '----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.text_TournamentName,
    //         dataIndex: 'tournamentName',
    //         key: 'tournamentName',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '100px' }}>{e || '----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.text_TournamentType,
    //         dataIndex: 'tournamentType',
    //         key: 'tournamentType',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '100px' }}>{e || '----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.label_TournamentDate,
    //         dataIndex: 'tournamentDate',
    //         key: 'tournamentDate',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '120px' }}>{e || '----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.label_TimeToPlayFirstRound,
    //         dataIndex: 'timeFirstRound',
    //         key: 'timeFirstRound',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '150px' }}>{e || '----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_OrganizerName,
    //         key: 'organizerName',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '120px' }}>{e?.ownerType === 'admin' ?
    //                     e?.ownerType : `${e?.ownerPlayerId?.name}-${e?.ownerType}`}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_RankingNeeded,
    //         dataIndex: 'rankingNeeded',
    //         key: 'rankingNeeded',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '120px' }}>{e || '----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_Fee,
    //         render: (e) => {
    //             return (
    //                 e?.invitesContacts?.includes(user?._id) ?
    //                     < div style={{ display: 'flex', flexDirection: 'column' }}>
    //                         <p style={{
    //                             width: '80px',
    //                             color: '#ec671b',
    //                             background: ' #ec671b38',
    //                             padding: '4px 10px',
    //                             textAlign: 'center',
    //                             borderRadius: ' 20px'
    //                         }}>{selectedLanguage?.info_Free}</p>
    //                         <p style={{
    //                             width: '120px',
    //                         }}>{selectedLanguage?.info_youAreInvited}</p>
    //                     </div> :
    //                     < div style={{ display: 'flex', flexDirection: 'column' }}>
    //                         <p style={{ width: '120px' }}>{e?.isStake ? `$${e?.participationMoney}-${selectedLanguage?.text_money}` : `${e?.participationFee}-${selectedLanguage?.text_points}`}</p>
    //                         {e?.iAmInvited &&
    //                             <p style={{
    //                                 width: '120px',
    //                             }}>{selectedLanguage?.info_youAreInvited}</p>
    //                         }
    //                     </div>

    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_MaxPlayers,
    //         dataIndex: 'maximumPlayer',
    //         key: 'maximumPlayer',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '120px' }}>{e || 0}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_Registered,
    //         dataIndex: 'tournamentParticipate',
    //         key: 'tournamentParticipate',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '120px' }}>{e?.length || 0}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.Text_invitesContact,
    //         dataIndex: 'invitesContacts',
    //         key: 'invitesContacts',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '120px' }}>{e?.length || '---'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.text_Private,
    //         key: 'isPrivate',
    //         render: (e) => {
    //             return (
    //                 <>
    //                     {
    //                         e?.isPrivate && e?.ownerType === 'admin' ? selectedLanguage?.text_AdminPrivate :
    //                             e?.isPrivate && e?.ownerType === 'player' ? selectedLanguage?.text_playerPrivate : selectedLanguage?.text_public
    //                     }
    //                 </>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.text_Promotion,
    //         render: (e) => {
    //             return (
    //                 <>
    //                     {e?.isPromoter && e?.promoterId ?
    //                         <p className='p-promoted'>{selectedLanguage?.text_promoted}</p> :
    //                         <p className='p-not-promoted'>{selectedLanguage?.text_notpromoted}</p>
    //                     }</>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.Info,
    //         render: (e) => {
    //             return (
    //                 <BsInfoCircleFill size={20}
    //                     color='#ec671b'
    //                     onClick={() => {
    //                         history?.push({ pathname: allPaths?.TOURNAMENT_PARTICIPATION, state: e });
    //                     }} />
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_Action,
    //         key: 'Action',
    //         render: (e) => {
    //             let isSubscribed = e?.tournamentParticipate?.some(item => item.userId._id === user?._id);
    //             let timeFirstRound = e?.timeFirstRound
    //             let tournamentDate = e?.tournamentDate
    //             const disabledDateTime = tournamentDate + ' ' + timeFirstRound
    //             const disabledBefore = e?.withdrawTimeInHours
    //             const isPast = isDisabledSubscribe(tournamentDate, timeFirstRound);
    //             const isWithinPeriod = isWithinDisabledPeriod(disabledDateTime, disabledBefore);
    //             console.log(isWithinPeriod, 'isWithinPeriod')
    //             return (
    //                 <div style={{ display: 'flex' }}>
    //                     {
    //                         isPast &&
    //                         <Button
    //                             style={{ marginRight: 10 }}
    //                             onClick={() => { setviewTournament(e) }}
    //                             type='primary'
    //                         > {selectedLanguage?.button_View}</Button>
    //                     }
    //                     {isSubscribed ?
    //                         <Button
    //                             type='primary'
    //                             danger
    //                             onClick={() => {
    //                                 // e?.isStake ?
    //                                 //     errorMessage('$tournament widthrawal section is in process') :
    //                                 setCurrentWithdrawalData(e)
    //                             }}
    //                             disabled={isWithinPeriod}
    //                             loading={withdrawalLoading[e?._id]}
    //                         > {selectedLanguage?.buttonText_Withdrawal}</Button>
    //                         :
    //                         e?.tournamentParticipate?.length == e?.maximumPlayer ?
    //                             <p style={{
    //                                 background: '#00aeff',
    //                                 padding: '3px 4px',
    //                                 color: 'white',
    //                                 borderRadius: '4px'
    //                             }}>{selectedLanguage?.buttonText_Fullybocked}</p>
    //                             :
    //                             isPast ?
    //                                 <Button
    //                                     loading={subscribeLoading[e?._id]}
    //                                     className='dissabled-sub'
    //                                     style={{ height: '30px', width: '90px', marginRight: '4px' }
    //                                     }
    //                                 > {selectedLanguage?.buttonText_Subscribe}</Button>
    //                                 :
    //                                 <Button
    //                                     loading={subscribeLoading[e?._id]}
    //                                     onClick={() => {
    //                                         if (e?.isStake) {
    //                                             setDollerTournamentData(e)
    //                                             // return infoMessage('($) tournament subscription is in working ')
    //                                         }
    //                                         else if (e?.isPromoter && e?.promoterId) {
    //                                             setCurrentPromotedSubscribeData(e)
    //                                         }
    //                                         else {
    //                                             setCurrentSubscribeData(e)
    //                                         }
    //                                     }}
    //                                     className='addproduct-text'
    //                                     style={{ height: '30px', width: '90px', marginRight: '4px' }
    //                                     }
    //                                 > {selectedLanguage?.buttonText_Subscribe}</Button>}

    //                     {e?.ownerPlayerId?._id === user?._id ?
    //                         e?.tournamentParticipate?.length ? null :
    //                             <div style={{ display: 'flex' }}>
    //                                 <Button
    //                                     onClick={() => {
    //                                         setEditData(e)
    //                                     }}
    //                                     className='addproduct-text'
    //                                     style={{ height: '30px', width: '90px', marginRight: '2px' }}
    //                                 >{selectedLanguage?.buttonText_Edit}</Button >

    //                                 {e?.tournamentParticipate?.length ? null : <Popconfirm
    //                                     title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.tournamentName}`)} ${selectedLanguage?.title_Tournament} ?`}
    //                                     okText={selectedLanguage?.textDelete}
    //                                     cancelText={selectedLanguage?.textCancel}
    //                                     okButtonProps={{
    //                                         type: 'primary',
    //                                         className: 'form-button'
    //                                     }}
    //                                     cancelButtonProps={{ style: { borderRadius: 4 } }}
    //                                     onConfirm={() => {
    //                                         deleteTournament(e?._id)
    //                                     }}
    //                                 >
    //                                     <Button
    //                                         loading={deleteLoading[e?._id]}
    //                                         className='addproduct-text '
    //                                         style={{ height: '30px', width: '90px', marginLeft: '2px' }}
    //                                     >{selectedLanguage?.buttonText_Delete}</Button>
    //                                 </Popconfirm>}
    //                             </div> :
    //                         null
    //                     }
    //                 </div>
    //             )
    //         }
    //     }
    // ]

    const handleClose = () => {
        setPrivateTournamentData({})
        setPrivateCodeValue('')
    }

    function handlePrivateCodeChange(event) {
        const { value } = event.target;
        const trimmedValue = value.trim()
        setPrivateCodeValue(trimmedValue);
    }
    function handlecouponCodeChange(event) {
        const { value } = event.target;
        const trimmedValue = value.trim()
        setCouponCodeValue(trimmedValue);
    }

    return (
        <>
            {viewTournament?._id &&
                <TournamentGraph onCancel={() => setviewTournament({})} viewTournament={viewTournament} {...props} />}
            {
                editdata?._id ?

                    <div style={{ marginTop: '40px' }}>
                        <div style={{ display: 'flex', marginLeft: '20px', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <IoIosArrowBack
                                onClick={() => {
                                    setEditData({})
                                }}
                                style={{ marginRight: '5px' ,color:'white'}}
                                size={28} />
                            <p className='admin-home-title' style={{color:'white'}}>{selectedLanguage?.button_updateTournament}</p>
                        </div>
                        <CreatePlayerTournament {...props} edit={editdata} editSuccess={editSuccess} />
                    </div>
                    :
                    <div className='tournamnent-select-main-div'>
                        <div
                            className='sidebar-scroll-class-black'
                            style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
                        >
                            <div>
                                <p className='admin-home-title-white' style={{ marginTop: '10px' }}>
                                    {selectedLanguage?.title_Tournament}</p>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <div
                                    className='sidebar-scroll-class-black'
                                    style={{
                                        overflow: 'scroll',
                                        width: '98%',
                                        margin: '0px auto'
                                    }}
                                >
                                    <div style={{
                                        marginTop: '10px',
                                        marginBottom: '5px',
                                        // width: '100%',
                                        width: '3400px',
                                    }}>
                                        <div style={{
                                            width: '100%',
                                            height: '60px',
                                            borderTopRightRadius: '4px',
                                            borderTopLeftRadius: '4px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            padding: '5px',
                                            alignItems: 'center',
                                        }}>
                                            {[
                                                { title: selectedLanguage?.title_Game },
                                                { title: selectedLanguage?.title_GameType },
                                                { title: selectedLanguage?.text_TournamentName },
                                                { title: selectedLanguage?.text_TournamentType },
                                                { title: selectedLanguage?.label_TournamentDate },
                                                { title: selectedLanguage?.label_TimeToPlayFirstRound },
                                                // { title: selectedLanguage?.label_TimetoPlayFinal },
                                                { title: selectedLanguage?.title_OrganizerName },
                                                { title: selectedLanguage?.title_RankingNeeded },
                                                { title: selectedLanguage?.title_Fee },
                                                { title: selectedLanguage?.title_MaxPlayers },
                                                { title: selectedLanguage?.title_Registered },
                                                { title: selectedLanguage?.Text_invitesContact },
                                                { title: selectedLanguage?.text_Private },
                                                { title: selectedLanguage?.text_Promotion },
                                                { title: selectedLanguage?.Info },
                                                { title: selectedLanguage?.title_Action },
                                            ]?.map((v, i) => {
                                                return (
                                                    <p className='tableValue'
                                                        style={{
                                                            margin: '0px',
                                                            color: 'white',
                                                            fontWeight: 'bolder',
                                                            fontSize: '20px',
                                                            width: v?.title === selectedLanguage?.title_Action ? '570px' : '290px',
                                                            marginLeft: '10px',
                                                            marginRight: '10px'
                                                        }}
                                                    >{v?.title}</p>
                                                )
                                            })}
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                borderBottomRightRadius: '4px',
                                                borderBottomLeftRadius: '4px',
                                                justifyContent: 'space-between',
                                                padding: '5px',
                                                alignItems: 'center',
                                            }}>
                                            {tournaments?.length ?
                                                tournaments?.map((v, i) => {
                                                    let isSubscribed = v?.tournamentParticipate?.some(item => item.userId._id === user?._id);
                                                    let timeFirstRound = v?.timeFirstRound
                                                    let tournamentDate = v?.tournamentDate
                                                    const disabledDateTime = tournamentDate + ' ' + timeFirstRound
                                                    const disabledBefore = v?.withdrawTimeInHours
                                                    const isPast = isDisabledSubscribe(tournamentDate, timeFirstRound);
                                                    const isWithinPeriod = isWithinDisabledPeriod(disabledDateTime, disabledBefore);
                                                    console.log(isWithinPeriod, 'isWithinPeriod')
                                                    return (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: '50px',
                                                                borderTopRightRadius: '4px',
                                                                borderTopLeftRadius: '4px',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                padding: '5px',
                                                                alignItems: 'center',
                                                                margin: '0px',
                                                                padding: '0px'
                                                            }}>
                                                            <p className='tableValue table-p'>
                                                                {v?.gameId?.name}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.gameId?.gameType}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.tournamentName}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.tournamentType}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.tournamentDate}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.timeFirstRound}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.ownerType === 'admin' ?
                                                                    v?.ownerType : `${v?.ownerPlayerId?.name}`}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.rankingNeeded}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.invitesContacts?.includes(user?._id) ?
                                                                    < div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <p style={{
                                                                            width: '80px',
                                                                            color: '#ec671b',
                                                                            background: ' #ec671b38',
                                                                            padding: '4px 10px',
                                                                            textAlign: 'center',
                                                                            borderRadius: ' 20px'
                                                                        }}>{selectedLanguage?.info_Free}</p>
                                                                        <p style={{
                                                                            width: '120px',
                                                                        }}>{selectedLanguage?.info_youAreInvited}</p>
                                                                    </div> :
                                                                    < div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <p style={{ width: '120px' }}>
                                                                            {v?.isStake ?
                                                                                `€${convertNumber(v?.participationMoney)}-${selectedLanguage?.text_money}`
                                                                                :
                                                                                `${convertNumber(v?.participationFee)}-${selectedLanguage?.text_points}`
                                                                            }
                                                                        </p>
                                                                        {v?.iAmInvited &&
                                                                            <p style={{
                                                                                width: '120px',
                                                                            }}>{selectedLanguage?.info_youAreInvited}</p>
                                                                        }
                                                                    </div>
                                                                }
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.maximumPlayer || 0}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.tournamentParticipate?.length || '----'}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.invitesContacts?.length || '----'}
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {
                                                                    v?.isPrivate && v?.ownerType === 'admin' ? selectedLanguage?.text_AdminPrivate :
                                                                        v?.isPrivate && v?.ownerType === 'player' ?
                                                                            selectedLanguage?.text_playerPrivate :
                                                                            selectedLanguage?.text_public
                                                                }
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                {v?.isPromoter && v?.promoterId ?
                                                                    <p className='p-promoted'>{selectedLanguage?.text_promoted}</p> :
                                                                    <p className='p-not-promoted'>{selectedLanguage?.text_notpromoted}</p>
                                                                }
                                                            </p>
                                                            <p className='tableValue table-p'>
                                                                <BsInfoCircleFill size={20}
                                                                    color='#ec671b'
                                                                    onClick={() => {
                                                                        history?.push({ pathname: allPaths?.TOURNAMENT_PARTICIPATION, state: v });
                                                                    }} />
                                                            </p>
                                                            <p className='tableValue table-p' style={{ width: '500px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    {
                                                                        v?.tournamentHistory?.length ?
                                                                            <Button
                                                                                style={{ marginRight: 10 }}
                                                                                onClick={() => { setviewTournament(v) }}
                                                                                type='primary'
                                                                            > {selectedLanguage?.button_View}</Button>
                                                                            : null
                                                                    }
                                                                    {
                                                                        isSubscribed ?
                                                                            <Button
                                                                                type='primary'
                                                                                danger
                                                                                onClick={() => {
                                                                                    // e?.isStake ?
                                                                                    //     errorMessage('$tournament widthrawal section is in process') :
                                                                                    setCurrentWithdrawalData(v)
                                                                                }}
                                                                                disabled={isWithinPeriod}
                                                                                loading={withdrawalLoading[v?._id]}
                                                                            > {selectedLanguage?.buttonText_Withdrawal}</Button>
                                                                            :
                                                                            v?.tournamentParticipate?.length == v?.maximumPlayer ?
                                                                                <p style={{
                                                                                    background: '#00aeff',
                                                                                    padding: '3px 4px',
                                                                                    color: 'white',
                                                                                    borderRadius: '4px'
                                                                                }}>{selectedLanguage?.buttonText_Fullybocked}</p>
                                                                                :
                                                                                isPast ?
                                                                                    <Button
                                                                                        loading={subscribeLoading[v?._id]}
                                                                                        className='dissabled-sub'
                                                                                        style={{ height: '30px', width: '90px', marginRight: '4px' }
                                                                                        }
                                                                                    > {selectedLanguage?.buttonText_Subscribe}</Button>
                                                                                    :
                                                                                    <Button
                                                                                        loading={subscribeLoading[v?._id]}
                                                                                        onClick={() => {
                                                                                            if (v?.isStake) {
                                                                                                setDollerTournamentData(v)
                                                                                                // return infoMessage('($) tournament subscription is in working ')
                                                                                            }
                                                                                            else if (v?.isPromoter && v?.promoterId) {
                                                                                                setCurrentPromotedSubscribeData(v)
                                                                                            }
                                                                                            else {
                                                                                                setCurrentSubscribeData(v)
                                                                                            }
                                                                                        }}
                                                                                        className='addproduct-text'
                                                                                        style={{ height: '30px', width: '90px', marginRight: '4px' }
                                                                                        }
                                                                                    > {selectedLanguage?.buttonText_Subscribe}</Button>}

                                                                    {v?.ownerPlayerId?._id === user?._id ?
                                                                        v?.tournamentParticipate?.length ? null :
                                                                            <div style={{ display: 'flex' }}>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setEditData(v)
                                                                                    }}
                                                                                    className='addproduct-text'
                                                                                    style={{ height: '30px', width: '90px', marginRight: '2px' }}
                                                                                >{selectedLanguage?.buttonText_Edit}</Button >

                                                                                {v?.tournamentParticipate?.length ? null : <Popconfirm
                                                                                    title={`${selectedLanguage?.textDelete} ${convertTitle(`${v?.tournamentName}`)} ${selectedLanguage?.title_Tournament} ?`}
                                                                                    okText={selectedLanguage?.textDelete}
                                                                                    cancelText={selectedLanguage?.textCancel}
                                                                                    okButtonProps={{
                                                                                        type: 'primary',
                                                                                        className: 'form-button'
                                                                                    }}
                                                                                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                                    onConfirm={() => {
                                                                                        deleteTournament(v?._id)
                                                                                    }}
                                                                                >
                                                                                    <Button
                                                                                        loading={deleteLoading[v?._id]}
                                                                                        className='addproduct-text '
                                                                                        style={{ height: '30px', width: '90px', marginLeft: '2px' }}
                                                                                    >{selectedLanguage?.buttonText_Delete}</Button>
                                                                                </Popconfirm>}
                                                                            </div> :
                                                                        null
                                                                    }
                                                                </div>
                                                            </p>

                                                        </div>
                                                    )
                                                })
                                                :
                                                <div style={{ height: '160px', textAlign: 'center' }}>
                                                    <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ marginTop: '20px' }} >
                                <Table
                                    dataSource={tournaments}
                                    columns={columns}
                                    pagination={false}
                                    scroll={{ x: 800 }}
                                    loading={loading}
                                />
                            </div> */}

                        </div>

                        {/* private subscribe */}
                        <Modal
                            open={PrivateTournamentData?._id}
                            handleClose={handleClose}
                            onCancel={handleClose}
                            footer={null}
                        >
                            <div style={{ marginTop: '10px' }}>
                                <span >
                                    <p className='admin-home-title' style={{ fontSize: '22px' }}>{selectedLanguage?.text_SubscribePrivateTournament}</p>
                                    <p className='p-none-margin-bolder'>
                                        {selectedLanguage?.text_youNeedPrivateCodetoSubscribePrivateTournament}
                                    </p>
                                    <p className='p-none-margin-bolder'>
                                        {selectedLanguage?.text_ContactAdminToGetPrivateCode}
                                    </p>
                                </span>
                                <div style={{ marginTop: '15px' }}>
                                    <p style={{ margin: '0px', padding: '4px' }}>{selectedLanguage?.text_PrivateCode}</p>
                                    <Input
                                        value={privateCodeValue}
                                        onChange={handlePrivateCodeChange}
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.text_EnterPrivateCode}
                                    />
                                    <>
                                        {!privateCodeValue ?
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                                <button
                                                    className='same-button-not-allowed'
                                                >
                                                    {selectedLanguage?.btn_Subscribe}
                                                </button>
                                            </div>
                                            :
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                                <Button
                                                    disabled={!privateCodeValue}
                                                    className='addproduct-text '
                                                    style={{ height: '45px', width: '180px', marginTop: '10px' }}
                                                    htmlType='submit'
                                                    loading={PrivateLoading}
                                                    onClick={() => {
                                                        handlePrivateSubscription(PrivateTournamentData)
                                                    }}
                                                >
                                                    {selectedLanguage?.btn_Subscribe}
                                                </Button>
                                            </div>
                                        }
                                    </>
                                </div>
                            </div>
                        </Modal>
                        {/* private subscribe */}
                        {/* ------------------------------------------------------ */}
                        {/* confirm subscribe */}
                        <Modal
                            open={currentSubscribeData?._id}
                            footer={null}
                            className='pop-info-modal'
                            style={{ top: '10px' }}
                        >
                            <div>
                                <p className='p-question'>{selectedLanguage?.text_AreYouSureYouWantToSubscribeTournament}</p>
                                <p className='p-opt'>{selectedLanguage?.text_IfYouSubscribePointsWillBeDeductFromYourAccount}</p>
                                <p className='p-opt'>{selectedLanguage?.text_point}:{currentSubscribeData?.participationFee}</p>
                                <p className='p-opt'>{selectedLanguage?.text_penaltyPoint}:{currentSubscribeData?.participationFee}</p>
                                <p className='p-opt'>{selectedLanguage?.text_TotalPoint}:{currentSubscribeData?.participationFee + currentSubscribeData?.participationFee}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <button className='cancel-btn' onClick={handleCancelSubscribeModal}>{selectedLanguage?.text_Cancel}</button>
                                <button className='confirm-btn' onClick={handleOKSubscribeModal}>{selectedLanguage?.btn_Subscribe}</button>
                            </div>

                        </Modal>
                        {/* confirm subscribe */}
                        {/* ------------------------------------------------------ */}
                        {/* Withdrawal/cancel subscribe */}
                        <Modal
                            open={currentWithdrawalData?._id}
                            footer={null}
                            className='pop-info-modal'
                            style={{ top: '10px', }}
                        >
                            <div>
                                <p className='p-question'>{selectedLanguage?.text_AreYouSureYouWantToWithdrawalCancelATournamet}</p>
                                {currentWithdrawalData?.promoterId?._id &&
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <p>{selectedLanguage?.text_advertisement}</p>
                                        <Image style={{ width: '100%', boxShadow: 'darkgrey 0px 0px 9px', borderRadius: '8px' }} src={currentWithdrawalData?.promoterId?.tournamentFile?.url} />
                                    </div>}
                                <p className='p-opt'>{selectedLanguage?.text_byWithdrawalYouWillGetPointMoneyHalfOfPalenty}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <button className='cancel-btn' onClick={handleCancelWithdrawalModal}>{selectedLanguage?.text_Cancel}</button>
                                <button className='confirm-btn' onClick={handleOKWithdrawalModal}>{selectedLanguage?.buttonText_Withdrawal}</button>
                            </div>
                        </Modal>
                        {/* Withdrawal/cancel subscribe*/}
                        {/* ------------------------------------------------------ */}

                        {/* promoted subscribe */}
                        <Modal
                            open={currentPromotedSubscribeData?._id}
                            footer={null}
                            className='pop-info-modal'
                            style={{ top: '10px' }}
                        >
                            <div>
                                <p className='p-question'>{selectedLanguage?.text_AreYouSureYouWantToSubscribeTournament}</p>
                                {currentPromotedSubscribeData?.promoterId?._id &&
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <p>{selectedLanguage?.text_advertisement}</p>
                                        <Image style={{ width: '100%', boxShadow: 'darkgrey 0px 0px 9px', borderRadius: '8px' }} src={currentPromotedSubscribeData?.promoterId?.tournamentFile?.url} />
                                    </div>
                                }
                                <p className='p-opt'>{selectedLanguage?.text_IfYouSubscribePointsWillBeDeductFromYourAccount}</p>
                                <p className='p-opt'>{selectedLanguage?.text_point}:{currentPromotedSubscribeData?.participationFee}</p>
                                <p className='p-opt'>{selectedLanguage?.text_penaltyPoint}:{currentPromotedSubscribeData?.participationFee}</p>
                                <p className='p-opt'>{selectedLanguage?.text_TotalPoint}:{currentPromotedSubscribeData?.participationFee + currentPromotedSubscribeData?.participationFee}</p>
                            </div>
                            <div >
                                <p
                                    style={{ marginLeft: '4px', color: '#0095ffd9', cursor: 'pointer' }}
                                    onClick={() => setShowCouponInput(!showCouponInput)}>{!showCouponInput ? selectedLanguage?.text_DoYouCanCouponCode : selectedLanguage?.text_idonthavecouponcode}</p>
                                {showCouponInput &&
                                    <Input
                                        value={CouponCodeValue}
                                        onChange={handlecouponCodeChange}
                                        className='form-input-new-style'
                                        placeholder={'###-###-###'}
                                    />}
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <button className='cancel-btn' onClick={handleCancelPromotedSubModal}>{selectedLanguage?.text_Cancel}</button>
                                <button className='confirm-btn' onClick={handleOKPromotedSubModal}>{loadingPromoted && <Spin />}{selectedLanguage?.btn_Subscribe}</button>
                            </div>
                        </Modal>

                        {/* promoted subscribe */}

                        {dollerTournamentData?._id &&
                            <DollerTournamentSubscribeModal
                                /* getAllTournament={getAllTournament} */
                                data={dollerTournamentData}
                                handleClose={() => setDollerTournamentData({})}
                                {...props} />
                        }


                    </div >
            }
        </>
    )
}


export default PlayerTournamentSelect