import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux'
import { ADMIN } from "../../../utils/apis";
import { AddAllPlayers } from "../../../Redux/actions/adminAllPlayerAction";
import ViewCard from "../../Cards/ViewCard";
import { createInvoicePdf, createPaymentReport, errorNotification } from "../../../utils/helpers";
import moment from "moment";
import { Button, DatePicker, Select, Table } from "antd";
const { RangePicker } = DatePicker
const { Option } = Select

const BtwInvoice = (props) => {
    const { user, selectedLanguage, isEnglish } = props
    const [spinLoading, setSpinLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reports, setReports] = useState([])
    const [reportObj, setReportObj] = useState({})
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })

    let columns = [
        {
            title: selectedLanguage?.invoice_ReportRange,
            key: 'Report',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5, }}>{selectedLanguage?.invoice_Report}</p>

        },
        {
            title: selectedLanguage?.invoice_action,
            dataIndex: 'action',
            key: 'action',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5, color: 'blue', cursor: 'pointer' }}
            onClick={() => createPaymentReport(user, dateRange, reports)}>{selectedLanguage?.invoice_download}</p>
        },
    ]
    const createReportRange = (d) => {
        let obj = {
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        }
        setDateRange(obj)
    }

    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }



    const createReport = () => {
        setLoading(true)
        let obj = {
            adminId: user?._id,
            date1: dateRange?.date1,
            date2: dateRange?.date2,
        }
        console.log(obj, 'obj')
        axios.post(ADMIN.GET_SUBSCRIPTION_REPORT, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setReports(data?.data || [])
                    let invoiceObj = data?.data[0]
                    setReportObj(transformObject(invoiceObj))

                }
                else {
                    errorNotification(data?.message)
                }
            })
            .catch((e) => {
                errorNotification()
                setLoading(false)
                console.log('e', e)
            })
    }

    useEffect(() => {
        createReport()
    }, [dateRange,])

    function transformObject(object) {
        return Object.entries(object).map(([price, data]) => ({
            price: parseInt(price),
            data,
        }));
    }

    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <p className='admin-home-title'>{selectedLanguage?.invoice_BTWReport}</p>
                <div className='flex-mobile' style={{ display: 'flex' }}>
                    <RangePicker
                        defaultValue={[moment().add(-7, 'days'), moment()]}
                        onChange={createReportRange}
                        style={{ marginBottom: 10 }}
                        disabledDate={disabledDate}
                    />
                    <Button style={{marginLeft:'8px'}} onClick={createReport}>{selectedLanguage?.invoice_CreateReport}</Button>
                </div>

                <div style={{ marginTop: '20px' }}>
                    <Table
                        loading={loading}
                        dataSource={reportObj?.length ? reports : []}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 700 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default BtwInvoice