import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { getUserData } from "../../../utils/helpers";
import { Table } from "antd";
import moment from "moment";

const ClubInfo = (props) => {
    const { user, selectedLanguage, userActions, isEnglish } = props
    const myClubInfo = useSelector(state => state.userReducer?.clubInfo)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getUserData(user?._id, userActions)
        console.log(myClubInfo, '=myClubInfo')
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000);
    }, [])

    const columns = [
        {
            title: selectedLanguage?.txt_CompetitionName,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.competition?.competitionName || '----'}</p>
                )
            }
        },
        {
            title: 'Score',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.score || '0'}</p>
                )
            }
        },
        {
            title: 'Max-Score',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.maxScore || '0'}</p>
                )
            }
        },
        {
            title: 'Created_at',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{moment(e?.created).format('DD-MM-YYYY')}</p>
                )
            }
        },
    ]
    return (
        <div className='home-main-div'>
            <div style={{ margin: '10px', marginTop: '20px' }}>
                <p className='admin-home-title'>
                    {selectedLanguage?.title_ClubInformation}
                </p>
                <div style={{ margin: '20px' }}>
                    <Table
                        dataSource={myClubInfo}
                        columns={columns}
                        pagination={false}
                        loading={loading}
                        scroll={{ x: 400 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ClubInfo



