import { GAMES, PROMOTER, ADMIN_TOURNAMENT, ALL_COMPETITIONS, ALL_PLAYERS_USERS, ALL_CLUBS_USERS, ALL_ADMIN_CLUBS } from '../types'

const addAllGames = (games) => {
    return {
        type: GAMES,
        games
    }
}

const addAllPromoter = (promoter) => {
    return {
        type: PROMOTER,
        promoter
    }
}

const adminTournament = (adminTournament) => {
    return {
        type: ADMIN_TOURNAMENT,
        adminTournament
    }
}
const allCompetitions = (allCompetitions) => {
    return {
        type: ALL_COMPETITIONS,
        allCompetitions
    }
}
const allPlayersUsers = (allPlayersUsers) => {
    return {
        type: ALL_PLAYERS_USERS,
        allPlayersUsers
    }
}
const allClubsUsers = (allClubsUsers) => {
    return {
        type: ALL_CLUBS_USERS,
        allClubsUsers
    }
}
const allAdminClubs = (adminClubs) => {
    return {
        type: ALL_ADMIN_CLUBS,
        adminClubs
    }
}

export {
    addAllGames,
    addAllPromoter,
    adminTournament,
    allCompetitions,
    allPlayersUsers,
    allClubsUsers,
    allAdminClubs
}