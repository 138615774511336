import React, { useEffect, useState } from 'react'
import { Button, Form, Table } from 'antd'
import { IoIosArrowBack } from "react-icons/io"
import AddGameSuggestion from './AddGameSuggestion'
import axios from 'axios'
import { GET } from '../../../utils/apis'
import { getUserData } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import NoData from '../../../assets/noDataImae.png'

const PlayerGameSuggestion = (props) => {
    const { selectedLanguage, isEnglish, user, userActions } = props
    const gameSuggestions = useSelector(state => state?.userReducer?.gameSuggestions)
    const [newSuggestion, setNewSuggestion] = useState(false)
    useEffect(() => {
        getUserData(user?._id, userActions)

    }, [])

    const goBack = () => {
        setNewSuggestion(false)
        // getGameSuggestion()
    }

    // const columns = [
    //     {
    //         title: selectedLanguage?.title_ID,
    //         dataIndex: '_id',
    //         key: 'id',
    //         render: (e) => {
    //             let Number = e.substr(e.length - 5)
    //             return (
    //                 <p className='p_button_style'>{Number}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_OwnerName,
    //         dataIndex: 'userId',
    //         key: 'userId',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '160px' }}>{e?.name || '---'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_Name,
    //         dataIndex: 'name',
    //         key: 'name',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '100px' }}>{e || '----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.text_gameType,
    //         dataIndex: 'gameType',
    //         key: 'gameType',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '120px' }}>{e || '----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_Description,
    //         dataIndex: 'description',
    //         key: 'description',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '120px' }}>{e || '----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.title_PlayingTimeAvgmin,
    //         dataIndex: 'playingTime',
    //         key: 'playingTime',
    //         render: (e) => {
    //             return (
    //                 <p>{e}{' '}{selectedLanguage?.Text_Min}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: '',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '160px' }} >{
    //                     e?.verify ?
    //                         selectedLanguage?.text_ApprevedByAdmin :
    //                         e?.isDeleted ?
    //                             selectedLanguage?.text_DeletedFromAdmin :
    //                             selectedLanguage?.text_Pending
    //                 }</p>
    //             )
    //         }
    //     },
    // ]

    return (
        <div className='game-sugg-p-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }}
            >
                {newSuggestion ?
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '10px' }}>
                            <span
                                onClick={() => {
                                    goBack()
                                }}
                                style={{ marginTop: '2px', cursor: 'pointer', }}>
                                <IoIosArrowBack size={28} color='white' />
                            </span>
                            <p className='admin-home-title-white'>
                                {selectedLanguage?.title_AddGameSuggestion}
                            </p>
                        </div>
                        <AddGameSuggestion {...props} />
                    </div>
                    :
                    <div>
                        <p
                            style={{
                                marginTop: '10px',
                            }}
                            className='admin-home-title-white'>
                            {selectedLanguage?.text_GameSuggestionCreatedbyMe}
                        </p>
                        <div style={{ marginTop: '20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                <Button
                                    style={{
                                        padding: '0px 20px 0px 20px',
                                        background: 'rgba(92, 93, 93, 1)',
                                        color: 'white',
                                        border: 'none',
                                        height: '45px',
                                        marginTop: '10px',
                                        borderRadius: 6
                                    }}
                                    onClick={() => { setNewSuggestion(true) }}
                                >
                                    {selectedLanguage?.btn_AddNewGameSuggestion}
                                </Button>
                            </div>
                            {/* <div style={{ marginTop: '20px' }} >
                                <Table
                                    dataSource={gameSuggestions || []}
                                    columns={columns}
                                    pagination={false}
                                    scroll={{ x: 800 }}
                                />
                            </div> */}
                            <div style={{ marginTop: '20px' }}>
                                <div
                                    className='sidebar-scroll-class-black'
                                    style={{
                                        overflow: 'scroll',
                                        width: '98%',
                                        margin: '0px auto'
                                    }}
                                >
                                    <div style={{
                                        marginTop: '10px',
                                        marginBottom: '5px',
                                        width: '1100px',
                                    }}>
                                        <div style={{
                                            width: '100%',
                                            height: '60px',
                                            borderTopRightRadius: '4px',
                                            borderTopLeftRadius: '4px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            padding: '5px',
                                            alignItems: 'center',
                                        }}>
                                            {[
                                                {
                                                    title: selectedLanguage?.title_OwnerName,
                                                    width: 200
                                                },
                                                {
                                                    title: selectedLanguage?.title_Name,
                                                    width: 200
                                                },
                                                {
                                                    title: selectedLanguage?.text_gameType,
                                                    width: 200
                                                },
                                                {
                                                    title: selectedLanguage?.title_Description,
                                                    width: 200
                                                },
                                                {
                                                    title: selectedLanguage?.title_PlayingTimeAvgmin,
                                                    width: 300
                                                },
                                                {
                                                    title: 'null',
                                                    width: 200
                                                },
                                            ]?.map((v, i) => {
                                                return (
                                                    <p className='tableValue'
                                                        style={{
                                                            margin: '0px',
                                                            color: 'white',
                                                            fontWeight: 'bolder',
                                                            fontSize: '20px',
                                                            width: v?.width,
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                        }}
                                                    >{v?.title === 'null' ? '' : v?.title}</p>
                                                )
                                            })}
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                borderBottomRightRadius: '4px',
                                                borderBottomLeftRadius: '4px',
                                                justifyContent: 'space-between',
                                                padding: '5px',
                                                alignItems: 'center',
                                            }}>
                                            {
                                                gameSuggestions?.length ?
                                                    gameSuggestions?.map((v, i) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: '50px',
                                                                    borderTopRightRadius: '4px',
                                                                    borderTopLeftRadius: '4px',
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                    padding: '5px',
                                                                    alignItems: 'center',
                                                                    margin: '0px',
                                                                    padding: '0px'
                                                                }}>
                                                                <p className='tableValue table-p'
                                                                    style={{ width: '200px' }}>
                                                                    {v?.userId?.name}
                                                                </p>
                                                                <p className='tableValue table-p'
                                                                    style={{ width: '200px' }}>
                                                                    {v?.name}
                                                                </p>
                                                                <p className='tableValue table-p'
                                                                    style={{ width: '200px' }}>
                                                                    {v?.gameType}
                                                                </p>
                                                                <p className='tableValue table-p'
                                                                    style={{ width: '200px' }}>
                                                                    {v?.description}
                                                                </p>
                                                                <p className='tableValue table-p'
                                                                    style={{ width: '300px' }}>
                                                                    {v?.playingTime}{' '}{selectedLanguage?.Text_Min}
                                                                </p>
                                                                <p className='tableValue table-p'
                                                                    style={{ width: '200px' }}>
                                                                    <p style={{ width: '160px' }} >{
                                                                        v?.verify ?
                                                                            selectedLanguage?.text_ApprevedByAdmin :
                                                                            v?.isDeleted ?
                                                                                selectedLanguage?.text_DeletedFromAdmin :
                                                                                selectedLanguage?.text_Pending
                                                                    }</p>
                                                                </p>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div style={{ height: '160px', textAlign: 'center' }}>
                                                        <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default PlayerGameSuggestion