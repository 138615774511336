import React, { useState, useEffect } from 'react'
import { Button, Divider, Modal, Progress, Select, Skeleton } from 'antd'
import { calculateAverageForCleanliness, calculateAverageForFacilities, calculateAverageForFreeWifi, calculateAverageForLocation, calculateAverageForStaff, calculateAverageForValueForMoney, calculateReviewAverage } from "../../../utils/helpers"
import ReplyAllIcon from '@mui/icons-material/ReplyAll'
import { CommentOutlined, DotChartOutlined, WechatOutlined } from '@ant-design/icons'
import AddCourtReview from './AddCourtReview'

const CourtReview = (props) => {
    const { reviewModal, closeReviewModal, ProgressLoading, language, selectedLanguage } = props
    let filterTimeData = [
        { val: 'Newest first' },
        { val: 'Oldest first' },
        { val: 'Highest score' },
        { val: 'Lowest score' }
    ]

    const openModal = reviewModal?._id ? true : false
    const [reviewData, setReviewData] = useState(null)
    const [GuestReview, setGuestReview] = useState(null)
    const [filterTime, setFilterTime] = useState(filterTimeData[0]?.val)
    const [readingIndex, setReadingIndex] = useState(null)
    const [commentReadingIndex, setCommentReadingIndex] = useState(null)
    const [showReviewForm, setshowReviewForm] = useState(false)
    const { Option } = Select

    useEffect(() => {
        setReviewData(!reviewModal?.isReviewHide ? reviewModal?.review : [])
        let filterReviewModal = reviewModal?.review
        switch (filterTime) {
            case 'Newest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(b?.created) - new Date(a?.created) })
                break
            case 'Oldest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(a?.created) - new Date(b?.created) })
                break
            case 'Highest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return b?.average - a?.average })
                break
            case 'Lowest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return a?.average - b?.average })
                break
            default:
                break
        }
        setGuestReview(!reviewModal?.isReviewHide ? filterReviewModal : [])
    })

    const setFilterValue = (e) => {
        setFilterTime(e)
        let filterReviewModal = reviewModal?.review
        switch (e) {
            case 'Newest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(b?.created) - new Date(a?.created) })
                break
            case 'Oldest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(a?.created) - new Date(b?.created) })
                break
            case 'Highest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return b?.average - a?.average })
                break
            case 'Lowest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return a?.average - b?.average })
                break
            default:
                break
        }
        setGuestReview(!reviewModal?.isReviewHide ? filterReviewModal : [])
    }

    return (
        <>
            {showReviewForm
                ?
                <AddCourtReview reviewData={reviewModal} onCancel={() => setshowReviewForm(false)} {...props} /> :
                <Modal
                    open={openModal}
                    title={reviewModal?.name}
                    onCancel={closeReviewModal}
                    footer={
                        [
                            <Button key='back' onClick={closeReviewModal}>
                                {selectedLanguage?.Close}
                            </Button>
                        ]}
                >
                    <div>
                        <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                            <button onClick={() => setshowReviewForm(reviewModal)} style={{ marginTop: '10px', marginBottom: '10px', }}>
                                Add Review
                            </button>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '75%', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {ProgressLoading ?
                                        <Skeleton.Node active={true} style={{ height: 34, width: 34 }}>
                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                        </Skeleton.Node> : <button
                                            style={{
                                                height: 34,
                                                width: 34,
                                                padding: 0,
                                                background: '#ec671b',
                                                border: 'none',
                                                borderRadius: 4,
                                                fontSize: 12,
                                                fontWeight: 500,
                                                color: 'white',
                                            }}>
                                            {reviewData?.length ? calculateReviewAverage(reviewData) : 0}
                                        </button>}
                                    {ProgressLoading ? <div style={{ marginLeft: 7, marginBottom: 10 }}>
                                        <Skeleton.Node active={true} style={{ height: 10, width: 35 }}>
                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                        </Skeleton.Node>
                                        <br />
                                        <Skeleton.Node active={true} style={{ height: 10, width: 35 }}>
                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                        </Skeleton.Node>
                                    </div> : <div style={{ marginLeft: 10 }}>
                                        <p className='review-text' style={{ fontWeight: 700 }}>
                                            {reviewData?.length ?
                                                calculateReviewAverage(reviewData) < 4.5 ?
                                                    selectedLanguage?.Bad :
                                                    calculateReviewAverage(reviewData) < 5.9 ?
                                                        selectedLanguage?.Poor :
                                                        calculateReviewAverage(reviewData) < 6.9 ?
                                                            selectedLanguage?.Adeqate :
                                                            calculateReviewAverage(reviewData) < 8.9 ?
                                                                selectedLanguage?.Good :
                                                                calculateReviewAverage(reviewData) < 10 ?
                                                                    selectedLanguage?.Excellent :
                                                                    null : null}
                                        </p>
                                        <p className='review-text' style={{ fontWeight: 400 }}>{reviewData?.length ? reviewData?.length : 0}{' '} {selectedLanguage?.Reviews}</p>
                                    </div>}
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <p className='review-text review-text-aim' style={{ fontWeight: 600, color: '#6ad49b' }}>{selectedLanguage?.WeAimFor100RealReview}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className='review-text review-text-aim-2' style={{ fontWeight: 600, color: '#6ad49b' }}>{selectedLanguage?.WeAimFor100RealReview}</p>
                        </div>
                        <hr />
                        <div >
                            <h4 style={{ fontWeight: 600 }}>{selectedLanguage?.Categories}</h4>
                            <div className='progress-main-div'>
                                <div className='progress-inner-main-div'>
                                    <div className='progress-text-point-div'>
                                        <p className='review-text'>{selectedLanguage?.Staff}</p>
                                        <p className='review-text'>
                                            {ProgressLoading ?
                                                <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node> :
                                                reviewData?.length
                                                    ? calculateAverageForStaff(reviewData) : 0}
                                            {ProgressLoading ? null : '/10'}</p>
                                    </div>
                                    <div>
                                        {ProgressLoading ?
                                            <Skeleton.Input
                                                style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                active={true}
                                                block={true}
                                            /> : <Progress percent={reviewData?.length ?
                                                calculateAverageForStaff(reviewData) * 10 : 0}
                                                showInfo={false} />}
                                    </div>
                                </div>
                                <div className='progress-inner-main-div'>
                                    <div className='progress-text-point-div'>
                                        <p className='review-text'>{selectedLanguage?.Facilities}</p>
                                        <p className='review-text'>
                                            {ProgressLoading ?
                                                <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node> :
                                                reviewData?.length
                                                    ? calculateAverageForFacilities(reviewData) : 0}
                                            {ProgressLoading ? null : '/10'}</p>
                                    </div>
                                    <div>
                                        {ProgressLoading ?
                                            <Skeleton.Input
                                                style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                active={true}
                                                block={true}
                                            /> : <Progress percent={reviewData?.length ?
                                                calculateAverageForFacilities(reviewData) * 10 : 0}
                                                showInfo={false} />}
                                    </div>
                                </div>
                            </div>
                            <div className='progress-main-div'>
                                <div className='progress-inner-main-div'>
                                    <div className='progress-text-point-div'>
                                        <p className='review-text'>{selectedLanguage?.Cleanliness}</p>
                                        <p className='review-text'>
                                            {ProgressLoading ?
                                                <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node> :
                                                reviewData?.length
                                                    ? calculateAverageForCleanliness(reviewData) : 0}
                                            {ProgressLoading ? null : '/10'}</p>
                                    </div>
                                    <div>
                                        {ProgressLoading ?
                                            <Skeleton.Input
                                                style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                active={true}
                                                block={true}
                                            /> : <Progress percent={reviewData?.length ?
                                                calculateAverageForCleanliness(reviewData) * 10 : 0}
                                                showInfo={false} />}
                                    </div>
                                </div>
                                <div className='progress-inner-main-div'>
                                    <div className='progress-text-point-div'>
                                        <p className='review-text'>{selectedLanguage?.Comfort}</p>
                                        <p className='review-text'>
                                            {ProgressLoading ?
                                                <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node> : reviewData?.length
                                                    ? calculateAverageForCleanliness(reviewData) : 0}
                                            {ProgressLoading ? null : '/10'}</p>
                                    </div>
                                    <div>
                                        {ProgressLoading ?
                                            <Skeleton.Input
                                                style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                active={true}
                                                block={true}
                                            /> : <Progress percent={reviewData?.length ?
                                                calculateAverageForCleanliness(reviewData) * 10 : 0}
                                                showInfo={false} />}
                                    </div>
                                </div>
                            </div>
                            <div className='progress-main-div'>
                                <div className='progress-inner-main-div'>
                                    <div className='progress-text-point-div'>
                                        <p className='review-text'>{selectedLanguage?.ValueForMoney}</p>
                                        <p className='review-text'>
                                            {ProgressLoading ?
                                                <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node> : reviewData?.length
                                                    ? calculateAverageForValueForMoney(reviewData) : 0}
                                            {ProgressLoading ? null : '/10'}</p>
                                    </div>
                                    <div>
                                        {ProgressLoading ?
                                            <Skeleton.Input
                                                style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                active={true}
                                                block={true}
                                            /> : <Progress percent={reviewData?.length ?
                                                calculateAverageForValueForMoney(reviewData) * 10 : 0}
                                                showInfo={false} />}
                                    </div>
                                </div>
                                <div className='progress-inner-main-div'>
                                    <div className='progress-text-point-div'>
                                        <p className='review-text'>{selectedLanguage?.Location}</p>
                                        <p className='review-text'>
                                            {ProgressLoading ?
                                                <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node> : reviewData?.length
                                                    ? calculateAverageForLocation(reviewData) : 0}
                                            {ProgressLoading ? null : '/10'}</p>
                                    </div>
                                    <div>
                                        {ProgressLoading ?
                                            <Skeleton.Input
                                                style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                active={true}
                                                block={true}
                                            /> : <Progress percent={reviewData?.length ?
                                                calculateAverageForLocation(reviewData) * 10 : 0}
                                                showInfo={false} />}
                                    </div>
                                </div>
                            </div>
                            <div className='progress-main-div'>
                                <div className='progress-inner-main-div'>
                                    <div className='progress-text-point-div'>
                                        <p className='review-text'>{selectedLanguage?.FreeWifi}</p>
                                        <p className='review-text'>
                                            {ProgressLoading ?
                                                <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node> : reviewData?.length
                                                    ? calculateAverageForFreeWifi(reviewData) : 0}
                                            {ProgressLoading ? null : '/10'}</p>
                                    </div>
                                    <div>
                                        {ProgressLoading ?
                                            <Skeleton.Input
                                                style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                active={true}
                                                block={true}
                                            /> : <Progress percent={reviewData?.length ?
                                                calculateAverageForFreeWifi(reviewData) * 10 : 0}
                                                showInfo={false} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                        }}>
                            <Select
                                showSearch
                                showArrow
                                allowClear={false}
                                value={filterTime}
                                onChange={setFilterValue}
                                className='form-input'
                                optionFilterProp='children'
                                style={{ width: '250px', marginTop: '10px' }}
                            >
                                {filterTimeData?.map((v, i) => {
                                    return (
                                        <Option value={v?.val} key={i}>{`${v?.val}`}</Option>
                                    )
                                })
                                }
                            </Select>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <h4 style={{ fontWeight: 600 }}>{selectedLanguage?.GuestReview}</h4>
                        </div>
                        {
                            GuestReview?.length ?
                                GuestReview?.map((v, i) => {
                                    return (
                                        <>
                                            {!v?.isHide ?
                                                <div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
                                                            <div>
                                                                {ProgressLoading ?
                                                                    <div
                                                                        style={{ height: 40, width: 40, borderRadius: 100 }}
                                                                    >
                                                                        <Skeleton.Avatar
                                                                            style={{ margin: 5 }}
                                                                            active={true} shape={'circle'} />
                                                                    </div> :
                                                                    <div
                                                                        style={{ height: 40, width: 40, border: '2px solid #ec671b', borderRadius: 100 }}
                                                                    >
                                                                        <img src={v?.userId?.logoUrl ? v?.userId?.logoUrl : null}
                                                                            style={{ height: '100%', width: '100%', borderRadius: 100 }}
                                                                        />
                                                                    </div>}
                                                            </div>
                                                            {ProgressLoading ? <div style={{ marginLeft: 7, marginBottom: 15 }}>
                                                                <Skeleton.Node active={true} style={{ height: 10, width: 35 }}>
                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                </Skeleton.Node>
                                                                <br />
                                                                <Skeleton.Node active={true} style={{ height: 10, width: 35 }}>
                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                </Skeleton.Node>
                                                            </div> : <div style={{ margin: 5 }}>
                                                                <p className='review-text' style={{ fontWeight: 700 }}>{v?.userId?.name ? v?.userId?.name : '----'}</p>
                                                                <p className='review-text' style={{ fontWeight: 400 }}>{v?.userId?.city ? v?.userId?.city : '----'}</p>
                                                            </div>}
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', width: '20%', justifyContent: 'flex-end' }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'flex-end'
                                                                }}
                                                            >
                                                                {ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 35, marginBottom: 10 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node>
                                                                    : <p className='review-text' style={{ marginBottom: 2 }}>
                                                                        {v?.average ?
                                                                            v?.average < 4.5 ?
                                                                                selectedLanguage?.Bad :
                                                                                v?.average < 5.9 ?
                                                                                    selectedLanguage?.Poor :
                                                                                    v?.average < 6.9 ?
                                                                                        selectedLanguage?.Adeqate :
                                                                                        v?.average < 8.9 ?
                                                                                            selectedLanguage?.Good :
                                                                                            selectedLanguage?.Excellent : null}
                                                                    </p>}
                                                                {ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 34, width: 34 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> : <button
                                                                        style={{
                                                                            height: 34,
                                                                            width: 34,
                                                                            padding: 0,
                                                                            background: '#ec671b',
                                                                            border: 'none',
                                                                            borderRadius: 4,
                                                                            fontSize: 12,
                                                                            fontWeight: 500,
                                                                            color: 'white',
                                                                        }}>
                                                                        {v?.average ? v?.average?.toFixed(1) : 0}
                                                                    </button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ margin: 5 }}>
                                                        <p className='review-text' style={{ fontWeight: 700 }}>
                                                            {ProgressLoading ?
                                                                <Skeleton.Node active={true} style={{ height: 10, width: 35, marginBottom: 5 }}>
                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                </Skeleton.Node>
                                                                :
                                                                selectedLanguage?.Reviewed + ':'}
                                                            {ProgressLoading ?
                                                                <Skeleton.Node active={true} style={{ height: 10, width: 35, marginBottom: 5, marginLeft: 4 }}>
                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                </Skeleton.Node>
                                                                :
                                                                new Date(v?.created).toLocaleDateString()}
                                                        </p>
                                                        {ProgressLoading ?
                                                            <Skeleton.Node active={true} style={{ height: 40, marginBottom: 5 }}>
                                                                <DotChartOutlined style={{ fontSize: 0 }} />
                                                            </Skeleton.Node> : <div style={{ background: '#E9EBEE', width: '80%', padding: 20, borderRadius: 8 }}>
                                                                <p className={'review-text'}   >{v?.comment ? <>
                                                                    <WechatOutlined style={{ marginRight: 10 }} /> {
                                                                        v?.comment?.length > 100 && commentReadingIndex !== i ?
                                                                            v?.comment?.slice(0, 100) + '...'
                                                                            :
                                                                            v?.comment} </> : '-----'}
                                                                    <p className='review-text'
                                                                        style={{ color: '#007AFF', cursor: 'pointer' }}
                                                                        onClick={() => { setCommentReadingIndex(commentReadingIndex !== i ? i : null) }}
                                                                    >{v?.comment?.length > 100 ?
                                                                        commentReadingIndex !== i ?
                                                                            selectedLanguage?.ContinueReading : selectedLanguage?.ShowLess : null}</p>
                                                                </p>
                                                            </div>}
                                                    </div> {
                                                        v?.answered === null ? null :
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <div style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    padding: '14px',
                                                                    rotate: '180deg',
                                                                }}>{ProgressLoading ? <Skeleton.Node active={true} style={{ height: 20, width: 20 }}>
                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                </Skeleton.Node> : <ReplyAllIcon fontSize='large' />}
                                                                </div>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{
                                                                        height: 34,
                                                                        width: 100,
                                                                    }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> :
                                                                    <div style={{ background: '#E9EBEE', width: '80%', padding: 20, borderRadius: 8 }}>
                                                                        <p className={'review-text'}   >{v?.answered ? <>
                                                                            <WechatOutlined style={{ marginRight: 10 }} /> {
                                                                                v?.answered?.length > 100 && readingIndex !== i ?
                                                                                    v?.answered?.slice(0, 100) + '...'
                                                                                    :
                                                                                    v?.answered} </> : '-----'}
                                                                            <p className='review-text'
                                                                                style={{ color: '#007AFF', cursor: 'pointer' }}
                                                                                onClick={() => { setReadingIndex(readingIndex !== i ? i : null) }}
                                                                            >{v?.answered?.length > 100 ?
                                                                                readingIndex !== i ?
                                                                                    selectedLanguage?.ContinueReading : selectedLanguage?.ShowLess : null}</p>
                                                                        </p>
                                                                    </div>}
                                                            </div>
                                                    }
                                                    <hr />
                                                </div> :
                                                null}
                                        </>

                                    )
                                })
                                :
                                <div>
                                    <p className='review-text'>{selectedLanguage?.NoReviewesYet}</p>
                                </div>
                        }
                    </div>
                </Modal >
            }
        </>
    )
}

export default CourtReview