import { PLAYER_INVOCE } from '../types'

const reducer = (state = [], action) => {
    switch (action.type) {
        case PLAYER_INVOCE: {
            return { ...state, playerInvoice: action.playerInvoice }
        }

        default: {
            return state
        }
    }
}

export default reducer