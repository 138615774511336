import React from 'react'
import { useHistory } from 'react-router-dom'
import WGPWhite from '../../assets/WGP-white.png'
import { allPaths } from '../../utils/constants'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { useSelector } from 'react-redux'
const LoginFooter = (props) => {
    const { home } = props
    const history = useHistory()
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'

    return (
        <div style={{
            background: 'black',
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
        }}>
            {home?<div style={{marginLeft:'8px'}}/>: 
                <div style={{ display: 'flex', marginBottom: '10px', width: 180 }}>
                    <img style={{ height: '110px', cursor: 'pointer', marginLeft: '-30px' }} src={WGPWhite} />
                </div>}

            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <p className='footer-text'>{selectedLanguage?.FooterText_Contact}</p>
                <p className='footer-text'>{selectedLanguage?.FooterText_Video}</p>
                <p className='footer-text'>{selectedLanguage?.FooterText_References}</p>
                <p className='footer-text'>{selectedLanguage?.FooterText_PrivacyPolicy}</p>
                <p className='footer-text'>{selectedLanguage?.FooterText_Terms}</p>
                <p className='footer-text'>2023 WGPplaza</p>
            </div>
        </div>
    )
}

export default LoginFooter