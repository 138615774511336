import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Radio, Select, Switch, TimePicker } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FiPercent } from 'react-icons/fi'
import { GET, POST } from '../../../utils/apis'
import moment from 'moment'
import { BTW_TEX, convertRoundNumber, disabledDate, errorMessage, getPointSetting, getUserData, successMessage } from '../../../utils/helpers'
import { dateFormat } from '../../../utils/constants'
import { useSelector } from 'react-redux'
import { RxLockClosed, RxLockOpen1 } from 'react-icons/rx'
import { BiMoneyWithdraw, } from 'react-icons/bi'
import { GiRank1 } from 'react-icons/gi'

const CreatePlayerTournament = (props) => {
    const { Option } = Select
    const { selectedLanguage, isEnglish, user, edit, editSuccess, userActions, pointSettingAction, getUser } = props
    const AdminServiceFee = useSelector(state => state?.pointSettingReducer)
    const tournamentServiceFee = AdminServiceFee?.dollerTournamentOrganizerFee
    const tournamentServiceBTW = convertRoundNumber((tournamentServiceFee * BTW_TEX))
    const tournamentServiceFeeWithBTW = convertRoundNumber(tournamentServiceFee + (tournamentServiceFee * BTW_TEX))
    const gameSettings = useSelector(state => state?.userReducer?.gameSettings)
    const playerContacts = useSelector(state => state?.userReducer?.playerContacts)
    const [loading, setLoading] = useState(false)
    const [contactLoading, setContactLoading] = useState(false)
    const [repeat, setRepeat] = useState(false)
    const [allPlayerRanks, setAllPlayerRanks] = useState(null)
    const [type, setType] = useState(edit?.gameId?.gameType || '1-1 Match Game')
    const [tournamentDate, setTournamentDate] = useState(edit?.tournamentDate || null)
    const [allowInvitation, setAllowInvitation] = useState(edit ? edit?.allowInvitation : true)
    const [allowFreeEntry, setAllowFreeEntry] = useState(edit ? edit?.allowFreeEntry : true)
    const [time1, setTime1] = useState(edit?.timeFirstRound || null)
    const [Private, setPrivate] = useState(edit?.isPrivate ? true : false)
    const [stake, setStake] = useState(edit?.isStake ? true : false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [allValues, setAllValues] = useState({})
    const [form] = Form.useForm()
    const format = 'HH:mm'
    const myContactIds = edit?._id ? edit?.onlyInviteContact : playerContacts?.map(item => item.contactId._id);

    useEffect(() => {
        getUserData(user?._id, userActions)
        edit?._id && getGameRanks(edit?.gameId?._id)
    }, [])
    useEffect(() => {
        getPointSetting(pointSettingAction)
    }, [])

    const handleChangeGame = (value) => {
        getGameRanks(value)
    };
    const getGameRanks = (id) => {
        setContactLoading(true)
        axios.get(`${GET?.GET_GAME_RANKS}/${id}`,)
            .then((res) => {
                const { data } = res
                setContactLoading(false)
                if (data?.success) {
                    setAllPlayerRanks(data?.data || [])
                }
                else {
                    setAllPlayerRanks([])
                }
            })
            .catch((e) => {
                setContactLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const handleOk = () => {
        console.log(allValues, 'values form submit')
        submitForm(allValues)
        setConfirmModal(false)
    }

    const handleCancel = () => {
        setConfirmModal(false)
    }

    const onFinish = (values) => {
        console.log('finist', values)
        edit?._id && submitForm(values);
        edit?._id && setAllValues(values);
        !edit?._id && stake ? setConfirmModal(true) : submitForm(values)
        !edit?._id && stake && setAllValues(values)
    }

    const submitForm = (values) => {
        values.tournamentDate = tournamentDate
        values.timeFirstRound = time1
        values.allowInvitation = allowInvitation
        values.onlyInviteContact = allowInvitation ? myContactIds : []
        values.invitesContacts = values?.invitesContacts ? values?.invitesContacts : []
        values.allowFreeEntry = allowFreeEntry
        values.isStake = stake
        values.ownerType = 'player'
        values.ownerPlayerId = user?._id
        values.isPrivate = Private
        values.timeRange = values?.timeRange ? values.timeRange : null
        values.privateCode = values?.privateCode ? values.privateCode : null
        values.prizeOne = values?.prizeOne ? `${values.prizeOne}` : `40`
        values.prizeTwo = values?.prizeTwo ? `${values.prizeTwo}` : `20`
        values.prizeThree = values?.prizeThree ? `${values.prizeThree}` : `15`
        values.prizeFour = values?.prizeFour ? `${values.prizeFour}` : `5`
        edit?._id && (values._id = edit?._id)
        console.log(values, 'values form submit')
        let allPrizes = Number(values?.prizeOne) + Number(values?.prizeTwo) + Number(values?.prizeThree) + Number(values?.prizeFour)
        console.log(allPrizes, 'allPrizes')
        if (allPrizes >= 100) {
            errorMessage(selectedLanguage?.text_theSumOfAllPrizesMustBeLessThenHndred)
            return true
        }
        setLoading(true)
        axios.post(edit?._id ? POST?.EDIT_TOURNAMENT : POST?.CREATE_TOURNAMENT, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    form.resetFields()
                    setAllowFreeEntry(true)
                    setAllowInvitation(true)
                    getUser()
                    edit?._id && editSuccess()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }


    const onChangeDate = (date) => {
        setTournamentDate(moment(date).format(dateFormat))
    }
    const onChangeTime1 = (date) => {
        setTime1(moment(date).format(format))
    }

    const handleAllowInvitation = (e) => {
        setAllowInvitation(e.target.value)
    }
    const handleAllowFreeEntry = (e) => {
        setAllowFreeEntry(e.target.value)
    }
    function onChangeCheckBox(e) {
        setPrivate(e/* .target.checked */)
        // setPrivate(e.target.checked)
        setAllowInvitation(true)
        form.setFieldsValue({
            allowInvitation: true,
        })
    }
    function onChangeStack(e) {
        setStake(e/* .target.checked */)
    }

    return (
        <div className='create-tournament-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginTop: '10px',
                }}
            >
                <div>
                    {!edit?._id &&
                        <p className='admin-home-title-white'>
                            {selectedLanguage?.tittle_Createtournament}
                        </p>}
                    <div style={{ marginTop: '20px' }}>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <p style={{ padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px' }} className='my-form-label'>
                                        {selectedLanguage?.text_SelectGameType}</p>
                                    <Select defaultValue={type}
                                        placeholder={selectedLanguage?.text_SelectGameType}
                                        className='form-input-new-style'
                                        onChange={(e) => { setType(e) }}
                                        style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                    >
                                        <Option value={'1-1 Match Game'}>{selectedLanguage?.text_OneByOneMatchGame}</Option>
                                        <Option value={'Team Match Game'}>{selectedLanguage?.text_TeamMatchGame}</Option>
                                    </Select>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='gameId'
                                        hasFeedback
                                        label={<p className='my-form-label'>{selectedLanguage?.label_SelectGame}</p>}
                                        initialValue={edit?.gameId?._id}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_pleaseSelectGame
                                            }
                                        ]}
                                    >
                                        <Select
                                            onChange={handleChangeGame}
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.placeHolder_SelectGamefortournament}>
                                            {gameSettings?.length && gameSettings?.map((v, i) => {
                                                return (
                                                    v?.gameId?.gameType === type &&
                                                    <Option value={v?.gameId?._id}>{`${v?.gameId?.name} - ${v?.gameId?.gameType} - ${v?.gameId?.description} `}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='form-input-single-input'>
                                <Form.Item
                                    name='tournamentName'
                                    hasFeedback
                                    label={<p className='my-form-label'>{selectedLanguage?.label_TournamentName}</p>}
                                    initialValue={edit?.tournamentName}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_pleaseEnterTournamentName
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_EnterTournamentNameHere}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='tournamentType'
                                        hasFeedback
                                        label={<p className='my-form-label'>{selectedLanguage?.label_TournamentType}</p>}
                                        initialValue={edit?.tournamentType}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_pleaseEnterTournamentType
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.placeHolder_EnterTournamentTypeHere}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='tournamentDate'
                                        initialValue={edit?.tournamentDate && moment(edit?.tournamentDate, 'DD-MM-YYYY')}
                                        hasFeedback
                                        label={<p className='my-form-label'>{selectedLanguage?.label_TournamentDate}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_pleaseEnterTournamentDate
                                            }
                                        ]}
                                    >
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            onChange={onChangeDate}
                                            // defaultValue={edit?.tournamentDate && moment(edit?.tournamentDate, 'DD-MM-YYYY')}
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.placeHolder_TournamentDate}
                                            style={{ width: '100%' }}
                                            allowClear={false}
                                            format={dateFormat}
                                        />
                                    </Form.Item>
                                </div>
                            </div>


                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.Item
                                        name='maximumPlayer'
                                        initialValue={edit?.maximumPlayer}
                                        hasFeedback
                                        label={<p className='my-form-label'>{selectedLanguage?.label_Maximumplayer}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_pleaseEnteraMaxPlayer
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value === null || value > 1) {
                                                        return Promise.resolve()
                                                    }
                                                    else if (value === 0) {
                                                        return Promise.reject(selectedLanguage?.text_MaximumPlayerMustBeOrMmoreThen)
                                                    }
                                                    else {
                                                        return Promise.reject(selectedLanguage?.text_MaximumPlayerMustBeOrMmoreThen)
                                                    }
                                                },
                                            })
                                        ]}
                                    >
                                        <InputNumber
                                            max={64}
                                            min={4}
                                            step={1}
                                            type='number'
                                            style={{ width: '100%' }}
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.placeHolder_EnterMaximumPlayerhere}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.Item
                                        name='rankingNeeded'
                                        initialValue={edit?.rankingNeeded}
                                        hasFeedback
                                        label={<p className='my-form-label'>{selectedLanguage?.label_RankingNeeded}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_pleaseEnterRankingNeeded
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            type='number'
                                            style={{ width: '100%' }}
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.placeHolder_EnterRankingNeededHere}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input newInpuHeightFull' style={{ width: '100%' }}>
                                    <Form.Item
                                        name='withdrawTimeInHours'
                                        initialValue={edit?.withdrawTimeInHours}
                                        hasFeedback
                                        label={<p className='my-form-label'>{selectedLanguage?.label_WithdrawTimeHours}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_pleaseEnterWithdrawTimehours
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={24}
                                            type='number'
                                            style={{ width: '100%' }}
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.placeHolder_EnterWithdrawtimeHourshere}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input '>
                                    <Form.Item
                                        label={<p className='my-form-label'>{selectedLanguage?.text_Stackswitch}</p>}
                                        name='isStake'
                                        initialValue={stake}
                                        onChange={onChangeStack}
                                        valuePropName='checked'>
                                        <div
                                            className='form-input-new-style'
                                            style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white' }}
                                        >
                                            <p style={{ margin: '0px 10px' }}>{selectedLanguage?.title_Point}</p>
                                            <Switch
                                                disabled={edit?._id}
                                                onChange={onChangeStack}
                                                checked={stake}
                                                checkedChildren={<BiMoneyWithdraw size={19} style={{ height: 22, marginRight: 4 }} />}
                                                unCheckedChildren={<GiRank1 size={19} style={{ height: 22, marginLeft: 4 }} />} />
                                            <p style={{ margin: '0px 10px' }}>{selectedLanguage?.text_money}</p>
                                        </div>
                                    </Form.Item>
                                </div>

                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    {!stake ?
                                        <Form.Item
                                            name='participationFee'
                                            initialValue={edit?.participationFee}
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.label_ParticipationFeePoints}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.error_pleaseEnterParticipationFee
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                type='number'
                                                min={1}
                                                step={0.5}  // Set the step to 0.01 for two decimal places
                                                precision={2}
                                                style={{ width: '100%' }}
                                                className='form-input-new-style'
                                                placeholder={selectedLanguage?.label_ParticipationFeePoints}
                                            />
                                        </Form.Item> :
                                        <Form.Item
                                            name='participationMoney'
                                            initialValue={edit?.participationMoney}
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.label_ParticipationfeeMoney}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.error_PleaseEnterParticipationFeeMoney
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                type='number'
                                                step={0.5}    // Set the step to 0.01 for two decimal places
                                                min={1}        // Set the minimum value
                                                precision={2}
                                                style={{ width: '100%' }}
                                                className='form-input-new-style'
                                                placeholder={selectedLanguage?.placeHolder_EnterParticipationFeeMoney}
                                            />
                                        </Form.Item>}
                                </div>

                            </div>
                            {stake &&
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input newInpuHeightFull'
                                        style={{ width: '100%' }}>
                                        <Form.Item
                                            name='totalTournamentPoints'
                                            initialValue={edit?.totalTournamentPoints}
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.text_TotalTournamentPointsPrizes}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.error_PleaseEnterTotalTournamentPoints
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                type='number'
                                                style={{ width: '100%' }}
                                                className='form-input-new-style'
                                                placeholder={selectedLanguage?.placeholder_EnterTotalTournamentPointsHere}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>}

                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='timeFirstRound'
                                        initialValue={edit?.timeFirstRound && moment(edit?.timeFirstRound, 'HH:mm')}
                                        hasFeedback
                                        label={<p className='my-form-label'>{selectedLanguage?.label_TimeToPlayFirstRound}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_pleaseEnterTimeToPlayFirstRound
                                            },
                                        ]}
                                    >
                                        <TimePicker
                                            // defaultValue={edit?.timeFirstRound && moment(edit?.timeFirstRound, 'HH:mm')}
                                            minuteStep={15}
                                            onChange={onChangeTime1}
                                            style={{ width: '100%' }}
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.placeHolder_EnterTimePlayFirstRoundHere}
                                            format={format} />
                                    </Form.Item>
                                </div>

                            </div>

                            {user?.isPrizeEditor && <>
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                        <Form.Item
                                            name='prizeOne'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.label_Prizewinner}</p>}
                                            initialValue={'40'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.label_Prizewinner
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                type='number'
                                                className='form-input-new-style form-input-new-style-sufix'
                                                placeholder={selectedLanguage?.label_Prizewinner}
                                                addonAfter={<FiPercent />}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                        <Form.Item
                                            name='prizeTwo'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.label_Prizefinalist}</p>}
                                            initialValue={'20'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.label_Prizefinalist
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                type='number'
                                                className='form-input-new-style form-input-new-style-sufix'
                                                placeholder={selectedLanguage?.label_Prizefinalist}
                                                addonAfter={<FiPercent />}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                        <Form.Item
                                            name='prizeThree'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.label_PrizenoThree}</p>}
                                            initialValue={'15'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.label_PrizenoThree
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                type='number'
                                                className='form-input-new-style form-input-new-style-sufix'
                                                placeholder={selectedLanguage?.label_PrizenoThree}
                                                addonAfter={<FiPercent />}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                        <Form.Item
                                            name='prizeFour'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.label_PrizenoFour}</p>}
                                            initialValue={'5'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.label_PrizenoFour
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                type='number'
                                                className='form-input-new-style form-input-new-style-sufix'
                                                placeholder={selectedLanguage?.label_PrizenoFour}
                                                addonAfter={<FiPercent />}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </>}

                            <div className='form-two-input-flex'>
                                {/*     <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='kindOfTournament'
                                        hasFeedback
                                        label={<p className='my-form-label'>{selectedLanguage?.label_Kindoftournament}</p>}
                                        initialValue={edit?.kindOfTournament || 'Knock out'}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_Selectakindoftournament
                                            }
                                        ]}
                                    >
                                        <Radio.Group
                                            className='form-input-new-style'
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundColor: 'white'
                                            }}
                                        >
                                            <Radio value={'Knock out'}>{selectedLanguage?.option_Knockout}</Radio>
                                            <Radio value={'Tournament pool'}>{selectedLanguage?.option_Tournamentpool}</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>*/}
                                {user?.isTournamentRepeat && !edit?._id ?
                                    <div className='form-two-input-flex-inner-input '>
                                        <Form.Item
                                            name='repeat'
                                            label=' '
                                            onChange={(e) => setRepeat(e.target.checked)}
                                            valuePropName='checked'>
                                            <Checkbox
                                                className='form-input-new-style' style={{ width: '100%', padding: '17px 14px', backgroundColor: 'white' }}>Repeat</Checkbox>
                                        </Form.Item>
                                    </div> : null}
                            </div>

                            {repeat &&
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='repeatType'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.text_SelectRepeatType}</p>}
                                            initialValue={'weekly'}
                                        >

                                            <Radio.Group
                                                className='form-input-new-style'
                                                style={{
                                                    width: '100%',
                                                    padding: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    backgroundColor: 'white'
                                                }}
                                            >
                                                <Radio value={'daily'}>{selectedLanguage?.text_Daily}</Radio>
                                                <Radio value={'weekly'}>{selectedLanguage?.text_Weekly}</Radio>
                                                <Radio value={'monthly'}>{selectedLanguage?.text_Monthly}</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                        <Form.Item
                                            name='RepeatNumbers'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.text_NoOfRepeats}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.error_NoOfRepeats
                                                }
                                            ]}
                                            initialValue={2}
                                        >
                                            <InputNumber
                                                controls={false}
                                                type='number'
                                                min={2}
                                                step={1}
                                                precision={0}
                                                style={{ width: '100%' }}
                                                className='form-input-new-style'
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            }
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input '>
                                    <Form.Item
                                        label={<p className='my-form-label'>{selectedLanguage?.CreatePrivateit}</p>}
                                        name='isPrivate'
                                        initialValue={Private}
                                        onChange={onChangeCheckBox}
                                        valuePropName='checked'>
                                        <div
                                            className='form-input-new-style'
                                            style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white' }}
                                        >
                                            <p style={{ margin: '0px 10px' }}>{selectedLanguage?.text_public}</p>
                                            <Switch
                                                onChange={onChangeCheckBox}
                                                checked={Private}
                                                checkedChildren={<RxLockClosed size={19} style={{ height: 22, marginRight: 4 }} />}
                                                unCheckedChildren={<RxLockOpen1 size={19} style={{ height: 22, marginLeft: 4 }} />} />
                                            <p style={{ margin: '0px 10px' }}>{selectedLanguage?.text_private}</p>
                                        </div>
                                        {/* <Checkbox
                                            disabled={edit?.privateCode}
                                        >Private Tournament</Checkbox> */}
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='allowInvitation'
                                        hasFeedback
                                        label={<p className='my-form-label'>{selectedLanguage?.text_Invitecontacts}</p>}
                                        initialValue={Private ? true : edit?.allowInvitation || true}
                                    >

                                        <Radio.Group
                                            disabled={Private}
                                            onChange={handleAllowInvitation}
                                            className='form-input-new-style'
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundColor: 'white'
                                            }}
                                        >
                                            <Radio value={true}>{selectedLanguage?.text_yes}</Radio>
                                            <Radio value={false}>{selectedLanguage?.text_No}</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </div>
                            {allowInvitation &&
                                <>
                                    <div className='form-two-input-flex'>
                                        <div className='form-two-input-flex-inner-input'>
                                            <Form.Item
                                                name='allowFreeEntry'
                                                hasFeedback
                                                label={<p className='my-form-label'>{selectedLanguage?.text__Sendfreeentrytocontacts}</p>}
                                                initialValue={allowFreeEntry}
                                            // initialValue={}
                                            >

                                                <Radio.Group
                                                    onChange={handleAllowFreeEntry}
                                                    className='form-input-new-style'
                                                    style={{
                                                        width: '100%',
                                                        padding: '10px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        backgroundColor: 'white'

                                                    }}
                                                >
                                                    <Radio value={true}>{selectedLanguage?.text_yes}</Radio>
                                                    <Radio value={false}>{selectedLanguage?.text_No}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    {allowFreeEntry &&
                                        <div className='form-two-input-flex'>
                                            <div className='form-two-input-flex-inner-input'>
                                                <Form.Item
                                                    name='invitesContacts'
                                                    initialValue={edit?.invitesContacts}
                                                    hasFeedback
                                                    label={<p className='my-form-label'>{selectedLanguage?.label_InviteSelectedContactsFreely}</p>}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: selectedLanguage?.label_PleaseInviteSelectedContactsFreely
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        loading={contactLoading}
                                                        disabled={edit?._id ? false : !allPlayerRanks?.length}
                                                        mode='multiple'
                                                        className='form-input-new-style-select-minhi'
                                                        placeholder={selectedLanguage?.place_InviteSelectedContactsFreely}
                                                        notFoundContent={<p className='custom-nodata-p'>{selectedLanguage?.error_ConatctNotFoundWithSameGameSetting}</p>}
                                                        optionFilterProp='children'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }>
                                                        {playerContacts?.length && playerContacts?.map((v, i) => {
                                                            let isSameGameContact = allPlayerRanks?.filter((x) => x.userId._id === v.contactId._id);
                                                            return (
                                                                isSameGameContact?.[0]?._id &&
                                                                <Option value={v?.contactId?._id}>{`${v?.contactId?.name} - ${v?.contactId?.email} `}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                {/* <p style={{
                                                        margin: '0px',
                                                        color: '#eb1515',
                                                        marginTop: '-20px'
                                                    }}>{!allPlayerRanks?.length ?
                                                        selectedLanguage?.erorr_PleaseSelectGameFromTop : !playerContacts?.length ?
                                                            selectedLanguage?.error_YouHaveNotContactAddContactFromSetting : ''}</p> */}
                                            </div>
                                        </div>
                                    }
                                    {allowInvitation && !Private &&
                                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                            <Form.Item
                                                name='timeRange'
                                                initialValue={edit?.timeRange}
                                                hasFeedback
                                                label={<p className='my-form-label'>{selectedLanguage?.label_TimeRange}</p>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: selectedLanguage?.text_pleaseSetAReservedTime
                                                    }
                                                ]}
                                            >
                                                <InputNumber
                                                    type='number'
                                                    min={1}
                                                    max={24}
                                                    // step={0.5}
                                                    style={{ width: '100%' }}
                                                    className='form-input-new-style'
                                                    placeholder={selectedLanguage?.text_EnterTimeRangeInHrs}
                                                />
                                            </Form.Item>
                                        </div>
                                    }
                                </>
                            }
                            <Form.Item>
                                <Button
                                    // style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                    // className='addproduct-text'
                                    style={{
                                        padding: '0px 20px 0px 20px',
                                        background: 'rgba(92, 93, 93, 1)',
                                        color: 'white',
                                        border: 'none',
                                        height: '45px',
                                        marginTop: '10px',
                                        borderRadius: 6
                                    }}
                                    htmlType='submit'
                                    loading={loading}
                                >{edit?._id ? selectedLanguage?.button_updateTournament : selectedLanguage?.btntext_Createtournament}</Button>
                            </Form.Item>
                        </Form>
                    </div>


                </div>
                {/* Modal Confirm */}
                <Modal
                    open={confirmModal}
                    footer={null}
                    className='pop-info-modal'
                    style={{ top: '10px', }}
                >
                    <div>
                        <p className='p-question'>{selectedLanguage?.text_tournamentOrganizerMustPayTheCostFee}</p>
                        <p className='p-opt'>{selectedLanguage?.text_AdminserviceFee}:€ {tournamentServiceFee || 0}</p>
                        <p className='p-opt'>{selectedLanguage?.text_tmntyoneBTW}:€ {tournamentServiceBTW}</p>
                        <p className='p-opt'>{selectedLanguage?.text_AdminserviceFeeBTW}:€ {tournamentServiceFeeWithBTW}</p>
                        <p className='p-opt'>{selectedLanguage?.text_TotalinclBTW}:€ {`${(tournamentServiceFeeWithBTW)}`}</p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <button className='cancel-btn' onClick={handleCancel}>{selectedLanguage?.text_Cancel}</button>
                        <button className='confirm-btn' onClick={handleOk}>{selectedLanguage?.sidemenuTextCreateTournament}</button>
                    </div>
                </Modal>
                {/*  Modal Confirm*/}
            </div>
        </div>
    )
}

export default CreatePlayerTournament