import { Image, Input, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { BTW_TEX, convertRoundNumber, errorMessage, getPointSetting, successMessage } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import axios from "axios";
import { POST } from "../../../utils/apis";

const DollerTournamentSubscribeModal = (props) => {
    const { selectedLanguage, isEnglish, user, userActions, handleClose, data, /* getAllTournament,  */pointSettingAction, getUser } = props
    const AdminServiceFee = useSelector(state => state?.pointSettingReducer)
    const tournamentServiceFee = AdminServiceFee?.dollerTournamentSubscriptionFee
    const tournamentServiceBTW = convertRoundNumber((tournamentServiceFee * BTW_TEX))
    const tournamentServiceFeeWithBTW = convertRoundNumber(tournamentServiceFee + (tournamentServiceFee * BTW_TEX))
    const userRanks = useSelector(state => state.userReducer.userRanks)
    let invitedUser = data?.invitesContacts?.includes(user?._id)
    const [privateCodeValue, setPrivateCodeValue] = useState('')
    const [showCouponInput, setShowCouponInput] = useState(false)
    const [loading, setLoading] = useState(false)
    console.log(tournamentServiceFeeWithBTW, "=tournamentServiceFeeWithBTW")
    useEffect(() => {
        setLoading(false)
        getPointSetting(pointSettingAction)
    }, [])

    function handlePrivateCodeChange(event) {
        const { value } = event.target;
        const trimmedValue = value.trim()
        setPrivateCodeValue(trimmedValue);
        // Do something with the input value
    }
    const handleSuccess = () => {
        handleClose()
        getUser()
    }

    const handleOKPromotedSubModal = () => {
        console.log(data)
        let gameId = data?.gameId?._id
        let participationMoney = data?.participationMoney
        let rankingNeeded = data?.rankingNeeded
        let tournamentId = data?._id
        let isFreeInvitedUser = data?.invitesContacts?.includes(user?._id)
        let isPrivate = data?.isPrivate
        let isPrivateCodeFound = data?.privateCode
        let gameIdExist = userRanks?.find((v) => v?.gameId?._id?.toString() === gameId)?._id

        if (!gameIdExist) {
            return errorMessage(`${selectedLanguage?.text_Add} ${data?.gameId?.name} ${selectedLanguage?.text_inyourgamesettings}!`)
        }
        setLoading(true)
        if (isPrivateCodeFound) {
            if (isPrivateCodeFound && !privateCodeValue) {
                setLoading(false)
                return errorMessage(selectedLanguage?.text_EnterPrivateCode)
            }
            if (privateCodeValue === data?.privateCode) {
                let obj = {
                    userId: user?._id,
                    tournamentId: tournamentId,
                    moneyDeduct: isFreeInvitedUser ? participationMoney : participationMoney * 2,
                    gameId,
                    participationMoney,
                    rankingNeeded,
                }
                console.log(obj)
                axios.post(POST?.DOLLER_TOURNAMENT_PARTICIPATE, obj)
                    .then((res) => {
                        const { data } = res
                        setLoading(false)
                        if (data?.success) {
                            successMessage(isEnglish ? data?.message : data?.messageNl)
                            handleSuccess()
                        }
                        else {
                            errorMessage(isEnglish ? data?.message : data?.messageNl)
                        }
                    })
                    .catch((e) => {
                        setLoading(false)
                        errorMessage(isEnglish ? e?.message : e?.messageNl)
                    })
            }
            else {
                setTimeout(() => {
                    setLoading(false)
                    errorMessage(selectedLanguage?.error_ThePrivateCodeisIncorrectorWrong)
                }, 1500);
            }
        }
        else {
            let obj = {
                userId: user?._id,
                tournamentId: tournamentId,
                moneyDeduct: isFreeInvitedUser ? participationMoney : participationMoney * 2,
                gameId,
                participationMoney,
                rankingNeeded,
                isInvited: isFreeInvitedUser
            }
            console.log(obj)
            axios.post(POST?.DOLLER_TOURNAMENT_PARTICIPATE, obj)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data?.success) {
                        successMessage(isEnglish ? data?.message : data?.messageNl)
                        handleSuccess()
                    }
                    else {
                        errorMessage(isEnglish ? data?.message : data?.messageNl)
                    }
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage(isEnglish ? e?.message : e?.messageNl)
                })
        }
    }
    return (
        <>
            <Modal
                open={data?._id}
                footer={null}
                className='pop-info-modal'
                style={{ top: '10px', }}
            >
                <div>
                    <p className='p-question'>{selectedLanguage?.text_AreYouSureYouWantToSubscribeTournament}</p>
                    {data?.promoterId?._id &&
                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <p>{selectedLanguage?.text_advertisement}</p>
                            <Image style={{ width: '100%', boxShadow: 'darkgrey 0px 0px 9px', borderRadius: '8px' }} src={data?.promoterId?.tournamentFile?.url} />
                        </div>
                    }
                    <p className='p-opt'>{selectedLanguage?.text_bySubscribingTournamentMoneyWillBeDeductFromYourWallet}</p>
                    <p className='p-opt'>{selectedLanguage?.text_money}:{invitedUser ? '$ 0' : `€ ${data?.participationMoney}`}</p>
                    <p className='p-opt'>{selectedLanguage?.text_penaltymoney}:€ {`${data?.participationMoney}`}</p>
                    <p className='p-opt'>{selectedLanguage?.text_AdminserviceFee}: € {tournamentServiceFee || 0}</p>
                    <p className='p-opt'>{selectedLanguage?.text_tmntyoneBTW}:€ {tournamentServiceBTW}</p>
                    <p className='p-opt'>{selectedLanguage?.text_AdminserviceFeeBTW}:€ {tournamentServiceFeeWithBTW}</p>
                    <p className='p-opt'>{selectedLanguage?.text_TotalinclBTW}:€ {`${(invitedUser ? data?.participationMoney : data?.participationMoney * 2) + (+tournamentServiceFeeWithBTW)}`}</p>
                </div>
                {data?.privateCode &&
                    <div >
                        <p style={{ margin: '0px', padding: '4px' }}>{selectedLanguage?.text_ThisTournamentIsPrivateFromAdminEnterPrivateCodeForSubscribtion}</p>
                        <p style={{ margin: '0px', padding: '4px' }}>{selectedLanguage?.text_PrivateCode}</p>
                        <Input
                            value={privateCodeValue}
                            onChange={handlePrivateCodeChange}
                            className='form-input-new-style'
                            placeholder={selectedLanguage?.text_EnterPrivateCode}
                        />
                    </div>}
                <div style={{ display: 'flex', marginTop: 10 }}>
                    <button className='cancel-btn' onClick={handleClose}>{selectedLanguage?.text_Cancel}</button>
                    <button className='confirm-btn' onClick={handleOKPromotedSubModal}>{loading && <Spin />}{selectedLanguage?.btn_Subscribe}</button>
                </div>
            </Modal >
        </>
    )
}

export default DollerTournamentSubscribeModal