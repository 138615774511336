import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { Button, Card, Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillStar } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import NoCards from "../../../assets/NoCards.png";
import { POST } from '../../../utils/apis';
import { ageOptions } from '../../../utils/constants';
import { calculateReviewAverage, errorMessage, successMessage } from '../../../utils/helpers';
import CourtReview from '../PlayerReview/CourtReview';
import BuyCourt from '../BuyCourt/BuyCourt';
import AddPrivateCode from '../AddPrivateCode/AddPrivateCode';
import { loginUser } from '../../../Redux/actions/authActions';

const PlayerPrivateCourt = (props) => {
    const { user, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [showSecretCode, setShowSecretCode] = useState(false)
    const [filteredProduct, setFilteredProduct] = useState()
    const [productCode, setProductCode] = useState(user?.productCode)
    const [buyProduct, setBuyProduct] = useState({})
    const [favouriteSpin, setFavouriteSpin] = useState({})
    const [userData, setUserData] = useState(user)
    const [reviewModal, setReviewModal] = useState({})
    const [ProgressLoading, setProgressLoading] = useState(false)
    const dispatch = useDispatch()
    const isEnglish = language?.language === 'english'

    useEffect(() => {
        getPrivateCourt()
    }, [productCode])

    const gotoReview = (v) => {
        setProgressLoading(true)
        setReviewModal(v)
        setTimeout(() => {
            setProgressLoading(false)
        }, 2000);
    }
    const closeReviewModal = (v) => {
        setReviewModal({})
    }

    const getPrivateCourt = () => {
        setLoading(true)
        let obj = {
            // productCode: user?.productCode
            productCode: [...productCode]
        }

        axios.post(POST?.GET_PRIVATE_COURTS, obj)
            .then((res) => {
                const { data } = res
                setFilteredProduct(data?.data)
                console.log('data?.datadata?.datadata?.data', data?.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
            })
    }

    const calcSoldProducts = (arr) => {
        return arr?.reduce((sum, { soldProduct }) => sum + soldProduct, 0)
    }

    const changeFav = (_id, add) => {
        setFavouriteSpin({ [_id]: true })
        let tempData = { ...userData }
        let obj = {
            favourites: [...userData?.favourites, _id],
            _id: user?._id
        }

        if (!add) {
            obj.favourites = userData?.favourites?.filter((v) => v !== _id)
        }

        tempData.favourites = obj.favourites
        axios.post(POST?.ADD_FAVOURITE_COURT, obj)
            .then((res) => {
                const { data } = res
                setFavouriteSpin({})
                if (data?.success) {
                    setUserData(tempData)
                    dispatch(loginUser(tempData))
                    getPrivateCourt()
                    successMessage(add ? selectedLanguage?.SuccessfullyAddedToFavourites : selectedLanguage?.Successfullyremovedfromfavourites)

                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setFavouriteSpin({})
                console.log('e', e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }


    return (
        <div className='player-privat-court-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginTop: '10px',
                }}
            >
                {showSecretCode ?
                    <div className='flex-column menu-main'>
                        <AddPrivateCode
                            onCancel={() => (setShowSecretCode(false))}
                            getProducts={getPrivateCourt}
                            setProductCode={setProductCode}
                            {...props} />
                    </div> :
                    buyProduct?._id ?
                        <div className='flex-column menu-main'>
                            <BuyCourt calcSoldProducts={calcSoldProducts} product={buyProduct} onCancel={() => setBuyProduct({})} {...props} />
                        </div> :
                        <div className='cards-main-div-guest-all-crds' style={{ marginTop: '20px' }} >
                            <div className='div-flex-back-name'>
                                <div className='height-35'>
                                    <p className='screen-heading'>{selectedLanguage?.Text_PrivateCourts}</p>
                                </div>
                            </div>
                            <div className='div-flex-back-res'
                                style={{ margin: '0px', marginTop: '10px' }}
                            >
                                <div className='height-35' style={{
                                    marginRight: 14
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                        <Button
                                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                            className='addproduct-text'
                                            onClick={() => setShowSecretCode(true)}
                                        >
                                            {selectedLanguage?.AddPrivateCode}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                                <div
                                    className='sidebar-scroll_class'
                                    style={{
                                        marginTop: 8,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        overflowX: 'scroll'
                                    }}>
                                    {loading ?
                                        [1, 2, 3, 4].map((v, i) => {
                                            return (<Card
                                                hoverable
                                                loading={loading}
                                                bordered={false}
                                                className='loading-card-style'
                                                style={{
                                                    height: 285,
                                                    width: '290px',
                                                    minWidth: '290px',
                                                    background: '#00000057',
                                                    marginRight: '10px'
                                                }}
                                            />)
                                        }) :
                                        filteredProduct?.length ?
                                            filteredProduct.map((v, i) => {
                                                return (
                                                    <div
                                                        style={{
                                                            height: 285,
                                                            width: '290px',
                                                            minWidth: '290px',
                                                            borderRadius: '4px',
                                                            margin: '4px',
                                                            marginRight: '10px',
                                                            boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                            cursor: 'pointer',
                                                            background: 'rgba(0, 0, 0, 0.34)'
                                                        }}>
                                                        <div style={{ textAlign: 'center', margin: '8px', position: 'relative' }}                                                >
                                                            <img onClick={() => setBuyProduct(v)} src={v?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                                            <p style={{ position: 'absolute', top: '10px', left: '10px', background: 'white', height: '20px', width: '20px', borderRadius: '100px' }}>
                                                                {favouriteSpin[v?._id] ?
                                                                    <Spin /> :
                                                                    !userData?.favourites?.includes(v?._id) ?
                                                                        <HeartOutlined onClick={() => changeFav(v?._id, true)} style={{ color: 'red' }} />
                                                                        : <HeartFilled onClick={() => changeFav(v?._id, false)} style={{ color: 'red' }} />
                                                                }
                                                            </p>
                                                        </div>
                                                        <div style={{ textAlign: 'center', margin: '8px' }}>
                                                            <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                <div onClick={() => setBuyProduct(v)} >
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 18,
                                                                            color: 'white',
                                                                        }}>{v?.name || '------'}</p>
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: 'white',
                                                                            width: '100px',
                                                                        }}>{v?.userId?.city || '------'}</p>

                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'flex-end',
                                                                        height: '100px',
                                                                        marginTop: '-8px',
                                                                    }}
                                                                    onClick={() => { gotoReview(v) }} >
                                                                    <button className='guest-card-review-box'>
                                                                        {v?.review?.length ?
                                                                            <AiFillStar size={15} color='white' />
                                                                            : null}
                                                                        {!v?.isReviewHide ? v?.review?.length ? calculateReviewAverage(v?.review) : 0 : 0}
                                                                    </button>
                                                                    <p className='card_desc1' style={{ textAlign: 'end', color: 'white' }}>
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                calculateReviewAverage(v?.review) < 4.5 ?
                                                                                    selectedLanguage?.Bad + ',' :
                                                                                    calculateReviewAverage(v?.review) < 5.9 ?
                                                                                        selectedLanguage?.Poor + ',' :
                                                                                        calculateReviewAverage(v?.review) < 6.9 ?
                                                                                            selectedLanguage?.Adeqate + ',' :
                                                                                            calculateReviewAverage(v?.review) < 8.9 ?
                                                                                                selectedLanguage?.Good + ',' :
                                                                                                selectedLanguage?.Excellent + ',' : null : null}
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                v?.review?.length !== 1 ?
                                                                                    v?.review?.length > 999 ?
                                                                                        ' ' +
                                                                                        `${v?.review?.length?.slice}`(0, 1) +
                                                                                        '.' +
                                                                                        `${v?.review?.length}`?.slice(1, 2) +
                                                                                        'k' + ' ' + selectedLanguage?.Reviews :
                                                                                        ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                                    ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                                                selectedLanguage?.NoReviewesYet :
                                                                            selectedLanguage?.NoReviewesYet}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <div onClick={() => setBuyProduct(v)}>
                                                                <p style={{
                                                                    color: 'white',
                                                                    textAlign: 'start',
                                                                    lineHeight: 1.4,
                                                                    height: '40px',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    lineBreak: 'anywhere',
                                                                    marginBottom: 0
                                                                }}
                                                                    className="card_sold"
                                                                >
                                                                    {v?.description || '-------------------------------'}
                                                                </p>
                                                            </div>
                                                            <span onClick={() => setBuyProduct(v)}>
                                                                <div className='card_sold_price'>
                                                                    <p className='card_sold' style={{ color: 'white' }}>{selectedLanguage?.Sold}:{calcSoldProducts(v?.reservation?.settings || [])}</p>
                                                                    <p className='card_sold' style={{ color: 'white' }}>€{' ' + v?.price}</p>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{ height: '280px', width: '100%' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                }

                <CourtReview ProgressLoading={ProgressLoading} reviewModal={reviewModal} closeReviewModal={closeReviewModal}  {...props} />
            </div>
        </div>
    )
}

export default PlayerPrivateCourt