import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Typography, InputNumber } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { POST } from '../../../utils/apis'
import { errorMessage, getPointSetting, getUserData, successMessage } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
const { Title } = Typography

const AddCashback = (props) => {
    const { user, userActions, getCashBackForm, selectedLanguage, isEnglish, getUser, pointSettingAction } = props
    const AdminServiceFee = useSelector(state => state?.pointSettingReducer)
    const cashBackServiceFee = AdminServiceFee?.cashbackServiceFee || 0
    const [visible, setVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState({})
    const [amount, setAmount] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)
    const wallet = user?.walletBalance

    useEffect(() => {
        getUserData(user?._id, userActions)
        getUser()
        getPointSetting(pointSettingAction)
    }, [])

    console.log('wallet', wallet)
    console.log('cashBackServiceFee', cashBackServiceFee)

    const showModal = () => {
        setVisible(true)
    }

    const handleOk = () => {
        setConfirmLoading(true)
        let obj = {
            playerId: user?._id,
            amount: Number(amount),
            serviceCharge: cashBackServiceFee
        }
        console.log(obj, "obj")
        setLoading(true)
        if (Number(amount) > wallet) {
            setLoading(false)
            errorMessage(selectedLanguage?.backendMsg_InsufficientBalanceinYourwallet)
        } else if ((Number(amount) + cashBackServiceFee) > wallet) {
            setLoading(false)
            errorMessage(`${selectedLanguage?.text_Themaximumamountyoucanwithdrawis} € ${wallet - cashBackServiceFee}, ${selectedLanguage?.text_As} ${cashBackServiceFee} ${selectedLanguage?.text_isServiceCharges}`)


        } else {
            setLoading(true)
            axios.post(POST.CASH_BACK_REQUEST, obj)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        getCashBackForm()
                        successMessage(isEnglish ? data?.message : data?.messageNl)
                        setVisible(false)
                    }
                    else {
                        console.log('else')
                        errorMessage(isEnglish ? data?.message : data?.messageNl)
                    }
                })
                .catch((e) => {
                    console.log('e ', e)
                    setVisible(false)
                    setLoading(false)
                    errorMessage(isEnglish ? e?.message : e?.messageNl)
                })
        }

        showModal1()
    }

    const handleCancel = () => {
        setVisible(false)
        showModal1()
    }

    const showModal1 = () => {
        setIsModalVisible(true)
    }

    const handleCancel1 = () => {
        setImage({})
    }

    return (
        <div>
            <Button
                style={{
                    padding: '0px 20px 0px 20px',
                    background: 'rgba(92, 93, 93, 1)',
                    color: 'white',
                    border: 'none',
                    height: '45px',
                    marginTop: '10px',
                    borderRadius: 6
                }} onClick={showModal} >
                {selectedLanguage?.text_CashbackRequest}
            </Button>
            <Modal
                title={<center>
                    <Title level={4}>{selectedLanguage?.title_CashBackFrom}</Title>
                </center>}
                open={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                closable={false}
                footer={[
                    <Button key='back' onClick={handleCancel} className='form-input'>
                        {selectedLanguage?.btn_Cancel}
                    </Button>,
                    <Button
                        key='ok'
                        style={{
                            padding: '0px 20px 0px 20px',
                            background: 'rgba(92, 93, 93, 1)',
                            color: 'white',
                            border: 'none',
                            // height: '45px',
                            marginTop: '10px',
                            borderRadius: 6
                        }}
                        onClick={handleOk}
                        loading={loading}
                    >
                        {selectedLanguage?.btn_ok}
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>{selectedLanguage?.text_EnteryourCashBackAmount}</h4>
                    <InputNumber
                        type='number'
                        className='form-input-new-style input12345'
                        style={{ width: '100%', height: '45px' }}
                        autoFocus
                        required
                        step={1}
                        maxLength={11}
                        value={amount}
                        prefix="€"
                        onChange={(e) => setAmount(e)}
                        placeholder='e.g: 145'
                    />
                    <p>{selectedLanguage?.text_Themaximumamountyoucanwithdrawis} € {(wallet - (cashBackServiceFee ? cashBackServiceFee : 0)).toFixed(2)} , {selectedLanguage?.text_As} € {(cashBackServiceFee ? cashBackServiceFee : 0)} {' '}{selectedLanguage?.text_isServiceCharges}</p>
                </div>
            </Modal>


        </div>
    )
}

export default AddCashback