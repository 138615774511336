import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { GET } from '../../../utils/apis'
import { adjustDataForProRank, errorMessage } from '../../../utils/helpers'
import NoData from '../../../assets/noDataImae.png'
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { TbEqual } from "react-icons/tb"

const AllProRankingPiont = (props) => {
    const { selectedLanguage, isEnglish, selectedGame, user } = props
    const [loading, setLoading] = useState(false)
    const [gameRanks, setGameRanks] = useState([])

    const getGameRanks = () => {
        setLoading(true)
        axios.get(`${GET?.GET_GAME_RANKS}/${selectedGame}`,)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setGameRanks(adjustDataForProRank(data?.data) || [])
                }
                else {
                    setGameRanks([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    useEffect(() => {
        getGameRanks()
    }, [selectedGame])




    // const columnsAllPlayer = [
    //     {
    //         title: selectedLanguage?.title_PlayerName,
    //         dataIndex: 'userId',
    //         key: 'name',
    //         render: (e) => {
    //             return (e?._id === user?._id ? e?.name + (selectedLanguage?.text_me) : e?.name)
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.tableText_Game,
    //         dataIndex: 'gameId',
    //         key: 'game',
    //         render: (e) => {
    //             return (e?.name)
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.tableText_Ranking,
    //         dataIndex: 'rank',
    //         key: 'ranking',
    //         render: (e) => {
    //             return (e)
    //         }
    //     },
    //     {
    //         title: `${selectedLanguage?.tableText_Points}-($)`,
    //         dataIndex: 'proPoint',
    //         key: 'proPoint',
    //     },
    // ]


    return (
        <div
            className='sidebar-scroll-class-black'
            style={{
                overflow: 'scroll',
                width: '98%',
                margin: '0px auto'
            }}
        >
            <div style={{
                marginTop: '10px',
                marginBottom: '5px',
                width: '100%',
                minWidth: '450px',
            }}>
                <div style={{
                    width: '100%',
                    height: '60px',
                    borderTopRightRadius: '4px',
                    borderTopLeftRadius: '4px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px',
                    alignItems: 'center',
                }}>
                    {[
                        { title: selectedLanguage?.title_PlayerName },
                        { title: selectedLanguage?.tableText_Ranking },
                        { title: `${selectedLanguage?.tableText_Points}-($)` },
                        { title: selectedLanguage?.tableText_WeeklyResult },
                    ]?.map((v, i) => {
                        return (
                            <p className='tableValue'
                                style={{ margin: '0px', color: 'white', fontWeight: 'bolder', fontSize: '20px', width: '20%' }}
                            >{v?.title}</p>
                        )
                    })}
                </div>
                <div
                    style={{
                        width: '100%',
                        borderBottomRightRadius: '4px',
                        borderBottomLeftRadius: '4px',
                        justifyContent: 'space-between',
                        padding: '5px',
                        alignItems: 'center',
                    }}>
                    {gameRanks?.length ?
                        gameRanks?.map((v, i) => {
                            return (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        borderTopRightRadius: '4px',
                                        borderTopLeftRadius: '4px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        padding: '5px',
                                        alignItems: 'center',
                                        margin: '0px',
                                        padding: '0px'
                                    }}>
                                    <p className='tableValue'>{v?.userId?._id === user?._id ? v?.userId?.name + (selectedLanguage?.text_me) : v?.userId?.name}</p>
                                    <p className='tableValue'>{v?.rank}</p>
                                    <p className='tableValue'>{v?.proPoint}</p>
                                    <p className='tableValue'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            {!v?.weaklyProPointReport?.length || v?.weaklyProPointReport?.[0]?.difference === 0 ?
                                                <TbEqual color='black' size={22} style={{
                                                    cursor: 'pointer',
                                                    background: 'white',
                                                    margin: '10px',
                                                    height: '30px',
                                                    width: '30px',
                                                    borderRadius: '20px',
                                                    padding: '4px'
                                                }} />
                                                :
                                                v?.proPointUp ?
                                                    <AiOutlineArrowUp color='green' size={22} style={{
                                                        cursor: 'pointer',
                                                        background: 'white',
                                                        margin: '10px',
                                                        height: '30px',
                                                        width: '30px',
                                                        borderRadius: '20px',
                                                        padding: '4px'
                                                    }} /> :
                                                    <AiOutlineArrowDown color='red' size={22} style={{
                                                        cursor: 'pointer',
                                                        background: 'white',
                                                        margin: '10px',
                                                        height: '30px',
                                                        width: '30px',
                                                        borderRadius: '20px',
                                                        padding: '4px'
                                                    }} />}
                                            <p style={{ margin: '16px' }}>
                                                {v?.weaklyProPointReport?.[0]?.difference || 0}
                                            </p>
                                        </div>
                                    </p>
                                </div>
                            )
                        })
                        :
                        <div style={{ height: '160px', textAlign: 'center' }}>
                            <img src={NoData} style={{ height: '150px', width: '150px' }} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AllProRankingPiont

