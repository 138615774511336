import PDFDocument from '@react-pdf/pdfkit'
import { message, notification } from 'antd'
import axios from 'axios'
import blobStream from 'blob-stream'
import moment from 'moment'
import QRCode from 'qrcode'
import allPaths from '../Config/paths'
import { ADMIN, GET } from './apis'
import momentTz from 'moment-timezone'

const generateQR = async (text) => {
    let result = await QRCode.toDataURL(text)

    return result
}

const errorMessageWithDuration = (desc = 'Oops Something Went Wrong!') => {
    return message.error(desc, [0.8])
}
const requiredMessage = (value) => `Please input your ${value}!`

const inputPlace = (value) => `Input your ${value} Here...!`

const setActiveMenu = (path) => path === allPaths.HOME ? 0 : 1

const successMessage = (desc = 'Successfully Complete!') => {
    return message.success(desc)
}

const infoMessage = (desc = 'Successfully Complete!') => {
    return message.info(desc)
}

const errorMessage = (desc = 'Oops Something Went Wrong!') => {
    return message.error(desc)
}

const warningMessage = (desc = 'Warning!') => {
    return message.warning(desc)
}

const successNotification = (message = 'Successfully Complete!') => {
    return notification.success({ message })
}

const errorNotification = (message = 'Oops Something Went Wrong!') => {
    return notification.error({ message })
}

const convertTitle = (val) => val.charAt(0).toUpperCase() + val.slice(1)

const stringLimiter = (val, limit = 50) => val?.length > limit ? `${val.slice(0, limit)}...` : val

const assignRanks = (arr, userId) => {
    if (arr?.length) {
        const sortedArr = arr.sort((a, b) => {
            if (a.gameId._id === b.gameId._id) {
                return b.point - a.point;
            } else {
                return a.gameId._id.localeCompare(b.gameId._id);
            }
        });

        let ranks = {};
        let rank = 1;

        for (let i = 0; i < sortedArr.length; i++) {
            let obj = sortedArr[i];
            let gameId = obj.gameId._id;
            let point = obj.point;
            // let userId = obj.userId._id;

            if (ranks[gameId] === undefined) {
                ranks[gameId] = {};
                rank = 1;
            } else {
                if (ranks[gameId][point] !== undefined) {
                    rank = ranks[gameId][point] + 1;
                }
            }

            obj.rank = rank;
            ranks[gameId][point] = rank;

            rank++;
        }

        // return sortedArr;

        const filteredArray = sortedArr?.filter(obj => obj?.userId?._id === userId);
        const sortedArray = filteredArray?.sort((a, b) => {
            if (a.rank > b.rank) {
                return -1;
            }
            if (a.rank < b.rank) {
                return 1;
            }
            return 0;
        }).sort((a, b) => {
            if (a.rank === 1) {
                return -1;
            }
            if (b.rank === 1) {
                return 1;
            }
            return 0;
        });
        return sortedArray;
    }
    else {
        return [];
    }
}
const assignProRanks = (arr, userId) => {
    if (arr?.length) {
        const sortedArr = arr.sort((a, b) => {
            if (a.gameId._id === b.gameId._id) {
                return b.proPoint - a.proPoint;
            } else {
                return a.gameId._id.localeCompare(b.gameId._id);
            }
        });

        let ranks = {};
        let rank = 1;

        for (let i = 0; i < sortedArr.length; i++) {
            let obj = sortedArr[i];
            let gameId = obj.gameId._id;
            let proPoint = obj.proPoint;
            // let userId = obj.userId._id;

            if (ranks[gameId] === undefined) {
                ranks[gameId] = {};
                rank = 1;
            } else {
                if (ranks[gameId][proPoint] !== undefined) {
                    rank = ranks[gameId][proPoint] + 1;
                }
            }

            obj.rank = rank;
            ranks[gameId][proPoint] = rank;

            rank++;
        }

        // return sortedArr;

        const filteredArray = sortedArr?.filter((obj) => obj?.userId?._id === userId);
        const sortedArray = filteredArray
            .sort((a, b) => {
                if (a.rank > b.rank) {
                    return -1;
                }
                if (a.rank < b.rank) {
                    return 1;
                }
                return 0;
            })
            .sort((a, b) => {
                if (a.rank === 1) {
                    return -1;
                }
                if (b.rank === 1) {
                    return 1;
                }
                return 0;
            });
        return sortedArray;
    } else {
        return [];
    }
};
const assignCompetitionRanks = (arr, userId) => {
    if (arr?.length) {
        const sortedArr = arr.sort((a, b) => {
            if (a.competition._id === b.competition._id) {
                return b.point - a.point;
            } else {
                return a.competition._id.localeCompare(b.competition._id);
            }
        });

        let ranks = {};
        let rank = 1;

        for (let i = 0; i < sortedArr.length; i++) {
            let obj = sortedArr[i];
            let competition = obj.competition._id;
            let point = obj.point;
            // let userId = obj.userId._id;

            if (ranks[competition] === undefined) {
                ranks[competition] = {};
                rank = 1;
            } else {
                if (ranks[competition][point] !== undefined) {
                    rank = ranks[competition][point] + 1;
                }
            }

            obj.rank = rank;
            ranks[competition][point] = rank;

            rank++;
        }

        // return sortedArr;
        const filteredArray = sortedArr?.filter((obj) => obj?.userId?._id === userId);
        const sortedArray = filteredArray
            .sort((a, b) => {
                if (a.rank > b.rank) {
                    return -1;
                }
                if (a.rank < b.rank) {
                    return 1;
                }
                return 0;
            })
            .sort((a, b) => {
                if (a.rank === 1) {
                    return -1;
                }
                if (b.rank === 1) {
                    return 1;
                }
                return 0;
            });
        return sortedArray;
    } else {
        return [];
    }
}

let myTournametSetting = (tournament, myGameSetting, userId) => {
    const tournaments = tournament
    if (!tournaments?.length) {
        return []
    }
    const invitesContactsTournaments = tournaments?.filter((tournament) => {
        return (
            tournament.invitesContacts && tournament?.invitesContacts?.includes(userId)
        );
    });

    const sortedInvitesContactsTournaments = invitesContactsTournaments?.sort((a, b) => {
        let dateA = `${a.tournamentDate} ${a.timeFirstRound}`
        let dateB = `${b.tournamentDate} ${b.timeFirstRound}`
        const aMoment = moment(dateA, 'DD-MM-YYYY HH:mm');
        const bMoment = moment(dateB, 'DD-MM-YYYY HH:mm');
        if (aMoment.isBefore(bMoment)) {
            return -1;
        } else if (aMoment.isAfter(bMoment)) {
            return 1;
        } else {
            return 0;
        }
    });

    const onlyInviteContactTournaments = tournaments.filter((tournament) => {
        return (
            !tournament?.invitesContacts?.includes(userId) && tournament.onlyInviteContact && tournament.onlyInviteContact?.includes(userId)
        );
    });

    const sortedOnlyInviteContactTournaments = onlyInviteContactTournaments?.sort((a, b) => {
        let dateA = `${a.tournamentDate} ${a.timeFirstRound}`
        let dateB = `${b.tournamentDate} ${b.timeFirstRound}`
        const aMoment = moment(dateA, 'DD-MM-YYYY HH:mm');
        const bMoment = moment(dateB, 'DD-MM-YYYY HH:mm');
        if (aMoment.isBefore(bMoment)) {
            return -1;
        } else if (aMoment.isAfter(bMoment)) {
            return 1;
        } else {
            return 0;
        }
    });

    const adminTournaments = tournaments.filter((tournament) => {
        return (
            !tournament?.invitesContacts?.includes(userId) &&
            !tournament.onlyInviteContact?.includes(userId) &&
            tournament.ownerType === 'admin'
        );
    });

    const sortedAdmintournamnet = adminTournaments?.sort((a, b) => {
        let dateA = `${a.tournamentDate} ${a.timeFirstRound}`
        let dateB = `${b.tournamentDate} ${b.timeFirstRound}`
        const aMoment = moment(dateA, 'DD-MM-YYYY HH:mm');
        const bMoment = moment(dateB, 'DD-MM-YYYY HH:mm');
        if (aMoment.isBefore(bMoment)) {
            return -1;
        } else if (aMoment.isAfter(bMoment)) {
            return 1;
        } else {
            return 0;
        }
    });

    const playerTournaments = tournaments.filter((tournament) => {
        return (
            !tournament?.invitesContacts?.includes(userId) &&
            !tournament.onlyInviteContact?.includes(userId) &&
            tournament.ownerType === 'player'
        );
    });

    const sortedPlayerTournaments = playerTournaments?.sort((a, b) => {
        let dateA = `${a.tournamentDate} ${a.timeFirstRound}`
        let dateB = `${b.tournamentDate} ${b.timeFirstRound}`
        const aMoment = moment(dateA, 'DD-MM-YYYY HH:mm');
        const bMoment = moment(dateB, 'DD-MM-YYYY HH:mm');
        if (aMoment.isBefore(bMoment)) {
            return -1;
        } else if (aMoment.isAfter(bMoment)) {
            return 1;
        } else {
            return 0;
        }
    });

    let sortedTournament = [...sortedInvitesContactsTournaments, ...sortedOnlyInviteContactTournaments, ...sortedAdmintournamnet, ...sortedPlayerTournaments]
    let myGameIds = myGameSetting?.map(obj => obj?.gameId)
    let filteredTournament = sortedTournament?.filter((item) => {
        let createdTime = moment(item?.created).format('YYYY-MM-DD HH:mm:ss')
        let showingDate = moment(createdTime).add(item?.timeRange, 'hours');
        let showDateWithFormat = showingDate.format('YYYY-MM-DD HH:mm:ss')
        let hasPassed = moment(showDateWithFormat).isBefore(moment());
        let contactIdIncludesForFreeInvite = item?.invitesContacts?.includes(userId)
        let contactIdIncludesForInvite = item?.onlyInviteContact?.includes(userId)
        let isPlayerPrivate = item?.isPrivate && item?.ownerType === 'player'
        item.iAmInvited = contactIdIncludesForInvite ? true : false //craete new key in obj
        return myGameIds?.includes(item?.gameId?._id)
            && isPlayerPrivate ?
            (contactIdIncludesForFreeInvite || contactIdIncludesForInvite || userId === item?.ownerPlayerId?._id) :
            (contactIdIncludesForFreeInvite || hasPassed || contactIdIncludesForInvite || userId === item?.ownerPlayerId?._id);
    });
    return filteredTournament || []
}



function isWithinDisabledPeriod(dateTimeString, disabledBefore) {
    const [date, time] = dateTimeString.split(' ');
    const [day, month, year] = date.split('-');
    const [hours, minutes] = time.split(':');
    // Create a new Date object for the disabled date and time
    const disabledDate = new Date(
        Date.UTC(year, month - 1, day, hours, minutes)
    );
    // Get the timezone offset of the current timezone in minutes
    const timezoneOffset = new Date().getTimezoneOffset();
    // Adjust the disabled date and time based on the timezone offset
    const adjustedDisabledDate = new Date(
        disabledDate.getTime() + timezoneOffset * 60000
    );
    // Calculate the difference between the adjusted disabled date and the current date in hours
    const currentDate = new Date();
    const hoursDiff = (adjustedDisabledDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60);
    return hoursDiff <= disabledBefore;
}


function isDisabledSubscribe(disabledDate, disabledTime) {
    // Convert the string into a Date object
    const [day, month, year] = disabledDate.split('-');
    const [hour, minute] = disabledTime.split(':');
    const dateObject = new Date(year, month - 1, day, hour, minute);
    // Get the current date and time
    const currentDate = new Date();
    // Compare the two dates and times and return the result
    if (dateObject < currentDate) {
        return true; // The date and time are in the past
    } else {
        return false; // The date and time are in the future
    }
}
function disabledDate(current) {
    return current && current < moment().startOf('day');
}

function sortOponentArray(array, contact,) {
    let sortedArray = [];

    contact.forEach(c => {
        let contactIndex = array.findIndex(a => a._id === c.contactId?._id);
        if (contactIndex !== -1) {
            sortedArray.push(array[contactIndex]);
            array.splice(contactIndex, 1);
        }
    });

    return [...sortedArray, ...array];
}

function removePlayerById(arr, id) {
    return arr.filter(obj => obj.playerId._id !== id);
}

function sortPlayersForMatchRequest(player, playerContacts) {

    const sortedPlayersABC = player?.sort((a, b) => {
        const emailA = a.email.toLowerCase();
        const emailB = b.email.toLowerCase();

        if (emailA < emailB) {
            return -1;
        } else if (emailA > emailB) {
            return 1;
        } else {
            return 0;
        }
    });

    const sortedPlayers = sortedPlayersABC?.sort((a, b) => {
        const contactA = playerContacts.find(contact => contact?.contactId?._id === a._id);
        const contactB = playerContacts.find(contact => contact?.contactId?._id === b._id);

        if (contactA && !contactB) {
            return -1;
        } else if (!contactA && contactB) {
            return 1;
        } else {
            return 0;
        }
    });

    return sortedPlayers;
}

const adjustDataForRank = (data) => {
    data?.sort((a, b) => b.point - a.point);
    for (let i = 0; i < data?.length; i++) {
        data[i].rank = i + 1;
    }
    return data
}
const adjustDataForProRank = (data) => {
    data?.sort((a, b) => b.proPoint - a.proPoint);
    for (let i = 0; i < data?.length; i++) {
        data[i].rank = i + 1;
    }
    return data
}


const getGeneralData = async (generalActions) => {

    axios.get(ADMIN.GET_ALL_GAME)
        .then((res) => {
            const { data } = res
            generalActions.addAllGames(data?.data || [])
        })

    axios.get(ADMIN.GET_ALL_PROMOTER)
        .then((res) => {
            const { data } = res
            generalActions.addAllPromoter(data?.data || [])
        })

    axios.get(ADMIN?.GET_ALL_TOURNAMENT)
        .then((res) => {
            const { data } = res
            generalActions.adminTournament(data?.data || [])
        })

    axios.get(GET?.GET_ALL_COMPETITIONS)
        .then((res) => {
            const { data } = res
            generalActions.allCompetitions(data?.data || [])
        })
    axios.get(ADMIN?.GET_ALL_PLAYER_USERS)
        .then((res) => {
            const { data } = res
            generalActions.allPlayersUsers(data?.data || [])
        })
    axios.get(ADMIN?.GET_ALL_CLUB_USERS)
        .then((res) => {
            const { data } = res
            generalActions.allClubsUsers(data?.data || [])
        })
    axios.get(ADMIN?.GET_ADMIN_CLUBS)
        .then((res) => {
            const { data } = res
            generalActions.allAdminClubs(data?.data || [])
        })
}

const getPointSetting = async (pointSettingAction) => {
    axios.get(ADMIN.GET_SEND_DOLLER_MATCH_REQ_FEE)
        .then((res) => {
            const { data } = res
            pointSettingAction.AddMatchReqSendFee(data?.data?.[0]?.money || {})
        })
    axios.get(ADMIN.GET_SUBSCRIPTION_FEE_FOR_DOLLER_TOURNAMENT)
        .then((res) => {
            const { data } = res
            pointSettingAction.AddSubscriptionFeeDollerTournamnet(data?.data?.[0]?.money || 0)
        })
    axios.get(ADMIN.GET_TOURNAMENT_ORGANIZER_PAY_COST_POINT)
        .then((res) => {
            const { data } = res
            pointSettingAction.AddDollerTournamnetOrganizer(data?.data?.[0]?.money || 0)
        })
    axios.get(ADMIN.GET_CASHBACK_SERVICE_FEE)
        .then((res) => {
            const { data } = res
            pointSettingAction.AddCashbackServiceFee(data?.data?.[0]?.money || 0)
        })
    axios.get(ADMIN.GET_COMPETITION_SUB_SERVICE_FEE)
        .then((res) => {
            const { data } = res
            pointSettingAction.AddCompetitionSubServiceFee(data?.data?.[0]?.money || 0)
        })

}

const getUserData = async (userId, userActions) => {

    axios.get(`${GET.GET_PLAYER_CONTACT}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllPlayerContacts(data?.data || [])
        })
    axios.get(`${GET.GET_ALL_PLAYER}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllPlayer(data?.data || [])
        })
    axios.get(`${GET.GET_CLUB_INFO}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addClubInfo(data?.data || [])
        })
    // axios.get(`${GET.GET_PLAYER_GAME_SUGGESTION}/${userId}`)
    //     .then((res) => {
    //         const { data } = res
    //         userActions.addAllGameSuggestions(data?.data || [])
    //     })
    // axios.get(`${GET?.GET_ALL_TOURNAMENT}/${userId}`)
    //     .then((res) => {
    //         const { data } = res
    //         if (data?.success) {
    //             return userActions.addAllUserTournaments(data?.data || [])
    //         }
    //         else {
    //             return userActions.addAllUserTournaments([])
    //         }
    //     })
    // axios.get(`${GET?.GET_PLAYER_RANKS}/${userId}`)
    //     .then((res) => {
    //         const { data } = res
    //         userActions.addUserRanks(assignRanks(data?.data, userId) || [])
    //         userActions.addUserProRanks(assignProRanks(data?.data, userId) || [])
    //     })
    axios.get(`${GET?.GET_COMPETITION_RANKS}`)
        .then((res) => {
            const { data } = res
            userActions.addCompetitionRanks(assignCompetitionRanks(data?.data, userId) || [])
        })
    axios.get(`${GET?.GET_WALLET_TRANSACTION}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addWalletTransaction(data?.data || [])
        })

    axios.get(`${GET?.GET_SUBSCRIBED_COMPETITIONS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addSubscribedCompetitions(data?.data || [])
        })

    // new 

    axios.get(`${GET?.GET_COURTS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllCourts(data?.data || [])
        })

    axios.get(`${GET?.GET_COUPLE_REQUESTS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllCoupleRequests(data?.data || [])
        })

    axios.get(`${GET.GET_COUPLE}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllCouples(data?.data || [])
        })
    axios.get(`${GET.GET_OPPONENTS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllOpponents(data?.data || [])
        })
    axios.get(`${GET.GET_MEMBERS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllMembers(data?.data || [])
        })

    axios.get(`${GET.GET_COMPETITION_SUBSCRIPTIONS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addMySubscriptions(data?.data || [])
        })
    axios.get(`${GET.GET_MATCHES_COUNT}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addMyMatches(data?.data || [])
        })
    axios.get(`${GET.GET_MY_COMPETITION}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addMyCompetitions(data?.data || [])
        })

}

const getAllCourts = async (userId, userActions) => {
    axios.get(`${GET?.GET_COURTS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllCourts(data?.data || [])
        })
}

const getAllTournaments = async (userId, userActions) => {

    axios.get(`${GET.GET_ALL_TOURNAMENT}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllTournaments(myTournametSetting(data?.data, data?.myGameSetting, userId) || [])
        })

}
const getFaqHelper = async (faqSettingAction) => {
    axios.get(`${ADMIN?.GET_FAQ_QUESTION}`)
        .then((res) => {
            const { data } = res
            if (data?.success) {

                data?.howIsWorkArray?.length && data?.howIsWorkArray.sort((a, b) => a.priority - b.priority)
                data?.FaqArrayGrourp.Tournaments?.length && data?.FaqArrayGrourp.Tournaments.sort((a, b) => a.priority - b.priority);
                data?.FaqArrayGrourp.Goal?.length && data?.FaqArrayGrourp.Goal.sort((a, b) => a.priority - b.priority);
                data?.FaqArrayGrourp.Participation?.length && data?.FaqArrayGrourp.Participation.sort((a, b) => a.priority - b.priority);
                faqSettingAction.AddFaqSetting({
                    FaqArrayGrourp: data?.FaqArrayGrourp, howIsWorkArray: data?.howIsWorkArray
                })
            }
            else {
                faqSettingAction.AddFaqSetting({
                    FaqArrayGrourp: [], howIsWorkArray: []
                })
            }
        })
}

const getTournamentMatchData = async (userId, userActions) => {

    axios.get(`${GET.GET_ALL_TOURNAMENT}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllTournaments(myTournametSetting(data?.data, data?.myGameSetting, userId) || [])
        })

    axios.get(`${GET?.GET_TOURNAMENT_MATCHES}/${userId}`)
        .then((res) => {
            const { data } = res

            let response = data?.data?.map(item => {

                let matchDateTime = item?.matchDate + ' ' + item?.matchTime
                let momentMatchDate = moment(matchDateTime, 'DD-MM-YYYY HH:mm').add((Number(item?.gameId?.playingTime) * 1.5), 'minutes')
                let currentDateTime = moment(moment().format('DD-MM-YYYY HH:mm'), 'DD-MM-YYYY HH:mm')
                let timePassed = currentDateTime.isAfter(momentMatchDate, 'minutes')
                return { ...item, timePassed }
            })
            userActions.addAllTournamentMatches(response || [])
        })

}
const get1_1_MatchData = async (userId, userActions) => {
    axios.get(`${GET.GET_SENT_MATCH_REQUEST}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllSentMatches(data?.data || [])
        })
    axios.get(`${GET.GET_RECIVE_MATCH_REQUEST}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllReceiveMatches(data?.data || [])
        })
    axios.get(`${GET?.GET_APPROVED_MATCH_REQUEST}/${userId}`)
        .then((res) => {
            const { data } = res
            const newData = (data?.data || []).map(item => {
                if (item?.updateTime) {
                    let diffMinutes = moment.utc().diff(moment.utc(item?.updateTime), 'minutes');
                    return { ...item, diffMinutes: diffMinutes }
                } else {
                    return { ...item, diffMinutes: -1 }
                }
            })
            userActions.addAllMatchResults(newData || [])
        })
}

const getCompetitionMatches = async (userId, userActions) => {
    axios.get(`${GET.GET_SENT_MATCH_REQUEST_COMPETITION}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllSentCompetitionMatches(data?.data || [])
        })
    // new 
    axios.get(`${GET.GET_RECIVE_MATCH_REQUEST_COMPETITION}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllReceiveCompetitionMatches(data?.data || [])
        })

    axios.get(`${GET?.GET_COMPETITION_APPROVED_MATCH_REQUEST}/${userId}`)
        .then((res) => {
            const { data } = res

            const newData = (data?.data || []).map(item => {
                if (item?.updateTime) {
                    let diffMinutes = moment.utc().diff(moment.utc(item?.updateTime), 'minutes');
                    return { ...item, diffMinutes: diffMinutes }
                } else {
                    return { ...item, diffMinutes: -1 }
                }
            })
            userActions.addAllCompetitionMatches(newData || [])
        })
}

function checkIfDateTimePassedForMatchResultBtns(obj) {
    let gamePlayingTime = +(obj?.gameId?.playingTime) || 0
    let currentTime = moment(); // Get the current date and time
    let itemDateTime = moment(`${obj?.matchDate} ${obj?.matchTime}`, 'DD-MM-YYYY HH:mm');
    itemDateTime.add(gamePlayingTime, 'minutes');// Add minitues to match dates
    return itemDateTime.isBefore(currentTime);
}

const toBase64 = async (url) => {
    try {
        const data = await fetch(url)
        const blob = await data.blob()
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                const base64data = reader.result
                resolve(base64data)
            }
        })
    } catch (e) {
        console.log('e', e)
    }
}

const createGiftCard = async (arr, spinFunc) => {
    try {
        const doc = new PDFDocument({ size: [907.1, 1275.6] })
        const stream = doc.pipe(blobStream())

        let width = 209.76
        let height = 147.4

        var x = 13
        var y = 10
        console.log('a')
        let iconUrl = arr[0].imageUrl || 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'
        let imageBase64 = await toBase64(iconUrl)

        for (var i in arr) {
            console.log('e')

            doc.image(imageBase64, x, y, { width, height })

            var index = Number(i) + 1

            if (index % 32 === 0) {
                x = 13
                y = 10
                doc.addPage()
            }
            else if (index % 4 === 0) {
                x = 13
                y += height + 10
            }
            else {
                x += width + 14
            }
        }
        console.log('b')
        doc.addPage()

        var xAxis = 0
        var yAxis = 0
        let QRwidth = 180
        let QRheight = 100


        for (var i in arr) {
            console.log('c')

            let obj = arr[i]
            console.log('obj', obj)
            let code = obj?.couponCode || obj?.giftCardCode
            doc.moveTo(20 + xAxis, 20 + yAxis).lineTo(215 + xAxis, 20 + yAxis).stroke()
            doc.moveTo(20 + xAxis, 20 + yAxis).lineTo(20 + xAxis, 153 + yAxis).stroke()
            doc.moveTo(20 + xAxis, 153 + yAxis).lineTo(215 + xAxis, 153 + yAxis).stroke()
            doc.moveTo(215 + xAxis, 20 + yAxis).lineTo(215 + xAxis, 153 + yAxis).stroke()

            let qrImage = await generateQR(code)

            let qrX = 60 + xAxis
            let qrY = 21 + yAxis

            doc.image(qrImage, qrX, qrY, { QRwidth, QRheight })

            y = 100 + yAxis
            x = 80 + xAxis
            let lineX = 78 + xAxis
            let lineY = 114 + yAxis
            var lineXEnd = 156 + xAxis

            lineY = lineY + 35
            y = y + 35

            // doc.text('abcd', x, y-5)
            // 
            doc.text(code, x, y)
            doc.moveTo(lineX, lineY).lineTo(lineXEnd, lineY).stroke()

            var index = Number(i) + 1

            if (index % 32 === 0) {
                xAxis = 0
                yAxis = 0
                doc.addPage()
            }
            else if (index % 4 === 0) {
                xAxis = 0
                yAxis += height + 10
            }
            else {
                xAxis += width + 14
            }
        }
        console.log('d')

        doc.end()

        stream.on('finish', () => {
            const blob = stream.toBlob('application/pdf')
            const blobUrl = URL.createObjectURL(blob)
            const a = document.createElement('a')

            spinFunc()

            a.href = blobUrl
            a.download = 'coupon.pdf'
            a.click()
        })
    } catch (e) {
        console.log('error', e)
        spinFunc()
        return errorMessage('some thing went wrong')
    }

}
const convertNumber = (num) => `${parseFloat(num)?.toFixed(2)}`
const convertRoundNumber = (num) => `${(Math.round(num * 100) / 100).toFixed(2)}`
const convertRoundNumber4 = (num) => {
  const roundedNum = Math.round(num * 1000) / 1000; // Round to 3 decimal places
  return roundedNum.toFixed(4); // Format with 3 decimal places
};
const convertNumberCardCoupon = (num) => { return `${parseFloat(num).toFixed(2)}` }
const dateFormater = (d) => momentTz(d).format('ddd MMM DD YYYY')
const BTW_TEX = 0.21

const createInvoicePdf = async (invoice, user, action, viewInvoice) => {
    // console.log('create Invoice Pdf function is working')
    const doc = new PDFDocument()

    // console.log('invoice', invoice)

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/dguw0zsyj/image/upload/v1685012827/SideWGPLogo_nqczua.png'

    
    let imageBase64 = await toBase64(iconUrl)

    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })

    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
    }
    doc.text(user?.name, 60, 120)
    doc.text(user?.country, 60, 140)
    // doc.text(user?.streetNumber, 60, 140)
    // doc.text(user?.postalCodeCity, 60, 160)

    doc.fontSize(14).text(`Invoice Number: ${invoice?.invoiceNumber}`, 60, 220)

    // doc.text(`${user?.postalCodeCity}, ${dateFormater(invoice?.created)}`, 60, 240)
    doc.text(`Voorschoten, ${dateFormater(invoice?.created)}`, 60, 240)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 250)

    let x = 280
    let leftVal = 360
    let rightVal = 515

    // console.log('invoice subscription Name', invoice?.subscriptionName, 'amount', invoice?.subscriptionAmount)
    if (invoice?.subscriptionAmount) {
        doc.text(`Subscription A: ${invoice?.subscriptionName?.slice(0, 50)}`, 60, x)

        let price = convertNumber(invoice?.subscriptionAmount)

        // doc.text(`Price`, leftVal, x)
        doc.text(`€ ${price}`, rightVal - (price?.length * 5), x)

        x = x + 20

        doc.text(invoice?.subscriptionName?.slice(51), 60, x)

        x = x + 20
    }

    if (invoice?.postalCardQuantity) {
        let postalCard = convertNumber(invoice?.postalCardQuantity * invoice?.postalCardRate)
        doc.text(`Postal cards (Weekly)      € ${convertNumber(invoice?.postalCardRate)} / piece`, 60, x)

        doc.text(`${invoice?.postalCardQuantity} cards x € ${convertNumber(invoice?.postalCardRate)}`, leftVal, x)

        doc.text(`€ ${postalCard}`, rightVal - (postalCard?.length * 5), x)
        x = x + 20
    }

    if (invoice?.fixedCardQuantity) {
        let fixedCard = convertNumber(invoice?.fixedCardQuantity * invoice?.fixedCardRate)
        doc.text(`Postal cards (Fixed Day) € ${convertNumber(invoice?.fixedCardRate)} / piece`, 60, x)

        doc.text(`${invoice?.fixedCardQuantity} cards x € ${convertNumber(invoice?.fixedCardRate)}`, leftVal, x)

        doc.text(`$ ${fixedCard}`, rightVal - (fixedCard?.length * 5), x)
        x = x + 20
    }

    if (invoice?.whatsappQuantity) {
        let whatsapp = convertNumber(invoice?.whatsappQuantity * invoice?.whatsappRate)
        doc.text(`Whatsapp                         € ${convertNumber(invoice?.whatsappRate)} / message`, 60, x)

        doc.text(`${invoice?.whatsappQuantity} messages x € ${convertNumber(invoice?.whatsappRate)}`, leftVal, x)

        doc.text(`$ ${whatsapp}`, rightVal - (whatsapp?.length * 5), x)
        x = x + 20
    }

    if (invoice?.couponsQuantity) {
        let coupons = convertNumber(invoice?.couponsQuantity * invoice?.couponRate)
        doc.text(`Loyalty Coupons               € ${convertNumber(invoice?.couponRate)} / piece`, 60, x)

        doc.text(`${invoice?.couponsQuantity} cards x € ${convertNumber(invoice?.couponRate)}`, leftVal, x)

        doc.text(`$ ${coupons}`, rightVal - (coupons?.length * 5), x)
        x = x + 20
    }


    if (invoice?.couponsCardQuantity) {
        let couponsCard = convertNumberCardCoupon(invoice?.couponsCardQuantity * invoice?.couponCardRate)

        doc.text(`Loyalty Coupons Card      € ${convertNumberCardCoupon(invoice?.couponCardRate)} / piece`, 60, x)
        // console.log('invoice?.couponCardRate', invoice?.couponCardRate)
        doc.text(`${invoice?.couponsCardQuantity} cards x € ${convertNumberCardCoupon(invoice?.couponCardRate)}`, leftVal, x)

        doc.text(`$ ${couponsCard}`, rightVal - (couponsCard?.length * 5), x)
    }

    if (invoice?.lotteryQuantity) {
        let lottery = convertNumber(invoice?.lotteryQuantity * invoice?.lotteryRate)
        doc.text(`Lottery               € ${convertNumber(invoice?.lotteryRate)} / piece`, 60, x)

        doc.text(`${invoice?.lotteryQuantity} cards x € ${convertNumber(invoice?.lotteryRate)}`, leftVal, x)

        doc.text(`$ ${lottery}`, rightVal - (lottery?.length * 5), x)
        x = x + 20
    }

    if (invoice?.lotteryCardQuantity) {
        let lotteryCard = convertNumber(invoice?.lotteryCardQuantity * invoice?.lotteryCardRate)
        doc.text(`Lottery Card      € ${convertNumber(invoice?.lotteryCardRate)} / piece`, 60, x)
        // console.log('invoice?.couponCardRate', invoice?.couponCardRate)
        doc.text(`${invoice?.lotteryCardQuantity} cards x € ${convertNumber(invoice?.lotteryCardRate)}`, leftVal, x)

        doc.text(`$ ${lotteryCard}`, rightVal - (lotteryCard?.length * 5), x)
    }

    if (invoice?.subscriptionName === 'Double Chance Game') {
        let amount = convertNumber(invoice?.subscriptionAmount)
        doc.text(`Game Of Chance      € ${convertNumber(invoice?.totalAmount)}`, 60, x)

        // doc.text(`${invoice?.postalCardQuantity} cards x € ${convertNumber(invoice?.postalCardRate)}`, leftVal, x)

        // doc.text($ ${amount}`, rightVal - (postalCard?.length * 5), x)
        doc.text(`€ ${amount}`, rightVal - (amount?.length * 5), x)
        x = x + 20
    }
    if (invoice?.subscriptionName === 'Wallet Recharge subscription') {
        let amount = convertNumber(invoice?.subscriptionAmount)
        doc.text(`Wallet Recharge      € ${convertNumber(invoice?.totalAmount)}`, 60, x)

        // doc.text(`${invoice?.postalCardQuantity} cards x € ${convertNumber(invoice?.postalCardRate)}`, leftVal, x)

        // doc.text(`$ ${amount}`, rightVal - (postalCard?.length * 5), x)
        doc.text(`€ ${amount}`, rightVal - (amount?.length * 5), x)
        x = x + 20
    }
    x = x + 80

    // doc.text('Subtotaal', leftVal, x)

    // let SubTotaal = convertNumber(invoice?.totalAmount)

    // doc.text(`€ ${SubTotaal}`, rightVal - (SubTotaal?.length * 5), x)

    // x = x + 20

    // doc.text('21 % BTW', leftVal, x)

    // let btw = convertNumber(invoice?.totalAmount * 0.21)

    // doc.text(`€ ${btw}`, rightVal - (btw?.length * 5), x)

    // x = x + 20

    // doc.text('Totaal inc BTW', leftVal, x)

    // let total = convertNumber(invoice?.totalAmount + (invoice?.totalAmount * 0.21))

    // doc.text(`€ ${total}`, rightVal - (total?.length * 5), x)

    doc.text('Subtotal', leftVal, x)

    let SubTotaal = convertNumber(invoice?.totalAmount * (100 / 121))

    let total = convertNumber(invoice?.totalAmount)

    if (invoice?.subscriptionName !== 'Wallet Recharge subscription') {

        doc.text(`$ ${SubTotaal}`, rightVal - (SubTotaal?.length * 5), x)

        x = x + 20

        doc.text('21 % BTW', leftVal, x)

        let btw = convertNumber(invoice?.totalAmount * (21 / 121))

        doc.text(`$ ${btw}`, rightVal - (btw?.length * 5), x)

        x = x + 20

        doc.text('Total incl BTW', leftVal, x)

    } else {
        doc.text(`€ ${total}`, rightVal - (total?.length * 5), x)
        x = x + 20
        doc.text('0 % BTW', leftVal, x)

        let btw = convertNumber(invoice?.totalAmount * (21 / 121))
        doc.text(`€ 0.00`, rightVal - (btw?.length * 5), x)

        x = x + 20
        doc.text('Total ', leftVal, x)
    }

    doc.text(`€ ${total}`, rightVal - (total?.length * 5), x)


    x = x + 40

    // doc.text(`Wij zien uw betaling graag binnen 5 dagen tegemoet tnv 2keepintouch.com`, 60, x)

    x = x + 20

    doc.text('______________________________________________________________________________________', 60, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('wgpplaza.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        if (action === 'view') {
            viewInvoice(blobUrl)
        } else {
            a.href = blobUrl
            a.download = 'invoice.pdf'
            a.click()
        }
    })
}

const createPaymentReport = async (user, dateRange, invoice) => {
    let invoiceObj = invoice[0]
    function transformObject(object) {
        return Object.entries(object).map(([price, data]) => ({
            price: parseInt(price),
            data,
        }));
    }

    const transformedArray = transformObject(invoiceObj);

    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    const formattedDate1 = moment(dateRange?.date1, 'YYYY-MM-DD').format('ddd MMM DD YYYY');
    const formattedDate2 = moment(dateRange?.date2, 'YYYY-MM-DD').format('ddd MMM DD YYYY');

    let iconUrl = 'https://res.cloudinary.com/dguw0zsyj/image/upload/v1685012827/SideWGPLogo_nqczua.png'

    let imageBase64 = await toBase64(iconUrl)

    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })

    doc.text(user?.name, 60, 120)
    doc.text(user?.country, 60, 140)

    doc.fontSize(14).text(`BTW Report All`, 60, 220)
    doc.fontSize(14).text(`Report Date Range: ${formattedDate1}-${formattedDate2}`, 60, 240)

    doc.font('Helvetica').fontSize(10).text('_______________________________________________________________________________________________', 60, 250)

    let x = 280
    let indVal = 60
    let quantityVal = 180
    let costVal = 250
    let amountVal = 330
    let btwVal = 450
    let TotalVal = 530



    doc.font('Helvetica-Bold').fontSize(11).text(`Product Name`, indVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Quantity`, quantityVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Cost`, costVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Amount`, amountVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`BTW`, btwVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Total`, TotalVal, x)
    x = x + 20
    var TotalQuantity
    var TotalAmount
    var TotalBTW
    var AllOverTotal


    {
        transformedArray?.length &&
            transformedArray?.map((v, index) => {
                TotalQuantity = TotalQuantity ? TotalQuantity + v?.data?.length : v?.data?.length
                let totalPrice = (+v?.price) * (+v?.data?.length)
                TotalAmount = TotalAmount ? TotalAmount + totalPrice : totalPrice
                let btwPrice = convertRoundNumber(totalPrice * 0.21)
                const btwPriceRound = convertRoundNumber(btwPrice)
                TotalBTW = TotalBTW ? (+TotalBTW) + (+btwPrice) : (+btwPrice)
                const total = convertRoundNumber(+btwPrice + totalPrice)
                AllOverTotal = AllOverTotal ? (+AllOverTotal) + (+total) : (+total)
                doc.font('Helvetica').fontSize(11).text(`Easy Load Card ${v?.price}`, indVal, x)
                doc.font('Helvetica').fontSize(11).text(v?.data?.length, quantityVal, x)
                doc.font('Helvetica').fontSize(11).text(`${v?.price}`, costVal, x)
                doc.font('Helvetica').fontSize(11).text(`€ ${convertRoundNumber(totalPrice)}`, amountVal, x)
                doc.font('Helvetica').fontSize(11).text(`€ ${btwPriceRound}`, btwVal, x)
                doc.font('Helvetica-Bold').fontSize(11).text(`€ ${total}`, TotalVal, x)
                x = x + 20
            })
    }

    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`Total`, indVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(TotalQuantity, quantityVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${convertRoundNumber(TotalAmount)}`, amountVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${convertRoundNumber(TotalBTW)}`, btwVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${convertRoundNumber(AllOverTotal)}`, TotalVal, x)

    x = x + 20

    doc.text('______________________________________________________________________________________', 60, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('wgpplaza.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'invoice.pdf'
        a.click()
    })
}



const createCashBackReport = async (user, dateRange, pendingRequest) => {
    console.log(pendingRequest, "=pendingRequest")


    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    const formattedDate1 = moment(dateRange?.date1, 'YYYY-MM-DD').format('ddd MMM DD YYYY');
    const formattedDate2 = moment(dateRange?.date2, 'YYYY-MM-DD').format('ddd MMM DD YYYY');

    let iconUrl = 'https://res.cloudinary.com/dguw0zsyj/image/upload/v1685012827/SideWGPLogo_nqczua.png'

    let imageBase64 = await toBase64(iconUrl)

    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })

    doc.text(user?.name, 60, 120)
    doc.text(user?.country, 60, 140)

    doc.fontSize(14).text(`Report Cashback Progress`, 60, 220)
    doc.fontSize(14).text(`Date:${moment(Date.now()).format('DD-MM-YYYY')}`, 60, 240)
    doc.fontSize(14).text(`Report Date Range: ${formattedDate1}-${formattedDate2}`, 60, 260)
    doc.fontSize(14).text(`Total Cashback Requests: ${pendingRequest?.length || 0}`, 60, 280)

    doc.font('Helvetica').fontSize(10).text('____________________________________________________________________________________________', 60, 290)

    let x = 310
    let indVal = 60
    let fullname = 100
    let quantityVal = 215
    let costVal = 340
    let amountVal = 470


    doc.font('Helvetica-Bold').fontSize(11).text(`S.no`, indVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Full Name`, fullname, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Bank Account`, quantityVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`IBAN `, costVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Amount`, amountVal, x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }
    var totalAmount


    {
        pendingRequest?.length &&
            pendingRequest?.map((v, index) => {
                totalAmount = totalAmount ? totalAmount + v?.amount : v?.amount

                doc.font('Helvetica').fontSize(11).text(`${(index + 1)}`, indVal, x)
                doc.font('Helvetica').fontSize(11).text(`${(v?.playerId?.name)}`, fullname, x)
                doc.font('Helvetica').fontSize(11).text(`${(v?.playerId?.bankAccount?.length > 18 ? ((v?.playerId?.bankAccount).substring(0, 18)) + '...' : v?.playerId?.bankAccount)}`, quantityVal, x)
                doc.font('Helvetica').fontSize(11).text(`${(v?.playerId?.IBAN?.length > 18 ? ((v?.playerId?.IBAN).substring(0, 16)) + '...' : v?.playerId?.IBAN)}`, costVal, x)
                doc.font('Helvetica').fontSize(11).text(`€ ${(convertRoundNumber(v?.amount))}`, amountVal, x)
                if (x >= 700) {
                    doc.addPage()
                    x = 30
                } else {
                    x = x + 20
                }
            })
    }

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }


    doc.font('Helvetica').fontSize(11).text(`Total`, costVal, x)
    doc.font('Helvetica').fontSize(11).text(`€ ${(convertRoundNumber(totalAmount))}`, amountVal, x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }

    doc.text('____________________________________________________________________________________', 60, x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }

    doc.font('Helvetica-Bold').text('wgpplaza.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'cashbackReport.pdf'
        a.click()
    })
}


const createCompetitionInvoice = async (data, action, viewInvoice) => {
    let user = data?.userId

    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/dguw0zsyj/image/upload/v1685012827/SideWGPLogo_nqczua.png'

    let imageBase64 = await toBase64(iconUrl)

    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })

    doc.text(user?.name, 60, 120)
    doc.text(user?.country, 60, 140)

    doc.fontSize(14).text(`Competition name:${data?.competitionId?.competitionName}`, 60, 220)
    doc.fontSize(14).text(`Date:${moment(data?.created).format('DD-MM-YYYY')}`, 60, 240)

    doc.font('Helvetica').fontSize(10).text('____________________________________________________________________________________________', 60, 260)

    let x = 280
    let indVal = 60
    let amountVal = 470
    let costVal = 350

    let subTotal = (data?.moneyCollected - data?.serviceFee + data?.serviceFeeOnly) * 100 / 121
    let total = convertRoundNumber(data?.moneyCollected - data?.serviceFee + data?.serviceFeeOnly)

    doc.font('Helvetica-Bold').fontSize(11).text(`Competition subscription fee :`, indVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${convertRoundNumber(data?.competitionId?.participationMoney)}`, amountVal, x)

    if (data?.isCouponUser) {
        if (x >= 700) {
            doc.addPage()
            x = 30
        } else {
            x = x + 20
        }

        doc.font('Helvetica-Bold').fontSize(11).text(`Coupon discount :`, indVal, x)
        doc.font('Helvetica-Bold').fontSize(11).text(`€ ${(convertRoundNumber(+data?.couponUsed?.money))}`, amountVal, x)
    }

    if (data?.isCouponUser) {
        if (x >= 700) {
            doc.addPage()
            x = 30
        } else {
            x = x + 20
        }

        doc.font('Helvetica-Bold').fontSize(11).text(`subscription fee collected :`, indVal, x)
        doc.font('Helvetica-Bold').fontSize(11).text(`€ ${convertRoundNumber(data?.moneyCollected - data?.serviceFee)}`, amountVal, x)
    }

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }

    doc.font('Helvetica-Bold').fontSize(11).text(`Service fee :`, indVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${convertRoundNumber(data?.serviceFeeOnly)}`, amountVal, x)




    if (x >= 700) {
        doc.addPage()
        x = 60
    } else {
        x = x + 50
    }


    doc.font('Helvetica-Bold').fontSize(11).text(`Sub total :`, costVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${convertRoundNumber(subTotal)}`, amountVal, x)/* 11*100/121 */

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }


    doc.font('Helvetica-Bold').fontSize(11).text(`21% of BTW :`, costVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${convertRoundNumber(+(total) - (+subTotal))}`, amountVal, x)


    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }


    doc.font('Helvetica-Bold').fontSize(11).text(`Total :`, costVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${total}`, amountVal, x)


    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }

    doc.text('____________________________________________________________________________________', 60, x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }

    doc.font('Helvetica-Bold').text('wgpplaza.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)

        if (action) {
            return viewInvoice(blobUrl)
        }
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'competition-subscription.pdf'
        a.click()
    })
}

const validateTime = (t) => {
    if (t != 'none' && t?.length === 4) {
        return `${t}0`;
    }
    return t;
}

const calculateReviewAverage = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.average;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)

}


const calculateAverageForStaff = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.staff;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForFacilities = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.facilities;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForCleanliness = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.cleanliness;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForComfort = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.comfort;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForValueForMoney = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.valueForMoney;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForLocation = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.location;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForFreeWifi = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.freeWifi;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
function isWithin7Days(dateString) {
    const inputDate = moment(dateString, 'YYYY-MM-DD');
    const currentDate = moment();

    const differenceInDays = inputDate.diff(currentDate, 'days');

    return differenceInDays < 7 ? true : false;
}
const getCoupleObj = (array, id) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i]._id === id) {
            return array[i];
        }
    }
    return null;
}
export {
    requiredMessage,
    inputPlace,
    setActiveMenu,
    successMessage,
    infoMessage,
    errorMessage,
    warningMessage,
    successNotification,
    errorNotification,
    convertTitle,
    stringLimiter,
    assignRanks,
    myTournametSetting,
    isWithinDisabledPeriod,
    isDisabledSubscribe,
    disabledDate,
    sortOponentArray,
    removePlayerById,
    adjustDataForRank,
    getGeneralData,
    getUserData,
    checkIfDateTimePassedForMatchResultBtns,
    createGiftCard,
    sortPlayersForMatchRequest,
    adjustDataForProRank,
    getPointSetting,
    createInvoicePdf,
    convertNumber,
    convertNumberCardCoupon,
    dateFormater,
    BTW_TEX,
    convertRoundNumber,
    getAllTournaments,
    getTournamentMatchData,
    get1_1_MatchData,
    createPaymentReport,
    getFaqHelper,
    createCashBackReport,
    assignCompetitionRanks,
    getCompetitionMatches,
    createCompetitionInvoice,
    errorMessageWithDuration,
    validateTime,
    calculateReviewAverage,
    calculateAverageForStaff,
    calculateAverageForFacilities,
    calculateAverageForCleanliness,
    calculateAverageForComfort,
    calculateAverageForValueForMoney,
    calculateAverageForLocation,
    calculateAverageForFreeWifi,
    getAllCourts,
    isWithin7Days,
    getCoupleObj,
    convertRoundNumber4
}
