import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Select, TimePicker } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET, POST } from '../../../utils/apis'
import { dateFormat } from '../../../utils/constants'
import { convertRoundNumber, errorMessage, getUserData, successMessage, validateTime } from '../../../utils/helpers'

const { Option } = Select
const CreateCompetitionMatchRequest = (props) => {
    const { selectedLanguage, generalActions, user, isEnglish, userActions, getUser, pointSettingAction } = props
    const subscribedCompetitions = useSelector(state => state.userReducer.subscribedCompetitions)
    const mySubscriptions = useSelector(state => state.userReducer.mySubscriptions)
    const myMatches = useSelector(state => state.userReducer.myMatches)
    const allCouples = useSelector(state => state?.userReducer?.allCouples)
    const allClubs = useSelector(state => state.generalReducer.allClubsUsers)
    const [form] = Form.useForm()
    const format = 'HH:mm'
    const [loading, setLoading] = useState(false)
    const [booked, setBooked] = useState(false)
    const [selectedCompetition, setSelectedCompetition] = useState(null)
    const [clubSelected, setClubSelected] = useState({})
    const [courtLoading, setCourtLoading] = useState(false)
    const [allCourts, setAllCourts] = useState([])
    const [filteredDates, setFilteredDates] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [shifts, setShifts] = useState([])
    const [filteredShifts, setFilteredShifts] = useState([])
    const [selectedCourt, setSelectedCourt] = useState({})
    const [selectedCourtDetail, setSelectedCourtDetail] = useState({})
    const [priceDeduct, setPriceDeduct] = useState(null)
    const [dayShifts, setDayShifts] = useState([])

    console.log('selectedCompetition', selectedCompetition)
    // console.log('allCourts', allCourts)
    // console.log('mySubscriptions', mySubscriptions)
    // console.log('allCouples', allCouples)
    console.log('subscribedCompetitions', subscribedCompetitions)
    // console.log('selectedDate', selectedDate)
    console.log('myMatches', myMatches)
    console.log('allClubs', allClubs)
    // console.log('subscribedCompetitions', subscribedCompetitions)

    useEffect(() => {
        getUserData(user?._id, userActions)
    }, [])
    useEffect(() => {
        let dayReservations = []
        let shiftReservations = []
        allCourts?.filter((x) => (x?.reservation?._id && x?.gameType?.includes(selectedCompetition?.gameType)))?.forEach((v) => {
            let dayData = v?.reservation?.otherSettings?.filter((x, i) => (x?.maximumProduct && x?.shifts?.reduce((a, { maxProduct }) => a + maxProduct, 0)))
            console.log('daydata', dayData)
            let shiftData = () => dayData?.filter((x, i) => x?.maximumProduct)?.forEach((x) => {
                x?.shifts.forEach((z) => {
                    z.interval = v?.reservation?.interval
                    z.name = v?.name
                    z.price = v?.price
                    z.reservationId = v?.reservation?._id
                    z.courtId = v?._id
                    z.dayId = x?._id
                })
                let newShifts = x?.shifts?.filter((z) => z?.maxProduct > 0)
                shiftReservations.push(newShifts)
            })
            shiftData()
            dayReservations.push(dayData)
        })
        dayReservations = dayReservations.flat()
        shiftReservations = shiftReservations.flat()
        let availableDates = _.uniq(dayReservations?.map((v) => moment(v?.date).format('YYYY-MM-DD')))
        availableDates = availableDates.filter((v) => moment(v, 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD')))
        setFilteredDates(availableDates)
        setDayShifts(dayReservations)
        setShifts(shiftReservations)
    }, [allCourts])

    const competitionChange = (e) => {
        let competitionSelected = subscribedCompetitions?.find((v) => v?._id === e)
        setSelectedCompetition(competitionSelected)

        form.setFieldsValue({
            matchDate: null
        })
    }
    const handleChange = (value) => {
        let selectedClub = allClubs.find((v) => v?._id === value)
        setClubSelected(selectedClub || {})
        getCourts(value)
        form.setFieldsValue({
            reservation: null,
            matchDate: null
        })
    }
    const getCourts = (id) => {
        setCourtLoading(true)
        axios.get(`${GET?.GET_SELECTED_COURTS}/?id=${id}&gameType=${selectedCompetition?.gameType}`,)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setAllCourts(data?.data || [])
                }
                else {
                    setAllCourts([])
                }
                setCourtLoading(false)

            })
            .catch((e) => {
                setCourtLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    const onChangeDate = (date) => {
        setSelectedDate(date)
        setFilteredShifts(shifts?.filter((v) => moment(v?.date).format('YYYY-MM-DD') === date))
    }
    const handleCourtChange = (value) => {
        console.log('function called', value)
        if (value) {
            let selectedShift = shifts.find((v) => v?._id === value)
            setSelectedCourt(selectedShift)
            let selectedClub = allCourts.find((v) => v?._id === selectedShift?.courtId)
            setSelectedCourtDetail(selectedClub)
            // let price = selectedShift?.price / numPlayers
            // setPriceDeduct(price)
        }
    }
    const onFinish = (fieldsValue) => {

        let mySubscription = mySubscriptions.find((v) => v?.competitionId === selectedCompetition?._id)
        let coupleDetail = allCouples.find((v) => v?._id === mySubscription?.coupleId)
        console.log('coupleDetail', coupleDetail)
        // return true
        if (!selectedCourtDetail?.acceptNonMember) {
            if (!(user?.preferredClub?.isApproved && user?.preferredClub?.club === selectedCourtDetail?.userId)) {
                return errorMessage("You are not a Member of this club, This court is not for Non-Members")
            }

            if (selectedCompetition?.playerNumbers === 4) {
                if (!(coupleDetail?.partnerId?.preferredClub?.isApproved && coupleDetail?.partnerId?.preferredClub?.club === selectedCourtDetail?.userId)) {
                    return errorMessage("Your partner is not a Member of this club, This court is not for Non-Members")
                }
            }
        }

        if (user?.walletBalance < getPrice()) {
            return errorMessage("You don't have enough balance to create this match request")
        }

        if (selectedCompetition?.playerNumbers === 4) {
            fieldsValue.partner = coupleDetail?.partnerId?._id
            fieldsValue.couple = coupleDetail?._id
        }
        let myCouples = allCouples.filter((v) => (v?.userId?._id === user?._id || v?.partnerId?._id === user?._id) && (v?.userId?._id === coupleDetail?.partnerId?._id || v?.partnerId?._id === coupleDetail?.partnerId?._id)).map((v) => v._id)

        // console.log('myCouples', myCouples)
        fieldsValue.myCouples = myCouples
        fieldsValue.senderId = user?._id
        fieldsValue.userId = user?._id
        fieldsValue.reservationId = selectedCourt?.reservationId
        fieldsValue.reservation = selectedCourt?.reservationId
        fieldsValue.courtId = selectedCourt?.courtId
        fieldsValue.shiftId = selectedCourt?._id
        fieldsValue.dayId = selectedCourt?.dayId
        fieldsValue.gameType = selectedCompetition?.gameType
        fieldsValue.matchDate = moment(selectedCourt?.date).format()
        fieldsValue.matchTime = selectedCourt?.startTime
        fieldsValue.subscriptionId = mySubscription?._id
        fieldsValue.price = getPrice()

        console.log('fieldsValue', fieldsValue)

        setLoading(true)
        axios.post(POST.CREATE_COMPETITION_MATCH, fieldsValue)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    getUser()
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    form.resetFields()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            }).catch((e) => {
                setLoading(false)
                console.log(e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const onFinishBook = (fieldsValue) => {

        let mySubscription = mySubscriptions.find((v) => v?.competitionId === selectedCompetition?._id)

        let coupleDetail = allCouples.find((v) => v?._id === mySubscription?.coupleId)
        console.log('coupleDetail', coupleDetail)

        const values = {
            ...fieldsValue,
            matchDate: fieldsValue['matchDate2'].format('YYYY-MM-DD'),
            matchTime: fieldsValue['matchTime'].format('HH:mm'),
            userId: user?._id,
            playerNumbers: selectedCompetition?.playerNumbers,
            senderId: user?._id,
            gameType: selectedCompetition?.gameType
        }
        values.price = values.amountPaid / selectedCompetition?.playerNumbers

        if (user?.walletBalance < values?.price) {
            return errorMessage("You don't have enough balance to create this match request")
        }

        if (selectedCompetition?.playerNumbers === 4) {
            values.partner = coupleDetail?.partnerId?._id
            values.couple = coupleDetail?._id
        }
        let myCouples = allCouples.filter((v) => (v?.userId?._id === user?._id || v?.partnerId?._id === user?._id) && (v?.userId?._id === coupleDetail?.partnerId?._id || v?.partnerId?._id === coupleDetail?.partnerId?._id)).map((v) => v._id)

        values.subscriptionId = mySubscription?._id

        values.myCouples = myCouples

        console.log('values', values)

        setLoading(true)
        axios.post(POST.CREATE_BOOKED_COMPETITION_MATCH, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    getUser()
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    form.resetFields()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            }).catch((e) => {
                setLoading(false)
                console.log(e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })

    }
    const getPrice = () => {
        let price = selectedCourt?.price

        let calculatedPrice = (price / selectedCompetition?.playerNumbers)
        calculatedPrice = convertRoundNumber(calculatedPrice)
        return Number(calculatedPrice)

    }

    const preBookChange = (value) => {
        let newValue = value.target.checked
        setBooked(newValue)

        form.setFieldsValue({
            alreadyPaid: newValue
        })
    }

    const disabledDate = (current) => {

        let competitionDate1 = moment(selectedCompetition?.competitionDuration?.date1, 'YYYY-MM-DD')
        let competitionDate2 = moment(selectedCompetition?.competitionDuration?.date2, 'YYYY-MM-DD').add(1, 'days')
        if (!selectedCompetition) {
            return current && current.isBefore(moment(), 'day')
        } else {
            return current && current.isBefore(moment(), 'day') || current.isBefore(competitionDate1, 'day') || !current.isBefore(competitionDate2, 'day')
        }
    }

    return (
        <div className='match-req-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
            >
                <p className='admin-home-title-white'>
                    {selectedLanguage?.text_Create_CompetitionmatchRequest}
                </p>
                <div style={{ marginTop: 20 }}>
                    <Form
                        name='form'
                        form={form}
                        onFinish={!booked ? onFinish : onFinishBook}
                        layout={'vertical'}
                    >

                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='competitionId'
                                    hasFeedback
                                    label={<p className='my-form-label'>{selectedLanguage?.text_Selectcompetitionname}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.text_Pleaseselectcompetitionname
                                        }
                                    ]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        onChange={competitionChange}
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_SelectGameforCompetition}>
                                        {subscribedCompetitions.filter((v) => (myMatches[v?._id] || 0) < v?.maximumMatches)?.map((v, i) => {
                                            return (
                                                <Option value={v?._id}>{`${v?.competitionName} | € ${v?.participationMoney}`}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input '>
                                <Form.Item
                                    // label={<p className='my-form-label'>{selectedLanguage?.text_Promoter}</p>}
                                    name='alreadyPaid'
                                    valuePropName='checked'
                                    initialValue={false}
                                >
                                    <Checkbox
                                        disabled={booked}
                                        className='form-input-new-style'
                                        style={{ width: '100%', padding: '17px 14px', background: 'white' }}>
                                        {selectedLanguage?.txt_AlreadyPaid}
                                    </Checkbox>
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input '>
                                <Form.Item
                                    name='preBooked'
                                    valuePropName='checked'
                                    initialValue={false}
                                >
                                    <Checkbox
                                        onChange={preBookChange}
                                        className='form-input-new-style'
                                        style={{ width: '100%', padding: '17px 14px', background: 'white' }}>
                                        {selectedCompetition?.txt_PreBooked}
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='clubId'
                                    hasFeedback
                                    label={<p className='my-form-label'>{selectedLanguage?.txt_SelectClub}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.txt_Pleaseselectclub
                                        }
                                    ]}
                                >
                                    {booked ? <Select
                                        disabled={!selectedCompetition?._id}
                                        onChange={handleChange}
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.txt_Selectclubhere}>
                                        {allClubs?.map((v, i) => {
                                            return (
                                                <Option value={v?._id} key={i}>{`${v?.name} - ${v?.city}`}</Option>
                                            )
                                        })}
                                    </Select>
                                        :
                                        <Select
                                            disabled={!selectedCompetition?._id}
                                            onChange={handleChange}
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.txt_Selectclubhere}>
                                            {(selectedCompetition?.clubScore?.length ? selectedCompetition?.clubScore?.filter((v) => v?.maxScore - v?.score) : allClubs?.length ? allClubs : [])?.map((v, i) => {
                                                return (
                                                    <Option value={v?.club?._id} key={i}>{`${v?.club?.name} - ${v?.club?.city}`}</Option>
                                                )
                                            })}
                                        </Select>}
                                </Form.Item>
                            </div>
                        </div>

                        {!booked ?
                            <>
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='matchDate'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.txt_SelectDate}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Date'
                                                }
                                            ]}
                                            validateStatus={courtLoading ? 'error' : null}
                                            help={courtLoading ? selectedLanguage?.txt_Thedataisfetching : null}
                                        >
                                            <Select
                                                disabled={!selectedCompetition?._id}
                                                loading={courtLoading}
                                                onChange={onChangeDate}
                                                className='form-input-new-style'
                                                placeholder={'Select Date'}>
                                                {filteredDates?.length && filteredDates?.map((v, i) => {
                                                    return (
                                                        <Option value={v} label={v} key={i} />
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='reservation'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.txt_SelectReservation}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.txt_PleaseSelectReservation
                                                }
                                            ]}
                                        >
                                            <Select
                                                disabled={!selectedCompetition?._id}
                                                onChange={handleCourtChange}
                                                className='form-input-new-style'
                                                placeholder={selectedLanguage?.txt_SelectReservation}>
                                                {filteredShifts?.length && filteredShifts?.map((v, i) => {
                                                    return (
                                                        <Option key={i} value={v?._id}>{`${moment(v?.date).format('YYYY-MM-DD')} ${validateTime(v?.startTime)} Duration:${v?.interval} ${v?.name} Price: € ${v?.price}`}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='form-input-single-input' style={{ padding: 5 }}>
                                    <Form.Item
                                        name='message'
                                        hasFeedback
                                        label={<p className='my-form-label'>{selectedLanguage?.label_Message}</p>}
                                    >
                                        <Input
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.placeHolder_enterAmessage}
                                        />
                                    </Form.Item>
                                </div>

                            </>
                            :
                            <>
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='matchDate2'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.label_Gamedate}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.error_pleaseselectgamedate
                                                }
                                            ]}
                                        >
                                            <DatePicker
                                                disabled={!selectedCompetition}
                                                // onChange={onChangeDate}
                                                disabledDate={disabledDate}
                                                className='form-input-new-style'
                                                placeholder={selectedLanguage?.placeHolder_selectgamedate}
                                                style={{ width: '100%' }}
                                                allowClear={false}
                                                format={dateFormat}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='matchTime'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.label_gametime}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.error_pleaseselectgametime
                                                }
                                            ]}
                                        >
                                            <TimePicker
                                                disabled={!selectedCompetition}
                                                // onChange={onChangeTime}
                                                minuteStep={15}
                                                style={{ width: '100%' }}
                                                className='form-input-new-style'
                                                placeholder={selectedLanguage?.plaecholder_selectgametime}
                                                format={format} />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                        <Form.Item
                                            name='amountPaid'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.txt_PaidAmount}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.txt_Pleaseenteramountyouhavepaid
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={1}
                                                step={0.5}
                                                type='number'
                                                style={{ width: '100%' }}
                                                className='form-input-new-style'
                                                placeholder={selectedLanguage?.txt_EnterAmountyouhavepaid}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </>
                        }
                        <Form.Item>
                            <Button
                                style={{
                                    padding: '0px 20px 0px 20px',
                                    background: 'rgba(92, 93, 93, 1)',
                                    color: 'white',
                                    border: 'none',
                                    height: '45px',
                                    marginTop: '10px',
                                    borderRadius: 6
                                }}
                                htmlType='submit'
                                loading={loading}
                            >{selectedLanguage?.btn_SendMatchRequest_send}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div >
        </div >
    )
}

export default CreateCompetitionMatchRequest