import React, { useState } from 'react'

const AdminHome = (props) => {
    const { selectedLanguage, isEnglish } = props
    return (
        <div style={{ margin: '20px' }} >
            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                <p className='admin-home-title'>{selectedLanguage?.title_AdminsettingsWGPplaza}</p>
                <p className='admin-home-title'>{selectedLanguage?.title_DontForgetToSaveNewSettings}</p>
                <div style={{marginTop:'20px'}}>
                    <button
                        style={{ height: '40px', width: '160px', marginTop: '10px' }}
                        className='addproduct-text'
                    >{selectedLanguage?.buttonText_Tournament}</button>
                </div>
                <div>
                    <button
                        style={{ height: '40px', width: '160px', marginTop: '10px', marginRight: '2px' }}
                        className='addproduct-text'
                    >{selectedLanguage?.buttonText_UsersWebsite}</button>
                    <button
                        style={{ height: '40px', width: '160px', marginTop: '10px', marginLeft: '2px' }}
                        className='addproduct-text'
                    >{selectedLanguage?.buttonText_MetricReports}</button>
                </div>
            </div>
        </div>
    )
}

export default AdminHome