import React, { useEffect, useState } from "react";
import { Button, Form, InputNumber, Modal } from "antd";
import { convertRoundNumber, errorMessage, successMessage } from '../../../utils/helpers'
import { ADMIN } from '../../../utils/apis'
import axios from "axios";

const ServiceFeeForCompetition = (props) => {
    const { user, isEnglish, selectedLanguage } = props
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [cashbackServiceFee, setcashbackServiceFee] = useState(null)

    const getcashbackServiceFee = () => {
        axios.get(ADMIN?.GET_COMPETITION_SUB_SERVICE_FEE)
            .then((res) => {
                const { data } = res
                setcashbackServiceFee(data?.data?.[0] || [])
            })
            .catch((e) => {
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }


    const onFinish = (values) => {
        values.adminId = user?._id
        cashbackServiceFee?._id && (values._id = cashbackServiceFee?._id)
        axios.post(cashbackServiceFee?._id ? ADMIN?.EDIT_COMPETITION_SUB_SERVICE_FEE : ADMIN?.ADD_COMPETITION_SUB_SERVICE_FEE, values)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getcashbackServiceFee()
                }
            })
            .catch((e) => {
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    useEffect(() => {
        getcashbackServiceFee()
    }, [])

    const handleOk = () => {
        setVisible(false)
    }


    return (
        <>
            <div className="reward-point-box">
                <p className="p-none-margin-bolder-p">{selectedLanguage?.ttx_PlayerNeedToPayCompetitionSubscriptionServiceFeethatyousetHhere}</p>
                <p className="p-none-margin-bolder-p">
                    {selectedLanguage?.ttx_PlayerWantToPay}
                    ( {cashbackServiceFee?.money ? `€ ${convertRoundNumber(cashbackServiceFee?.money)}` : '---'} )
                    {selectedLanguage?.ttx_forSubscriptionOfCompetitionAsServiceFee}</p>
                <div style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    <Button className='update-btn-text' onClick={() => { setVisible(true) }} > {selectedLanguage?.ttx_Updatecompetitionservicefee}</Button>
                </div>
            </div>



            {/* --------Modal----- */}
            <Modal
                open={visible}
                onOk={handleOk}
                onCancel={handleOk}
                footer={null}>
                <div style={{ marginTop: '10px' }}>
                    <span style={{ textAlign: 'center' }}>
                        <p className='admin-home-title' style={{ fontSize: '22px' }}>{selectedLanguage?.text_UpdateCashbackServiecFee}</p>
                    </span>
                    <div style={{ marginTop: '35px' }}>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex-inner-input newInpuHeightFull' style={{ width: '100%' }}>
                                <Form.Item
                                    name='money'
                                    initialValue={cashbackServiceFee?.money}
                                    hasFeedback
                                    label={selectedLanguage?.text_CashbackServiecFee}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_CashbackServiecFee
                                        }
                                    ]}
                                >
                                    <InputNumber
                                        step={0.01}    // Set the step to 0.01 for two decimal places
                                        precision={2}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.place_CashbackServiecFee}
                                    />
                                </Form.Item>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                <Form.Item>
                                    <Button
                                        className='addproduct-text '
                                        style={{ height: '45px', width: '180px', marginTop: '10px' }}
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        {cashbackServiceFee?.money ? selectedLanguage?.text_UpdatePointSetting : selectedLanguage?.text_AddPointSetting}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div >
            </Modal >
        </>
    )
}



export default ServiceFeeForCompetition