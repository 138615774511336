import React from "react";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io"

const CompetitionResult = (props) => {
    const history = useHistory()
    const { user, selectedLanguage } = props
    let competition = history?.location?.state

    return (
        <div className='tournamnent-info-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span style={{ marginTop: '2px', cursor: 'pointer' }}>
                        <IoIosArrowBack
                            style={{ marginRight: '5px', color: 'white' }}
                            size={28}
                            onClick={() => history?.goBack()} />
                    </span>
                    <p className='admin-home-title-white'>
                        {selectedLanguage?.text_CompetitionResult}
                    </p>
                </div>
                <div style={{ margin: '30px' }}>
                    <h3 className="main-title-result" >{selectedLanguage?.text_Competition} : {competition?.competitionName || '----'}</h3>
                    {/* <h3 className="main-title-result" >{selectedLanguage?.text_TotalPrie}: € {competition?.totalPrize}</h3> */}
                    <h3 className="main-title-result" >{selectedLanguage?.text_PromotedUsers}:{competition?.result?.promotedUsers?.length}</h3>
                    {/*  */}
                    <div style={{ margin: '10px' }}>
                        {competition?.result?.promotedUsers?.length ?
                            competition?.result?.promotedUsers?.map((v, i) => {
                                return (
                                    <div className="ppp-box">
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{i + 1}:{v?.userId?.name}</h3>
                                        {/* <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_prize}:{v?.prize}</h3> */}
                                    </div>
                                )
                            }) :
                            <div className="ppp-box">
                                <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_NoPromotedUser}</h3>
                            </div>
                        }
                    </div>

                    {/*  */}
                    <h3 className="main-title-result">{selectedLanguage?.text_DegradedUsers}:{competition?.result?.degradedUsers?.length}</h3>
                    <div style={{ margin: '10px' }}>
                        {competition?.result?.degradedUsers?.length ?
                            competition?.result?.degradedUsers?.map((v, i) => {
                                return (
                                    <div className="ppp-box">
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{i + 1}:{v?.userId?.name}</h3>
                                        {/* <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_prize}:{v?.prize}</h3> */}
                                    </div>
                                )
                            }) :
                            <div className="ppp-box">
                                <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_NoDegradedUsers}</h3>
                            </div>
                        }
                    </div>


                    {/*  */}
                    <h3 className="main-title-result">{'First winner'}:{competition?.result?.PrizeOne?.length}</h3>
                    <div style={{ margin: '10px' }}>
                        {competition?.result?.PrizeOne?.length ?
                            competition?.result?.PrizeOne?.map((v, i) => {
                                return (
                                    <div className="ppp-box">
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{i + 1}:{v?.userId?.name}</h3>
                                        {/* <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_prize}:{v?.prize}</h3> */}
                                    </div>
                                )
                            }) :
                            <div className="ppp-box">
                                <h3 className="main-title-result" style={{ fontSize: '16px' }}>{'No first winner decided'}</h3>
                            </div>
                        }
                    </div>


                    {/*  */}
                    <h3 className="main-title-result">{'Second winner'}:{competition?.result?.PrizeTwo?.length}</h3>
                    <div style={{ margin: '10px' }}>
                        {competition?.result?.PrizeTwo?.length ?
                            competition?.result?.PrizeTwo?.map((v, i) => {
                                return (
                                    <div className="ppp-box">
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{i + 1}:{v?.userId?.name}</h3>
                                        {/* <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_prize}:{v?.prize}</h3> */}
                                    </div>
                                )
                            }) :
                            <div className="ppp-box">
                                <h3 className="main-title-result" style={{ fontSize: '16px' }}>{'No second Winner decided'}</h3>
                            </div>
                        }
                    </div>

                    {/*  */}
                    <h3 className="main-title-result">{'Third winner'}:{competition?.result?.PrizeThree?.length}</h3>
                    <div style={{ margin: '10px' }}>
                        {competition?.result?.PrizeThree?.length ?
                            competition?.result?.PrizeThree?.map((v, i) => {
                                return (
                                    <div className="ppp-box">
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{i + 1}:{v?.userId?.name}</h3>
                                        {/* <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_prize}:{v?.prize}</h3> */}
                                    </div>
                                )
                            }) :
                            <div className="ppp-box">
                                <h3 className="main-title-result" style={{ fontSize: '16px' }}>{'No third winner decided'}</h3>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}


export default CompetitionResult