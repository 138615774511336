import React, { useEffect, useState } from "react";
import { Button, DatePicker, Modal, Popconfirm, Select, Table } from "antd";
import { useSelector } from "react-redux";
import { convertTitle, errorMessage, getGeneralData, getUserData, infoMessage, successMessage, validateTime } from "../../../utils/helpers";
import axios from "axios";
import { ADMIN, GET } from "../../../utils/apis";
import moment from "moment";
import { AiFillSetting } from 'react-icons/ai'
import ActualRankingModal from "./ActualRankingModal";
import UserRankingModal from "./UserRankingModal";

const { RangePicker } = DatePicker

const CompetitionMatches = (props) => {
    const { user, generalActions, userActions, isEnglish, selectedLanguage } = props
    const [requests, setRequest] = useState([])
    const [loading, setLoading] = useState(false)
    const allCompetitions = useSelector(state => state.generalReducer.allCompetitions)
    const [competitionId, setCompetitionId] = useState(allCompetitions?.[0]?._id)
    const [dateRange, setDateRange] = useState([moment().add(-1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [detail, setDetail] = useState({})
    const { Option } = Select
    useEffect(() => {
        getUserData(user?._id, userActions)
        getGeneralData(generalActions)
    }, [])
    useEffect(() => {
        allCompetitions?.length && getCompetitionRequest()
    }, [competitionId, dateRange])

    const getCompetitionRequest = () => {
        axios.get(`${GET?.GET_COMPETITION_RANK_HISTORY}/?competitionId=${competitionId}&dateRange=${JSON.stringify(dateRange)}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    console.log(data?.data, 'data?.data')
                    setRequest(data?.data)
                    setLoading(false)
                } else {
                    setRequest([])
                    setLoading(false)
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
                console.log(e, 'error 28 competiton request')
            })

    }

    const columns = [
        {
            title: selectedLanguage?.title_Club,
            dataIndex: ['club', 'name'],
            align: 'center'
        },
        {
            title: selectedLanguage?.title_GameType,
            dataIndex: 'gameType',
            align: 'center',
            render: (e) => convertTitle(e)
        },
        {
            title: selectedLanguage?.title_title_Challenger,
            align: 'center',
            render: (e) => (e?.partner?._id ? <>{e?.senderId?.name}, {e?.partner?.name}</> : <>{e?.senderId?.name}</>)
        },
        {
            title: selectedLanguage?.title_Couple,
            align: 'center',
            dataIndex: 'couple',
            render: (e) => e?.coupleName || '-'

        },
        {
            title: selectedLanguage?.title_DateTime,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p'>
                        {e?.matchDate || '-----'} | {validateTime(e?.matchTime) || '-----'}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Players,
            dataIndex: 'playerNumbers',
            align: 'center'
        },
        {
            title: selectedLanguage?.txt_OpponentCouple,
            align: 'center',
            width: 250,
            dataIndex: 'opponentCouple',
            render: (e) => e?.coupleName || '-'
        },
        {
            title: selectedLanguage?.txt_Opponent,
            align: 'center',
            dataIndex: 'opponents',
            render: (e) => e.map((v) => (<>{v?.name},</>))
        },
        {
            title: selectedLanguage?.txt_Score,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' > {e?.score || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Message,
            dataIndex: 'message',
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' > {e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            render: (e) => {
                return (
                    <Button
                        type='primary'
                        style={{ marginLeft: 10 }}
                        onClick={() => setDetail(e)}
                    >
                        details
                    </Button>
                )
            }
        },
    ]

    return (
        <>
            <div className='create-match-req-main-div'>
                <div
                    className='sidebar-scroll-class-black'
                    style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
                >
                    <p className='admin-home-title-white'>
                        {selectedLanguage?.txt_Competitionsubscriptionrequest}
                    </p>
                    <div className='flex-mobile' style={{ marginTop: 10, margin: '10px auto' }}>
                        <Select
                            // disabled={loading}
                            // loading={loading}
                            showSearch
                            showArrow
                            allowClear={false}
                            value={competitionId}
                            onChange={(e) => {
                                setCompetitionId(e)
                            }}
                            className='form-input'
                            placeholder={selectedLanguage?.txt_SelectCompetetion}
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                                option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 300 }}
                        >
                            {allCompetitions?.length ?
                                allCompetitions?.map((v, i) => {
                                    return (
                                        <Option value={v?._id} key={i}>{`${v?.competitionName}- € ${v?.participationMoney}`}</Option>
                                    )
                                })
                                : null
                            }
                        </Select>
                        <br />
                        <br />
                        <RangePicker
                            defaultValue={[moment().add(-1, 'months'), moment()]}
                            onChange={(e) => setDateRange([e[0]?.format('YYYY-MM-DD'), e[1]?.format('YYYY-MM-DD')])}
                            // onChange={createReportRange}
                            style={{ marginBottom: 10 }}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Table
                            loading={loading}
                            dataSource={requests}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: 800 }}
                        />
                    </div>
                    <Modal
                        open={detail?._id}
                        onCancel={() => setDetail({})}
                        footer={null}>
                        <div>
                            <ul>
                                {detail?.rankHistory?.map((v, i) => (
                                    <li>
                                        {v?.userId?.name}
                                        <ul>
                                            <li>
                                                {selectedLanguage?.txt_Bondsnumber} :{v?.userId?.bondsnummer || '----'}
                                            </li>
                                            <li>
                                                {selectedLanguage?.txt_OldRank} : {v?.oldRank}
                                            </li>
                                            <li>
                                                {selectedLanguage?.txt_NewRank} : {v?.newRank}
                                            </li>
                                            <li>
                                                {selectedLanguage?.txt_Difference} : {v?.difference}
                                            </li>
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default CompetitionMatches