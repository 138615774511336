import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Radio, Select, Switch, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { dateFormat, gameTypes } from '../../../utils/constants'
import { GET, POST } from '../../../utils/apis'
import axios from 'axios'
import moment from 'moment'
import { BTW_TEX, convertNumber, convertRoundNumber, convertTitle, disabledDate, errorMessage, getPointSetting, getUserData, infoMessage, removePlayerById, sortOponentArray, sortPlayersForMatchRequest, successMessage, successNotification, validateTime } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import { BiMoneyWithdraw, } from 'react-icons/bi'
import { GiRank1 } from 'react-icons/gi'
import _ from 'lodash'

const CreateMatchRequest = (props) => {
    const { Option } = Select
    const { selectedLanguage, user, isEnglish, userActions, getUser, pointSettingAction } = props
    const allClubs = useSelector(state => state.generalReducer.allClubsUsers)
    const playerContacts = useSelector(state => state?.userReducer?.playerContacts) || []
    const allOpponents = useSelector(state => state?.userReducer?.allOpponents) || []
    const allCouples = useSelector(state => state?.userReducer?.allCouples)
    const [booked, setBooked] = useState(false)
    const [priceDeduct, setPriceDeduct] = useState(null)
    const [allCourts, setAllCourts] = useState([])
    const [selectedCourt, setSelectedCourt] = useState({})
    const [selectedCourtDetail, setSelectedCourtDetail] = useState({})
    const [clubSelected, setClubSelected] = useState({})
    const [numPlayers, setNumPlayers] = useState(2)
    const AdminServiceFee = useSelector(state => state?.pointSettingReducer)
    const matchServiceFee = AdminServiceFee?.dollerMatchReqSendFee
    const matchServiceBTW = convertRoundNumber((matchServiceFee * BTW_TEX))
    const matchServiceFeeWithBTW = convertRoundNumber(matchServiceFee + (matchServiceFee * BTW_TEX))
    const [form] = Form.useForm()
    const format = 'HH:mm'
    const [type, setType] = useState(gameTypes[0])
    const [allValues, setAllValues] = useState({})
    const [loading, setLoading] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [dayShifts, setDayShifts] = useState([])
    const [filteredDates, setFilteredDates] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [shifts, setShifts] = useState([])
    const [filteredShifts, setFilteredShifts] = useState([])
    const [allOponent, setAllOpenent] = useState([])
    const [selectedOpponent, setSelectedOpponent] = useState([])
    const [opponentCouple, setOpponentCouple] = useState([])
    const [courtLoading, setCourtLoading] = useState(false)
    const [opponentLoading, setOpponentLoading] = useState(false)
    // console.log('user', user)
    // console.log('allOponent', allOponent)
    // console.log('opponentCouple', opponentCouple)
    // console.log('allCouples', allCouples)
    // console.log('selectedCourt-->', selectedCourt)
    // console.log('selectedOpponent-->', selectedOpponent)
    useEffect(() => {
        getPointSetting(pointSettingAction)
        getUserData(user?._id, userActions)
        let player = playerContacts?.map((v) => v?.contactId)
        setAllOpenent([...player, ...allOpponents])
    }, [])

    useEffect(() => {
        let dayReservations = []
        let shiftReservations = []
        allCourts?.filter((x) => (x?.reservation?._id && x?.gameType?.includes(type)))?.forEach((v) => {
            let dayData = v?.reservation?.rentSettings?.filter((x, i) => (x?.maximumProduct && x?.shifts?.reduce((a, { maxProduct }) => a + maxProduct, 0)))
            let shiftData = () => dayData?.filter((x, i) => x?.maximumProduct)?.forEach((x) => {
                x?.shifts.forEach((z) => {
                    z.interval = v?.reservation?.interval
                    z.name = v?.name
                    z.price = v?.rentPrice
                    z.reservationId = v?.reservation?._id
                    z.courtId = v?._id
                    z.dayId = x?._id
                })
                let newShifts = x?.shifts?.filter((z) => z?.maxProduct > 0)
                shiftReservations.push(newShifts)
            })
            shiftData()
            dayReservations.push(dayData)
        })
        dayReservations = dayReservations.flat()
        shiftReservations = shiftReservations.flat()
        let availableDates = _.uniq(dayReservations?.map((v) => moment(v?.date).format('YYYY-MM-DD')))
        availableDates = availableDates.filter((v) => moment(v, 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD')))
        setFilteredDates(availableDates)
        setDayShifts(dayReservations)
        setShifts(shiftReservations)
    }, [allCourts])

    const getCourts = (id) => {
        setCourtLoading(true)
        axios.get(`${GET?.GET_SELECTED_COURTS}/?id=${id}&gameType=${type}`,)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setAllCourts(data?.data || [])
                }
                else {
                    setAllCourts([])
                }
                setCourtLoading(false)

            })
            .catch((e) => {
                setCourtLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    const getOpponentCouples = (id) => {
        setOpponentLoading(true)
        axios.get(`${GET?.GET_OPPONENT_COUPLES}/?clubId=${id}&userId=${user?._id}`,)
            .then((res) => {
                const { data } = res
                setOpponentLoading(false)
                if (data?.success) {
                    console.log('data---=>', data?.data)
                    setOpponentCouple(data?.data || [])
                }
                else {
                    setOpponentCouple([])
                }
            })
            .catch((e) => {
                setOpponentLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const onChangeDate = (date) => {
        setSelectedDate(date)
        setFilteredShifts(shifts?.filter((v) => moment(v?.date).format('YYYY-MM-DD') === date))
    }

    const handleChange = (value) => {
        getOpponentCouples(value)
        let selectedClub = allClubs.find((v) => v?._id === value)
        setClubSelected(selectedClub || {})
        getCourts(value)

        form.setFieldsValue({
            reservation: null,
            matchDate: null
        })
    }

    const handleCourtChange = (value) => {
        console.log('function called', value)
        if (value) {
            let selectedShift = shifts.find((v) => v?._id === value)
            setSelectedCourt(selectedShift)
            let selectedClub = allCourts.find((v) => v?._id === selectedShift?.courtId)
            setSelectedCourtDetail(selectedClub)
            let price = selectedShift?.price / numPlayers
            setPriceDeduct(price)
            // if (selectedClub?.userId === user?.preferredClub?.club) {
            //     setPriceDeduct(0)
            // } else {
            // }
        }
    }

    const handleNumberChange = (value) => {
        setNumPlayers(value)
        let formValue = form.getFieldValue('reservation')
        if (formValue) {
            let selectedShift = shifts.find((v) => v?._id === formValue)
            setSelectedCourt(selectedShift)
            let selectedClub = allCourts.find((v) => v?._id === selectedShift?.courtId)
            setSelectedCourtDetail(selectedClub)
            let price = selectedShift?.price / value
            setPriceDeduct(price)
            // if (selectedClub?.userId === user?.preferredClub?.club) {
            //     setPriceDeduct(0)
            // } else {
            // }
        }
    }
    const preBookChange = (value) => {
        let newValue = value.target.checked
        setBooked(newValue)

        form.setFieldsValue({
            alreadyPaid: newValue
        })
    }
    const handleOk = () => {
        if (booked) {
            onFinishBook(allValues)
        } else {
            submitForm(allValues)
        }
        setConfirmModal(false)
    }

    const handleCancel = () => {
        setConfirmModal(false)
    }

    const onFinish = (values) => {
        setAllValues(values)
        setConfirmModal(true)
    }
    console.log('selectedCourtDetail', selectedCourtDetail)
    const submitForm = (values) => {
        if (!selectedCourtDetail?.acceptNonMember) {
            if (!(user?.preferredClub?.isApproved && user?.preferredClub?.club === selectedCourtDetail?.userId)) {
                return errorMessage("You are not a Member of this club, This court is not for Non-Members")
            }

            if (numPlayers === 4) {
                let coupleDetail = allCouples.find((v) => v?._id === values.couple)
                if (!(coupleDetail?.partnerId?.preferredClub?.isApproved && coupleDetail?.partnerId?.preferredClub?.club === selectedCourtDetail?.userId)) {
                    return errorMessage("Your partner is not a Member of this club, This court is not for Non-Members")
                }
            }

        }
        if (user?.walletBalance < getPrice()) {
            return errorMessage("You don't have enough balance to create this match request")
        }

        if (numPlayers === 4) {
            let coupleData = opponentCouple.find((v) => v?._id === values?.opponentCouple)
            let mycoupleData = allCouples.find((v) => v?._id === values?.couple)
            values.opponents = [coupleData?.userId?._id, coupleData?.partnerId?._id]
            values.partner = mycoupleData?.partnerId?._id
        }

        values.senderId = user?._id
        values.reservationId = selectedCourt?.reservationId
        values.reservation = selectedCourt?.reservationId
        values.courtId = selectedCourt?.courtId
        values.shiftId = selectedCourt?._id
        values.dayId = selectedCourt?.dayId
        values.gameType = type
        values.matchDate = moment(selectedCourt?.date).format()
        values.matchTime = selectedCourt?.startTime
        values.price = getPrice()
        console.log('values', values?.couple)

        // return true
        setLoading(true)
        axios.post(POST?.CREATE_MATCH_REQUEST, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    form.resetFields()
                    getUser()
                }
                else {
                    errorMessage(isEnglish ? data.message : data.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }

    const onFinishBook = (fieldsValue) => {
        const values = {
            ...fieldsValue,
            matchDate: fieldsValue['matchDate2'].format('YYYY-MM-DD'),
            matchTime: fieldsValue['matchTime'].format('HH:mm'),
            userId: user?._id,
            senderId: user?._id,
            gameType: type
        }
        values.price = values?.amountPaid / numPlayers

        if (user?.walletBalance < values?.price) {
            return errorMessage("You don't have enough balance to create this match request")
        }

        if (numPlayers === 4) {
            let coupleData = opponentCouple.find((v) => v?._id === values?.opponentCouple)
            let mycoupleData = allCouples.find((v) => v?._id === values?.couple)
            values.opponents = [coupleData?.userId?._id, coupleData?.partnerId?._id]
            values.partner = mycoupleData?.partnerId?._id
        }

        console.log('values', values)

        setLoading(true)
        axios.post(POST?.CREATE_BOOKED_MATCH_REQUEST, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    form.resetFields()
                    getUser()
                }
                else {
                    errorMessage(isEnglish ? data.message : data.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }

    const onTypeChange = (e) => {
        setType(e)
        if (e === gameTypes[1]) {
            setNumPlayers(4)
            form.setFieldsValue({ playerNumbers: 4 })
        }
    }
    const handleOpponent = (val) => {
        let num = form.getFieldValue('playerNumbers') / 2
        setSelectedOpponent(val.slice(-num) || [])
        form.setFieldsValue({
            opponents: val.slice(-num) || []
        })
    }
    const getPrice = () => {
        let totalMembers = []
        let members = 0
        let nonMembers = 0
        let allValues = form.getFieldsValue()
        let price = selectedCourt?.price

        if (booked) {
            let calculatedPrice = (allValues.amountPaid / allValues?.playerNumbers)
            calculatedPrice = convertRoundNumber(calculatedPrice)

            return Number(calculatedPrice)
        }

        if (!selectedCourtDetail?.memberDiscount) {
            let calculatedPrice = (price / allValues?.playerNumbers)
            calculatedPrice = convertRoundNumber(calculatedPrice)

            return Number(calculatedPrice)
        }


        totalMembers.push(user)

        if (allValues.playerNumbers === 2) {
            for (let v of allValues?.opponents) {
                let memberDetail = allOponent.find((x) => x?._id === v)
                totalMembers.push(memberDetail)
            }
        } else {
            let memberDetail = opponentCouple.find((v) => v?._id === allValues?.opponentCouple)
            let mycoupleData = allCouples.find((v) => v?._id === allValues?.couple)

            totalMembers.push(memberDetail?.partnerId)
            totalMembers.push(memberDetail?.userId)
            totalMembers.push(mycoupleData?.partnerId)
        }

        for (let v of totalMembers) {
            if (v?.preferredClub?.club === selectedCourtDetail?.userId && v?.preferredClub?.isApproved) {
                members += 1
            } else {
                nonMembers += 1
            }
        }

        let calculatedPrice = (price - (members * (price / totalMembers.length))) / totalMembers.length
        calculatedPrice = convertRoundNumber(calculatedPrice)

        return Number(calculatedPrice)

    }
    return (
        <div className='paddel_create-match-req-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
            >
                <p className='admin-home-title-white'>
                    {selectedLanguage?.titleCreateMatchRequest}
                </p>

                <div style={{ marginTop: '20px' }}>
                    <Form
                        name='form'
                        form={form}
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <p
                                    className='my-form-label'
                                    style={{
                                        padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px'
                                    }}
                                >{'Game Type'}</p>
                                <Select defaultValue={type}
                                    placeholder={'Select Game Type'}
                                    className='form-input-new-style'
                                    onChange={onTypeChange}
                                    style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                >
                                    {gameTypes?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </div>
                            <div className='form-two-input-flex-inner-input' >
                                <Form.Item
                                    name='playerNumbers'
                                    label={<p className='my-form-label'>{'Select Number of Players'}</p>}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.textPleaseSelectWinnerOrDecidedToDrawIsMatch
                                        }
                                    ]}
                                    initialValue={numPlayers}
                                >
                                    <Radio.Group
                                        value={numPlayers}
                                        onChange={(e) => handleNumberChange(e?.target?.value)}
                                        className='form-input-new-style'
                                        style={{ width: '100%', padding: '10px', display: 'flex', alignItems: 'center', backgroundColor: 'white' }}
                                        disabled={type === gameTypes[1]}
                                    >
                                        <Radio value={2}>{'2 Player'}</Radio>
                                        <Radio value={4}>{'4 Player'}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>

                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input '>
                                <Form.Item
                                    // label={<p className='my-form-label'>{selectedLanguage?.text_Promoter}</p>}
                                    name='alreadyPaid'
                                    valuePropName='checked'
                                    initialValue={false}
                                >
                                    <Checkbox
                                        disabled={booked}
                                        className='form-input-new-style'
                                        style={{ width: '100%', padding: '17px 14px', background: 'white' }}>
                                        {'Already Paid'}
                                    </Checkbox>
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input '>
                                <Form.Item
                                    name='preBooked'
                                    valuePropName='checked'
                                    initialValue={false}
                                >
                                    <Checkbox
                                        onChange={preBookChange}
                                        className='form-input-new-style'
                                        style={{ width: '100%', padding: '17px 14px', background: 'white' }}>
                                        {'Pre Booked'}
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </div>
                        {!booked ?
                            <>
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='club'
                                            hasFeedback
                                            label={<p className='my-form-label'>{'Select Club'}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Club'
                                                }
                                            ]}
                                        >
                                            <Select
                                                onChange={handleChange}
                                                className='form-input-new-style'
                                                placeholder={'Select Club'}>
                                                {allClubs?.length && allClubs?.map((v, i) => {
                                                    return (
                                                        <Option value={v?._id} key={i}>{`${v?.name} - ${v?.city}`}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='matchDate'
                                            hasFeedback
                                            label={<p className='my-form-label'>{'Select Date'}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Date'
                                                }
                                            ]}
                                            validateStatus={courtLoading ? 'error' : null}
                                            help={courtLoading ? 'The data is fetching...' : null}
                                        >
                                            <Select
                                                loading={courtLoading}
                                                onChange={onChangeDate}
                                                className='form-input-new-style'
                                                placeholder={'Select Date'}>
                                                {filteredDates?.length && filteredDates?.map((v, i) => {
                                                    return (
                                                        <Option value={v} label={v} key={i} />
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='reservation'
                                            hasFeedback
                                            label={<p className='my-form-label'>{'Select Reservation'}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Reservation'
                                                }
                                            ]}
                                        >
                                            <Select
                                                onChange={handleCourtChange}
                                                className='form-input-new-style'
                                                placeholder={'Select Reservation'}>
                                                {filteredShifts?.length && filteredShifts?.map((v, i) => {
                                                    return (
                                                        <Option key={i} value={v?._id}>{`${moment(v?.date).format('YYYY-MM-DD')} ${validateTime(v?.startTime)} Duration:${v?.interval} ${v?.name} Price: € ${v?.price}`}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input'>
                                        {numPlayers === 2 ?
                                            <Form.Item
                                                name='opponents'
                                                hasFeedback
                                                label={<p className='my-form-label'>{'Select Opponent'}</p>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Select Opponent'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    mode='multiple'
                                                    value={selectedOpponent}
                                                    onChange={handleOpponent}
                                                    className='form-input-new-style'
                                                    placeholder={'Select Opponent'}>
                                                    {allOponent?.length && allOponent?.map((v, i) => {
                                                        return (
                                                            <Option value={v?._id} key={i}>{v?.email}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item> :
                                            <Form.Item
                                                name='opponentCouple'
                                                hasFeedback
                                                label={<p className='my-form-label'>{'Select Opponent Couple'}</p>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Select Opponent'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    loading={opponentLoading}
                                                    className='form-input-new-style'
                                                    placeholder={'Select Opponent'}>
                                                    {opponentCouple?.length && opponentCouple?.map((v, i) => {
                                                        return (
                                                            <Option value={v?._id} key={i}>{v?.coupleName}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='club'
                                            hasFeedback
                                            label={<p className='my-form-label'>{'Select Club'}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Club'
                                                }
                                            ]}
                                        >
                                            <Select
                                                onChange={handleChange}
                                                className='form-input-new-style'
                                                placeholder={'Select Club'}>
                                                {allClubs?.length && allClubs?.map((v, i) => {
                                                    return (
                                                        <Option value={v?._id} key={i}>{`${v?.name} - ${v?.city}`}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='matchDate2'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.label_Gamedate}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.error_pleaseselectgamedate
                                                }
                                            ]}
                                        >
                                            <DatePicker
                                                // disabled={!selectedCompetition}
                                                // onChange={onChangeDate}
                                                disabledDate={disabledDate}
                                                className='form-input-new-style'
                                                placeholder={selectedLanguage?.placeHolder_selectgamedate}
                                                style={{ width: '100%' }}
                                                allowClear={false}
                                                format={dateFormat}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='matchTime'
                                            hasFeedback
                                            label={<p className='my-form-label'>{selectedLanguage?.label_gametime}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.error_pleaseselectgametime
                                                }
                                            ]}
                                        >
                                            <TimePicker
                                                // disabled={!selectedCompetition}
                                                // onChange={onChangeTime}
                                                minuteStep={15}
                                                style={{ width: '100%' }}
                                                className='form-input-new-style'
                                                placeholder={selectedLanguage?.plaecholder_selectgametime}
                                                format={format} />
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input'>
                                        {numPlayers === 2 ?
                                            <Form.Item
                                                name='opponents'
                                                hasFeedback
                                                label={<p className='my-form-label'>{'Select Opponent'}</p>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Select Opponent'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    mode='multiple'
                                                    value={selectedOpponent}
                                                    onChange={handleOpponent}
                                                    className='form-input-new-style'
                                                    placeholder={'Select Opponent'}>
                                                    {allOponent?.length && allOponent?.map((v, i) => {
                                                        return (
                                                            <Option value={v?._id} key={i}>{v?.email}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item> :
                                            <Form.Item
                                                name='opponentCouple'
                                                hasFeedback
                                                label={<p className='my-form-label'>{'Select Opponent Couple'}</p>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Select Opponent'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    loading={opponentLoading}
                                                    className='form-input-new-style'
                                                    placeholder={'Select Opponent'}>
                                                    {opponentCouple?.length && opponentCouple?.map((v, i) => {
                                                        return (
                                                            <Option value={v?._id} key={i}>{v?.coupleName}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        <div className='form-two-input-flex'>
                            {booked &&
                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.Item
                                        name='amountPaid'
                                        hasFeedback
                                        label={<p className='my-form-label'>{'Paid Amount'}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter amount you have paid'
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            step={0.5}
                                            type='number'
                                            style={{ width: '100%' }}
                                            className='form-input-new-style'
                                            placeholder={'Enter Amount you have paid'}
                                        />
                                    </Form.Item>
                                </div>
                            }
                            {numPlayers > 2 &&
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='couple'
                                        hasFeedback
                                        label={<p className='my-form-label'>{'Select Partner Couple'}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select Couple'
                                            }
                                        ]}
                                    >
                                        <Select
                                            className='form-input-new-style'
                                            placeholder={'Select Couple'}>
                                            {allCouples?.filter((v) => v?.userId?._id === user?._id)?.length && allCouples?.filter((v) => v?.userId?._id === user?._id)?.map((v, i) => {
                                                return (
                                                    <Option value={v?._id} key={i}>{v?.coupleName}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                            }
                        </div>

                        <div className='form-input-single-input'>
                            <Form.Item
                                name='message'
                                hasFeedback
                                label={<p className='my-form-label'>{selectedLanguage?.label_Message}</p>}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_enterAmessage}
                                />
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button
                                style={{
                                    padding: '0px 20px 0px 20px',
                                    background: 'rgba(92, 93, 93, 1)',
                                    color: 'white',
                                    border: 'none',
                                    height: '45px',
                                    marginTop: 10,
                                    borderRadius: 6
                                }}
                                htmlType='submit'
                                loading={loading}
                            >{selectedLanguage?.btn_SendMatchRequest_send}</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div >
            {/* Modal Confirm */}
            {confirmModal && < Modal
                open={confirmModal}
                footer={null}
                className='pop-info-modal'
                style={{ top: '10px', }}
            >
                <div>
                    <p className='p-question'>{selectedLanguage?.textAreYouSureYOuWantToCreatMacthRequest}</p>
                    <p className='p-opt'>By Creating a match request € {/* priceDeduct */ getPrice()} will be deduct from you wallet</p>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }}>
                    <button className='cancel-btn' onClick={handleCancel}>{selectedLanguage?.text_Cancel}</button>
                    <button className='confirm-btn' onClick={handleOk}>{selectedLanguage?.btn_SendMatchRequest_send}</button>
                </div>
            </Modal >}
            {/*  Modal Confirm*/}


        </div >
    )
}

export default CreateMatchRequest