const adminApi = `/api/admin`
const authApi = `/api/user`
const getApi = `/api/get`
const postApi = `/api/post`

const AUTH = {
    LOGIN: `${authApi}/login`,
    SIGNUP: `${authApi}/signup`,
    FORGOT_PASSWORD: `${authApi}/forgot-password`,
    UPDATE_PASSWORD: `${authApi}/update-password`,
    UPDATE_SETTINGS: `${authApi}/update-settings`,
    CHANGE_PASSWORD: `${authApi}/change-password`,
}
const GET = {
    USER_BY_ID: `${getApi}/get-user`,
    VERIFY_TOKEN: `${getApi}/verifytoken`,
    VERIFY_EMAIL: `${getApi}/verifyemail`,
    GET_PLAYER_GAME_SUGGESTION: `${getApi}/get-player-game-suggestion`,
    GET_PLAYER_GAME_SETTING: `${getApi}/get-player-game-setting`,
    GET_ALL_PLAYER: `${getApi}/get-all-player`,
    GET_PLAYER_CONTACT: `${getApi}/get-player-contact`,
    GET_ALL_TOURNAMENT: `${getApi}/get-all-tournament`,
    GET_PLAYER_RANKS: `${getApi}/get-player-ranks`,
    GET_GAME_RANKS: `${getApi}/get-game-ranks`,
    GET_PLAYER_GAME_RANK: `${getApi}/get-player-game-rank`,
    GET_GAME_SETTING: `${getApi}/get-game-setting`,
    GET_SENT_MATCH_REQUEST: `${getApi}/get-sent-match-request`,
    GET_RECIVE_MATCH_REQUEST: `${getApi}/get-recive-match-request`,
    GET_APPROVED_MATCH_REQUEST: `${getApi}/get-match-reqest-approved`,
    GET_PLAYER_CLAIMS_ARGUES: `${getApi}/get-claims-argues-player`,
    GET_INVITED_FRIEND: `${getApi}/get-invited-friend`,
    GET_TOURNAMENT_MATCHES: `${getApi}/get-tournament-matches`,
    PAYMENT_STATUS: `${getApi}/get-payment-status`,
    GET_TOURNAMENT_CLAIMS_ARGUE: `${getApi}/get-tournament-claims-argues`,
    GET_USER_CASH_BACK: `${getApi}/get-cash-back`,
    GET_USER_CASH_BACK_AMOUNT: `${getApi}/get-user-cash-back-amount`,
    GET_WALLET_TRANSACTION: `${getApi}/get-walet-transaction`,
    GET_ALL_COMPETITIONS: `${getApi}/get-all-competitions`,
    GET_COMPETITION_RANKS: `${getApi}/get-competition-ranks`,
    GET_PARTICULAR_COMPETITION_RANKS: `${getApi}/get-particular-competition-ranks`,
    GET_SENT_MATCH_REQUEST_COMPETITION: `${getApi}/get-sent-match-request-competition`,
    GET_RECIVE_MATCH_REQUEST_COMPETITION: `${getApi}/get-recive-match-request-competition`,
    GET_PLAYER_COMPETITION_SUBSCRIPTION: `${getApi}/get-player-competition-subscription`,
    GET_COMPETITION_APPROVED_MATCH_REQUEST: `${getApi}/get-competition-accepted-matches`,
    GET_SUBSCRIBED_COMPETITIONS: `${getApi}/get-subscribed-competitions`,

    // courts
    GET_COURTS: `${getApi}/get-courts`,
    GET_COURT_FOR_PLAYER: `${getApi}/get-courts-for-players`,

    GET_COUPLE_REQUESTS: `${getApi}/get-couple-requests`,
    GET_SELECTED_COURTS: `${getApi}/get-selected-courts`,
    GET_COURT_BY_ID: `${getApi}/get-court-by-id`,

    GET_COUPLE: `${getApi}/get-couples`,
    GET_OPPONENTS: `${getApi}/get-extra-opponents`,
    GET_MEMBERS: `${getApi}/get-members`,

    GET_OPPONENT_COUPLES: `${getApi}/get-opponent-couples`,
    GET_PLAYER_INVOICES: `${getApi}/get-player-ivoices`,

    GET_COMPETITION_SUBSCRIPTIONS: `${getApi}/get-competition-subscriptions`,

    GET_MATCHES_COUNT: `${getApi}/get-matches-count`,
    GET_CLUB_INFO: `${getApi}/get-club-information`,
    GET_MY_COMPETITION: `${getApi}/get-my-competition`,

    GET_COMPETITION_RANK_HISTORY: `${getApi}/get-competition-rank-history`,

}

const POST = {
    CREATE_GAME_SUGGESTION: `${postApi}/create-game-suggestion`,
    CREATE_PLAYER_GAME_SETTING: `${postApi}/create-player-game-setting`,
    DELETE_PLAYER_GAME_SETTING: `${postApi}/delete-player-game-setting`,
    EDIT_PLAYER_GAME_SETTING: `${postApi}/edit-player-game-setting`,
    CREATE_CONTACT: `${postApi}/create-contact`,
    DELETE_CONTACT: `${postApi}/delete-contact`,
    CREATE_TOURNAMENT: `${postApi}/create-tournament`,
    DELETE_TOURNAMENT: `${postApi}/delete-tournament`,
    EDIT_TOURNAMENT: `${postApi}/edit-tournament`,
    TOURNAMENT_PARTICIPATE: `${postApi}/tournament-participate`,
    CANCEL_PARTICIPATE: `${postApi}/cancel-participate`,
    CREATE_MATCH_REQUEST: `${postApi}/create-match-request`,
    CREATE_BOOKED_MATCH_REQUEST: `${postApi}/create-booked-match-request`,
    DELETE_MATCH_REQUEST: `${postApi}/delete-match-request`,
    REJECT_MATCH_REQUEST: `${postApi}/reject-match-request`,
    ACCEPT_MATCH_REQUEST: `${postApi}/accept-match-request`,
    GET_FILE_URL: `${postApi}/get-file-url`, /* Post File */
    TOURNAMENT_PARTICIPATE_WITH_COUPON: `${postApi}/tournament-participate-with-coupon`,
    DOLLER_TOURNAMENT_PARTICIPATE: `${postApi}/doller-tournament-participate`,

    USER_LOST_MATCH: `${postApi}/user-match-lost`,
    SUBMIT_WIN_PROOF: `${postApi}/submit-win-proof`,
    USER_MATCH_DRAW: `${postApi}/user-match-draw`,
    USER_CONFIRM_MATCH_DRAW: `${postApi}/user-confirm-match-draw`,

    TOURNAMENT_LOST_MATCH: `${postApi}/tournament-loss-match`,

    WALLET_RECHARGE: `${postApi}/wallet-recharge`,
    RESOLVE_TOURNAMENT_ARGUE: `${postApi}/resolve-tournament-argue`,

    CASH_BACK_REQUEST: `${postApi}/cash-back-request`,
    COMPETITION_SUBSCRIPTION: `${postApi}/competition-subscription`,
    CREATE_COMPETITION_MATCH: `${postApi}/create-competition-match-request`,
    CREATE_BOOKED_COMPETITION_MATCH: `${postApi}/create-booked-competition-match-request`,

    // competition matches
    DELETE_COMPETITION_MATCH_REQUEST: `${postApi}/delete-competition-match-request`,
    REJECT_COMPETITION_MATCH_REQUEST: `${postApi}/reject-competition-match-request`,
    ACCEPT_COMPETITION_MATCH_REQUEST: `${postApi}/accept-competition-match-request`,

    USER_COMPETITION_LOST_MATCH: `${postApi}/user-competition-match-lost`,

    // product
    CREATE_RESERVATION: `${postApi}/create-reservation`,
    UPDATE_RESERVATION_SETTING: `${postApi}/update-reservation-setting`,
    COPY_RESERVATION_SETTIN: `${postApi}/copy-reservation-setting`,

    CREATE_PRODUCT: `${postApi}/create-court`,
    EDIT_PRODUCT: `${postApi}/edit-court`,
    DELETE_PRODUCT: `${postApi}/delete-court`,
    CHANGE_PRIVATE_CODE: `${postApi}/change-private-code`, // city and province in body
    ADD_FAVOURITE_COURT: `${postApi}/add-favourite-court`,
    ADD_SECRET_CODE: `${postApi}/add-secret-code`,
    GET_PRIVATE_COURTS: `${postApi}/get-private-courts-for-players`,

    ACCEPT_COUPLE_REQUEST: `${postApi}/accept-couple-request`, // city and province in body
    DELETE_COUPLE_REQUEST: `${postApi}/delete-couple-request`, // city and province in body
    CREATE_REVIEW: `${postApi}/create-review`, // Review
    CREATE_REVIEW_ANSWER: `${postApi}/create-review-answer`, // Review

    CREATE_COUPLE: `${postApi}/create-couple`,
    DELETE_COUPLE: `${postApi}/delete-couple`,

    APPROVE_MEMBERSHIP: `${postApi}/approve-membership`,
    REMOVE_MEMBERSHIP: `${postApi}/remove-membership`,
    INVITE_FRIEND_TO_PADEL: `${postApi}/invite-friend-to-padel`,
    GET_SUBSCRIPTION_OF_COMPETITION: `${postApi}/get-subscribtion-of-competitions`,

}

const ADMIN = {
    LOGIN: `${adminApi}/login`,
    ADMIN_SIGNUP: `${adminApi}/admin-signup`,
    EDIT_ADMIN_INFO: `${adminApi}/admin-edit-info`,
    ADD_NEW_GAME: `${adminApi}/add-new-game`,
    GET_ALL_GAME: `${adminApi}/get-all-games`,
    EDIT_GAME: `${adminApi}/edit-game`,
    DELETE_GAME: `${adminApi}/delete-game`,
    GET_GAME_SUGGESTION: `${adminApi}/get-game-suggestion`,
    DELETE_GAME_SUGGESTION: `${adminApi}/delete-game-suggestion`,
    APPROVE_GAME_SUGGESTION: `${adminApi}/approve-game-suggestion`,
    CREATE_TOURNAMENT: `${adminApi}/create-tournament`,
    GET_ALL_TOURNAMENT: `${adminApi}/get-all-tournament`,
    DELETE_TOURNAMENT: `${adminApi}/delete-tournament`,
    EDIT_TOURNAMENT: `${adminApi}/edit-tournament`,
    CREATE_PROMOTER: `${adminApi}/create-promoter`,
    GET_ALL_PROMOTER: `${adminApi}/get-promoter`,
    DELETE_PROMOTER: `${adminApi}/delete-promoter`,
    EDIT_PROMOTER: `${adminApi}/edit-promoter`,
    ADD_REFERRAL_POINTS: `${adminApi}/add-referral-points`,
    GET_REFERRAL_POINTS: `${adminApi}/get-referral-points`,
    EDIT_REFERRAL_POINTS: `${adminApi}/edit-referral-points`,
    GET_SELECTED_GAME_SETTING: `${adminApi}/get-selected-game-setting`,
    GET_PLAYER_NUMBER_OF_MATCHES: `${adminApi}/get-player-number-of-matches`,
    GET_CLAIM_ARGUES: `${adminApi}/get-claim-argues`,
    RESOLVE_ARGUES: `${adminApi}/resolve-argue`,
    ADD_TOURNAMENT_ORGANIZER_POINT: `${adminApi}/add-tournament-organizer-points`,
    EDIT_TOURNAMENT_ORGANIZER_POINT: `${adminApi}/edit-tournament-organizer-points`,
    GET_TOURNAMENT_ORGANIZER_POINT: `${adminApi}/get-tournament-organizer-points`,
    CREATE_TOURNAMENT_COUPOUN: `${adminApi}/create-tournament-coupons`,
    GET_TOURNAMENT_COUPOUN: `${adminApi}/get-tournament-coupons`,
    ADD_WITHDRAWAL_POINTS: `${adminApi}/add-withdrawal-points`,
    GET_WITHDRAWAL_POINTS: `${adminApi}/get-withdrawal-points`,
    EDIT_WITHDRAWAL_POINTS: `${adminApi}/edit-withdrawal-points`,
    GET_GAME_DATA: `${adminApi}/get-game-data`,
    ADD_TOURNAMENT_ORGANIZER_PAY_COST_POINT: `${adminApi}/add-tournament-organizer-pay-cost-points`,
    EDIT_TOURNAMENT_ORGANIZER_PAY_COST_POINT: `${adminApi}/edit-tournament-organizer-pay-cost-points`,
    GET_TOURNAMENT_ORGANIZER_PAY_COST_POINT: `${adminApi}/get-tournament-organizer-pay-cost-points`,
    ADD_SUBSCRIPTION_FEE_FOR_DOLLER_TOURNAMENT: `${adminApi}/add-subscription-fee-doller-tournament`,
    EDIT_SUBSCRIPTION_FEE_FOR_DOLLER_TOURNAMENT: `${adminApi}/edit-subscription-fee-doller-tournament`,
    GET_SUBSCRIPTION_FEE_FOR_DOLLER_TOURNAMENT: `${adminApi}/get-subscription-fee-doller-tournament`,
    ADD_SEND_DOLLER_MATCH_REQ_FEE: `${adminApi}/add-send-doller-match-req-fee`,
    EDIT_SEND_DOLLER_MATCH_REQ_FEE: `${adminApi}/edit-send-doller-match-req-fee`,
    GET_SEND_DOLLER_MATCH_REQ_FEE: `${adminApi}/get-send-doller-match-req-fee`,
    GET_ALL_PLAYERS: `${adminApi}/get-all-players`,
    GET_TOURNAMENT_CLAIM_ARGUES: `${adminApi}/get-tournament-claims-argues`,
    ADD_CASHBACK_SERVICE_FEE: `${adminApi}/add-cashback-service-fee`,
    EDIT_CASHBACK_SERVICE_FEE: `${adminApi}/edit-cashback-service-fee`,
    GET_CASHBACK_SERVICE_FEE: `${adminApi}/get-cashback-service-fee`,
    GET_ALL_CASH_BACKS: `${adminApi}/get-all-cash-backs`,
    CONFIRM_CASH_BACK: `${adminApi}/confirm-cash-back`,
    GET_SUBSCRIPTION_REPORT: `${adminApi}/get-subscription-report`,
    ADD_FAQ_QUESTION: `${adminApi}/add-faq-question`,
    GET_FAQ_QUESTION: `${adminApi}/get-faq-question`,
    DELETE_FAQ_QUESTION: `${adminApi}/delete-faq-question`,
    EDIT_FAQ_QUESTION: `${adminApi}/edit-faq-question`,
    CREATE_COMPETITION: `${adminApi}/create-competition`,
    ADD_COMPETITION_SUB_SERVICE_FEE: `${adminApi}/add-competition-sub-service-fee`,
    EDIT_COMPETITION_SUB_SERVICE_FEE: `${adminApi}/edit-competition-sub-service-fee`,
    GET_COMPETITION_SUB_SERVICE_FEE: `${adminApi}/get-competition-sub-service-fee`,
    GET_COMPETITION_SUB_BY_COM_ID: `${adminApi}/get-competition-subscription-by-comptetion-id`,
    APPROVE_COMPETITION_SUB_REQUEST: `${adminApi}/approve-competition-subscription-request`,
    REJECT_COMPETITION_SUB_REQUEST: `${adminApi}/reject-competition-subscription-request`,


    // new work for clubs

    GET_ALL_CLUB_USERS: `${adminApi}/get-all-club-users`,
    GET_ALL_PLAYER_USERS: `${adminApi}/get-all-player-users`,
    APPROVE_COURT: `${adminApi}/approve-court`,
    DELETE_COURT: `${adminApi}/delete-court`,
    ADD_ADMIN_CLUBS: `${adminApi}/add-admin-clubs`,
    GET_ADMIN_CLUBS: `${adminApi}/get-admin-clubs`,
    DELETE_ADMIN_CLUBS: `${adminApi}/delete-admin-clubs`,
    EDIT_ADMIN_CLUBS: `${adminApi}/edit-admin-clubs`,
    HIDE_SINGLE_REVIEW: `${adminApi}/hide-single-review`,
    SHOW_SINGLE_REVIEW: `${adminApi}/show-single-review`,
    UPDATE_COMPETETION_SUB_RANK: `${adminApi}/update-competition-subscriber-rank`,
    UPDATE_COMPETETION_SUB_USER_RANK: `${adminApi}/update-competition-subscribed-user-rank`,

}


export {
    AUTH,
    GET,
    ADMIN,
    POST
}