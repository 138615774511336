import React, { useEffect, useState } from "react";
import { Table } from 'antd'
import { getUserData } from '../../../utils/helpers'
import { useSelector } from 'react-redux'

const Competitionstatus = (props) => {
    const { userActions, selectedLanguage, user } = props
    const competitionRanks = useSelector(state => state.userReducer.competitionRanks)

    console.log('competitionRanks', competitionRanks)
    useEffect(() => {
        getUserData(user?._id, userActions)
    }, [])

    const columns = [
        {
            title: selectedLanguage?.txt_competitionname,
            dataIndex: ['competition', 'competitionName'],
            render: (e) => {
                return (
                    <p>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_couplename,
            dataIndex: ['coupleId', 'coupleName'],
            render: (e) => {
                return (
                    <p>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_username,
            dataIndex: ['userId', 'name'],
            render: (e) => {
                return (
                    <p>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txxt_level,
            dataIndex: ['competition', 'level'],
            render: (e) => {
                return (
                    <p>{e || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_numberOfMatchesPlayed,
            dataIndex: 'numberOfMatches',
            render: (e) => {
                return (
                    <p>{e || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_won,
            dataIndex: 'win',
            render: (e) => {
                return (
                    <p>{e || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Draw,
            dataIndex: 'draws',
            render: (e) => {
                return (
                    <p>{e || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_loss,
            dataIndex: 'losses',
            render: (e) => {
                return (
                    <p>{e || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_TotalPoints,
            dataIndex: 'point',
            render: (e) => {
                return (
                    <p>{e || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Rank,
            dataIndex: 'rank',
            render: (e) => {
                return (
                    <p>{e || '----'}</p>
                )
            }
        },
    ]

    return (
        <div className='create-match-req-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
            >
                <p className='admin-home-title-white'>
                    {selectedLanguage?.txt_CompetitionStatus}
                </p>
                <Table
                    style={{ marginTop: 20 }}
                    dataSource={competitionRanks}
                    columns={columns}
                    className='specific-css-table'
                    pagination={false}
                    scroll={{ x: 2000, y: 200 }}
                />
            </div>
        </div>
    )
}

export default Competitionstatus