import { Button } from "antd";
import React from "react";
import InvitePointSetting from "./InvitePointsSetting";
import TournamentOrganizePointSetting from "./TournamentOrganizePointSetting";
import WithdrawPointSetting from "./WithdrawPointSetting";
import OrganizePayCostSetting from "./OrganizePayCostSetting";
import SubscriptionFeeDollerTournament from "./SubscriptionFeeDollerTournament";
import ServiceFeeForSentDollerMatchRequest from "./ServiceFeeForSentDollerMatchRequest";
import ServiceFeeForCashBack from "./ServiceFeeForCashBack";
import ServiceFeeForCompetition from "./ServiceFeeCompetition";

const PointSetting = (props) => {
    const { user, selectedLanguage } = props
    return (
        <div style={{ margin: '20px' }}>
            <div>
                <p className='admin-home-title'>
                    {selectedLanguage?.text_PointRewardSetting}
                </p>
                <div>
                    <InvitePointSetting {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <p className='admin-home-title'>
                    {selectedLanguage?.text_TournamentOrganizePointSetting}
                </p>
                <div>
                    <TournamentOrganizePointSetting {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <p className='admin-home-title'>
                    {selectedLanguage?.text_WithdrawPointSetting}
                </p>
                <div>
                    <WithdrawPointSetting {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <p className='admin-home-title'>
                    {selectedLanguage?.text_OrganisorOfTournamentPayCostSetting}
                </p>
                <div>
                    <OrganizePayCostSetting {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <p className='admin-home-title'>
                    {selectedLanguage?.text_ServiceFeeForSubscribeTournaments}
                </p>
                <div>
                    <SubscriptionFeeDollerTournament {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <p className='admin-home-title'>
                    {selectedLanguage?.text_ServiceFeeForMatchRequest}
                </p>
                <div>
                    <ServiceFeeForSentDollerMatchRequest {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <p className='admin-home-title'>
                    {selectedLanguage?.ttx_ServiceFeeForCompetitionSubscription}
                </p>
                <div>
                    <ServiceFeeForCompetition {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <p className='admin-home-title'>
                    {selectedLanguage?.txt_ServiceFeeForCashback}
                </p>
                <div>
                    <ServiceFeeForCashBack {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
        </div >
    )
}

export default PointSetting