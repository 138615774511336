import React, { useState } from 'react'
import AdminHome from '../AdminScreen/Home/home'
import PlayerHome from '../PlayerScreen/Home/Home'
import ClubHome from '../Club/ClubHome/ClubHome'

const Home = (props) => {
    const { selectedLanguage, isEnglish, user } = props
    return (
        <>{user?.userType === "club" ?
            <ClubHome {...props} /> :
            user?.userType === "player" ?
                <PlayerHome {...props} /> :
                user?._id ?
                    <AdminHome {...props} />
                    : null}
        </>
    )
}

export default Home