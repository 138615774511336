import { Button, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import { GET } from '../../../utils/apis'
import { convertNumber, errorMessage } from '../../../utils/helpers'
import PlayerClaimDetail from './PlayerClaimDetail'
import NoData from '../../../assets/noDataImae.png'
import { deployedURL } from '../../../utils/constants'

const PlayerClaim = (props) => {
    const { selectedLanguage, isEnglish, user } = props
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [resolveData, setResolveData] = useState([])
    const [showArgues, setShowArgues] = useState(false)
    const [solveArueData, setSolveArueData] = useState([])
    const [claimDetail, setClaimDetail] = useState({})

    useEffect(() => {
        getSocketInfo()
        setLoading(true)
        getData()
        getTournamentArgueData()
    }, [])

    let origin = 'https://www.padeldater.com'
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    console.log('origin', origin)
    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('matchRequestResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            getData()
        })
    }

    const getTournamentArgueData = () => {
        console.log('called')
        axios.get(`${GET?.GET_TOURNAMENT_CLAIMS_ARGUE}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setResolveData(data?.data)
                }
                else {
                    setResolveData([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }

    const getData = () => {
        axios.get(`${GET?.GET_PLAYER_CLAIMS_ARGUES}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setData(data?.data)
                }
                else {
                    setData([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }

    const columns = [
        {
            title: selectedLanguage?.txt_CompetitionName,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.competition?.competitionName || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_ClubName,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.club?.name || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_GameType,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.gameType || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.NumberOfPlayer,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.playerNumbers || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_MatchDate_Time,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{`${e?.matchDate ? e?.matchDate : '----'}|${e?.matchTime ? e?.matchTime : '----'}`}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_SenderName,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.senderId?.name || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_SenderPartnerName,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.partner?.name || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_SenderCouple,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.couple?.coupleName || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_OponentCouple,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.opponentCouple?.coupleName || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.Oponents,
            render: (e) => {
                return (
                    e?.opponents?.length ?
                        e?.opponents?.map((v, i) => {
                            return (
                                <>
                                    < p className='table_p' style={{ width: '150px' }}> {v?.name} .</p ><br />
                                </>
                            )
                        }) :
                        < p className='table_p' style={{ width: '150px' }}> {'----'}</p >
                )
            }
        },
        {
            title: selectedLanguage?.txt_MessagefromSenders,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.message || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Status,
            render: (e) => {
                return (
                    <>
                        {e?.isCompleted ?
                            <p className='pending-stats'>{selectedLanguage?.Completed}</p>
                            :
                            <p className='pending-stats'>{selectedLanguage?.text_Pending}</p>
                        }
                    </>
                )
            }
        },
    ]

    // const argueColumns = [
    //     {
    //         title: selectedLanguage?.title_Game,
    //         dataIndex: 'gameId',
    //         render: (e) => {
    //             return (
    //                 <p className='table_p' style={{ width: '110px' }}>{e?.name || '-----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.text_GameType,
    //         dataIndex: 'gameId',
    //         render: (e) => {
    //             return (
    //                 <p className='table_p' style={{ width: '110px' }}>{e?.name || '-----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.text_MatchDateTime,
    //         render: (e) => {
    //             return (
    //                 <p className='table_p' style={{ width: '150px' }}>{e?.matchDate || '----'}|{e?.matchTime}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.text_SenderOfMatchRequest,
    //         dataIndex: 'senderId',
    //         render: (e) => {
    //             return (
    //                 <p className='table_p' style={{ width: '150px' }}>{e?.name}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.text_ReceiverOfMatchRequest,
    //         dataIndex: 'receiverId',
    //         render: (e) => {
    //             return (
    //                 <p className='table_p' style={{ width: '150px' }}>{e?.name}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: `${selectedLanguage?.text_PointsOfMatch}/${selectedLanguage?.text_money}`,
    //         align: 'center',
    //         render: (e) => {
    //             return (
    //                 <p className='table_p' style={{ width: '150px' }}>{e?.isStake ? `$ ${convertNumber(e?.money)}` : convertNumber(e?.points)}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: selectedLanguage?.text_viewDetails,
    //         render: (e) => {
    //             return (
    //                 <Button
    //                     onClick={() => setClaimDetail(e)}
    //                     className='i-won-button'
    //                     style={{ marginLeft: '10px' }}
    //                 >{selectedLanguage?.text_viewDetails}</Button>
    //             )
    //         }
    //     },
    // ]

    const handleOk = () => {
        setClaimDetail({})
    }
    return (
        <div className='claim-p-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }}
            >
                <p
                    style={{
                        marginTop: '10px',
                    }}
                    className='admin-home-title-white'>
                    {selectedLanguage?.text_MyClaim}
                </p>
                {
                    claimDetail?._id &&
                    <PlayerClaimDetail data={claimDetail} onCancel={() => setClaimDetail({})} visible={claimDetail._id ? true : false} handleOk={handleOk} {...props} />
                }
                {/* <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    {showArgues ?
                        <Button
                            style={{
                                padding: '0px 20px 0px 20px',
                                background: 'rgba(92, 93, 93, 1)',
                                color: 'white',
                                border: 'none',
                                height: '45px',
                                marginTop: '10px',
                                borderRadius: 6
                            }}
                            onClick={() => setShowArgues(false)}>
                            {selectedLanguage?.text_Myclaims}
                        </Button>
                        :
                        <Button
                            style={{
                                padding: '0px 20px 0px 20px',
                                background: 'rgba(92, 93, 93, 1)',
                                color: 'white',
                                border: 'none',
                                height: '45px',
                                marginTop: '10px',
                                borderRadius: 6
                            }}
                            onClick={() => setShowArgues(true)}>
                            {selectedLanguage?.text_Resolveclaims}
                        </Button>}
                </span> */}
                <div>
                    <Table
                        dataSource={/* showArgues ? resolveData : */ data}
                        columns={/* showArgues ? argueColumns : */ columns}
                        pagination={false}
                        loading={loading}
                        scroll={{ x: 400 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PlayerClaim