import React, { useEffect, useState } from 'react'
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io"
import { useHistory } from 'react-router-dom'
import { FaqsParticipation, FaqsGoal, FaqsTournaments, HowWork } from "../../../utils/faqConstant"
import EnglishLanguage from '../../../Languages/EnglishLanguage.json'
import NlLanguage from '../../../Languages/NetherlandsLanguage.json'
import { useSelector } from 'react-redux'
import FaqFooter from '../../../Components/Faq/FaqFooter'
import axios from 'axios'
import { ADMIN } from '../../../utils/apis'
import { errorMessage, getFaqHelper, successMessage } from '../../../utils/helpers'
import { AiFillDelete } from 'react-icons/ai'
import { FaEdit } from 'react-icons/fa'
import { Button, Popconfirm, Spin } from 'antd'
import AddFaq from './AddFaq'
import { GiBabyfootPlayers } from 'react-icons/gi'
import { ImClubs } from 'react-icons/im'

const AdminFaq = (props) => {
    const { faqSettingAction, user, selectedLanguage, isEnglish } = props
    const faqSettingData = useSelector(state => state?.faqSettingReducer?.faqSetting)
    const [editFaq, setEditFaq] = useState({})
    const [loading, setLoading] = useState({})
    const [reading, setReading] = useState('')
    const [readingGoals, setReadingGoals] = useState('')
    const [readingTournaments, setReadingTournaments] = useState('')


    const deleteFaq = (id) => {
        setLoading({ [id]: true })
        axios.post(ADMIN?.DELETE_FAQ_QUESTION, { id })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getFaqHelper(faqSettingAction)
                    setLoading({})
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                    setLoading({})
                }
            })
            .catch((e) => {
                setLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    useEffect(() => {
        getFaqHelper(faqSettingAction)
    }, [])


    return (
        <div style={{ backgroundColor: 'black',/*  height: '100%' */ }}>
            {!editFaq?._id ?
                <>
                    <div>
                        <div style={{ padding: '20px' }}>
                            <div>
                                <div className='height-35'>
                                    <p className='screen-heading'>{selectedLanguage?.title_HowItWorks}</p>
                                </div>
                            </div>
                            <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                                {faqSettingData?.howIsWorkArray?.map((v, i) => {
                                    return (
                                        <>
                                            <div
                                                key={1 + i}
                                                style={{
                                                    padding: '20px',
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                    borderRadius: '8px',
                                                    paddingTop: '30px',
                                                    background: 'white'
                                                }}>
                                                <p className='ico-dis-play'>
                                                    {v?.isClub ?
                                                        <ImClubs size={18} />
                                                        :
                                                        <GiBabyfootPlayers size={18} />
                                                    }
                                                </p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <p style={{
                                                        fontWeight: '600',
                                                        fontSize: 16,
                                                        width: '90%',
                                                        margin: '0px',
                                                        padding: '0px',
                                                        lineBreak: 'anywhere',
                                                        color: '#ec671b'
                                                    }}>{isEnglish ? v?.titleEn : v?.titleNl}</p>

                                                    <FaEdit size={20} color='#EC671B' onClick={() => setEditFaq(v)} />
                                                    <Popconfirm
                                                        title={selectedLanguage?.faq_AreYouSureYouDeletethisfaq}
                                                        okText={selectedLanguage?.textDelete}
                                                        cancelText={selectedLanguage?.textCancel}
                                                        okButtonProps={{
                                                            type: 'primary',
                                                            className: 'form-button'
                                                        }}
                                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                        onConfirm={() => {
                                                            deleteFaq(v?._id)
                                                        }}
                                                    >
                                                        {loading[v?._id] ? <Spin /> : <AiFillDelete size={20} color='#EC671B' />}
                                                    </Popconfirm>
                                                </div>
                                                <div>
                                                    <br />
                                                    <p style={{
                                                        width: '100%',
                                                        margin: '0px',
                                                        padding: '0px',
                                                        lineBreak: 'anywhere',
                                                        color: '#ec671b'
                                                    }}>{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                }
                            </div>

                            <div className='div-flex-back-name' style={{ marginTop: '35px' }}>
                                <div className='height-35'>
                                    <p className='screen-heading'>{selectedLanguage?.faq_FAQS}</p>
                                </div>
                            </div>
                            {faqSettingData?.FaqArrayGrourp?.Participation?.length &&
                                <div>
                                    <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                                        <p className='screen-heading' style={{
                                            textAlign: 'center',
                                            color: 'black',
                                            color: '#ec671b'
                                        }}>{selectedLanguage?.title_Participation}</p>
                                    </div>
                                    <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                                        {faqSettingData?.FaqArrayGrourp?.Participation?.map((v, i) => {
                                            return (
                                                <>
                                                    <div
                                                        key={1 + i}
                                                        style={{
                                                            padding: '20px',
                                                            width: '100%',
                                                            marginTop: '20px',
                                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                            borderRadius: '8px',
                                                            paddingTop: '30px',
                                                            background: 'white'
                                                        }}>
                                                        <p className='ico-dis-play'>
                                                            {v?.isClub ?
                                                                <ImClubs size={18} />
                                                                :
                                                                <GiBabyfootPlayers size={18} />
                                                            }
                                                        </p>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: '#ec671b' }}>Q:{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                                            <FaEdit size={20} color='#EC671B' onClick={() => setEditFaq(v)} />
                                                            <Popconfirm
                                                                title={selectedLanguage?.faq_AreYouSureYouDeletethisfaq}
                                                                okText={selectedLanguage?.textDelete}
                                                                cancelText={selectedLanguage?.textCancel}
                                                                okButtonProps={{
                                                                    type: 'primary',
                                                                    className: 'form-button'
                                                                }}
                                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                onConfirm={() => {
                                                                    deleteFaq(v?._id)
                                                                }}
                                                            >
                                                                {loading[v?._id] ? <Spin /> : <AiFillDelete size={20} color='#EC671B' />}
                                                            </Popconfirm>
                                                            {reading !== v?.titleEn ?
                                                                <IoIosArrowDown color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                    setReading(v?.titleEn)
                                                                }} /> :
                                                                <IoIosArrowUp color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                    setReading('')
                                                                }} />}
                                                        </div>
                                                        {reading === v?.titleEn ?
                                                            <div>
                                                                <br />
                                                                <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: '#ec671b' }}>A:{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                                            </div> : null}
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            }

                            {faqSettingData?.FaqArrayGrourp?.Goal?.length &&
                                <div>
                                    <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                                        <p className='screen-heading' style={{ textAlign: 'center', color: '#ec671b' }}>{selectedLanguage?.title_Goal}</p>
                                    </div>
                                    <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                                        {faqSettingData?.FaqArrayGrourp?.Goal?.map((v, i) => {
                                            return (
                                                <>
                                                    <div
                                                        key={1 + i}
                                                        style={{
                                                            padding: '20px',
                                                            width: '100%',
                                                            marginTop: '20px',
                                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                            borderRadius: '8px',
                                                            paddingTop: '30px',
                                                            background: 'white'
                                                        }}>
                                                        <p className='ico-dis-play'>
                                                            {v?.isClub ?
                                                                <ImClubs size={18} />
                                                                :
                                                                <GiBabyfootPlayers size={18} />
                                                            }
                                                        </p>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: '#ec671b' }}>Q:{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                                            <FaEdit size={20} color='#EC671B' onClick={() => setEditFaq(v)} />
                                                            <Popconfirm
                                                                title={selectedLanguage?.faq_AreYouSureYouDeletethisfaq}
                                                                okText={selectedLanguage?.textDelete}
                                                                cancelText={selectedLanguage?.textCancel}
                                                                okButtonProps={{
                                                                    type: 'primary',
                                                                    className: 'form-button'
                                                                }}
                                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                onConfirm={() => {
                                                                    deleteFaq(v?._id)
                                                                }}
                                                            >
                                                                {loading[v?._id] ? <Spin /> : <AiFillDelete size={20} color='#EC671B' />}
                                                            </Popconfirm>
                                                            {readingGoals !== v?.titleEn ?
                                                                <IoIosArrowDown color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                    setReadingGoals(v?.titleEn)
                                                                }} /> :
                                                                <IoIosArrowUp color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                    setReadingGoals('')
                                                                }} />}
                                                        </div>
                                                        {readingGoals === v?.titleEn ?
                                                            <div>
                                                                <br />
                                                                <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: '#ec671b' }}>A:{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                                            </div> : null}
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            }

                            {faqSettingData?.FaqArrayGrourp?.Tournaments?.length &&
                                <div>
                                    <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                                        <p className='screen-heading' style={{ textAlign: 'center', color: '#ec671b' }}>{selectedLanguage?.title_Tournaments}</p>
                                    </div>
                                    <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                                        {faqSettingData?.FaqArrayGrourp?.Tournaments?.map((v, i) => {
                                            return (
                                                <>
                                                    <div
                                                        key={1 + i}
                                                        style={{
                                                            padding: '20px',
                                                            width: '100%',
                                                            marginTop: '20px',
                                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                            borderRadius: '8px',
                                                            paddingTop: '30px',
                                                            background: 'white'
                                                        }}>
                                                        <p className='ico-dis-play'>
                                                            {v?.isClub ?
                                                                <ImClubs size={18} />
                                                                :
                                                                <GiBabyfootPlayers size={18} />
                                                            }
                                                        </p>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: '#ec671b' }}>Q:{isEnglish ? v?.titleEn : v?.titleNl}</p>
                                                            <FaEdit size={20} color='#EC671B' onClick={() => setEditFaq(v)} />
                                                            <Popconfirm
                                                                title={selectedLanguage?.faq_AreYouSureYouDeletethisfaq}
                                                                okText={selectedLanguage?.textDelete}
                                                                cancelText={selectedLanguage?.textCancel}
                                                                okButtonProps={{
                                                                    type: 'primary',
                                                                    className: 'form-button'
                                                                }}
                                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                onConfirm={() => {
                                                                    deleteFaq(v?._id)
                                                                }}
                                                            >
                                                                {loading[v?._id] ? <Spin /> : <AiFillDelete size={20} color='#EC671B' />}
                                                            </Popconfirm>
                                                            {readingTournaments !== v?.titleEn ?
                                                                <IoIosArrowDown color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                    setReadingTournaments(v?.titleEn)
                                                                }} /> :
                                                                <IoIosArrowUp color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                    setReadingTournaments('')
                                                                }} />}
                                                        </div>
                                                        {readingTournaments === v?.titleEn ?
                                                            <div>
                                                                <br />
                                                                <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: '#ec671b' }}>A:{isEnglish ? v?.DiscriptionEn : v?.DiscriptionNl}</p>
                                                            </div> : null}
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div >
                    <FaqFooter />
                </>
                :
                <>
                    <AddFaq
                        {...props}
                        editData={editFaq}
                        handleOK={() => setEditFaq({})}
                        getFun={() => { getFaqHelper(faqSettingAction) }}
                    />
                </>
            }
        </div >
    )
}

export default AdminFaq