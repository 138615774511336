import { Button, Card, Input, Popconfirm, Spin } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AiOutlineDelete } from "react-icons/ai"
import { CiEdit } from "react-icons/ci"
import { TbAdjustmentsHorizontal } from "react-icons/tb"
import NoCards from "../../assets/NoCards.png"
import { GET, POST } from '../../utils/apis'
import { convertTitle, getAllCourts, getUserData, successNotification } from '../../utils/helpers'
import AddProducts from './AddProduct'
import AddReservation from './AddReservation'
import ChangeCode from './ChangeCode'
import ReservationSetting from './ReservationSetting'
import { useSelector } from 'react-redux'
import ReservationSetting2 from './ReservationSetting2'
import io from 'socket.io-client'

const { Search } = Input;

const Products = (props) => {
    const { user, language, selectedLanguage, userActions } = props
    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [showReservationForm, setReservationForm] = useState(false)
    const [reservationSetting, setReservationsetting] = useState(false)
    const [showPrivate, setShowPrivate] = useState(false)
    const [reservationData, setReservationData] = useState({})
    const [edit, setEdit] = useState(false)
    const [allProduct, setAllProduct] = useState([])
    const [product, setProduct] = useState({})
    const [filteredArray, setFilteredArray] = useState([])
    const [spin, setSpin] = useState({})
    const [showRentData, setShowRentData] = useState(true)
    const courts = useSelector(state => state.userReducer.courts)
    useEffect(() => {
        getUserData(user?._id, userActions)
        getSocketInfo()
    }, [])
    useEffect(() => {
        setAllProduct(courts || [])
        setFilteredArray(courts || [])
    }, [courts])

    let origin = `https://www.padeldater.com`
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('reservationResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            getAllCourts(user?._id, userActions)
        })
    }

    const gotoReservationSetting = (data) => {
        setReservationData(data?.reservation)
        setReservationsetting(true)
    }
    const calcSoldProducts = (arr) => {
        return arr?.reduce((sum, { soldProduct }) => sum + soldProduct, 0)
    }
    const deleteProduct = (_id) => {
        setSpin({ [_id]: true })
        let deleteProduct = {
            _id: _id,
            userId: user?._id
        }
        axios.post(`${POST?.DELETE_PRODUCT}`, deleteProduct)
            .then((res) => {
                const { data } = res
                setSpin({})
                // getcourts()
                getAllCourts(user?._id, userActions)

                if (data?.success) {
                    successNotification(selectedLanguage?.SuccessfullyDeleted)
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }
    const searchProduct = (val) => {
        let tempArr = [...allProduct]
        if (!val) {
            setFilteredArray(tempArr)
        } else {
            setFilteredArray(tempArr?.filter((v) => v?.name?.toLowerCase()?.includes(val?.toLowerCase())))
        }

    }

    return (
        <div className='club-peddel-p-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }}
            >
                {showPrivate ?
                    <ChangeCode
                        onCancel={() => setShowPrivate(false)}
                        {...props} />
                    :
                    showForm || edit?._id ?
                        <AddProducts
                            getProducts={() => getAllCourts(user?._id, userActions)}
                            edit={edit} onCancel={() => (setShowForm(false), setEdit(null))}
                            {...props} />
                        : showReservationForm ?
                            <AddReservation
                                product={product}
                                allProduct={courts}
                                setReservationsetting={setReservationsetting}
                                setReservationData={setReservationData}
                                getProducts={() => getAllCourts(user?._id, userActions)}
                                onCancel={() => (setReservationForm(false), setProduct({}))}
                                user={user}
                                {...props} />
                            :
                            reservationSetting ?
                                (showRentData ? <ReservationSetting
                                    showRentData={showRentData}
                                    setShowRentData={setShowRentData}
                                    reservationData={reservationData}
                                    setReservationsetting={setReservationsetting}
                                    user={user}
                                    {...props}
                                    getProducts={() => getAllCourts(user?._id, userActions)}
                                /> :
                                    <ReservationSetting2
                                        showRentData={showRentData}
                                        setShowRentData={setShowRentData}
                                        reservationData={reservationData}
                                        setReservationsetting={setReservationsetting}
                                        user={user}
                                        {...props}
                                        getProducts={() => getAllCourts(user?._id, userActions)}
                                    />)
                                :
                                <div className='main-div-new-style'>
                                    <div className='div-flex-back-name'>
                                        <div className='height-35'>
                                            <p className='screen-heading'>{selectedLanguage?.text_Courts}</p>
                                        </div>
                                    </div>
                                    <div className='div-flex-back-res'>
                                        <div className='height-35' style={{
                                            marginRight: 14
                                        }}>
                                            <p className='addproduct-text'
                                                onClick={() => setShowPrivate(true)}
                                            >{selectedLanguage?.PrivateCode}</p>
                                        </div>
                                        <div className='height-35'>
                                            <p className='addproduct-text'
                                                onClick={() => setShowForm(true)}
                                            >{selectedLanguage?.button_CreateCourt}</p>
                                        </div>
                                    </div>
                                    <div className='div-flex-back-name'>
                                        <div>
                                            <Search
                                                className='new-input-search'
                                                placeholder={selectedLanguage?.New_SearchCourtName}
                                                onSearch={searchProduct} enterButton />
                                        </div>

                                    </div>
                                    <div
                                        className='DesktopTable sidebar-scroll_class'
                                        style={{
                                            marginTop: 8,
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            overflowX: 'scroll'
                                        }}>
                                        {loading ?
                                            [1, 2, 3, 4].map((v, i) => {
                                                return (<Card
                                                    loading={loading}
                                                    bordered={false}
                                                    className='loading-card-style'
                                                    style={{
                                                        height: 380,
                                                        width: '285px',
                                                        minWidth: '285px',
                                                        background: '#00000057',
                                                        marginRight: '10px'
                                                    }}
                                                />)
                                            }) :
                                            filteredArray?.length ?
                                                filteredArray?.map((v, i) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                height: 380,
                                                                width: '285px',
                                                                minWidth: '285px',
                                                                borderRadius: '4px',
                                                                margin: '4px',
                                                                marginRight: '10px',
                                                                boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                                cursor: 'pointer',
                                                                backgroundColor: '#00000057'
                                                            }}>
                                                            <div style={{ textAlign: 'center', margin: '8px' }}>
                                                                <img src={v?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                                            </div>
                                                            <div style={{ textAlign: 'center', margin: '8px' }}>
                                                                <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                    <div>
                                                                        <p style={{
                                                                            fontSize: 18,
                                                                            fontWeight: 'bold',
                                                                            color: 'white',
                                                                            width: '160px',
                                                                            textAlign: 'start',
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis'
                                                                        }}>{v?.name || '------'}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p style={{ color: 'white' }}>€{v?.price || 0}</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p style={{
                                                                        color: 'white',
                                                                        textAlign: 'start',
                                                                        lineHeight: 1.4,
                                                                        height: '42px',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        lineBreak: 'anywhere',
                                                                        marginBottom: 0
                                                                    }}>
                                                                        {v?.description || '-------------------------------'}
                                                                    </p>
                                                                </div>
                                                                <div>

                                                                    <p style={{
                                                                        textAlign: 'start',
                                                                        lineHeight: 1.4,
                                                                        color: 'white',
                                                                        marginBottom: 0
                                                                    }}>
                                                                        {selectedLanguage?.Sold}: {calcSoldProducts(v?.reservation?.settings || []) || 0}
                                                                    </p>
                                                                    <p style={{
                                                                        color: 'white',
                                                                        textAlign: 'start',
                                                                        lineHeight: 1.4,
                                                                        marginBottom: 0
                                                                    }}>
                                                                        {selectedLanguage?.text_PrivateCode}:{v?.productCode || '------'}
                                                                    </p>
                                                                </div>
                                                                <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                    <div>
                                                                        <p style={{
                                                                            color: 'white',
                                                                            textAlign: 'start',
                                                                            lineHeight: 1.4,
                                                                            color: 'white',
                                                                            marginBottom: 0
                                                                        }}>{selectedLanguage?.text_CreateDate}:{v?.created ? moment(v?.created).format('DD/MM/YY') : '------'}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p style={{ fontSize: '12px', color: 'white' }}>{selectedLanguage?.text__Status}:
                                                                            <span style={{ color: '#ec671b' }}>
                                                                                {
                                                                                    v?.isApproved ? ' ' + selectedLanguage?.text_Approved : ' ' + selectedLanguage?.text_Pending
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='div-flex-back-name' style={{ justifyContent: 'space-between', margin: 8, marginTop: 0 }}>
                                                                <div style={{ width: '80px', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <CiEdit
                                                                        onClick={() => setEdit(v)}
                                                                        size={22} color='white' className='EditDeleteIcon' />
                                                                    <Popconfirm
                                                                        title={`${selectedLanguage?.textDelete} ${convertTitle(`${v?.name} `)} ${selectedLanguage?.Product} ?`}
                                                                        okText={selectedLanguage?.textDelete}
                                                                        cancelText={selectedLanguage?.textCancel}
                                                                        okButtonProps={{
                                                                            type: 'primary',
                                                                            className: 'form-button'
                                                                        }}
                                                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                        onConfirm={() => { deleteProduct(v?._id) }}
                                                                    >{spin[v?._id] ?
                                                                        <Spin /> :
                                                                        <AiOutlineDelete
                                                                            size={22} color='white' className='EditDeleteIcon' />
                                                                        }</Popconfirm>
                                                                </div>
                                                                <Button
                                                                    onClick={() => v?.reservation?._id ?
                                                                        gotoReservationSetting(v)
                                                                        :
                                                                        (setProduct(v),
                                                                            setReservationForm(true))}
                                                                    style={{ height: '30px', width: '85px', padding: '1.5px' }} className='new-delete-Btn'
                                                                >
                                                                    {selectedLanguage?.text_Reservation}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                <div style={{ height: '280px', width: '100%', marginTop: '10px' }}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div >
                }
            </div >
        </div >
    )

}

export default Products

