import React, { useState } from 'react'
import allPaths from '../../../Config/paths'
import { useHistory } from 'react-router-dom'

const ClubHome = (props) => {
    const { selectedLanguage, isEnglish, user } = props
    const history = useHistory()
    return (
        <>
            <div className='home-main-div'>
                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                    <p className='admin-home-title white-label'>{selectedLanguage?.txt_ClubHomePage}</p>
                    <p className='admin-home-title white-label'>{'Hey'}{' '}{user?.name}{' '}{'!'}</p>

                </div>
            </div>
        </>
    )
}

export default ClubHome