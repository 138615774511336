import { Button, InputNumber, Table } from 'antd'
import 'antd/dist/antd.css'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { POST } from '../../utils/apis'
import { errorMessage, errorMessageWithDuration, successNotification, validateTime } from '../../utils/helpers'
const ReservationSetting2 = (props) => {
    const { setReservationsetting, setShowRentData, showRentData, reservationData, user, getProducts, language, selectedLanguage } = props
    let tableColumn = []
    const [tableColumnState, setTableColumnState] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableNewData, setTableNewData] = useState(reservationData?.otherSettings || [])
    const [loading, setLoading] = useState(false)
    const [copyLoading, setCopyLoading] = useState(false)
    useEffect(() => {
        let data = []
        for (var v of tableNewData) {
            let dataRow = {}
            dataRow['date'] = v?.date
            dataRow['maximumProducts'] = v
            dataRow['soldProduct'] = v?.soldProduct
            dataRow['dateId'] = v?._id
            dataRow['timeData'] = {}
            for (var s of v?.shifts) {
                s.maximumProducts = v?.maximumProduct
                dataRow[`${s?.startTime}`] = s
            }
            data?.push(dataRow)
        }

        if (tableNewData?.length) {
            let dynamicColumns = reservationData && tableNewData?.[0]?.shifts
            for (var d of dynamicColumns) {
                let columnObj = {
                    title: validateTime(`${d?.startTime}`),
                    dataIndex: d?.startTime,
                    width: 80,
                    fixed: 'center',
                    render: (v, record) => {
                        const anotherDate = moment(v?.date)
                        return (
                            <div>
                                <InputNumber
                                    style={{ width: 60 }}
                                    controls={false}
                                    maxLength={1}
                                    pattern='[0-9]*'
                                    precision={0}
                                    // type='number'
                                    step={1}
                                    min={0}
                                    max={1}
                                    disabled={(moment().isAfter(anotherDate) ? true : false) || v?.soldProduct}
                                    defaultValue={v?.maxProduct}
                                    onChange={(e) => {
                                        onInputChange('maxProduct', e, record, v?._id, data?.dateId)

                                    }} />
                            </div>
                        )
                    }
                }

                tableColumn.push(columnObj)
            }

            setTableData([...data])
            let tableColumns = [
                {
                    title: '',
                    width: 150,
                    dataIndex: 'date',
                    fixed: 'left',
                    render: (date) => {
                        return {
                            props: {
                                style: {
                                    background: '#ec671b',
                                    border: '2px solid #ec671b',
                                    color: 'white'
                                },
                            },
                            children: <div>{moment(date)?.format('ddd, DD MMM YYYY')}</div>,
                            // Mon, 08 Mar 2023
                        };
                    }
                },
                ...tableColumn,
                {
                    title: selectedLanguage?.Max,
                    width: 100,
                    dataIndex: 'maximumProducts',
                    render: (v, record) => {
                        const givenDate = moment(v?.date)
                        return (
                            <div style={{ width: 120 }}>
                                <InputNumber
                                    pattern='[0-9]*'
                                    type='number'
                                    min={0}
                                    max={9999}
                                    disabled={moment().isAfter(givenDate) ? true : false}
                                    defaultValue={v?.maximumProduct}
                                    onChange={(e) => {
                                        onMaxProductChange('maximumProduct', e, record, v?._id)
                                    }} />
                            </div>
                        )
                    }
                },
                {
                    title: selectedLanguage?.Total,
                    width: 100,
                    dataIndex: 'soldProduct'
                }
            ]

            setTableColumnState([...tableColumns])
        }
    }, [reservationData, tableNewData])


    const onInputChange = (key, text, record, id, data) => {
        const newData = [...tableNewData]
        var indexDate = _.findIndex(newData, { _id: record?.dateId })
        var indexShift = _.findIndex(newData[indexDate]?.shifts, { _id: id })
        if (text > newData[indexDate]?.maximumProduct) {
            return errorMessageWithDuration(selectedLanguage?.valueCannotBbeGreaterthanMaxofTheDay)
        }
        newData[indexDate].shifts[indexShift][key] = Number(text)
        setTableNewData(newData)
    }

    const onMaxProductChange = (key, text, record, id) => {
        const newData = [...tableNewData]

        var indexDate = _.findIndex(newData, { _id: id })

        newData[indexDate].maximumProduct = Number(text)
        setTableNewData(newData)
    }
    const onConfirm = () => {
        let obj = {
            otherSettings: tableNewData,
            _id: reservationData?._id,
            userId: user?._id
        }
        if (obj?.otherSettings?.length) {
            setLoading(true)
            axios.post(POST?.UPDATE_RESERVATION_SETTING, obj)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successNotification(data?.message)
                        return setTimeout(() => {
                            getProducts()
                            setReservationsetting(false)
                        }, 200)
                    } else {
                        errorMessage(data?.message)
                    }
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage()
                })
        } else {
            console.log('err')
        }
    }

    const copySetting = () => {

        let obj = {
            _id: reservationData?._id,
            settings: 'other'
        }
        setCopyLoading(true)
        axios.post(POST?.COPY_RESERVATION_SETTIN, obj)
            .then((res) => {
                const { data } = res
                setCopyLoading(false)
                if (data?.success) {
                    successNotification(data?.message)
                    return setTimeout(() => {
                        getProducts()
                        setReservationsetting(false)
                    }, 200)
                } else {
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                setCopyLoading(false)
                errorMessage()
            })
    }


    return (
        <>
            <div>
                <div className='div-flex-back-name' style={{ marginBottom: '20px', marginTop: '10px' }}>
                    <div style={{ marginLeft: '10px' }} className='screen-back-icion'>
                        <IoIosArrowBack size={32}
                            color='#ec671b'
                            onClick={() => {
                                setReservationsetting(false)
                                getProducts()
                            }} />
                    </div>
                    <div className='height-35'>
                        <p className='screen-heading'>{selectedLanguage?.ReservationTool}</p>
                    </div>
                </div>

                <div className='div-flex-back-res'>
                    <div className='height-35' style={{
                        marginRight: 14
                    }}>
                        {showRentData ?
                            <p className='addproduct-text'
                                onClick={() => setShowRentData(false)}
                            >{'Competition'}</p>
                            :
                            <p className='addproduct-text'
                                onClick={() => setShowRentData(true)}
                            >{'Rent'}</p>
                        }
                    </div>
                </div>

                <div >
                    <Table
                        className='new-reservation-setting-form sidebar-scroll_class'
                        rowKey='id'
                        columns={tableColumnState}
                        dataSource={tableData}
                        pagination={false}
                        scroll={{ x: 800, y: 'calc(100vh - 320px)' }}
                    />
                </div>
                <br />
                <div className='div-flex-back-name' style={{ marginTop: '-15px' }}>
                    <div className='height-35'>
                        <Button loading={loading} className='addproduct-text' type='primary' onClick={onConfirm}>
                            {selectedLanguage?.ConfirmChanges}
                        </Button>
                    </div>
                    &nbsp;&nbsp;
                    <div className='screen-back-icion'>
                        {tableData?.length > 7
                            ? <Button type='primary' className='addproduct-text' loading={copyLoading} onClick={copySetting}>{selectedLanguage?.CopySetting}</Button>
                            : null}
                    </div>
                </div>

            </div >
        </>
    )
}

export default ReservationSetting2