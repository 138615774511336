import React, { useEffect, useState } from 'react'
import { FaInfoCircle } from "react-icons/fa"
import { Button, Form, Input, Modal, Select } from 'antd'
import axios from 'axios'
import { POST } from '../../../utils/apis'
import { errorMessage, successMessage } from '../../../utils/helpers'

const SendInvitation = (props) => {
    const { Option } = Select
    const { selectedLanguage, isEnglish, user, visible, handleOk, handleSuccess, myGameSetting, refferalPoints } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const onFinish = (values) => {
        values.userId = user?._id
        // values.point = refferalPoints?.point || 0
        setLoading(true)
        axios.post(POST?.INVITE_FRIEND_TO_PADEL, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    handleSuccess()
                    form.resetFields()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    return (
        <div>
            <Modal
                open={visible}
                onOk={handleOk}
                onCancel={handleOk}
                footer={null}>
                <div style={{ marginTop: '10px' }}>
                    <span >
                        <p className='admin-home-title' style={{ fontSize: '22px' }}>{selectedLanguage?.title_SendInvitationYourFriend}</p>
                        {/* <p className='p-none-margin-bolder'
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '10px',
                            }}>
                            <FaInfoCircle size={20} style={{ color: '#ec671b', marginRight: '10px' }} />
                            {selectedLanguage?.text_YouGet}
                            <p style={{
                                paddingLeft: 4,
                                paddingRight: 4,
                                textAlign: 'center',
                                color: '#ec671b',
                                margin: '0px'
                            }}> {refferalPoints?.point || '--'} </p>
                            {selectedLanguage?.text_pointWhenTheInvitedFreindGetRegistered}
                        </p> */}
                    </span>
                    <div style={{ marginTop: '35px' }}>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            {/* <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='gameId'
                                    hasFeedback
                                    label={selectedLanguage?.text_SelectGameInWhichPointsYouWant}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_pleaseSelectGame
                                        }
                                    ]}
                                >
                                    <Select
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_SelectGamefortournament}>
                                        {myGameSetting?.length && myGameSetting?.map((v, i) => {
                                            return (
                                                <Option value={v?.gameId?._id}>{`${v?.gameId?.name} - ${v?.gameId?.gameType} - ${v?.gameId?.description} `}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div> */} 

                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='email'
                                    label={selectedLanguage?.text_EnterYourFriendEmail}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.text_PleaseEnterYourFriendEmail
                                        },
                                        {
                                            type: 'email',
                                            message: selectedLanguage?.error_EmailIsNotValid
                                        },
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeholder_EnterYourFriendEmail}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='name'
                                    label={selectedLanguage?.text_EnterYourFriendsName}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.err_PleaseEnterYourFriendsName
                                        },
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeholder_EnterYourFriendName}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                <Form.Item>
                                    <Button
                                        className='addproduct-text '
                                        style={{ height: '45px', width: '180px', marginTop: '10px' }}
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        {selectedLanguage?.text_Sent}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default SendInvitation