import React, { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Tooltip } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { GET, POST } from '../../../utils/apis'
import { getUserData, successNotification, errorMessage, convertNumber, convertRoundNumber } from '../../../utils/helpers'
import moment from 'moment'
import NoData from '../../../assets/noDataImae.png'
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"

const WalletTransactions = (props) => {
    const { user, userActions, selectedLanguage, isEnglish } = props
    const [transactions, setTransactions] = useState([])
    const MyTransaction = useSelector(state => state.userReducer.walletTransaction)
    console.log(MyTransaction, "=MyTransaction")
    useEffect(() => {
        getUserData(user?._id, userActions)
    }, [])

    return (
        <div className='wallet-p-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }}
            >
                <p
                    style={{
                        marginTop: '10px',
                    }}
                    className='admin-home-title-white'>{selectedLanguage?.text_Wallet_Transactions}</p>

                <div style={{ marginTop: '20px' }}>
                    <div
                        className='sidebar-scroll-class-black'
                        style={{
                            overflow: 'scroll',
                            width: '98%',
                            margin: '0px auto'
                        }}
                    >
                        <div style={{
                            marginTop: '10px',
                            marginBottom: '5px',
                            // width: '100%',
                            width: '1000px',
                        }}>
                            <div style={{
                                width: '100%',
                                height: '60px',
                                borderTopRightRadius: '4px',
                                borderTopLeftRadius: '4px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '5px',
                                alignItems: 'center',
                            }}>
                                {[
                                    {
                                        title: selectedLanguage?.text_Name_tr,
                                        width: 240
                                    },
                                    {
                                        title: selectedLanguage?.text_Date_tr,
                                        width: 80,
                                    },
                                    {
                                        title: selectedLanguage?.text_Amount_tr,
                                        width: 120,
                                    },
                                    {
                                        title: selectedLanguage?.text_Fee_tr,
                                        width: 80,
                                    },
                                    {
                                        title: selectedLanguage?.text_Oldamount_tr,
                                        width: 200,
                                    },
                                    {
                                        title: selectedLanguage?.text_newAmount_tr,
                                        width: 200,
                                    },
                                ]?.map((v, i) => {
                                    return (
                                        <p className='tableValue'
                                            style={{
                                                margin: '0px',
                                                color: 'white',
                                                fontWeight: 'bolder',
                                                fontSize: '20px',
                                                width: v?.width,
                                                marginLeft: '10px',
                                                marginRight: '10px'
                                            }}
                                        >{v?.title}</p>
                                    )
                                })}
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    borderBottomRightRadius: '4px',
                                    borderBottomLeftRadius: '4px',
                                    justifyContent: 'space-between',
                                    padding: '5px',
                                    alignItems: 'center',
                                }}>
                                {MyTransaction?.length ?
                                    MyTransaction?.map((v, i) => {
                                        console.log(v, '======')
                                        return (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '50px',
                                                    borderTopRightRadius: '4px',
                                                    borderTopLeftRadius: '4px',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    padding: '5px',
                                                    alignItems: 'center',
                                                    margin: '0px',
                                                    padding: '0px'
                                                }}>
                                                <p className='tableValue table-p' style={{ width: '240px' }}>
                                                    {v?.name}
                                                </p>
                                                <p className='tableValue table-p' style={{ width: '80px' }}>
                                                    {moment(v?.created).format('DD-MM-YYYY')}
                                                </p>
                                                <p className='tableValue table-p' style={{ width: '120px' }}>
                                                    € {convertRoundNumber(v?.amount)}
                                                </p>
                                                <p className='tableValue table-p' style={{ width: '80px' }}>
                                                    {v?.serviceFee ? `€ ${v?.serviceFee}` : 0}
                                                </p>
                                                <p className='tableValue table-p' style={{ width: '200px' }}>
                                                    € {convertRoundNumber(v?.oldAmount)}
                                                </p>
                                                <p className='tableValue table-p' style={{ width: '200px' }}>
                                                    € {v?.reversed ?
                                                        convertRoundNumber(v?.oldAmount + v?.amount)
                                                        : v?.type ?
                                                            convertRoundNumber(v?.oldAmount + v?.amount)
                                                            : convertRoundNumber(v?.oldAmount - v?.amount)}
                                                </p>
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{ height: '160px', textAlign: 'center' }}>
                                        <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Table
           loading={loading}
           dataSource={form}
           columns={columns}
           pagination={{ hideOnSinglePage: true }}
           scroll={{ x: 800 }}
        /> */}
            </div>
        </div>
    )
}

export default WalletTransactions