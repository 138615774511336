import React, { useState } from 'react'
import { Button, Form, Input, InputNumber, Modal, Result, Select, Upload, } from 'antd'
import { errorMessage, successMessage, warningMessage } from '../../../utils/helpers'
import { SmileOutlined } from '@ant-design/icons'
import axios from 'axios'
import { ADMIN, POST } from '../../../utils/apis'
import { UploadOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import EnglishLanguage from '../../../Languages/EnglishLanguage.json'
import NlLanguage from '../../../Languages/NetherlandsLanguage.json'
import logo from '../../../assets/WGP_logo_text.png'
import countryCodes from '../../../utils/countryCodes'

const PromotingForm = (props) => {
    const { Option } = Select
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [tournamentFileList, settournamentFileList] = useState([])
    const [adFileList, setadFileList] = useState([])
    const [fillform, setFillform] = useState(false)
    const resetForm = () => {
        setadFileList([])
        settournamentFileList([])
        setFillform(true)
        setTimeout(() => {
            setFillform(false)
        }, 5000);
    }
    const onFinish = async (values) => {
        setLoading(true)
        values.name = values.name

        if (tournamentFileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', tournamentFileList[0]?.originFileObj)
            await axios.post(POST.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.tournamentFile = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage()

                })
        } else if (tournamentFileList[0]?.url) {
            let fileObj = {
                mediaType: tournamentFileList[0]?.mediaType,
                name: tournamentFileList[0]?.name,
                url: tournamentFileList[0]?.url,
            }
            values.tournamentFile = fileObj
        }

        if (adFileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', adFileList[0]?.originFileObj)
            await axios.post(POST.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.adFile = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage(selectedLanguage?.backendMsg_SomethingWentWrongPleaseRefreshThisPage)

                })
        } else if (adFileList[0]?.url) {
            let fileObj = {
                mediaType: adFileList[0]?.mediaType,
                name: adFileList[0]?.name,
                url: adFileList[0]?.url,
            }
            values.adFile = fileObj
        }

        if (!values.adFile?.url || !values.tournamentFile?.url) {
            setLoading(false)
            return warningMessage(selectedLanguage?.setting_FileIsRequired)
        }
        console.log('values', values)
        axios.post(ADMIN.CREATE_PROMOTER, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    // successMessage(isEnglish ? data.message : data.messageNl)
                    resetForm()
                    form.resetFields()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const normFile = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return settournamentFileList(e?.fileList);
    }
    const normFile2 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return setadFileList(e?.fileList);
    }
    return (
        <div style={{ paddingTop: 40, paddingBottom: 40 }}>
            <div
                className='promoter-form'
                style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                    background: 'white', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    margin: '0px auto',
                    borderRadius: '5px'
                }}>
                <div style={{ padding: '20px' }} >
                    <div style={{ marginTop: '20px' }}>
                        <div style={{ height: '150px', textAlign: 'center' }}>
                            <img src={logo} style={{ height: '150px' }} />
                        </div>
                        <p className='admin-home-title' style={{ marginTop: '15px', textAlign: 'center', fontSize: '36px' }}>
                            {selectedLanguage?.Promotion_Promotion}
                        </p>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <Form.Item
                                name='name'
                                hasFeedback
                                label={selectedLanguage?.title_Name}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_InputYourNameHere}
                                />
                            </Form.Item>
                            <Form.Item
                                name='email'
                                label={selectedLanguage?.label_Email}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourEmail
                                    },
                                    {
                                        type: 'email',
                                        message: selectedLanguage?.error_EmailIsNotValid
                                    },
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_PleaseEnterYourEmail}
                                />
                            </Form.Item>
                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='houseNumber'
                                    label={selectedLanguage?.label_houseno}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_PleaseInputYourhouseno
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_InputYourhousenoHere}
                                    />
                                </Form.Item>
                            </div>

                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='streetNumber'
                                    label={selectedLanguage?.StreetNumber}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.PleaseIputYourStreetNumber
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.InputYourStreetNumberHere}
                                    />
                                </Form.Item>
                            </div>

                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='postalCodeCity'
                                    label={selectedLanguage?.PostalCode}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.PleaseInputYourPostalCode
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.InputYourPostalCodeHere}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='country'
                                    label={selectedLanguage?.label_Country}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_PleaseSelectYourCountry
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        showArrow
                                        allowClear={false}
                                        className='form-input-new-style input-none'
                                        placeholder={selectedLanguage?.placeHolder_SelectYourCountry}
                                        optionFilterProp='children'
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{isEnglish ? v?.name : v?.nameNL}</Option>)}

                                    </Select>
                                </Form.Item>
                            </div>

                            <div className='form-two-input-flex-inner-input' style={{width:'100%'}}>
                                <Form.Item
                                    name='city'
                                    label={selectedLanguage?.label_City}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_PleaseInputYourCity
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_InputYourCityHere}
                                    />
                                </Form.Item>
                            </div >

                            <div className='form-two-input-flex' style={{ display: 'block' }}>
                                <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                    <Form.Item
                                        label={selectedLanguage?.title_TournamentAd}
                                        name='tournamentFile'
                                        style={{ width: '100%' }}
                                        valuePropName='file'
                                        getValueFromEvent={normFile}
                                    >
                                        <Upload
                                            className='questionFileUpload'
                                            multiple={false}
                                            maxCount={1}
                                            beforeUpload={() => false}
                                            fileList={tournamentFileList}
                                            accept='image/*'
                                            action='/upload.do'
                                            style={{ width: '100%' }}
                                        >
                                            <Button style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                            <br />
                                        </Upload>
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                    <Form.Item
                                        label={selectedLanguage?.title_ApplicationAd}
                                        name='adFile'
                                        style={{ width: '100%' }}
                                        valuePropName='file'
                                        getValueFromEvent={normFile2}
                                    >
                                        <Upload
                                            className='questionFileUpload'
                                            multiple={false}
                                            maxCount={1}
                                            beforeUpload={() => false}
                                            fileList={adFileList}
                                            accept='image/*'
                                            action='/upload.do'
                                            style={{ width: '100%' }}
                                        >
                                            <Button style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                            <br />
                                        </Upload>
                                    </Form.Item>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                <Form.Item>
                                    <Button
                                        className='addproduct-text '
                                        style={{ height: '45px', width: '120px', marginTop: '10px' }}
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        {selectedLanguage?.text_AddPromotion}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            {/* {true & */}
            <Modal footer={null} closable={false} title='' visible={fillform} >
                <Result
                    status='success'
                    icon={<SmileOutlined />}
                    title={selectedLanguage?.text_ThankYouSoMuch}
                    extra={<>
                        <Button onClick={() => setFillform(false)} type='primary'>{selectedLanguage?.text_closeMe}</Button>
                        <br />
                    </>}
                />
            </Modal>
        </div>
    )
}

export default PromotingForm