import { Button, Popconfirm } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NoData from '../../assets/noDataImae.png'
import { POST } from '../../utils/apis'
import { convertTitle, errorMessage, getUserData, successMessage } from '../../utils/helpers'


const Members = (props) => {
    const { selectedLanguage, isEnglish, user, userActions } = props
    const allMembers = useSelector(state => state?.userReducer?.allMembers)
    const [visible, setVisible] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})

    useEffect(() => {
        getUserData(user?._id, userActions)
    }, [])

    const ApproveContact = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = { _id: id }
        axios.post(POST.APPROVE_MEMBERSHIP, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getUserData(user?._id, userActions)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    const removeContact = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = { _id: id }
        axios.post(POST.REMOVE_MEMBERSHIP, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getUserData(user?._id, userActions)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    return (
        <div className='contact-p-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }}
            >
                <p
                    style={{
                        marginTop: '10px',
                    }}
                    className='admin-home-title-white'>
                    {visible ? selectedLanguage?.txt_Members : selectedLanguage?.txt_MemberRequests}</p>
                <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    {visible ? <Button
                        style={{
                            padding: '0px 20px 0px 20px',
                            background: 'rgba(92, 93, 93, 1)',
                            color: 'white',
                            border: 'none',
                            height: '45px',
                            marginTop: '10px',
                            borderRadius: 6
                        }}
                        onClick={() => setVisible(false)}>{selectedLanguage?.txt_MemberRequests}</Button>
                        :
                        <Button
                            style={{
                                padding: '0px 20px 0px 20px',
                                background: 'rgba(92, 93, 93, 1)',
                                color: 'white',
                                border: 'none',
                                height: '45px',
                                marginTop: '10px',
                                borderRadius: 6
                            }}
                            onClick={() => setVisible(true)}>{selectedLanguage?.txt_Members}</Button>
                    }
                </span>

                <div style={{ marginTop: '20px' }}>
                    <div
                        className='sidebar-scroll-class-black'
                        style={{
                            overflow: 'scroll',
                            width: '98%',
                            margin: '0px auto'
                        }}
                    >
                        <div style={{
                            marginTop: '10px',
                            marginBottom: '5px',
                            width: '900px',
                        }}>
                            <div style={{
                                width: '100%',
                                height: '60px',
                                borderTopRightRadius: '4px',
                                borderTopLeftRadius: '4px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '5px',
                                alignItems: 'center',
                            }}>
                                {[
                                    {
                                        title: selectedLanguage?.label_Email,
                                        width: 190
                                    },
                                    {
                                        title: selectedLanguage?.title_Name,
                                        width: 150
                                    },
                                    {
                                        title: selectedLanguage?.label_Country,
                                        width: 100
                                    },
                                    {
                                        title: selectedLanguage?.title_Action,
                                        width: 100
                                    },
                                ]?.map((v, i) => {
                                    return (
                                        <p
                                            key={i}
                                            className='tableValue'
                                            style={{
                                                margin: '0px',
                                                color: 'white',
                                                fontWeight: 'bolder',
                                                fontSize: '20px',
                                                width: v?.width,
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                            }}
                                        >{v?.title}</p>
                                    )
                                })}
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    borderBottomRightRadius: '4px',
                                    borderBottomLeftRadius: '4px',
                                    justifyContent: 'space-between',
                                    padding: '5px',
                                    alignItems: 'center',
                                }}>
                                {
                                    allMembers?.filter((v) => visible ? v?.isApproved : !v?.isApproved)?.sort((a, b) => {
                                        if (a?.player?.email === undefined || a?.player?.email === null) {
                                            return 1
                                        }
                                        if (b?.player?.email === undefined || b?.player?.email === null) {
                                            return -1
                                        }
                                        return a?.player?.email.localeCompare(b?.player?.email);
                                    })?.length ?
                                        allMembers?.filter((v) => visible ? v?.isApproved : !v?.isApproved)?.sort((a, b) => {
                                            if (a?.player?.email === undefined || a?.player?.email === null) {
                                                return 1
                                            }
                                            if (b?.player?.email === undefined || b?.player?.email === null) {
                                                return -1
                                            }
                                            return a?.player?.email.localeCompare(b?.player?.email);
                                        })?.map((v, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    style={{
                                                        width: '100%',
                                                        height: '50px',
                                                        borderTopRightRadius: '4px',
                                                        borderTopLeftRadius: '4px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        padding: '5px',
                                                        alignItems: 'center',
                                                        margin: '0px',
                                                        padding: '0px'
                                                    }}>
                                                    <p className='tableValue table-p'
                                                        style={{ width: '190px' }}>
                                                        {v?.player?.email}
                                                    </p>
                                                    <p className='tableValue table-p'
                                                        style={{ width: '150px' }}>
                                                        {v?.player?.name}
                                                    </p>
                                                    <p className='tableValue table-p'
                                                        style={{ width: '100px' }}>
                                                        {v?.player?.country}
                                                    </p>
                                                    {!visible ?
                                                        <p className='tableValue table-p'
                                                            style={{ width: '100px' }}>
                                                            <Popconfirm
                                                                title={`${selectedLanguage?.txt_Approve} ${convertTitle(`${v?.player?.name} `)} ${selectedLanguage?.text_confirm_Contact} ?`}
                                                                okText={selectedLanguage?.txt_Approve}
                                                                cancelText={selectedLanguage?.textCancel}
                                                                okButtonProps={{
                                                                    type: 'primary',
                                                                    className: 'form-button'
                                                                }}
                                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                onConfirm={() => {
                                                                    ApproveContact(v?._id)
                                                                }}
                                                            >
                                                                <Button
                                                                    loading={deleteLoading[v?._id]}
                                                                    style={{
                                                                        padding: '0px 20px 0px 20px',
                                                                        background: 'rgba(92, 93, 93, 1)',
                                                                        color: 'white',
                                                                        border: 'none',
                                                                        height: '45px',
                                                                        marginTop: '10px',
                                                                        borderRadius: 6
                                                                    }}>
                                                                    {selectedLanguage?.txt_Approve}
                                                                </Button>
                                                            </Popconfirm>
                                                        </p>
                                                        :
                                                        <p className='tableValue table-p'
                                                            style={{ width: '100px' }}>
                                                            <Popconfirm
                                                                title={`${selectedLanguage?.txt_Remove} ${convertTitle(`${v?.player?.name} `)} ${selectedLanguage?.text_confirm_Contact} ?`}
                                                                okText={selectedLanguage?.txt_Remove}
                                                                cancelText={selectedLanguage?.textCancel}
                                                                okButtonProps={{
                                                                    type: 'primary',
                                                                    className: 'form-button'
                                                                }}
                                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                onConfirm={() => {
                                                                    removeContact(v?._id)
                                                                }}
                                                            >
                                                                <Button
                                                                    loading={deleteLoading[v?._id]}
                                                                    style={{
                                                                        padding: '0px 20px 0px 20px',
                                                                        background: 'rgba(92, 93, 93, 1)',
                                                                        color: 'white',
                                                                        border: 'none',
                                                                        height: '45px',
                                                                        marginTop: '10px',
                                                                        borderRadius: 6
                                                                    }}>
                                                                    {selectedLanguage?.txt_Remove}
                                                                </Button>
                                                            </Popconfirm>
                                                        </p>
                                                    }
                                                </div>
                                            )
                                        })
                                        :
                                        <div style={{ height: '160px', textAlign: 'center' }}>
                                            <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Members