import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import WGP_logo_Text from '../../assets/WGP-white.png'
import { allPaths } from '../../utils/constants'
import ReactFlagsSelect from 'react-flags-select' 
import { useDispatch, useSelector } from 'react-redux' 
import {setLanguage }from "../../Redux/actions/languageAction"
const LoginHeader = (props) => {
    const history = useHistory()
    const [selected, setSelected] = useState('GB') 
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)

    useEffect(() => {
        language?.language === 'english' ?
            setSelected('GB') :
            setSelected('NL')
    })

    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'GB') {
            dispatch(setLanguage('english'))
        }
        else {
            dispatch(setLanguage('netherlands'))
        }
    }
    return (
        <div style={{
            background: 'black',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
        }}>

            <div style={{ display: 'flex', marginBottom: '10px' }}>
                <img onClick={() => history?.push(allPaths?.LOGIN)} style={{ height: '110px', width: '200px', cursor: 'pointer' }} src={WGP_logo_Text} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p onClick={() => history?.push(allPaths?.FAQ)} style={{ color: 'white', fontWeight: '600', fontSize: '20px', cursor: 'pointer', marginBottom: '3px' }}>FAQ</p>
                <span style={{ marginLeft: '10px' }}>
                    <ReactFlagsSelect
                        className='countries-select'
                        fullWidth={false}
                        showOptionLabel={false}
                        showSelectedLabel={false}
                        countries={["GB", "NL"]}
                        selected={selected}
                        onSelect={(v) => onchangeLanguage(v)}
                    />
                </span>
            </div>
        </div>
    )
}

export default LoginHeader