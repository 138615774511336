const LOGIN_USER = 'LOGIN_USER'
const REMOVE_USER = 'REMOVE_USER'
const LANGUAGE = 'LANGUAGE'
const MENU_NONE = 'MENU_NONE'
const GAMES = 'GAMES'
const GAMES_SETTINGS = 'GAMES_SETTINGS'
const PLAYER_CONTACTS = 'PLAYER_CONTACTS'
const PLAYERS = 'PLAYERS'
const GAME_SUGGESTIONS = 'GAME_SUGGESTIONS'
const USER_TOURNAMENTS = 'USER_TOURNAMENTS'
const USER_RANKS = 'USER_RANKS'
const USER_PRO_RANKS = 'USER_PRO_RANKS'
const PROMOTER = 'PROMOTER'
const ADMIN_TOURNAMENT = 'ADMIN_TOURNAMENT'
const DOLLER_MATCH_REQ_SEND_FEE = 'DOLLER_MATCH_REQ_SEND_FEE'
const DOLLER_TOURNAMENT_SUB_FEE = 'DOLLER_TOURNAMENT_SUB_FEE'
const ADMIN_ALL_PLAYER = 'ADMIN_ALL_PLAYER'
const DOLLER_TOURNAMENT_ORGANIZER = 'DOLLER_TOURNAMENT_ORGANIZER'
const TOURNAMENTS = 'TOURNAMENTS'
const TOURNAMENTS_MATCHES = 'TOURNAMENTS_MATCHES'
const SENT_MATCHES = 'SENT_MATCHES'
const RECEIVE_MATCHES = 'RECEIVE_MATCHES'
const MATCH_RESULTS = 'MATCH_RESULTS'
const CASHBACK_SERVICE_FEE = 'CASHBACK_SERVICE_FEE'
const FAQ = 'FAQ'
const WALLET_TRANSACTIONS = 'WALLET_TRANSACTIONS'
const ALL_COMPETITIONS = 'ALL_COMPETITIONS'
const COMPETITIONS_RANKS = 'COMPETITIONS_RANKS'
const SENT_COMPETITION_MATCHES = 'SENT_COMPETITION_MATCHES'
const RECEIVE_COMPETITION_MATCHES = 'RECEIVE_COMPETITION_MATCHES'
const COMPETITION_MATCH_RESULTS = 'COMPETITION_MATCH_RESULTS'
const SUBSCRIBED_COMPETITION = 'SUBSCRIBED_COMPETITION'
const COMPETITION_SUB_SERVICE_FEE = 'COMPETITION_SUB_SERVICE_FEE'
// new work for clubs
const ALL_PLAYERS_USERS = 'ALL_PLAYERS_USERS'
const ALL_CLUBS_USERS = 'ALL_CLUBS_USERS'
const COURTS = 'COURTS'
const COURTS_FILTER = 'COURTS_FILTER'
const COUPLE_REQUESTS = 'COUPLE_REQUESTS'
const ALL_ADMIN_CLUBS = 'ALL_ADMIN_CLUBS'
const PLAYER_COUPLES = 'PLAYER_COUPLES'
const OPPONENTS = 'OPPONENTS'
const MEMBERS = 'MEMBERS'
const PLAYER_INVOCE = 'PLAYER_INVOCE'
const MY_SUBSCRIPTIONS = 'MY_SUBSCRIPTIONS'
const MY_MATCHES = 'MY_MATCHES'
const CLUB_INFO = 'CLUB_INFO'
const MY_COMPETITION = 'MY_COMPETITION'

export {
    LOGIN_USER,
    REMOVE_USER,
    LANGUAGE,
    MENU_NONE,
    GAMES,
    GAMES_SETTINGS,
    PLAYER_CONTACTS,
    PLAYERS,
    GAME_SUGGESTIONS,
    USER_TOURNAMENTS,
    USER_RANKS,
    PROMOTER,
    ADMIN_TOURNAMENT,
    USER_PRO_RANKS,
    DOLLER_MATCH_REQ_SEND_FEE,
    DOLLER_TOURNAMENT_SUB_FEE,
    ADMIN_ALL_PLAYER,
    DOLLER_TOURNAMENT_ORGANIZER,
    TOURNAMENTS,
    TOURNAMENTS_MATCHES,
    SENT_MATCHES,
    RECEIVE_MATCHES,
    MATCH_RESULTS,
    CASHBACK_SERVICE_FEE,
    FAQ,
    WALLET_TRANSACTIONS,
    ALL_COMPETITIONS,
    COMPETITIONS_RANKS,
    SENT_COMPETITION_MATCHES,
    RECEIVE_COMPETITION_MATCHES,
    COMPETITION_MATCH_RESULTS,
    SUBSCRIBED_COMPETITION,
    COMPETITION_SUB_SERVICE_FEE,
    // new work for clubs

    ALL_PLAYERS_USERS,
    ALL_CLUBS_USERS,
    COURTS,
    COURTS_FILTER,
    COUPLE_REQUESTS,
    ALL_ADMIN_CLUBS,
    PLAYER_COUPLES,
    OPPONENTS,
    MEMBERS,
    PLAYER_INVOCE,
    MY_SUBSCRIPTIONS,
    MY_MATCHES,
    CLUB_INFO,
    MY_COMPETITION
}
