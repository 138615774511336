import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { errorMessage, getGeneralData, successMessage } from '../../../utils/helpers'
import AddAdminClub from './AddAdminClub'

const AdminClubs = (props) => {
    const { isEnglish, user, generalActions, selectedLanguage } = props
    const [showAdminClubs, setShowAdminClubs] = useState(true)
    const [edit, setEdit] = useState({})
    const adminClubs = useSelector(state => state.generalReducer.adminClubs)
    const [spin, setSpin] = useState({})
    console.log('adminClubs', adminClubs)
    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    const deleteAdminClub = (id) => {
        setSpin({ [id]: true })
        axios.post(ADMIN?.DELETE_ADMIN_CLUBS, { id })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    getGeneralData(generalActions)
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    setSpin({})
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                    setSpin({})
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const columns = [
        {
            title: selectedLanguage?.txt_Name,
            dataIndex: 'name'
        },
        {
            title: selectedLanguage?.txt_Email,
            dataIndex: 'email'
        },
        {
            title: selectedLanguage?.text_PrivateCode,
            dataIndex: 'code',
            render: (e) => {
                return (
                    <CopyToClipboard text={e}
                        onCopy={() => successMessage(selectedLanguage?.SuccessfullyCopiedtoClipboard)}>
                        <p>{e}<Button type='link' icon={<CopyOutlined />} /></p>
                    </CopyToClipboard>
                )
            }
        },
        {
            title: selectedLanguage?.Registered,
            dataIndex: 'isRegistered',
            align: 'center',
            render: (e) => <>{e ? <AiFillCheckCircle color='green' size={25} /> : <AiFillCloseCircle color='red' size={25} />}</>
        },
        {
            title: selectedLanguage?.title_Action,
            key: 'action',
            render: (e) => {
                return (
                    <>
                        <Button type='link' icon={<EditOutlined />} onClick={() => (setEdit(e), setShowAdminClubs(false))} />
                        <Popconfirm
                            title={selectedLanguage?.faq_AreYouSureYouDeletethisfaq}
                            okText={selectedLanguage?.textDelete}
                            cancelText={selectedLanguage?.textCancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => {
                                deleteAdminClub(e?._id)
                            }}
                        >
                            {spin[e?._id] ? <Spin /> : <Button type='link' icon={<DeleteOutlined />} danger />}
                        </Popconfirm>
                    </>
                )
            }
        }

    ]

    return (
        <>
            {showAdminClubs ?
                <div style={{ margin: '20px' }}>
                    <p className='admin-home-title'>
                        {selectedLanguage?.AllCLUBS}
                    </p>
                    <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                        <Button className='addproduct-text' onClick={() => setShowAdminClubs(false)}>{selectedLanguage?.AddClubs}</Button>
                    </span>
                    <div style={{ marginTop: '20px' }}>
                        <Table
                            dataSource={adminClubs}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: 400 }}
                        />
                    </div>
                </div>
                :
                <AddAdminClub edit={edit} onCancel={() => (setShowAdminClubs(true), setEdit({}))} {...props} />
            }
        </>
    )
}

export default AdminClubs