import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successNotification, errorMessage } from '../../utils/helpers'
import { allPaths } from '../../utils/constants'
import { LoginFooter, LoginHeader } from '../../Components'
import { ADMIN } from '../../utils/apis'
import axios from 'axios'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'

const AdminLogin = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)
    const onFinish = (value) => {
        setLoading(true)
        axios.post(ADMIN.LOGIN, value)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(selectedLanguage?.message_SuccessfullyLoggedIn)
                    dispatch(loginUser(data.admin))
                    return setTimeout(() => {
                        history?.replace(allPaths?.HOME)
                    }, 300)
                }
                else {
                    errorMessage(isEnglish ? data.message : data.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }
    useEffect(() => {
        dispatch(removeUser())
    }, [])

    return (
        <div className='admin-login-main helper-main' >
            <LoginHeader />
            <div className='auth-screen-main-div' style={{ marginTop: '20px', }}>
                <p className='heading head-center' style={{ color: 'white' }}>{selectedLanguage?.title_AdminLogin}</p>
                <p style={{ textAlign: 'center', color: 'white' }}>{selectedLanguage?.subTitle_Welcome}</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='name'
                                label={<p className='my-form-label'>{selectedLanguage?.title_Name}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_InputYourNameHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='password'
                                label={<p className='my-form-label'>{selectedLanguage?.label_Password}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourPassword
                                    },
                                    {
                                        min: 8,
                                        message: selectedLanguage?.error_PasswordMustBeAtLeastEightCharacters
                                    }
                                ]}
                            >
                                <Input.Password
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.PlaceHolder_PleaseEnterYourPasswordHere}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item>
                        <Button
                         style={{
                            padding: '0px 20px 0px 20px',
                            background: 'rgba(92, 93, 93, 1)',
                            color: 'white',
                            border: 'none',
                            height: '50px',
                            marginTop: '10px',
                            borderRadius: 6,
                            width: '100%'
                        }}
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.ButtonText_Submit}
                        </Button>
                        <div style={{ textAlign: 'center', marginTop: '20px' ,color:'white'}}>
                            <p>{selectedLanguage?.minTitle_Gobackto}<Link className='linkStyle' to={allPaths?.LOGIN}> {selectedLanguage?.minTitle_Login}</Link>
                            </p>
                        </div>
                    </Form.Item>
                </Form>
            </div>
            <LoginFooter />
        </div>
    )
}

export default AdminLogin