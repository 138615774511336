import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import WGP_logo_Text from '../../assets/WGP_logo_text.png'
import { allPaths } from '../../utils/constants'
import ReactFlagsSelect from 'react-flags-select'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from "../../Redux/actions/languageAction"
import { AiOutlineLogout } from "react-icons/ai"
import { Tooltip } from 'antd'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { TfiMenuAlt } from 'react-icons/tfi'
import { SetMenuNone } from '../../Redux/actions/menuNoneAction'
import { convertNumber } from '../../utils/helpers'
const HeaderMenuLayout = (props) => {
    const { user, MenuNone } = props
    const history = useHistory()
    const [selected, setSelected] = useState('GB')
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)
    useEffect(() => {
        language?.language === 'english' ?
            setSelected('GB') :
            setSelected('NL')
    })

    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'GB') {
            dispatch(setLanguage('english'))
        }
        else {
            dispatch(setLanguage('netherlands'))
        }
    }
    const logout = () => {
        history.replace(allPaths?.MAIN_HOME)
        dispatch(removeUser())
    }
    return (
        <div style={{
            background: 'black',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            height: '62px'
        }}>

            <div style={{ display: 'flex', justifyContent: MenuNone ? 'space-between' : 'flex-end', width: '97%', alignItems: 'center' }}>
                <span
                    style={{
                        color: 'white',
                        // backgroundColor: '#ec671b',
                        borderRadius: '7px',
                        height: '33px',
                        width: '33px',
                        padding: '4px',
                        display: !MenuNone && 'none'
                    }}
                    onClick={() => { dispatch(SetMenuNone(false)) }}>
                    <TfiMenuAlt style={{ fontSize: 26 }} />
                </span>
                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {user?.userType === 'player' &&
                        <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <b style={{ color: 'white', marginBottom: 2 }}>{/* {'Wallet Balance is'}  */}€ {convertNumber(user?.walletBalance) || 0}</b>
                        </span>}
                    {user?.userType !== 'player' && <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <ReactFlagsSelect
                            className='countries-select'
                            fullWidth={false}
                            showOptionLabel={false}
                            showSelectedLabel={false}
                            countries={["GB", "NL"]}
                            selected={selected}
                            onSelect={(v) => onchangeLanguage(v)}
                        />
                    </span>}
                    <Tooltip placement="bottom" color='black' title={'Logout'}>
                        <AiOutlineLogout onClick={() => { logout() }} size={24} color='white' style={{ marginBottom: '6px', marginRight: '25px', cursor: 'pointer' }} className='logout-icon' />
                    </Tooltip>
                </span>
            </div>
        </div>
    )
}

export default HeaderMenuLayout