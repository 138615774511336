import React, { useEffect, useState } from 'react'
import NoData from '../../../assets/noDataImae.png'
import { Button, Modal, Popconfirm, Table } from 'antd'
import { convertRoundNumber, convertTitle, errorMessage, getCompetitionMatches, successMessage, validateTime } from '../../../utils/helpers'
import axios from 'axios'
import { POST } from '../../../utils/apis'
import { useSelector } from 'react-redux'

const ReceivedMatches = (props) => {
    const { selectedLanguage, user, matches, userActions, isEnglish, getUser } = props
    const mySubscriptions = useSelector(state => state.userReducer.mySubscriptions)
    const allCouples = useSelector(state => state?.userReducer?.allCouples)

    const [deleteLoading, setDeleteLoading] = useState({})
    const [acceptloading, setAcceptloading] = useState({})
    const [currentAccept, setCurrentAccept] = useState({})
    const [confirmModal, setConfirmModal] = useState(false)
    console.log('currentAccept', currentAccept)
    const RejectMatchRequest = (data) => {
        setDeleteLoading({ [data?._id]: true })
        let obj = {
            _id: data?._id,
            userId: user?._id,
            code: data?.code,
            price: data?.courtId?.price,
            reservation: data?.reservation,
            shiftId: data?.shiftId,
            dayId: data?.dayId,
            senderId: data?.senderId?._id,
            partnerId: data?.partner?._id,
            clubId: data?.club?._id,
            playerNumbers: data?.playerNumbers,
            preBooked: data?.preBooked,
        }
        axios.post(POST?.REJECT_COMPETITION_MATCH_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getCompetitionMatches(user?._id, userActions)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }
    const AcceptMatchRequest = (data) => {

        console.log('data', data)

        let mySubscription = mySubscriptions.find((v) => v?.competitionId === data?.competition?._id)
        let coupleDetail = allCouples.find((v) => v?._id === mySubscription?.coupleId)
        let price = getPrice()
        console.log('coupleDetail', coupleDetail)


        if (!data?.courtId?.acceptNonMember && data?.courtId?._id) {
            if (!(user?.preferredClub?.isApproved && user?.preferredClub?.club === data?.courtId?.userId)) {
                return errorMessage(selectedLanguage?.YouAreNotMemberOfThisClub_ThisCourtIsNotForNonMembers)
            }

            if (data?.playerNumbers === 4) {
                if (!(coupleDetail?.partnerId?.preferredClub?.isApproved && coupleDetail?.partnerId?.preferredClub?.club === data?.courtId?.userId)) {
                    return errorMessage(selectedLanguage?.YouArePartnerNotMemberOfThisClub_ThisCourtIsNotForNonMembers)
                }
            }
        }

        if (user?.walletBalance < price) {
            return errorMessage(selectedLanguage?.lessMoneyAlertRecivdMatch)
        }

        setAcceptloading({ [data?._id]: true })
        let obj = {
            _id: data?._id,
            userId: user?._id,
            partner: coupleDetail?.partnerId?._id,
            code: data?.code,
            competitionId: data?.competition?._id,
            coupleId: coupleDetail?._id,
            allCouples: [data?.couple?._id, data?.opponentCouple?._id],
            allPlayers: [data?.senderId?._id, data?.opponents?.[0]?._id],
            price: price,
            playerNumbers: data?.playerNumbers,
            club: data?.club?._id
        }
        console.log('obj', obj)
        axios.post(POST?.ACCEPT_COMPETITION_MATCH_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setAcceptloading({})
                if (data?.success) {
                    getUser()
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getCompetitionMatches(user?._id, userActions)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setAcceptloading({})
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }

    const handleOk = () => {
        AcceptMatchRequest(currentAccept)
        setConfirmModal(false)
    }
    const handleCancel = () => {
        setCurrentAccept({})
        setConfirmModal(false)
    }
    const columns = [
        {
            title: selectedLanguage?.txt_Competition,
            dataIndex: ['competition', 'competitionName'],
            align: 'center'
        },
        {
            title: selectedLanguage?.txt_Club,
            dataIndex: ['club', 'name'],
            align: 'center'
        },
        {
            title: selectedLanguage?.title_GameType,
            dataIndex: 'gameType',
            align: 'center',
            render: (e) => convertTitle(e)
        },
        {
            title: selectedLanguage?.txt_Partner,

            // dataIndex: ['opponents'],
            align: 'center',
            render: (e) => e?.playerNumbers === 2 ? '-' : e?.opponents?.[0]?._id !== user?._id ? convertTitle(e?.opponents?.[0]?.name) : convertTitle(e?.opponents?.[1]?.name)
        },
        {
            title: selectedLanguage?.txt_Couple,
            dataIndex: ['opponentCouple', 'coupleName'],
            align: 'center'
        },
        {
            title: selectedLanguage?.title_DateTime,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p'>
                        {e?.matchDate || '-----'} | {validateTime(e?.matchTime) || '-----'}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Players,
            dataIndex: 'playerNumbers',
            align: 'center'
        },
        {
            title: selectedLanguage?.txt_OpponentCouple,
            dataIndex: ['couple', 'coupleName'],
            align: 'center',
            width: 250,
        },
        {
            title: selectedLanguage?.txt_Opponent,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' >{e?.senderId?.name} {',' && e?.partner?.name}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Message,
            dataIndex: 'message',
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' > {e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Status,
            align: 'center',
            render: (e) => {
                return (
                    e?.isAccepted ?
                        <p className='accept-status'>{selectedLanguage?.sts_Accepted}</p>
                        : e?.isRejected ?
                            <p className='reject-status'>{selectedLanguage?.sts_rejected}</p>
                            :
                            <p className='pending-stats'>{selectedLanguage?.sts_pending}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            align: 'center',
            render: (e) => {
                return (
                    <>
                        {e?.opponents?.[0]?._id === user?._id && (
                            e?.isRejected ?
                                null
                                : e?.isAccepted ?
                                    <div style={{ display: 'flex' }} >
                                        <Button disabled={true} style={{ opacity: 0.2 }} className='accept_m_btn'>{selectedLanguage?.btn_Accept}</Button>
                                    </div > :
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            className='accept_m_btn'
                                            loading={acceptloading[e?._id]}
                                            onClick={() => {
                                                setConfirmModal(true)
                                                setCurrentAccept(e)
                                            }}
                                        >{selectedLanguage?.btn_Accept}</Button>
                                        <Popconfirm
                                            title={`${selectedLanguage?.text_AreYouWantToRejectMatchRequestThatRecivedFrom} ${e?.senderId?.name}?`}
                                            okText={selectedLanguage?.textDelete}
                                            cancelText={selectedLanguage?.textCancel}
                                            okButtonProps={{
                                                type: 'primary',
                                                className: 'form-button'
                                            }}
                                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                                            onConfirm={() => {
                                                RejectMatchRequest(e)
                                            }}
                                        >
                                            <Button
                                                type='primary'
                                                danger
                                                loading={deleteLoading[e?._id]}
                                                style={{ marginLeft: 10 }}
                                            >{selectedLanguage?.btn_reject}</Button>
                                        </Popconfirm>
                                    </div>)
                        }
                    </>
                )
            }
        }
    ]


    const getPrice = () => {
        let totalMembers = []
        let members = 0
        let nonMembers = 0
        let price = currentAccept?.courtId?.price
        // console.log('currentAccept', currentAccept)

        if (currentAccept?.preBooked) {
            let calculatedPrice = (currentAccept?.amountPaid / currentAccept?.playerNumbers)
            calculatedPrice = convertRoundNumber(calculatedPrice)

            return Number(calculatedPrice)
        }

        if (!currentAccept?.courtId?.memberDiscount) {
            let calculatedPrice = (price / currentAccept?.playerNumbers)
            calculatedPrice = convertRoundNumber(calculatedPrice)

            return Number(calculatedPrice)
        }

        totalMembers.push(user)

        if (currentAccept?.partner?._id) {
            totalMembers.push(currentAccept?.partner)
        }

        for (let v of (currentAccept?.opponents || [])) {
            totalMembers.push(v)
        }

        for (let v of totalMembers) {
            if (v?.preferredClub?.club === currentAccept?.club?._id && v?.preferredClub?.isApproved) {
                members += 1
            } else {
                nonMembers += 1
            }
        }
        console.log('members', members, 'totalMembers', totalMembers)
        let calculatedPrice = (price - (members * (price / totalMembers.length))) / totalMembers.length
        calculatedPrice = convertRoundNumber(calculatedPrice)

        return Number(calculatedPrice)

    }
    return (
        <div style={{ margin: '20px' }}>
            <div style={{ marginTop: '20px' }}>
                <Table
                    dataSource={matches}
                    columns={columns}
                    className='specific-css-table'
                    pagination={false}
                    scroll={{ x: 2000, y: 200 }}
                />
            </div>
            {/* Modal Confirm */}
            <Modal
                open={confirmModal}
                footer={null}
                className='pop-info-modal'
                style={{ top: 10 }}
            >
                <div>
                    <p className='p-question'>{selectedLanguage?.text_AreYouSureYouWantToAcceptMacthRequest}</p>
                    <p className='p-opt'>{selectedLanguage?.txt_ByAcceptingMatchRequest} € {getPrice()} {selectedLanguage?.txt_willBeDeductedFromyourandyourpartnerWallet}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }}>
                    <button className='cancel-btn' onClick={handleCancel}>{selectedLanguage?.text_Cancel}</button>
                    <button className='confirm-btn' onClick={handleOk}>{selectedLanguage?.btn_Accept}</button>
                </div>
            </Modal>
            {/*  Modal Confirm*/}
        </div>
    )
}

export default ReceivedMatches