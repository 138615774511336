import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { errorMessage, getUserData, successMessage } from "../../../utils/helpers";
import axios from "axios";
import { POST } from "../../../utils/apis";
import moment from "moment";

const SubscribedCompetition = (props) => {
    const { user, userActions, isEnglish, selectedLanguage } = props
    const [requests, setRequest] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUserData(user?._id, userActions)
        getCompetitionRequest(user?._id)
    }, [])

    const getCompetitionRequest = (e) => {
        setLoading(true)
        axios.post(POST?.GET_SUBSCRIPTION_OF_COMPETITION, { _id: e })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setRequest(data?.data)
                    setLoading(false)
                } else {
                    setRequest([])
                    setLoading(false)
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
                console.log(e, 'error 28 competiton request')
            })
    }

    const columns = [
        {
            title: selectedLanguage?.txt_CompetitionName,
            dataIndex: 'competitionId',
            render: (e) => {
                return (
                    <p>{e?.competitionName || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_CoupleName,
            dataIndex: 'coupleId',
            render: (e) => {
                return (
                    <p>{e?.coupleName || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_ParticipationFee,
            render: (e) => {
                return (
                    <p>€{e?.moneyCollected - e?.serviceFee}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_serviceFee,
            render: (e) => {
                return (
                    <p>€{e?.serviceFee}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_RequestDate,
            render: (e) => {
                return (
                    <p>{moment(e?.created)?.format('DD-MM-YYYY')}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Status,
            render: (e) => {
                return (
                    <p>{e?.isApproved ? 'Approved' : e?.isReject ? selectedLanguage?.sts_rejected : selectedLanguage?.sts_rejected}</p>
                )
            }
        }
    ]


    return (
        <>
            <div className='create-match-req-main-div'>
                <div
                    className='sidebar-scroll-class-black'
                    style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
                >
                    <p className='admin-home-title-white'>
                        {selectedLanguage?.txt_SubscribedCompetition}
                    </p>

                    <div style={{ marginTop: '20px' }}>
                        <Table
                            loading={loading}
                            dataSource={requests}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: 800 }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscribedCompetition