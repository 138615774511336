import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Select, Table } from "antd";
import { useSelector } from "react-redux";
import { errorMessage, getGeneralData, getUserData, infoMessage, successMessage } from "../../../utils/helpers";
import axios from "axios";
import { ADMIN } from "../../../utils/apis";
import moment from "moment";
import { AiFillSetting } from 'react-icons/ai'
import ActualRankingModal from "./ActualRankingModal";
import UserRankingModal from "./UserRankingModal";

const CompetitionRequest = (props) => {
    const { user, generalActions, userActions, isEnglish, selectedLanguage } = props
    const [requests, setRequest] = useState([])
    const [loading, setLoading] = useState(false)
    const [approveLoading, setApproveLoading] = useState({})
    const [rejectLoading, setRejectLoading] = useState({})
    const [settingCompetition, setSettingCompetition] = useState({})
    const [userSettingCompetition, setUserSettingCompetition] = useState({})
    const allCompetitions = useSelector(state => state.generalReducer.allCompetitions)
    const [selectedCompetition, setSelectedCompetition] = useState(null)
    const { Option } = Select
    useEffect(() => {
        getUserData(user?._id, userActions)
        getGeneralData(generalActions)
        allCompetitions?.length && getCompetitionRequest(allCompetitions?.[0]?._id)
    }, [])
    const getCompetitionRequest = (e) => {
        setSelectedCompetition(e)
        setLoading(true)
        axios.post(ADMIN?.GET_COMPETITION_SUB_BY_COM_ID, { _id: e })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    console.log(data?.data, 'data?.data')
                    setRequest(data?.data)
                    setLoading(false)
                } else {
                    setRequest([])
                    setLoading(false)
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
                console.log(e, 'error 28 competiton request')
            })

    }
    const ApproveRequest = (e) => {
        let obj = { _id: e }
        setApproveLoading({ [e]: true })
        axios.post(ADMIN?.APPROVE_COMPETITION_SUB_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getCompetitionRequest(selectedCompetition)
                    setApproveLoading({})
                }
                else {
                    setApproveLoading({})
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setApproveLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const DeleteRequest = (e) => {
        let obj = { _id: e }
        setRejectLoading({ [e]: true })

        axios.post(ADMIN?.REJECT_COMPETITION_SUB_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getCompetitionRequest(selectedCompetition)
                    setRejectLoading({})
                }
                else {
                    setRejectLoading({})
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setRejectLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    const columns = [
        {
            title: selectedLanguage?.txt_CompetitionName,
            dataIndex: 'competitionId',
            render: (e) => {
                return (
                    <p>{e?.competitionName || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_SubscriberName,
            dataIndex: 'userId',
            render: (e) => {
                return (
                    <p>{e?.name || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_CoupleName,
            dataIndex: 'coupleId',
            render: (e) => {
                return (
                    <p>{e?.coupleName || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_ParticipationFee,
            render: (e) => {
                return (
                    <p>€{e?.moneyCollected - e?.serviceFee}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_serviceFee,
            render: (e) => {
                return (
                    <p>€{e?.serviceFee}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_RequestDate,
            render: (e) => {
                return (
                    <p>{moment(e?.created)?.format('DD-MM-YYYY')}</p>
                )
            }
        },
        {
            title: selectedLanguage?.Status,
            render: (e) => {
                return (
                    <p>{e?.isApproved ? selectedLanguage?.txt_Approved : e?.isReject ? selectedLanguage?.txt_Rejected : selectedLanguage?.txt_NewRequestArrived}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Rankssetting,
            render: (e) => {
                return (
                    <AiFillSetting
                        onClick={() =>
                            e?.competitionId?.playerNumbers === 4 ?
                                setSettingCompetition(e)
                                :
                                setUserSettingCompetition(e)

                        }
                        color={'#ec671b'}
                        size={18}
                        style={{ cursor: 'pointer' }}
                    />
                )
            }
        },
        {
            title: selectedLanguage?.txt_Action,
            render: (e) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <Button
                            loading={approveLoading[e?._id]}
                            disabled={e?.isApproved || e?.isReject}
                            onClick={() => ApproveRequest(e?._id)}
                            style={{ height: '30px', width: '90px', marginRight: '2px' }}
                        >{selectedLanguage?.txt_Approve}</Button >
                        <Popconfirm
                            disabled={e?.isApproved || e?.isReject}
                            title={selectedLanguage?.txt_AreSureYouRejectthisRequest}
                            okText={selectedLanguage?.txt_Reject}
                            cancelText={selectedLanguage?.Cancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => {
                                DeleteRequest(e?._id)
                            }}
                        >
                            <Button
                                loading={rejectLoading[e?._id]}
                                disabled={e?.isApproved || e?.isReject}
                                style={{ height: '30px', width: '90px', marginRight: '2px' }}
                            >{selectedLanguage?.txt_Reject}</Button >
                        </Popconfirm>
                    </div>
                )
            }
        },
    ]


    console.log('==>settingCompetition', settingCompetition)

    return (
        <>
            <div className='create-match-req-main-div'>
                <div
                    className='sidebar-scroll-class-black'
                    style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
                >
                    <p className='admin-home-title-white'>
                        {selectedLanguage?.txt_Competitionsubscriptionrequest}
                    </p>
                    <div className='flex-mobile' style={{ marginTop: 10, margin: '10px auto' }}>
                        <Select
                            disabled={loading}
                            loading={loading}
                            showSearch
                            showArrow
                            allowClear={false}
                            value={selectedCompetition}
                            onChange={(e) => {
                                getCompetitionRequest(e)
                            }}
                            className='form-input'
                            placeholder={selectedLanguage?.txt_SelectCompetetion}
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                                option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '250px' }}
                        >
                            {allCompetitions?.length ?
                                allCompetitions?.map((v, i) => {
                                    return (
                                        <Option value={v?._id} key={i}>{`${v?.competitionName}- € ${v?.participationMoney}`}</Option>
                                    )
                                })
                                : null
                            }
                        </Select>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Table
                            loading={loading}
                            dataSource={requests}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: 800 }}
                        />
                    </div>
                </div>
                {settingCompetition?._id &&
                    <ActualRankingModal data={settingCompetition} getData={getCompetitionRequest} onCancel={() => setSettingCompetition({})} {...props} />
                }
                {userSettingCompetition?._id &&
                    <UserRankingModal
                        data={userSettingCompetition}
                        getData={getCompetitionRequest}
                        onCancel={() => setUserSettingCompetition({})}
                        {...props} />
                }
            </div>
        </>
    )
}

export default CompetitionRequest