import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, Select } from 'antd'
import { requiredMessage, inputPlace, errorMessage, successNotification, convertTitle } from '../../utils/helpers'
import { allPaths, gameTypes, provincieLanguage } from '../../utils/constants'
import AllCountries from '../../utils/allCountries'
import { LoginFooter, LoginHeader } from '../../Components'
import axios from 'axios'
import { ADMIN, AUTH } from '../../utils/apis'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import countryCodes from '../../utils/countryCodes'
import ReactFlagsSelect from 'react-flags-select'
import { allAdminClubs } from '../../Redux/actions/generalActions'

const { Option } = Select

const ClubSignup = (props) => {
    const history = useHistory()
    const [form] = Form.useForm()
    const formRef = useRef(null)
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'
    const [selected, setSelected] = useState('GB')
    const [userLanguage, setUserLanguage] = useState('english')
    const [country, setCountry] = useState('Netherlands')
    const [countryCode, setCountryCode] = useState('+31')
    const [loading, setLoading] = useState(false)
    const [selectedClub, setSelectedClub] = useState({})
    const [verified, setVerified] = useState(false)
    const adminClubs = useSelector(state => state.generalReducer.adminClubs) || []
    const dispatch = useDispatch()
    useEffect(() => {
        getAdminClubs()
    }, [])

    const getAdminClubs = () => {
        axios.get(ADMIN?.GET_ADMIN_CLUBS)
            .then((res) => {
                const { data } = res
                dispatch(allAdminClubs(data?.data || []))
            })
    }

    const onSelectClub = (e) => {
        console.log('e', e)
        setSelectedClub(adminClubs.find((v) => v?._id === e))
        form.setFieldsValue({
            code: null
        })
        setVerified(false)
    }
    console.log('selectedClub', selectedClub)

    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'GB') {
            setUserLanguage('english')
        }
        else {
            setUserLanguage('netherlands')
        }
    }
    console.log(userLanguage, '=userLanguage')

    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter((v) => v?.code === e)[0].dial_code

        setCountryCode(selectedCode)

        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    showSearch
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120,
                    }}
                    defaultValue={countryCode}
                    value={countryCode}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {countryCodes?.map((v, i) => (
                        <Option
                            value={v?.dial_code}
                            key={i}
                        >{`${v?.name} ${v?.dial_code}`}</Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }

    const onFinish = (value) => {
        value.userType = 'club'
        value.clubId = selectedClub?._id
        value.languageSetting = userLanguage
        value.name = selectedClub?.name
        value.email = selectedClub?.email
        setLoading(true)

        axios.post(AUTH.SIGNUP, value)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(selectedLanguage?.message_SuccessfullySignUpWeHaveSentYouVerificationLinkToYourEmailPpleaseVerifyYourEemailAndLogin)
                    return setTimeout(() => {
                        history.push('/login')
                    }, 300)
                }
                else {
                    errorMessage(isEnglish ? data.message : data.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }


    return (
        <div className='signup-main-screen helper-main' >
            <LoginHeader />
            <div className='auth-screen-main-div' style={{ marginTop: '20px', }}>
                <p className='heading head-center' style={{ color: 'white' }}>{selectedLanguage?.title_Register}</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                    form={form}
                    ref={formRef}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='club'
                                hasFeedback
                                // initialValue={edit?.tournamentName}
                                label={<p className='my-form-label'>{'Select Club'}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Club'
                                    }
                                ]}
                            >
                                <Select
                                    onChange={onSelectClub}
                                    placeholder={'Select Club'}
                                    className='form-input-new-style'
                                    style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                >
                                    {adminClubs?.filter((x) => !x?.isRegistered)?.map((v, i) => (<Option key={i} value={v?._id}>{v?.name} - {v?.email}</Option>))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='code'
                                label={<p className='my-form-label'>{'Enter Code'}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Code!'
                                    }
                                ]}
                            >
                                <Input
                                    onChange={(e) => (setVerified(e?.target.value === selectedClub?.code))}
                                    disabled={!selectedClub?._id}
                                    maxLength={11}
                                    className='form-input-new-style'
                                    placeholder={'Enter Code'}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='streetNumber'
                                label={<p className='my-form-label'>{selectedLanguage?.StreetNumber}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseIputYourStreetNumber
                                    }
                                ]}
                            >
                                <Input
                                    disabled={!verified}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourStreetNumberHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='postalCodeCity'
                                label={<p className='my-form-label'>{selectedLanguage?.PostalCode}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourPostalCode
                                    }
                                ]}
                            >
                                <Input
                                    disabled={!verified}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourPostalCodeHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='country'
                                label={<p className='my-form-label'>{selectedLanguage?.label_Country}</p>}
                                hasFeedback
                                initialValue={country}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseSelectYourCountry
                                    }
                                ]}
                            >
                                <Select
                                    disabled={!verified}
                                    showSearch
                                    showArrow
                                    allowClear={false}
                                    className='form-input-new-style input-none'
                                    placeholder={selectedLanguage?.placeHolder_SelectYourCountry}
                                    optionFilterProp='children'
                                    onChange={updateCountry}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{isEnglish ? v?.name : v?.nameNL}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='city'
                                label={<p className='my-form-label'>{selectedLanguage?.label_City}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourCity
                                    }
                                ]}
                            >
                                <Input
                                    disabled={!verified}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_InputYourCityHere}
                                />
                            </Form.Item>
                        </div >
                    </div >

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='provincie'
                                label={<p className='my-form-label'>{selectedLanguage?.Provincie}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourProvincie
                                    }
                                ]}
                            >
                                <Select
                                    disabled={!verified}
                                    className='form-input-new-style'
                                    showArrow
                                    placeholder={selectedLanguage?.SelectProvincie}
                                >
                                    {provincieLanguage?.map((v, i) => <Option value={v?.name} key={i}>{language?.language === 'english' ? v?.name : v?.nameNl}</Option>)}
                                </Select>
                            </Form.Item>
                        </div >
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='telNo'
                                label={<p className='my-form-label'>{selectedLanguage?.TelNo}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourTelNo
                                    }
                                ]}
                            >
                                <Input
                                    disabled={!verified}
                                    addonBefore={prefixSelector()}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.TelNo}
                                />
                            </Form.Item>
                        </div >
                    </div >

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='gameType'
                                label={<p className='my-form-label'>{'Game Types'}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Game Types'
                                    }
                                ]}
                            >
                                <Select
                                    disabled={!verified}
                                    mode='multiple'
                                    className='form-input-new-style'
                                    showArrow
                                    placeholder={'Please Select Game Types'}
                                >
                                    {gameTypes?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                        </div >
                    </div >

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='password'
                                label={<p className='my-form-label'>{selectedLanguage?.label_Password}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourPassword
                                    },
                                    {
                                        min: 8,
                                        message: selectedLanguage?.error_PasswordMustBeAtLeastEightCharacters
                                    }
                                ]}
                            >
                                <Input.Password
                                    disabled={!verified}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.PlaceHolder_PleaseEnterYourPasswordHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                label={<p className='my-form-label'>{selectedLanguage?.label_ConfirmPassword}</p>}
                                name='confirm'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourConfirmPassword,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve()
                                            }

                                            return Promise.reject(selectedLanguage?.error_TheTwoPasswordsThatYouEnteredDoNotMatch)
                                        },
                                    })
                                ]}
                            >
                                <Input.Password
                                    disabled={!verified}
                                    placeholder={selectedLanguage?.placeHolder_InputYourConfirmPassword}
                                    className='form-input-new-style'
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <p style={{
                            margin: '0px',
                            color: 'white',
                            fontWeight: 'bold',
                            marginLeft: '5px',
                            marginBottom: '7px',
                        }}>
                            {selectedLanguage?.tedxt_LanguageSetting}
                        </p>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            background: 'white',
                            height: '45px',
                            paddingTop: '6px',
                            paddingLeft: '10px',
                            borderRadius: '6px',
                            boxShadow: 'rgb(0 0 0 / 24%) 0px 2.5px 8px'
                        }}>
                            <ReactFlagsSelect
                                className='countries-select-grey'
                                fullWidth={false}
                                showOptionLabel={false}
                                showSelectedLabel={false}
                                countries={['GB', 'NL']}
                                selected={selected}
                                onSelect={(v) => onchangeLanguage(v)}
                            />
                        </div>
                    </div>

                    <Form.Item>
                        <Button
                            style={{
                                padding: '0px 20px 0px 20px',
                                background: 'rgba(92, 93, 93, 1)',
                                color: 'white',
                                border: 'none',
                                height: '50px',
                                marginTop: '10px',
                                borderRadius: 6,
                                width: '100%'
                            }}
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.ButtonText_Submit}
                        </Button>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <p style={{ color: 'white' }}>{selectedLanguage?.minTitle_AlreadyHaveAnAccount}
                                <Link className='linkStyle' to={allPaths?.LOGIN}>{' '}{selectedLanguage?.minTitle_Login}</Link>
                                <br />
                                <Link className='linkStyle' to={allPaths?.FORGOT_PASSOWRD}>{selectedLanguage?.minTitle_ForgotPassword}</Link>
                            </p>
                        </div>
                    </Form.Item>
                </Form>
            </div>
            <LoginFooter />
        </div>
    )
}

export default ClubSignup