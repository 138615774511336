import { Button, Form, Input, InputNumber, Modal, Select, Switch } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN, } from '../../../utils/apis'
import { errorMessage, getUserData, successMessage } from '../../../utils/helpers'
import { GiAmericanFootballPlayer } from 'react-icons/gi'
import { ImClubs } from 'react-icons/im'
const AddFaq = (props) => {
    const { Option } = Select
    const { selectedLanguage, userActions, isEnglish, user, editData, getFun, handleOK } = props
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState(editData?.type || 'How it works?')
    const [catagrie, setCatagrie] = useState(editData?.catagrie || 'Tournaments')
    const [isClub, setISClub] = useState(editData?.isClub ? true : false)
    const [form] = Form.useForm()

    function onChangeCheckBox(e) {
        setISClub(e/* .target.checked */)
    }

    // console.log(editData, "=editData")

    const onFinish = (values) => {
        editData?._id && (values._id = editData?._id)
        values.adminId = user?._id
        values.isClub = isClub
        values.type = type
        type === "FAQ's" && (values.catagrie = catagrie)
        console.log(values, "values")

        axios.post(editData?._id ? ADMIN.EDIT_FAQ_QUESTION : ADMIN.ADD_FAQ_QUESTION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(isEnglish ? data.message : data.messageNl)
                    form.resetFields()
                    editData?._id && (getFun())
                    editData?._id && (handleOK())
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <div style={{ marginTop: '10px' }}>
                    <p className='admin-home-title'>{selectedLanguage?.text_AddFaq}</p>
                </div>

                {editData?._id &&
                    <span style={{ marginTop: '10px' }} className='button-div-flex-end'>
                        <Button className='addproduct-text' onClick={handleOK}>{selectedLanguage?.faq_Cancel}</Button>
                    </span>
                }

                <div style={{ marginTop: '35px' }}>
                    <Form
                        name='form'
                        form={form}
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='titleEn'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.faq_TitleEnglish}</p>}
                                    initialValue={editData?.titleEn}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.faq_pleaseEnterEnglishTitle
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style input-placeholder-orange'
                                        placeholder={selectedLanguage?.faq_EnterEnglishTitleHere}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='titleNl'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.faq_TitleNl}</p>}
                                    initialValue={editData?.titleNl}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.faq_pleaseEnterNlTitleHere
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style input-placeholder-orange'
                                        placeholder={selectedLanguage?.faq_EnterNlTitleHere}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='DiscriptionEn'
                                    initialValue={editData?.DiscriptionEn}
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.faq_DiscriptionEnglish}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.faq_pleaseEnterEnglishDiscription
                                        }
                                    ]}
                                >
                                    <Input.TextArea
                                        className='form-input-new-style-select-minhi textarea-placeholder-orange'
                                        placeholder={selectedLanguage?.faq_EnterEnglishDiscriptionHere}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='DiscriptionNl'
                                    initialValue={editData?.DiscriptionNl}
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.faq_DiscriptionNl}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.faq_pleaseEnterNlDiscriptionHere
                                        }
                                    ]}
                                >
                                    <Input.TextArea
                                        className='form-input-new-style-select-minhi textarea-placeholder-orange'
                                        placeholder={selectedLanguage?.faq_EnterNlDiscriptionHere}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input '>
                                <Form.Item
                                    label={<p className='p-admin-title-text' >{'Display'}</p>}
                                    name='isClub'
                                    initialValue={isClub}
                                    onChange={onChangeCheckBox}
                                    valuePropName='checked'>
                                    <div
                                        className='form-input-new-style'
                                        style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white' }}
                                    >
                                        <p style={{ margin: '0px 10px' }} className='p-admin-title-text'>{'Player'}</p>
                                        <Switch
                                            onChange={onChangeCheckBox}
                                            checked={isClub}
                                            checkedChildren={<ImClubs size={19} style={{ height: 22, marginRight: 4 }} />}
                                            unCheckedChildren={<GiAmericanFootballPlayer size={19} style={{ height: 22, marginLeft: 4 }} />} />
                                        <p style={{ margin: '0px 10px' }} className='p-admin-title-text'>{'Club'}</p>
                                    </div>
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <p style={{ padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px' }}
                                    className='p-admin-title-text'
                                >{selectedLanguage?.faq_SelectType}</p>
                                <Select defaultValue={type}
                                    placeholder={selectedLanguage?.faq_SelectType}
                                    className='form-input-new-style select-placeholder-orange'
                                    onChange={(e) => { setType(e) }}
                                    style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                >
                                    <Option value={'How it works?'}>{selectedLanguage?.faq_HowItWork + ' ' + '?'}</Option>
                                    <Option value={"FAQ's"}>{selectedLanguage?.faq_FAQS}</Option>
                                </Select>
                            </div>
                        </div>

                        {type === "FAQ's" &&
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <p style={{ padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px' }}>{selectedLanguage?.faq_SelectCatagrie}</p>
                                    <Select defaultValue={catagrie}
                                        placeholder={selectedLanguage?.faq_SelectCatagrie}
                                        className='form-input-new-style select-placeholder-orange'
                                        onChange={(e) => { setCatagrie(e) }}
                                        style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                    >
                                        <Option value={"Tournaments"}>{selectedLanguage?.faq_Tournaments}</Option>
                                        <Option value={'Participation'}>{selectedLanguage?.faq_Participation}</Option>
                                        <Option value={"Goal"}>{selectedLanguage?.faq_Goal}</Option>
                                    </Select>
                                </div>
                            </div>
                        }

                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='priority'
                                    initialValue={editData?.priority}
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.faq_priority}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.faq_pleasePriorityHere
                                        }
                                    ]}
                                >
                                    <InputNumber
                                        step={1}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-style input-placeholder-orange'
                                        placeholder={selectedLanguage?.faq_Enterpriority}
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                            <Form.Item>
                                <Button
                                    className='addproduct-text'
                                    style={{ height: '45px', width: '180px', marginTop: '10px' }}
                                    htmlType='submit'
                                    loading={loading}
                                >
                                    {editData?._id ? selectedLanguage?.faq_UpdateFaq : selectedLanguage?.faq_AddFaq}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div >
        </div >
    )
}
export default AddFaq