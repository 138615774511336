import React, { useEffect, useState } from "react";
import { Button, Form, InputNumber, Modal } from "antd";
import { errorMessage, successMessage } from '../../../utils/helpers'
import { ADMIN } from '../../../utils/apis'
import axios from "axios";

const InvitePointSetting = (props) => {
    const { user, edit, selectedLanguage, isEnglish } = props
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [refferalPoints, setRefferalPoints] = useState(null)


    const onFinish = (values) => {
        setLoading(true)
        values.adminId = user?._id
        refferalPoints?.point && (values._id = refferalPoints?._id)

        axios.post(refferalPoints?._id ? ADMIN?.EDIT_REFERRAL_POINTS : ADMIN?.ADD_REFERRAL_POINTS, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getRefferalPoint()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }



    const handleOk = () => {
        setVisible(false)
    }

    const getRefferalPoint = () => {
        axios.get(ADMIN?.GET_REFERRAL_POINTS)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setRefferalPoints(data?.data[0])
                }
                else {
                    setRefferalPoints(null)
                }
            })
            .catch((e) => {
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })

    }

    useEffect(() => {
        getRefferalPoint()
    }, [])

    return (
        <>
            <div className="reward-point-box">
                <p className="p-none-margin-bolder-p">{selectedLanguage?.text_WhenPlayerInviteHisFriendAndHeWillRegistered} </p>
                <p className="p-none-margin-bolder-p">
                    {selectedLanguage?.text_PlayerGet} ( {refferalPoints?.point || '--'} ) {selectedLanguage?.text_PointAsReward}</p>
                <div style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    <Button className='update-btn-text' onClick={() => { setVisible(true) }} > {selectedLanguage?.text_updateRewardPoints}</Button>
                </div>
            </div>
            {/* --------Modal----- */}

            <Modal
                visible={visible}
                onOk={handleOk}
                onCancel={handleOk}
                footer={null}>
                <div style={{ marginTop: '10px' }}>
                    <span style={{ textAlign: 'center' }}>
                        <p className='admin-home-title' style={{ fontSize: '22px' }}>{selectedLanguage?.text_UpdateInviteRewardPoints}</p>
                    </span>
                    <div style={{ marginTop: '35px' }}>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex-inner-input newInpuHeightFull' style={{ width: '100%' }}>
                                <Form.Item
                                    name='point'
                                    initialValue={refferalPoints?.point}
                                    hasFeedback
                                    label={selectedLanguage?.text_RewardPoints}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.text_pleaseEnterPoint
                                        }
                                    ]}
                                >
                                    <InputNumber
                                        step={0.5}    // Set the step to 0.01 for two decimal places
                                        precision={2}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.text_EnterPointHere}
                                    />
                                </Form.Item>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                <Form.Item>
                                    <Button
                                        className='addproduct-text '
                                        style={{ height: '45px', width: '180px', marginTop: '10px' }}
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        {refferalPoints?.point ? selectedLanguage?.text_UpdatePointSetting : selectedLanguage?.text_AddPointSetting}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div >
            </Modal >
        </>
    )
}



export default InvitePointSetting