import { Button, Checkbox, Select, Spin, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { ADMIN, AUTH } from '../../../utils/apis';
import axios from 'axios';
import { errorMessage, getGeneralData, successMessage } from '../../../utils/helpers';
import { useSelector } from 'react-redux';

const PlayerDetail = (props) => {
const { Option } = Select
    const { selectedLanguage, isEnglish, generalActions } = props
    const games = useSelector(state => state.generalReducer.games)
    const [mydata, setMyData] = useState()
    const [loading, setLoading] = useState(false)
    const [disbaled, setDisbaled] = useState(false)
    const [prizeChecked, setPrizeChecked] = useState(false)
    const [currentPrize, setCurrentPrize] = useState({})
    const [prizeLoading, setPrizeLoading] = useState({})
    const [tournamentChecked, setTournamentChecked] = useState(false)
    const [currentTournament, setCurrentTournament] = useState({})
    const [tournamentLoading, setTournamentLoading] = useState({})
    const [selectedGame, setSelectedGame] = useState(games?.[0]?._id || null)

    useEffect(() => {
        getGeneralData(generalActions)
        getSelectedGameDetail(games?.[0]?._id, /* loading --->*/true)
    }, [])

    const getSelectedGameDetail = (id, loading) => {
        setLoading(loading)
        axios?.get(`${ADMIN?.GET_SELECTED_GAME_SETTING}/${id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setPrizeLoading({})
                setTournamentLoading({})
                setDisbaled(false)
                if (data?.success) {
                    let matchArray = data?.matchArray?.length ? data?.matchArray : [];
                    let numberOfTournamentJoined = data?.numberOfTournamentJoined?.length ? data?.numberOfTournamentJoined : [];
                    let tournamentOrginizedArray = data?.tournamentOrginizedArray?.length ? data?.tournamentOrginizedArray : [];
                    let players = data?.data || [];
                    const playersWithMatches = players.map((player, index) => {
                        const matchesCount = matchArray[index] || 0;
                        const tournamentCount = numberOfTournamentJoined[index] || 0;
                        const tournamentOrginizedCount = tournamentOrginizedArray[index] || 0;
                        return {
                            ...player,
                            numberOfMatches: matchesCount,
                            numberOfTournamentJoined: tournamentCount,
                            tournamentOrginizedArray: tournamentOrginizedCount
                        };
                    });
                    setMyData(playersWithMatches || [])
                }
                else {
                    setMyData([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const columns = [
        {
            title: selectedLanguage?.text_PlayerName,
            dataIndex: 'playerId',
            render: (e) => {
                return (
                    <p>{e?.name || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_nickName,
            dataIndex: 'nickName',
            render: (e) => {
                return (
                    <p>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_NumberOfMatches,
            dataIndex: 'numberOfMatches',
            render: (e) => {
                return <p>{e || 0}</p>
            },
        },
        {
            title: selectedLanguage?.text_NumberSubscribedTournament,
            dataIndex: 'numberOfTournamentJoined',
            render: (e) => {
                return (
                    <p>{e || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_numberTournamentOrginized,
            dataIndex: 'tournamentOrginizedArray',
            render: (e) => {
                return (
                    <p>{e || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_EditOwnTournamentPrizes,
            dataIndex: 'playerId',
            render: (e) => {
                const handleOnChange = (vv) => {
                    setCurrentPrize(e)
                    setPrizeChecked(vv.target.checked);
                    setPrizeLoading({ _id: e?._id })
                    setDisbaled(true)

                    let obj = {
                        _id: e?._id,
                        isPrizeEditor: vv.target.checked,
                    }
                    axios.post(AUTH.UPDATE_SETTINGS, obj)
                        .then((res) => {
                            const { data } = res
                            if (data?.success) {
                                setCurrentPrize({})
                                getSelectedGameDetail(selectedGame, false)
                            }
                        })
                        .catch((e) => {
                            console.log(e, '-')
                        })

                }
                return (
                    <>
                        {prizeLoading?._id === e?._id ?
                            <Spin />
                            : <Checkbox
                                disabled={disbaled}
                                checked={e?.isPrizeEditor ? true :
                                    currentPrize?._id === e?._id ? prizeChecked : false}
                                onChange={handleOnChange}
                            />}
                    </>
                )
            }
        },
        {
            title: selectedLanguage?.text_ShowRepeatCheckBox,
            dataIndex: 'playerId',
            render: (e) => {
                const handleOnChange = (vv) => {
                    setCurrentTournament(e)
                    setTournamentChecked(vv.target.checked);
                    setTournamentLoading({ _id: e?._id })
                    setDisbaled(true)

                    let obj = {
                        _id: e?._id,
                        isTournamentRepeat: vv.target.checked,
                    }
                    axios.post(AUTH.UPDATE_SETTINGS, obj)
                        .then((res) => {
                            const { data } = res
                            if (data?.success) {
                                setCurrentTournament({})
                                getSelectedGameDetail(selectedGame, false)
                            }
                        })
                        .catch((e) => {
                            console.log(e, '-')
                        })
                }
                return (
                    <>
                        {tournamentLoading?._id === e?._id ?
                            <Spin />
                            :
                            <Checkbox
                                disabled={disbaled}
                                checked={e?.isTournamentRepeat ? true :
                                    currentTournament?._id === e?._id ? tournamentChecked : false}
                                onChange={handleOnChange}
                            />}
                    </>
                )
            }

        }
    ]

    const onChange = (value) => {
        setSelectedGame(value)
        getSelectedGameDetail(value, true)
    }

    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <p className='admin-home-title'>{selectedLanguage?.title_PlayerDetail}</p>
                <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    <Select
                        style={{ width: '200px' }}
                        placeholder={selectedLanguage?.player_Selectagame}
                        onChange={onChange}
                        value={selectedGame}
                    >
                        {(games || [])?.length && (games || [])?.map((v, i) => {
                            return (
                                <Option value={v?._id}>{v?.name}</Option>
                            )
                        })}
                    </Select>
                </span>
                <div style={{ marginTop: '20px' }}>
                    <Table
                        columns={columns}
                        dataSource={mydata}
                        loading={loading}
                        pagination={false}
                        scroll={{ x: 800 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PlayerDetail