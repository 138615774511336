import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Input, Button, Modal } from 'antd'
import { errorMessage, successNotification } from '../../../utils/helpers'
import axios from 'axios'
import { POST } from '../../../utils/apis'



const AddCourtReview = (props) => {
    const { user,  reviewData, onCancel, language, selectedLanguage } = props

    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const userId = user?._id
    const courtId = reviewData?._id
    const { TextArea } = Input
    const isEnglish = language?.language === 'english'

    console.log(reviewData, "=reviewData")

    const onFinish = (values) => {

        let cleanliness = + values?.cleanliness
        let comfort = + values?.comfort
        let facilities = + values?.facilities
        let freeWifi = + values?.freeWifi
        let locationReview = + values?.location
        let staff = + values?.staff
        let valueForMoney = + values?.valueForMoney
        let avrageReview = cleanliness + comfort + facilities + freeWifi + locationReview + staff + valueForMoney
        values.userId = userId
        values.courtId = courtId
        values.average = avrageReview / 7
        // console.log(avrageReview / 7, "=avrageReview / 7")

        setLoading(true)
        axios.post(POST?.CREATE_REVIEW, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    successNotification(selectedLanguage?.ReviewSuccessfullySubmitted)
                    form.resetFields()
                    onCancel()
                }
                else {
                    setLoading(false)
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }



    return (
        <Modal
            open={reviewData?._id ? true : false}
            onCancel={onCancel}
            footer={null}
            style={{ padding: '0px' }}
        >

            <div className='card-form'>
                <div className='form-card-inner'>
                    <p className='heading head-center'>{selectedLanguage?.WriteAReview}</p>
                    <p className='review-text head-center' style={{ fontWeight: 600, color: '#ec671b', marginTop: -20 }}>{selectedLanguage?.WeAimFor100RealReview}</p>

                    <Form
                        name='form'
                        onFinish={onFinish}
                        form={form}
                        layout={'vertical'}
                        requiredMark={false}
                    >
                        <div className='form-input-single-input'>
                            <Form.Item
                                name='staff'
                                label={selectedLanguage?.StaffReview}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourStaffReview
                                    }
                                ]}
                            >
                                <Input
                                    min={1}
                                    max={10}
                                    className='form-input-new-style'
                                    type='number'
                                    step={0.01}
                                    precision={2}
                                    pattern='[0-9]*'
                                    placeholder={selectedLanguage?.InputYourStaffReviewHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-input-single-input'>
                            <Form.Item
                                name='facilities'
                                label={selectedLanguage?.FacilitiesReview}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourFacilitiesReview
                                    }
                                ]}
                            >
                                <Input
                                    min={1}
                                    max={10}
                                    className='form-input-new-style'
                                    type='number'
                                    step={0.01}
                                    precision={2}
                                    pattern='[0-9]*'
                                    placeholder={selectedLanguage?.InputYourFacilitiesReviewHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-input-single-input'>
                            <Form.Item
                                name='cleanliness'
                                label={selectedLanguage?.CleanlinessReview}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourCleanlinessReview
                                    }
                                ]}
                            >
                                <Input
                                    min={1}
                                    max={10}
                                    className='form-input-new-style'
                                    type='number'
                                    step={0.01}
                                    precision={2}
                                    pattern='[0-9]*'
                                    placeholder={selectedLanguage?.InputYourCleanlinessReviewHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-input-single-input'>
                            <Form.Item
                                name='comfort'
                                label={selectedLanguage?.ComfortReview}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PlasseInputYourComfortReview
                                    }
                                ]}
                            >
                                <Input
                                    min={1}
                                    max={10}
                                    className='form-input-new-style'
                                    type='number'
                                    step={0.01}
                                    precision={2}
                                    pattern='[0-9]*'
                                    placeholder={selectedLanguage?.InputYourComfortReviewHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-input-single-input'>
                            <Form.Item
                                name='valueForMoney'
                                label={selectedLanguage?.ValueForMoneyReview}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourValueForMoneyReview
                                    }
                                ]}
                            >
                                <Input
                                    min={1}
                                    max={10}
                                    className='form-input-new-style'
                                    type='number'
                                    step={0.01}
                                    precision={2}
                                    pattern='[0-9]*'
                                    placeholder={selectedLanguage?.InputYourValueForMoneyReviewHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-input-single-input'>
                            <Form.Item
                                name='location'
                                label={selectedLanguage?.LocationReview}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourLocationReview
                                    }
                                ]}
                            >
                                <Input
                                    min={1}
                                    max={10}
                                    className='form-input-new-style'
                                    type='number'
                                    step={0.01}
                                    precision={2}
                                    pattern='[0-9]*'
                                    placeholder={selectedLanguage?.InputYourLocationReviewHere}

                                />
                            </Form.Item>
                        </div>
                        <div className='form-input-single-input'>
                            <Form.Item
                                name='freeWifi'
                                label={selectedLanguage?.FreeWifiReview}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourFreeWifiReview

                                    }
                                ]}
                            >
                                <Input
                                    min={1}
                                    max={10}
                                    className='form-input-new-style'
                                    type='number'
                                    step={0.01}
                                    precision={2}
                                    pattern='[0-9]*'
                                    placeholder={selectedLanguage?.InputYourFreeWifiReviewHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-input-single-input'>
                            <Form.Item
                                name='comment'
                                label={selectedLanguage?.ReviewMessage}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourReviewMessage
                                    }
                                ]}
                            >
                                <TextArea
                                    className='form-input-new-style'
                                    showCount
                                    maxLength={250}
                                    style={{ height: 80 }}
                                    placeholder={selectedLanguage?.InputYourReviewMessageHere}
                                />
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button
                                style={{ height: '40px', width: '100%', marginTop: '10px' }}
                                className='addproduct-text'
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.SubmitYourReview}
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button key='back'
                                style={{ width: '100%', height: '40px', marginTop: '10px' }}
                                className='addproduct-text' onClick={onCancel}>
                                {selectedLanguage?.Cancel}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal >
    )
}

export default AddCourtReview