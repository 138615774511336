import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { getGeneralData } from '../../../utils/helpers'
import { useSelector } from 'react-redux'

const RegisteredClubs = (props) => {
    const { isEnglish, user, generalActions, selectedLanguage } = props
    const allClubs = useSelector(state => state.generalReducer.allClubsUsers)

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    const columns = [
        {
            title: selectedLanguage?.title_Name,
            dataIndex: 'name',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Email,
            dataIndex: 'email',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e || '-----'}</p>
                )
            }
        },
        {
            title:selectedLanguage?.title_Country,
            dataIndex: 'country',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_City,
            dataIndex: 'city',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e || '-----'}</p>
                )
            }
        },

    ]

    return (
        <div style={{ margin: '20px' }}>
            <p className='admin-home-title'>
                {selectedLanguage?.AllCLUBS}
            </p>

            <div style={{ marginTop: '20px' }}>
                <Table
                    dataSource={allClubs}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 400 }}
                />
            </div>
        </div>
    )
}

export default RegisteredClubs