import { Button, DatePicker, Form, Input, InputNumber, Select, TimePicker } from 'antd'
import axios from 'axios'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { POST } from '../../utils/apis'
import { intervalOptions } from '../../utils/constants'
import { errorMessage, successNotification } from '../../utils/helpers'

const format = 'HH:mm'

const { Option } = Select
const { RangePicker } = DatePicker

const AddReservation = (props) => {
    const { onCancel, setReservationData, product, user, allProduct, setReservationsetting, language, selectedLanguage } = props
    console.log('product', product)
    const [loading, setLoading] = useState(false)
    const [productIds, setProductIds] = useState([])
    const [interval, setInterval] = useState(intervalOptions?.[0]?.value)
    const [timeRange, setTimeRange] = useState({
        time1: moment().format(format),
        time2: moment().utc().add(7, 'days').format(format)
    });
    const [dateRange, setDateRange] = useState({
        date1: moment().format('YYYY-MM-DD'),
        date2: moment().utc().add(7, 'days').format('YYYY-MM-DD')
    })
    const [isError, setIsError] = useState(false)
    const onFinish = (values) => {

        console.log('values', values)
        values.interval = interval
        values.timeRange = timeRange
        values.dateRange = dateRange
        values.userId = user?._id

        if (values?.maximumProducts > 200) {
            errorMessage(selectedLanguage?.ValueCannotBeGreaterThan200)
            return setIsError(true)
        }
        setLoading(true)
        axios.post(POST?.CREATE_RESERVATION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(data?.message)
                    setReservationData(data?.data)
                    setReservationsetting(true)
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                } else {
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }
    const getLatestData = (d) => {
        let obj = {
            date1: d[0]?.format('YYYY-MM-DD'),
            date2: d[1]?.format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    const onChangeProductSelect = (id) => {
        setProductIds(id)
    }
    const onChangeIntervalSelect = (id) => {
        setInterval(id)
    }
    return (
        <div className='main-div-new-style'>
            <div className='div-flex-back-name'>
                <div className='screen-back-icion'>
                    <IoIosArrowBack onClick={onCancel} size={32}
                        color='#ec671b'
                    />
                </div>
                <div className='height-35'>
                    <p className='screen-heading'>{selectedLanguage?.CreateReservation}</p>
                </div>
            </div>
            <p className='screen-heading' style={{ fontSize: 18, marginTop: 15 }}>{selectedLanguage?.label_PleaseFillTheBelowFields}</p>
            <div style={{ marginTop: 20 }}>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='name'
                                label={<p className='my-form-label'>{selectedLanguage?.ReservationName}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourReservationName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourReservationName}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='courts'
                                label={<p className='my-form-label'>{selectedLanguage?.text_Courts}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourCourts
                                    }
                                ]}
                                initialValue={[product?._id]}
                            >
                                <Select
                                    disabled
                                    // value={[product?._id]}
                                    className='form-input-new-style'
                                    mode='multiple' /* onChange={onChangeProductSelect} */ placeholder={selectedLanguage?.text_SelectCourts}>

                                    {allProduct?.map((v, i) => {
                                        return (
                                            <Option value={v?._id}>{v?.name}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='dateRange'
                                label={<p className='my-form-label'>{selectedLanguage?.DateRange}</p>}
                                hasFeedback
                            >
                                <RangePicker
                                    className='form-input-new-style'
                                    style={{ width: '100%' }}
                                    defaultValue={[moment(dateRange?.date1), moment(dateRange?.date2)]}
                                    onChange={getLatestData}
                                    allowClear={false}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='interval'
                                label={<p className='my-form-label'>{selectedLanguage?.SelectInterval}</p>}
                                hasFeedback
                            >
                                <Select
                                    className='form-input-new-style'
                                    defaultValue={interval}
                                    style={{ width: '100%' }}
                                    onChange={onChangeIntervalSelect}
                                    options={intervalOptions}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='timeRange'
                                label={<p className='my-form-label'>{selectedLanguage?.TimeSlots}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: (interval == 'none' ? false : true),
                                        message: selectedLanguage?.PleaseTimeSlots
                                    }
                                ]}
                            >
                                <TimePicker.RangePicker
                                    className='form-input-new-style'
                                    format={format}
                                    disabled={interval == 'none' ? true : false}
                                    style={{ width: '100%' }}
                                    placeholder={selectedLanguage?.SelectTimeSlot}
                                    onChange={(value, dateString) => {
                                        setTimeRange({
                                            time1: dateString[0],
                                            time2: dateString[1]
                                        })
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                            <Form.Item
                                name='maximumProducts'
                                label={<p className='my-form-label'>{selectedLanguage?.DayMaximumProduct}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourDayMaximumProduct
                                    }
                                ]}
                            >
                                <InputNumber
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourDayMaximumProduct}
                                    min={0}
                                    max={200}
                                    style={{ width: '100%' }}
                                    type='number'
                                    pattern="[0-9]*"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item>
                        <Button
                            style={{ height: '40px', width: '160px', marginTop: '10px', marginLeft: '5px' }}
                            className='addproduct-text'
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.Create}
                        </Button>
                    </Form.Item>
                </Form>
            </div >
        </div >
    )
}

AddReservation.propTypes = {
    onCancel: PropTypes.func.isRequired
}

export default AddReservation