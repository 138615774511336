import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import SendInvitation from "./SendInviteFriend";
import axios from 'axios'
import { ADMIN, GET, POST } from '../../../utils/apis'
import moment from "moment";
import { errorMessage, getUserData } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import NoData from '../../../assets/noDataImae.png'

const InviteFriend = (props) => {
    const { selectedLanguage, user, userActions, isEnglish } = props
    const gameSettings = useSelector(state => state?.userReducer?.gameSettings)
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [invitedContatct, setInvitedContatct] = useState([])
    const [refferalPoints, setRefferalPoints] = useState(null)

    useEffect(() => {
        getUserData(user?._id, userActions)
        getInviteFriend()
        getRefferalPoint()
    }, [])


    const getRefferalPoint = () => {
        axios.get(ADMIN?.GET_REFERRAL_POINTS)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setRefferalPoints(data?.data[0])
                }
                else {
                    setRefferalPoints(null)
                }
            })
            .catch((e) => {
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })

    }

 
    const getInviteFriend = () => {
        setLoading(true)
        axios.get(`${GET.GET_INVITED_FRIEND}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    setInvitedContatct(data?.data || [])
                }
                else {
                    setInvitedContatct([])
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('error')
            })
    }
    const handleOk = () => {
        setVisible(false)
    }
    const handleSuccess = () => {
        getInviteFriend()
        handleOk()
    }
    return (
        <div className='invite-friend-p-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }}
            >
                <p
                    style={{
                        marginTop: '10px',
                    }}
                    className='admin-home-title-white'>
                    {selectedLanguage?.title_MyInvitedFriend}
                </p>
                <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    <Button
                        style={{
                            padding: '0px 20px 0px 20px',
                            background: 'rgba(92, 93, 93, 1)',
                            color: 'white',
                            border: 'none',
                            height: '45px',
                            marginTop: '10px',
                            borderRadius: 6
                        }}
                        onClick={() => setVisible(true)}>{selectedLanguage?.text_InviteFriend}</Button>
                </span>

                <div style={{ marginTop: '20px' }}>
                    <div
                        className='sidebar-scroll-class-black'
                        style={{
                            overflow: 'scroll',
                            width: '98%',
                            margin: '0px auto'
                        }}
                    >
                        <div style={{
                            marginTop: '10px',
                            marginBottom: '5px',
                            // width: '100%',
                            width: '1000px',
                        }}>
                            <div style={{
                                width: '100%',
                                height: '60px',
                                borderTopRightRadius: '4px',
                                borderTopLeftRadius: '4px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '5px',
                                alignItems: 'center',
                            }}>
                                {[
                                    { title: selectedLanguage?.txt_Name },
                                    { title: selectedLanguage?.txt_Email },
                                    { title: selectedLanguage?.txt_InvitationDate },
                                    { title: selectedLanguage?.txt_Status },
                                ]?.map((v, i) => {
                                    return (
                                        <p className='tableValue'
                                            key={i + 1}
                                            style={{
                                                margin: '0px',
                                                color: 'white',
                                                fontWeight: 'bolder',
                                                fontSize: '20px',
                                                width: '250px',
                                                marginLeft: '10px',
                                                marginRight: '10px'
                                            }}
                                        >{v?.title}</p>
                                    )
                                })}
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    borderBottomRightRadius: '4px',
                                    borderBottomLeftRadius: '4px',
                                    justifyContent: 'space-between',
                                    padding: '5px',
                                    alignItems: 'center',
                                }}>
                                {invitedContatct?.length ?
                                    invitedContatct?.map((v, i) => {
                                        return (
                                            <div
                                                key={i + 1}
                                                style={{
                                                    width: '100%',
                                                    height: '50px',
                                                    borderTopRightRadius: '4px',
                                                    borderTopLeftRadius: '4px',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    padding: '5px',
                                                    alignItems: 'center',
                                                    margin: '0px',
                                                    padding: '0px'
                                                }}>
                                                <p className='tableValue table-p'>
                                                    {v?.name}
                                                </p>

                                                <p className='tableValue table-p'>
                                                    {v?.email}
                                                </p>
                                                <p className='tableValue table-p'>
                                                    {moment(v?.created).utc().format('DD-MM-YYYY') || '-----'}
                                                </p>
                                                <p className='tableValue table-p'>
                                                    {v?.isRegistered ?
                                                        <p className='accept-status' style={{ color: 'white' }}>{selectedLanguage?.text_Registered}</p>
                                                        :
                                                        <p className='pending-stats' style={{ color: 'white' }}>{selectedLanguage?.text_Pending}</p>
                                                    }
                                                </p>
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{ height: '160px', textAlign: 'center' }}>
                                        <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {visible &&
                    <SendInvitation
                        refferalPoints={refferalPoints}
                        visible={visible}
                        handleOk={handleOk}
                        handleSuccess={handleSuccess}
                        myGameSetting={gameSettings}
                        {...props} />
                }
            </div>
        </div>
    )
}

export default InviteFriend 
