import { Button, Popconfirm, Table } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { POST } from '../../../utils/apis';
import { convertNumber, convertRoundNumber, convertTitle, errorMessage, successMessage, validateTime } from '../../../utils/helpers';
import NoData from '../../../assets/noDataImae.png'

const SentMatchRequest = (props) => {
  const { selectedLanguage, user, isEnglish, location, getUser, sentMatches } = props
  const [deleteLoading, setDeleteLoading] = useState({})
  const deleteMatchRequest = (data) => {

    console.log('data', data)
    setDeleteLoading({ [data?._id]: true })
    let obj = {
      _id: data?._id,
      price: data?.courtId?.rentPrice,
      reservation: data?.reservation,
      shiftId: data?.shiftId,
      dayId: data?.dayId,
      userId: user?._id,
      senderId: data?.senderId?._id,
      partnerId: data?.partner?._id,
      clubId: data?.club?._id,
      playerNumbers: data?.playerNumbers,
      price: data?.courtId?.rentPrice,
      preBooked: data?.preBooked
    } 
    axios.post(POST?.DELETE_MATCH_REQUEST, obj)
      .then((res) => {
        const { data } = res
        setDeleteLoading({})
        if (data?.success) {
          successMessage(isEnglish ? data?.message : data?.messageNl)
          getUser()
        }
        else {
          errorMessage(isEnglish ? data?.message : data?.messageNl)
        }
      })
      .catch((e) => {
        setDeleteLoading({})
        errorMessage(isEnglish ? e.message : e.messageNl)
      })
  }

  const columns = [
    {
      title: 'Club',
      dataIndex: ['club', 'name'],
      align: 'center'
    },
    {
      title: selectedLanguage?.title_GameType,
      dataIndex: 'gameType',
      align: 'center',
      render: (e) => convertTitle(e)
    },
    {
      title: 'Partner',
      // dataIndex: ['partner'],
      align: 'center',
      render: (e) => e?.playerNumbers === 2 ? '-' : e?.partner?._id !== user?._id ? convertTitle(e?.partner?.name) : convertTitle(e?.senderId?.name)
    },
    {
      title: 'Couple',
      dataIndex: ['couple', 'coupleName'],
      align: 'center'
    },
    {
      title: selectedLanguage?.title_DateTime,
      align: 'center',
      render: (e) => {
        return (
          <p className='table_p' >
            {e?.matchDate || '-----'} | {validateTime(e?.matchTime) || '-----'}
          </p>
        )
      }
    },
    {
      title: 'Players',
      dataIndex: 'playerNumbers',
      align: 'center'
    },
    {
      title: 'Opponent Couple',
      dataIndex: ['opponentCouple', 'coupleName'],
      align: 'center',
      width: 250
    },
    {
      title: 'Opponents',
      dataIndex: ['opponents'],
      align: 'center',
      render: (e) => {
        return (
          <p className='table_p' >{e?.[0]?.name} , {e?.[1]?.name}</p>
        )
      }
    },
    {
      title: selectedLanguage?.title_Message,
      dataIndex: 'message',
      align: 'center',
      render: (e) => {
        return (
          <p className='table_p' > {e || '----'}</p>
        )
      }
    },
    {
      title: 'Status',
      align: 'center',
      render: (e) => {
        return (
          e?.isAccepted ?
            <p className='accept-status'>{selectedLanguage?.sts_Accepted}</p>
            : e?.isRejected ?
              <p className='reject-status'>{selectedLanguage?.sts_rejected}</p>
              :
              <p className='pending-stats'>{selectedLanguage?.sts_pending}</p>
          /* }
        </> */
        )
      }
    },
    {
      title: selectedLanguage?.title_Action,
      align: 'center',
      render: (e) => {
        return (<>
          {user?._id === e?.senderId?._id &&
            <Popconfirm
              disabled={e?.isAccepted || e?.isRejected}
              title={`${selectedLanguage?.text_AreyouwanttodeleteMatchrequestthatyousendto} ${e?.opponentCouple?.coupleName || e?.opponents?.[0]?.name}?`}
              okText={selectedLanguage?.textDelete}
              cancelText={selectedLanguage?.textCancel}
              okButtonProps={{
                type: 'primary',
                className: 'form-button'
              }}
              cancelButtonProps={{ style: { borderRadius: 4 } }}
              onConfirm={() => {
                deleteMatchRequest(e)
              }}
            >
              <Button
                disabled={e?.isAccepted || e?.isRejected}
                type='primary'
                loading={deleteLoading[e?._id]}
                danger
              >{selectedLanguage?.buttonText_Delete}</Button>
            </Popconfirm>}
        </>
        )
      }
    }
  ]

  return (
    <div style={{ margin: '20px', paddingBottom: 10, borderBottom: `1px dashed white` }}>
      <div>
        <p className='admin-home-title-white'>
          {selectedLanguage?.button_SentMatchRequest}
        </p>
        <Table
          dataSource={sentMatches}
          columns={columns}
          className='specific-css-table'
          pagination={false}
          scroll={{ x: 2000, y: 200 }}
        />
      </div>
    </div>
  )
}

export default SentMatchRequest