import React, { useEffect, useState } from 'react';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { Card, Input, Spin } from 'antd';
import axios from 'axios';
import { GET, POST } from '../../../utils/apis';
import { calculateReviewAverage, convertTitle, errorMessage, successMessage, successNotification } from '../../../utils/helpers';
import FilterModal from '../Filter/FilterModal';
import BuyProduct from '../BuyCourt/BuyCourt';
import { loginUser } from '../../../Redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import CourtReview from '../PlayerReview/CourtReview';
import moment from 'moment';
import { TbAdjustmentsHorizontal } from "react-icons/tb"
import { AiFillStar } from "react-icons/ai"
import NoCards from "../../../assets/NoCards.png"
const PlayerHome = (props) => {
    const { user, filter, history, getUser, location, language, selectedLanguage } = props
    const isEnglish = language?.language === 'english'
    const userId = new URLSearchParams(location.search).get('userId') || null
    const [loading, setLoading] = useState(false)
    const [allProduct, setAllProduct] = useState([])
    const [filteredProduct, setFilteredProduct] = useState([])
    const [filterOpen, setFilterOpen] = useState(false)
    const [filterValue, setFilterValue] = useState(filter)
    const [buyProduct, setBuyProduct] = useState({})
    const [favouriteSpin, setFavouriteSpin] = useState({})
    const [reviewModal, setReviewModal] = useState({})
    const [ProgressLoading, setProgressLoading] = useState(false)
    const [userData, setUserData] = useState(user)
    const [recommendedCity, setRecommendedCity] = useState(user?.city)
    const [shopData, setShopData] = useState({})
    const cities = useSelector(state => state.generalReducer?.cities)
    const dispatch = useDispatch()
    const { Search } = Input;

    const checkDateofReservation = (settings) => {
        let lastSetting = settings[settings?.length - 1]
        let date = moment(lastSetting?.date).utcOffset(0, true)
        return !date.isBefore(moment().add(1, 'days'), 'day')
    }

    const gotoReview = (v) => {
        setProgressLoading(true)
        setReviewModal(v)
        setTimeout(() => {
            setProgressLoading(false)
        }, 2000);
    }
    const closeReviewModal = (v) => {
        setReviewModal({})
    }
    const calcSoldProducts = (arr) => {
        return arr?.reduce((sum, { soldProduct }) => sum + soldProduct, 0)
    }

    const getProducts = () => {
        let obj = {
            provincie: user?.provincie,
            productCode: user?.productCode,
            city: filterValue?.city,
            timeRange: filterValue?.timeRange,
            productType: filterValue?.productType,
        }
        axios.post(POST?.GET_PRODUCT_FOR_GUEST, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)

                if (filterValue?.favourites) {
                    setAllProduct(data?.data?.filter((v) => v?.reservation?.created && userData?.favourites?.includes(v?._id)) || [])
                    setFilteredProduct(data?.data?.filter((v) => v?.reservation?.created && checkDateofReservation(v?.reservation?.settings) && userData?.favourites?.includes(v?._id)) || [])
                } else {
                    setAllProduct(data?.data?.filter((v) => v?.reservation?.created))
                    setFilteredProduct(data?.data?.filter((v) => v?.reservation?.created && checkDateofReservation(v?.reservation?.settings)))
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
                errorMessage()
            })
    }

    const getCourt = () => {
        setLoading(true)
        axios.get(GET?.GET_COURT_FOR_PLAYER)
            .then((res) => {
                const { data } = res
                setLoading(false)

                if (data?.success) {
                    setAllProduct(data?.data || [])
                    setFilteredProduct(data?.data || [])
                    console.log(data?.data, 'data?.data')
                } else {
                    setAllProduct([])
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
                errorMessage()
            })
    }



    const changeFav = (_id, add) => {
        setFavouriteSpin({ [_id]: true })
        let tempData = { ...userData }
        let obj = {
            favourites: [...userData?.favourites, _id],
            _id: user?._id
        }
        if (!add) {
            obj.favourites = userData?.favourites?.filter((v) => v !== _id)
        }
        tempData.favourites = obj.favourites
        axios.post(POST?.ADD_FAVOURITE_COURT, obj)
            .then((res) => {
                const { data } = res
                setFavouriteSpin({})
                if (data?.success) {
                    setUserData(tempData)
                    dispatch(loginUser(tempData))
                    getCourt()
                    successMessage(add ? selectedLanguage?.SuccessfullyAddedToFavourites : selectedLanguage?.Successfullyremovedfromfavourites)
                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setFavouriteSpin({})
                console.log('e', e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    useEffect(() => {
        getCourt()
    }, [])

    return (
        <div className='Player-Home-court-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }}
            >
                {!buyProduct?._id ?
                    <div>

                        <FilterModal cities={cities} setFilterValue={setFilterValue} getProducts={getCourt} filterOpen={filterOpen} onCancel={() => setFilterOpen(false)} setFilterOpen={setFilterOpen} {...props} />

                        <div className='laptopDiv' style={{ width: `clac(100% )`, minHeight: '350px', position: 'relative' }}>
                            <div
                                style={{
                                    top: '10%',
                                    position: 'absolute',
                                    marginTop: '10%',
                                    marginLeft: '40px'
                                }}
                            >
                                <h1 className='guestHomeInfoHeading' style={{ fontSize: '3.5vw' }}>Experiences you’ll never forget</h1>
                                <div className='div-flex-back-name'>
                                    <div>
                                        <Search
                                            className='new-input-search-guest'
                                            placeholder={selectedLanguage?.New_SearchCourtName}
                                            onSearch={(e) => setRecommendedCity(e)}
                                            enterButton />
                                    </div>
                                    <div className='new-filter-btn'
                                        type={(filter?.city?.length || filter?.timeRange?.length || filter?.productType?.length || filter?.favourites)
                                            ? 'primary' : 'default'} onClick={() => setFilterOpen(true)}
                                    >
                                        <p className='new-filter-btn-text'>
                                            {'Filter'}</p>
                                        <TbAdjustmentsHorizontal size={20}
                                            color={'rgba(0, 0, 0, 0.4)'}
                                            style={{ marginRight: '5px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='mobileDiv'>
                            <div style={{ marginTop: '20px', width: '98%', margin: '10px auto' }}>
                                <div className='div-flex-back-name'>
                                    <div>
                                        <Search
                                            className='new-input-search-guest'
                                            placeholder={selectedLanguage?.New_SearchCourtName}
                                            onSearch={(e) => setRecommendedCity(e)}
                                            enterButton />
                                    </div>
                                    <div
                                        style={{ width: '50px', justifyContent: 'center' }}
                                        className='new-filter-btn'
                                        type={(filter?.city?.length || filter?.timeRange?.length || filter?.productType?.length || filter?.favourites)
                                            ? 'primary' : 'default'} onClick={() => setFilterOpen(true)}
                                    >
                                        <TbAdjustmentsHorizontal size={20}
                                            color={'rgba(0, 0, 0, 0.4)'}
                                            style={{ marginRight: '5px', marginLeft: '4px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='cards-main-div-guest-all-crds' >
                            <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                                <h1 className='GuestCardsTitle'>{selectedLanguage?.text_Courts}</h1>
                                <div
                                    className='sidebar-scroll_class'
                                    style={{
                                        marginTop: 8,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        overflowX: 'scroll'
                                    }}>
                                    {loading ?
                                        [1, 2, 3, 4].map((v, i) => {
                                            return (<Card
                                                hoverable
                                                loading={loading}
                                                bordered={false}
                                                className='loading-card-style'
                                                style={{
                                                    height: 285,
                                                    width: '290px',
                                                    minWidth: '290px',
                                                    background: '#00000057',
                                                    marginRight: '10px'
                                                }}
                                            />)
                                        })
                                        :
                                        filteredProduct?.length ?
                                            filteredProduct.map((v, i) => {
                                                return (
                                                    <div
                                                        style={{
                                                            height: 285,
                                                            width: '290px',
                                                            minWidth: '290px',
                                                            borderRadius: '4px',
                                                            margin: '4px',
                                                            marginRight: '10px',
                                                            boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                            cursor: 'pointer',
                                                            background: 'rgba(0, 0, 0, 0.34)'
                                                        }}>
                                                        <div style={{ textAlign: 'center', margin: '8px', position: 'relative' }}                                                >
                                                            <img onClick={() => setBuyProduct(v)} src={v?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                                            <p style={{ position: 'absolute', top: '10px', left: '10px', background: 'white', height: '20px', width: '20px', borderRadius: '100px' }}>
                                                                {favouriteSpin[v?._id] ?
                                                                    <Spin /> :
                                                                    !userData?.favourites?.includes(v?._id) ?
                                                                        <HeartOutlined onClick={() => changeFav(v?._id, true)} style={{ color: 'red' }} />
                                                                        : <HeartFilled onClick={() => changeFav(v?._id, false)} style={{ color: 'red' }} />
                                                                }
                                                            </p>
                                                        </div>
                                                        <div style={{ textAlign: 'center', margin: '8px' }}>
                                                            <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                <div onClick={() => setBuyProduct(v)} >
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 18,
                                                                            color: 'white',
                                                                        }}>{v?.name || '------'}</p>
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: 'white',
                                                                            width: '100px',
                                                                        }}>{v?.userId?.city || '------'}</p>

                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'flex-end',
                                                                        height: '100px',
                                                                        marginTop: '-8px',
                                                                    }}
                                                                    onClick={() => { gotoReview(v) }} >
                                                                    <button className='guest-card-review-box'>
                                                                        {v?.review?.length ?
                                                                            <AiFillStar size={15} color='white' />
                                                                            : null}
                                                                        {!v?.isReviewHide ? v?.review?.length ? calculateReviewAverage(v?.review) : 0 : 0}
                                                                    </button>
                                                                    <p className='card_desc1' style={{ textAlign: 'end', color: 'white' }}>
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                calculateReviewAverage(v?.review) < 4.5 ?
                                                                                    selectedLanguage?.Bad + ',' :
                                                                                    calculateReviewAverage(v?.review) < 5.9 ?
                                                                                        selectedLanguage?.Poor + ',' :
                                                                                        calculateReviewAverage(v?.review) < 6.9 ?
                                                                                            selectedLanguage?.Adeqate + ',' :
                                                                                            calculateReviewAverage(v?.review) < 8.9 ?
                                                                                                selectedLanguage?.Good + ',' :
                                                                                                selectedLanguage?.Excellent + ',' : null : null}
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                v?.review?.length !== 1 ?
                                                                                    v?.review?.length > 999 ?
                                                                                        ' ' +
                                                                                        `${v?.review?.length?.slice}`(0, 1) +
                                                                                        '.' +
                                                                                        `${v?.review?.length}`?.slice(1, 2) +
                                                                                        'k' + ' ' + selectedLanguage?.Reviews :
                                                                                        ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                                    ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                                                selectedLanguage?.NoReviewesYet :
                                                                            selectedLanguage?.NoReviewesYet}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <div onClick={() => setBuyProduct(v)}>
                                                                <p style={{
                                                                    color: 'white',
                                                                    textAlign: 'start',
                                                                    lineHeight: 1.4,
                                                                    height: '40px',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    lineBreak: 'anywhere',
                                                                    marginBottom: 0
                                                                }}
                                                                    className="card_sold"
                                                                >
                                                                    {v?.description || '-------------------------------'}
                                                                </p>
                                                            </div>
                                                            <span onClick={() => setBuyProduct(v)}>
                                                                <div className='card_sold_price'>
                                                                    <p className='card_sold' style={{ color: 'white' }}>{selectedLanguage?.Sold}:{calcSoldProducts(v?.reservation?.settings || [])}</p>
                                                                    <p className='card_sold' style={{ color: 'white' }}>€{' ' + v?.price}</p>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{ height: '280px', width: '100%' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <img
                                                        style={{
                                                            height: '200px',
                                                            width: '200px',
                                                            padding: '20px',
                                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                                        }} src={NoCards} />
                                                </div>
                                            </div>
                                    }
                                </div >
                            </div >
                        </div >
                    </div >
                    :
                    <div className='flex-column menu-main'>
                        <BuyProduct calcSoldProducts={calcSoldProducts} product={buyProduct} getProducts={getCourt} onCancel={() => setBuyProduct({})} {...props} />
                    </div>
                }
                <CourtReview ProgressLoading={ProgressLoading} reviewModal={reviewModal} closeReviewModal={closeReviewModal}  {...props} />
            </div>
        </div>
    )
}

export default PlayerHome