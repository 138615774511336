import { Button, Form, Input } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { useDispatch } from 'react-redux'
import { loginUser } from '../../../Redux/actions/authActions'
import { POST } from '../../../utils/apis'
import { errorMessage, successNotification, warningMessage } from '../../../utils/helpers'

const AddPrivateCode = (props) => {
    const { onCancel, user, getProducts, setProductCode, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const isEnglish = language?.language === 'english'

    const onFinish = (values) => {

        if (user?.productCode?.includes(values?.secretCode)) {
            return warningMessage(selectedLanguage?.Thiscodeisalreadyinserted)
        }

        setLoading(true)

        let obj = {
            productCode: [...user?.productCode, values?.secretCode],
            _id: user?._id
        }
        axios.post(POST?.ADD_SECRET_CODE, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                setLoading(false)
                if (data?.success) {
                    setProductCode(obj.productCode)
                    data.data.productCode = obj?.productCode
                    dispatch(loginUser(data?.data))
                    successNotification(isEnglish ? data?.message : data?.messageNl)
                    return setTimeout(() => {
                        setProductCode(obj.productCode)
                        getProducts()
                        onCancel()
                    }, 300)
                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    return (
        <div className='main-div-new-style'>
            <div className='div-flex-back-name'>
                <div className='screen-back-icion'>
                    <IoIosArrowBack onClick={onCancel} size={32} color='#ec671b' />
                </div>
                <div className='height-35'>
                    <p className='screen-heading'>{selectedLanguage?.SecretCode}</p>
                </div>
            </div>
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='secretCode'
                                label={<p className='my-form-label'>{selectedLanguage?.AddSecretCode}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourSecretCode
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder='###-###-###'
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item>
                        <Button
                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.Add}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
export default AddPrivateCode