import { FileOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, InputNumber, Select, Upload } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios'
import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import voucher_codes from 'voucher-code-generator'
import { POST } from '../../utils/apis'
import { gameTypes } from '../../utils/constants'
import { convertTitle, errorMessage, getUserData, successNotification, warningMessage } from '../../utils/helpers'

const { Option } = Select

const AddProducts = (props) => {
    const { onCancel, edit, user, getProducts, language, selectedLanguage, userActions } = props
    const [loading, setLoading] = useState(false)
    const [privateOne, setPrivateOne] = useState(edit?.privateOne)
    const [fileList, setFileList] = useState([])
    const [otherFileList, setOtherFileList] = useState([])
    const [showMaxDiscount, setShowMaxDiscount] = useState(edit?.joinDiscount || false)
    const [form] = Form.useForm()
    console.log('edit', edit)
    const onFinish = (values) => {
        console.log('values', values)
        // return true
        let originalFileList = otherFileList?.map((vv, ii) => {
            return vv?.originFileObj
        })
        values.file = fileList
        values.otherFiles = originalFileList
        edit?._id && (values._id = edit?._id)
        values.userId = user?._id

        if (!values._id && !values?.file?.length) {
            return warningMessage(selectedLanguage?.PleaseUploadImage)
        }
        if (!values._id && !values.otherFiles?.length) {
            return warningMessage(selectedLanguage?.PleaseUploadImage)
        }
        if (!values._id && values.otherFiles?.length < 3) {
            return warningMessage(selectedLanguage?.Product_PleaseUploadMinimumThreeOtherImages)
        }
        let formData = new FormData()

        if (values?.file?.length) {
            values.file[0].oldfile = true
            let obj = values.file[0]
            formData.append('files', obj)
        }

        if (values?.otherFiles?.length) {
            for (let fileNew of values?.otherFiles) {
                formData.append('newFiles', fileNew)
            }
        }

        if (values?._id) {
            formData.append('_id', values?._id)
        }
        if (values?.privateOne) {
            formData.append('productCode', values?.productCode)
        }
        if (values?.privateAll && !user?.productCode[0]) {
            return warningMessage(selectedLanguage?.errorMessage_PleaseGenerateyourprivateCodeFirsts)
        }
        if (values?.privateAll) {
            formData.append('productCode', user?.productCode[0])
        }
        values?.gameType?.forEach((v) => {
            formData.append('gameType[]', v)
        })
        formData.append('memberDiscount', values.memberDiscount || false)
        formData.append('acceptNonMember', values.acceptNonMember || false)
        formData.append('privateAll', values.privateAll || false)
        formData.append('privateOne', values.privateOne || false)
        formData.append('userId', values.userId)
        formData.append('name', values?.name)
        formData.append('description', values?.description)
        formData.append('promote', values.promote || false)
        // if (!user?.subscription?.subscriptionTypes?.includes('reservation')) {
        //     return errorMessage('Buy Subscription to use this Feature-->')
        // }
        formData.append('cancelHours', values.cancelHours)
        formData.append('price', values?.price)
        formData.append('rentPrice', values?.rentPrice)
        if(!edit?._id && values.joinDiscount){
            formData.append('joinDiscount', values.joinDiscount)
        }
        formData.append('maxDiscount', values?.maxDiscount || 0)

        setLoading(true)

        let ROUTE = !edit?._id ? POST?.CREATE_PRODUCT : POST?.EDIT_PRODUCT
        axios.post(ROUTE, formData)
            .then((res) => {
                const { data } = res
                console.log('res', res)
                setLoading(false)
                if (data.success) {
                    successNotification(data?.message)
                    // getProducts()
                    getUserData(user?._id, userActions)
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                } else {
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage()
            })
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const normOtherFile = (e) => {
        setOtherFileList([])
        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }
        e?.fileList?.length ? setOtherFileList(e?.fileList || []) : setOtherFileList([])
        return e && e.fileList
    }

    const generateCoupon = () => {
        let GenerateCoupon = voucher_codes.generate({
            count: 1,
            pattern: '###-###-###',
        })
        form.setFieldValue('productCode', GenerateCoupon[0])
    }

    return (
        <div className='main-div-new-style'>
            <div className='div-flex-back-name'>
                <div className='screen-back-icion'>
                    <IoIosArrowBack onClick={onCancel} size={32} color='#ec671b' />
                </div>
                <div className='height-35'>
                    <p className='screen-heading'>{edit?._id ? selectedLanguage?.text_UpdateCourt : selectedLanguage?.text_CreateCourt}</p>
                </div>
            </div>
            <p className='screen-heading' style={{ fontSize: '18px', marginTop: '15px' }}>{selectedLanguage?.label_PleaseFillTheBelowFields}</p>
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='name'
                                label={<p className='my-form-label'>{selectedLanguage?.text_CourtName}</p>}
                                hasFeedback
                                initialValue={edit?.name}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.text_PleaseInputCourtName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.text_InputCourtName}
                                    max={14}
                                    min={0}
                                    maxLength={14}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='gameType'
                                label={<p className='my-form-label'>{selectedLanguage?.title_GameType}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_selectGameType
                                    }
                                ]}
                                initialValue={edit?.gameType}
                            >
                                <Select
                                    mode='multiple'
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_selectGameType}>
                                    {gameTypes?.map((v, i) => {
                                        return (
                                            <Option value={v} key={i}>{convertTitle(v)}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-input-single-input'>
                        <Form.Item
                            name='description'
                            label={<p className='my-form-label'>{selectedLanguage?.text_CourtDescription}</p>}
                            hasFeedback
                            initialValue={edit?.description}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputDescription
                                },
                            ]}
                        >
                            <TextArea
                                style={{ paddingTop: '15px' }}
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourDescriptionHere}
                            />
                        </Form.Item>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                            <Form.Item
                                name='price'
                                label={<p className='my-form-label'>{selectedLanguage?.Competition_Price}</p>}
                                hasFeedback
                                initialValue={edit?.price === 0 ? 0 : edit?.price || null}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourPrice
                                    }
                                ]}
                            >
                                <InputNumber
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputyourPriceHere}
                                    style={{ width: '100%' }}
                                    min={1}
                                    type='number'
                                    pattern='[0-9]*'
                                    step={0.01}
                                    precision={2}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                            <Form.Item
                                name='rentPrice'
                                label={<p className='my-form-label'>{'Rent Price'}</p>}
                                hasFeedback
                                initialValue={edit?.rentPrice === 0 ? 0 : edit?.rentPrice || null}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Input Rent Price'
                                    }
                                ]}
                            >
                                <InputNumber
                                    className='form-input-new-style'
                                    placeholder={'Input Rent Price'}
                                    style={{ width: '100%' }}
                                    min={1}
                                    type='number'
                                    pattern='[0-9]*'
                                    step={0.01}
                                    precision={2}
                                />
                            </Form.Item>
                        </div>

                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='file'
                                label={<p className='my-form-label'>{selectedLanguage?.File}</p>}
                            >
                                <Upload
                                    name='file'
                                    multiple={false}
                                    beforeUpload={() => false}
                                    accept='image/png, image/jpeg'
                                    onChange={normFile}
                                    fileList={fileList}
                                >
                                    <Button disabled={edit?._id} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                </Upload>
                                {edit?.fileName && !fileList?.length ? <span><FileOutlined />&nbsp;{edit?.fileName}</span> : null}
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='otherFiles'
                                label={<p className='my-form-label'>{selectedLanguage?.Product_OtherFiles}</p>}
                            >
                                <Upload
                                    name='otherFiles'
                                    multiple={true}
                                    beforeUpload={() => false}
                                    maxCount={10}
                                    accept='image/png, image/jpeg'
                                    onChange={normOtherFile}
                                    fileList={otherFileList}
                                    showUploadList={true}
                                >
                                    <Button disabled={edit?._id} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                </Upload>
                                {edit?.fileName && !otherFileList?.length ?
                                    edit?.otherImages?.map((v, i) => {
                                        let imgURl = v
                                        let splitImgURl = imgURl.split('-')
                                        let lastWord = splitImgURl.slice(-1)
                                        return (
                                            <>
                                                <span><FileOutlined />&nbsp;{lastWord?.length ? lastWord[0] : v}</span>
                                                <br />
                                            </>
                                        )
                                    })
                                    : null}
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input form-input-new-style'
                            style={{ marginBottom: '10px', paddingTop: '12px', paddingLeft: '10px', backgroundColor: 'white' }}
                        >
                            <Form.Item
                                name='memberDiscount'
                                valuePropName='checked'
                                initialValue={edit?.memberDiscount || false}
                            >
                                <Checkbox>
                                    {'Member Discount'}
                                </Checkbox>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input form-input-new-style'
                            style={{ marginBottom: '10px', paddingTop: '12px', paddingLeft: '10px', backgroundColor: 'white' }}
                        >
                            <Form.Item
                                name='acceptNonMember'
                                valuePropName='checked'
                                initialValue={edit?.acceptNonMember || false}
                            >
                                <Checkbox>
                                    {'Accept non-member'}
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                            <Form.Item
                                name='cancelHours'
                                label={<p className='my-form-label'>{selectedLanguage?.label_CancelBefore}</p>}
                                hasFeedback
                                initialValue={edit?.cancelHours === 0 ? 0 : (edit?.cancelHours || 24)}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.requiredMessage_PleaseEnterCancellationHours
                                    }
                                ]}
                            >
                                <InputNumber
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeholder_cancellationHours}
                                    defaultValue={edit?.cancelHours === 0 ? 0 : (edit?.cancelHours || 24)}
                                    style={{ width: '100%' }}
                                    step={1}
                                    min={24}
                                    precision={0}
                                    type='number'
                                    pattern='[0-9]*'
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input form-input-new-style'
                            style={{ marginBottom: '10px', paddingTop: '12px', paddingLeft: '10px', backgroundColor: 'white' }}
                        >
                            <Form.Item
                                name='privateAll'
                                valuePropName='checked'
                                initialValue={edit?.privateAll || false}
                                onChange={(e) => { form.setFieldValue('privateOne', false); setPrivateOne(false) }}
                            >
                                <Checkbox>
                                    {selectedLanguage?.PrivateAll}
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </div>

                    {/* <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input form-input-new-style'
                            style={{ marginBottom: '10px', paddingTop: '12px', paddingLeft: '10px', backgroundColor: 'white' }}
                        >
                            <Form.Item
                                name='joinDiscount'
                                valuePropName='checked'
                                initialValue={edit?.joinDiscount || false}
                                onChange={(e) => { setShowMaxDiscount(e?.target?.checked) }}
                            >
                                <Checkbox>
                                    {selectedLanguage?.JoinedDiscount}
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </div> */}

                    {showMaxDiscount ?
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='maxDiscount'
                                    label={<p className='my-form-label'>{selectedLanguage?.MaximumDiscount}</p>}
                                    hasFeedback
                                    initialValue={edit?.maxDiscount || 0.5}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.PleaseInputMaximumDiscount
                                        }
                                    ]}
                                >
                                    <InputNumber
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.InputYourMaximumDiscountHere}
                                        defaultValue={edit?.maxDiscount || 0.5}
                                        min={0.5}
                                        style={{ width: '100%' }}
                                        step={0.5}
                                        precision={2}
                                        type='number'
                                        pattern='[0-9]*'
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        : null
                    }

                    {user?.subscription?.subscriptionTypes?.includes("promotion") ?
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input form-input-new-style'
                                style={{ marginBottom: '10px', paddingTop: '12px', paddingLeft: '10px', backgroundColor: 'white' }}
                            >
                                <Form.Item
                                    name='promote'
                                    valuePropName='checked'
                                    initialValue={edit?.promote || false}
                                    onChange={(e) => { form.setFieldValue('privateOne', false); setPrivateOne(false) }}
                                >
                                    <Checkbox>
                                        {selectedLanguage?.label_promote}
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </div>
                        : null}


                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input form-input-new-style'
                            style={{ marginBottom: '10px', paddingTop: '12px', paddingLeft: '10px', backgroundColor: 'white' }}
                        >
                            <Form.Item
                                name='privateOne'
                                valuePropName='checked'
                                initialValue={edit?.privateOne || false}
                                onChange={(e) => { form.setFieldValue('privateAll', false); setPrivateOne(e?.target?.checked) }}
                            >
                                <Checkbox>
                                    {selectedLanguage?.PrivateOne}
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                    {privateOne ?
                        <>
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input '>
                                    <Form.Item
                                        name='productCode'
                                        label={<p className='my-form-label'>{selectedLanguage?.PrivateCode}</p>}
                                        initialValue={edit?.productCode}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.PleaseGenerateYourPrivateCode
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled
                                            className='form-input-new-style'
                                            placeholder='###-###-###'
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <Button
                                style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                className='addproduct-text'
                                onClick={generateCoupon}
                            >
                                {'Generate'}
                            </Button>
                        </>
                        :
                        null
                    }
                    <Form.Item>
                        <Button
                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'
                            htmlType='submit'
                            loading={loading}
                        >
                            {edit?._id ? selectedLanguage?.Update : selectedLanguage?.Create}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div >
    )
}

export default AddProducts