import { Button, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import AddAdminCoupons from "./AddCoupons";
import { IoIosArrowBack } from 'react-icons/io'
import axios from "axios";
import { ADMIN } from "../../../utils/apis";
import { createGiftCard, errorMessage } from "../../../utils/helpers";


const AdminCoupons = (props) => {
    const { selectedLanguage, isEnglish, user } = props
    const [showForm, setShowform] = useState(false)
    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState({})
    const [allcoupons, setAllcoupons] = useState([])
    
    useEffect(() => {
        getCoupousData()
    }, [])

    const getCoupousData = () => {
        setLoading(true)
        axios.get(`${ADMIN?.GET_TOURNAMENT_COUPOUN}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    console.log(data?.data)
                    let tableData = Object.entries(data?.data)
                    tableData = tableData?.map((v) => {
                        return {
                            id: v[0],
                            value: v[1]
                        }
                    })
                    setLoading(false)
                    setAllcoupons([...tableData])
                }
                else {
                    console.log('else is work')
                    setLoading(false)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    let columns = [
        {
            title: selectedLanguage?.text_Description,
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            render: (e) => {
                return (
                    <p>{e ? e : '----'}</p>
                )
            }

        },
        {
            title: selectedLanguage?.title_Action,
            key: 'actions',
            render: (e) => {
                const spinFunc = () => {
                    let newSpin = { ...spin }
                    newSpin[e?.id] = false
                    setSpin(newSpin)
                }
                return (
                    <>
                        {
                            spin?.[e?.id] ? <Spin /> :
                                <p
                                    onClick={() =>
                                    (
                                        setSpin({ [e?.id]: true, ...spin }),
                                        createGiftCard(e?.value, spinFunc))
                                    }

                                    style={{
                                        cursor: 'pointer',
                                        color: "#23afe5",
                                        width: '110px'
                                    }}>{selectedLanguage?.text_ShowTotalPdfs}</p >
                        }
                    </>
                )
            }

        }
    ]


    return (
        <div style={{ margin: '20px' }}>

            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                {showForm &&
                    <IoIosArrowBack
                                            onClick={() => {
                            setShowform(false)
                            getCoupousData()
                        }}
                        style={{ marginRight: '5px',color:'white' }}
                        size={28} />}
                <p className='admin-home-title'>
                    {showForm ? selectedLanguage?.coupon_AddNewCoupons : selectedLanguage?.coupons_Coupons}
                </p>
            </div>
            {!showForm && <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                <Button className='addproduct-text' onClick={() => setShowform(true)}>{selectedLanguage?.coupon_AddNewCoupons}</Button>
            </span>}
            <div style={{ marginTop: '20px' }}>
                {!showForm ? <Table
                    dataSource={allcoupons}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                    scroll={{ x: 550 }}
                />
                    :
                    <AddAdminCoupons {...props} />}
            </div>
        </div>
    )
}
export default AdminCoupons