import HomeOutlined from '@ant-design/icons/HomeOutlined'
import allPaths from '../Config/paths'
import { CgProfile } from 'react-icons/cg'
import { FiSettings } from 'react-icons/fi'
import { TbDeviceGamepad2 } from 'react-icons/tb'
import { RiCouponLine, RiGamepadLine } from 'react-icons/ri'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { GiGameConsole, GiRank2, GiBabyfootPlayers, GiJugglingClubs } from 'react-icons/gi'
import { FaQuestionCircle } from 'react-icons/fa'
import { BiSolidUserDetail } from 'react-icons/bi'
import { TbFileInvoice, TbBrandCashapp } from 'react-icons/tb'
import { GrGraphQl } from 'react-icons/gr'
import { MdRateReview } from 'react-icons/md'
import { ProjectOutlined } from '@ant-design/icons'


const bgColor = '#0adc00'
const deployedURL = 'https://www.padeldater.com'
const drawerRoutesAdmin = [
    {
        title: 'Home',
        titleNl: 'Home',
        route: allPaths.HOME,
        icon: <HomeOutlined />
    },
    {
        title: 'Clubs',
        titleNl: 'Clubs',
        isSubMenu: true,
        icon: <GiJugglingClubs />,
        key: 3,
        children: [
            {
                title: 'Admin Clubs',
                titleNl: 'Admin Clubs',
                route: allPaths?.ADMIN_CLUBS,
            },
            {
                title: 'Registered Clubs',
                titleNl: 'Registered Clubs',
                route: allPaths?.REGISTERED_CLUBS,
            },
        ]
    },
    {
        title: 'Players',
        titleNl: 'Players',
        route: allPaths.ADMIN_PLAYER,
        icon: <BiSolidUserDetail />
    },
    {
        title: 'Review',
        titleNl: 'Review',
        route: allPaths.ADMIN_COURTS_REVIEW,
        icon: <MdRateReview />
    },
    {
        title: 'Profile',
        titleNl: 'Profiel',
        route: allPaths.ADMIN_PROFILE,
        icon: <CgProfile />
    },
    {
        title: 'Settings',
        titleNl: 'Instellingen',
        isSubMenu: true,
        icon: <FiSettings />,
        key: 1,
        children: [
            {
                title: 'Courts',
                titleNl: 'Courts',
                route: allPaths?.ADMIN_COURTS,
            },
            // {
            //     title: 'Promoter',
            //     titleNl: 'Promoter',
            //     route: allPaths?.ADMIN_PROMOTER_SETTINGS,
            // },
            {
                title: 'Points setting',
                titleNl: 'Points setting',
                route: allPaths?.ADMIN_POINT_SETTINGS,
            },
            {
                title: 'Create FAQ',
                titleNl: 'Maak FAQ',
                route: allPaths?.ADMIN_ADD_FAQ,
            },
        ]
    },
    {
        title: 'Competition',
        titleNl: 'Competition',
        isSubMenu: true,
        icon: <TbDeviceGamepad2 />,
        key: 2,
        children: [
            {
                title: 'Create Competition',
                titleNl: 'Maak Competition',
                route: allPaths?.ADMIN_CREATE_COMPETITION,
            },
            {
                title: 'Competition select',
                titleNl: 'Competition select',
                route: allPaths?.ADMIN_COMPETITION_SELECT,
            },
            {
                title: 'Subscription request',
                titleNl: 'Subscription request',
                route: allPaths?.ADMIN_COMPETITION_REQUEST,
            },
            {
                title: 'Competition Matches',
                titleNl: 'Competition Matches',
                route: allPaths?.ADMIN_COMPETITION_Matches,
            },
        ]
    },
    {
        title: 'Coupons',
        titleNl: 'Coupons',
        route: allPaths.ADMIN_COUPONS,
        icon: <RiCouponLine />
    },
    // {
    //     title: 'Players',
    //     titleNl: 'Players',
    //     route: allPaths.ADMIN_PLAYER_DETAIL,
    //     icon: <GiBabyfootPlayers />
    // },
    // {
    //     title: 'Claims & Argues',
    //     titleNl: 'Claims',
    //     route: allPaths.ADMIN_CLAIM_ARGUES,
    //     icon: <BsFillExclamationDiamondFill />
    // },
    {
        title: 'Financial ',
        titleNl: 'Financial ',
        isSubMenu: true,
        icon: <TbFileInvoice />,
        key: 4,
        children: [
            {
                title: 'PlayerInvoices',
                titleNl: 'PlayerInvoices',
                route: allPaths.ADMIN_PLAYER_INVOICES,
                // icon: <TbFileInvoice />
            },
            {
                title: 'BTW',
                titleNl: 'BTW',
                route: allPaths.ADMIN_BTW_INVOICES,
                // icon: <TbFileInvoice />
            },
            {
                title: 'Competition subscription invoices',
                titleNl: 'Competition subscription invoices',
                route: allPaths.ADMIN_COMPETITION_SUB_INVOICES,
                // icon: <TbFileInvoice />
            },
            {
                title: 'Cashback',
                titleNl: 'Cashback',
                route: allPaths.ADMIN_CASHBACK,
                // icon: <TbBrandCashapp />
            },
        ]
    },
    {
        title: 'FAQ',
        titleNl: 'FAQ',
        route: allPaths.ADMIN__FAQ,
        icon: <FaQuestionCircle />
    },
]

const drawerRoutesUser = [
    {
        title: 'Home',
        titleNl: 'Home',
        route: allPaths.HOME,
        icon: <HomeOutlined />
    },
    {
        title: 'Private courts',
        titleNl: 'Private courts',
        route: allPaths?.PLAYER_PRIVATE_COURTS,
        icon: <ProjectOutlined />
    },
    {
        title: 'Matches',
        titleNl: 'Matches',
        isSubMenu: true,
        icon: <GiGameConsole />,
        key: 1,
        children: [
            {
                title: 'Create Request',
                titleNl: 'Create Request',
                route: allPaths.PLAYER_CREATE_MATCH_REQ,
            },
            {
                title: 'Request',
                titleNl: 'Request',
                route: allPaths.PLAYER_MATCH_REQUESTS,
            },
            {
                title: 'Result',
                titleNl: 'Result',
                route: allPaths.PLAYER_MATCH_RESULT,
            },
            // {
            //     title: 'Tournament matches',
            //     titleNl: 'Tournament matches',
            //     route: allPaths.PLAYER_TOURNAMENT_MATCHES,
            // },
        ]
    },
    // {
    //     title: 'Tournaments',
    //     titleNl: 'Toernooien',
    //     isSubMenu: true,
    //     icon: <TbDeviceGamepad2 />,
    //     key: 2,
    //     children: [
    //         {
    //             title: 'Tournament Select',
    //             titleNl: 'Selecteer toernooi',
    //             route: allPaths.PLAYER_SELECT_TOURNAMENT,
    //         },
    //         {
    //             title: 'Create tournament',
    //             titleNl: 'Maak toernooi',
    //             route: allPaths.PLAYER_CREATE_TOURNAMENT,
    //         },
    //     ]
    // },
    {
        title: 'Competition',
        titleNl: 'Competition',
        isSubMenu: true,
        icon: <GrGraphQl />,
        key: 3,
        children: [
            {
                title: 'Select',
                titleNl: 'Select',
                route: allPaths.COMPETITION_SELECT,
            },
            {
                title: 'Create Request',
                titleNl: 'Create Request',
                route: allPaths.PLAYER_CREATE_COMPETITION_MATCH,
            },
            {
                title: 'Request',
                titleNl: 'Request',
                route: allPaths.COMPETITION_MATCHES,
            },
            {
                title: 'Result',
                titleNl: 'Result',
                route: allPaths.COMPETITION_MATCHES_RESULTS,
            },
            {
                title: 'Ranking',
                titleNl: 'Ranking',
                route: allPaths.COMPETITION_RANKING_POINT,
            },
            {
                title: 'Subscribed',
                titleNl: 'Subscribed',
                route: allPaths.SUBSCRIBED_COMPETITION,
            },
            {
                title: 'Status',
                titleNl: 'Status',
                route: allPaths.COMPETITION_STATUS,
            },
            // {
            //     title: 'Claims',
            //     titleNl: 'Claims',
            //     route: allPaths.PLAYER_CLAIM,
            //     // icon: <BsFillExclamationDiamondFill />
            // },
        ]
    },
    // {
    //     title: 'Points',
    //     titleNl: 'Points',
    //     isSubMenu: true,
    //     icon: <GiRank2 />,
    //     key: 4,
    //     children: [
    //         // {
    //         //     title: 'Ranking/points',
    //         //     titleNl: 'Ranking/points',
    //         //     route: allPaths.PLAYER_RANKING_POINT,
    //         //     // icon: <GiRank2 />
    //         // },
    //         // {
    //         //     title: 'Pro ranking/points ',
    //         //     titleNl: 'Pro ranking/points',
    //         //     route: allPaths.PLAYER__PRO_RANKING_POINT,
    //         //     // icon: <GiRank2 />
    //         // },
    //         {
    //             title: 'Competition ranking/points ',
    //             titleNl: 'Competition ranking/points',
    //             route: allPaths.COMPETITION_RANKING_POINT,
    //             // icon: <GiRank2 />
    //         },
    //     ]
    // },

    {
        title: 'Settings',
        titleNl: 'Instellingen',
        isSubMenu: true,
        icon: <FiSettings />,
        key: 4,
        children: [
            {
                title: 'Profile',
                titleNl: 'Profiel',
                route: allPaths?.PLAYER_PROFILE,
                // icon: <CgProfile />
            },
            {
                title: 'Contacts',
                titleNl: 'Contacts',
                route: allPaths.PLAYER_CONTACT,
                // icon: <RiContactsFill />
            },
            {
                title: 'Couples',
                titleNl: 'Couples',
                route: allPaths.PLAYER_COUPLE,
                // icon: <RiContactsFill />
            },

            {
                title: 'Invite friend',
                titleNl: 'Invite friend',
                route: allPaths?.PLAYER_INVITE_FRIEND,
                // icon: <CgProfile />
            },
        ]
    },
    {
        title: 'Financial ',
        titleNl: 'Financial ',
        isSubMenu: true,
        icon: <TbFileInvoice />,
        key: 5,
        children: [
            {
                title: 'Upload',
                titleNl: 'Upload',
                route: allPaths.PLAYER_SUBSCRIPTION,
                // icon: <MdAccountBalance />
            },
            {
                title: 'Invoice',
                titleNl: 'Invoice',
                route: allPaths.PLAYER_INVOIVE,
                // icon: <TbFileInvoice />
            },
            {
                title: 'competition Subscription',
                titleNl: 'competition Subscription',
                route: allPaths.COMPETITION_SUB_INVOICE,
                // icon: <TbFileInvoice />
            },
            {
                title: 'Cashback',
                titleNl: 'Cashback',
                route: allPaths.PLAYER_CASHBACK,
                // icon: <TbBrandCashapp />
            },
            {
                title: 'Transactions',
                titleNl: 'Transactions',
                route: allPaths.PLAYER_WALLET_TRANSACTION,
                // icon: <TbBrandCashapp />
            },
        ]
    },
    {
        title: 'FAQ',
        titleNl: 'FAQ',
        route: allPaths.SIDE_MENU_FAQ,
        icon: <FaQuestionCircle />
    },
]

const drawerRoutesClub = [
    {
        title: 'Home',
        titleNl: 'Home',
        route: allPaths.HOME,
        icon: <HomeOutlined />
    },
    {
        title: 'Courts',
        titleNl: 'Courts',
        route: allPaths?.BU_PRODUCT,
        icon: <ProjectOutlined />
    },
    {
        title: 'Members',
        titleNl: 'Members',
        route: allPaths?.MEMBERS,
        icon: <ProjectOutlined />
    },
    // {
    //     title: 'Matches',
    //     titleNl: 'Matches',
    //     isSubMenu: true,
    //     icon: <GiGameConsole />,
    //     key: 1,
    //     children: [
    //         {
    //             title: 'Create match request',
    //             titleNl: 'Create match request',
    //             route: allPaths.PLAYER_CREATE_MATCH_REQ,
    //         },
    //         {
    //             title: 'Match request',
    //             titleNl: 'Match request',
    //             route: allPaths.PLAYER_MATCH_REQUESTS,
    //         },
    //         {
    //             title: 'Match result',
    //             titleNl: 'Match result',
    //             route: allPaths.PLAYER_MATCH_RESULT,
    //         },
    //         {
    //             title: 'Tournament matches',
    //             titleNl: 'Tournament matches',
    //             route: allPaths.PLAYER_TOURNAMENT_MATCHES,
    //         },
    //     ]
    // },
    // {
    //     title: 'Competition',
    //     titleNl: 'Competition',
    //     isSubMenu: true,
    //     icon: <GrGraphQl />,
    //     key: 3,
    //     children: [
    //         {
    //             title: 'Select competition',
    //             titleNl: 'Select competition',
    //             route: allPaths.COMPETITION_SELECT,
    //         },
    //         {
    //             title: 'Create match request',
    //             titleNl: 'Create match request',
    //             route: allPaths.PLAYER_CREATE_COMPETITION_MATCH,
    //         },
    //         {
    //             title: 'Match requests',
    //             titleNl: 'Match requests',
    //             route: allPaths.COMPETITION_MATCHES,
    //         },
    //         {
    //             title: 'Match result',
    //             titleNl: 'Match result',
    //             route: allPaths.COMPETITION_MATCHES_RESULTS,
    //         },
    //     ]
    // },
    // {
    //     title: 'Points',
    //     titleNl: 'Points',
    //     isSubMenu: true,
    //     icon: <GiRank2 />,
    //     key: 4,
    //     children: [
    //         // {
    //         //     title: 'Ranking/points',
    //         //     titleNl: 'Ranking/points',
    //         //     route: allPaths.PLAYER_RANKING_POINT,
    //         //     // icon: <GiRank2 />
    //         // },
    //         // {
    //         //     title: 'Pro ranking/points ',
    //         //     titleNl: 'Pro ranking/points',
    //         //     route: allPaths.PLAYER__PRO_RANKING_POINT,
    //         //     // icon: <GiRank2 />
    //         // },
    //         {
    //             title: 'Competition ranking/points ',
    //             titleNl: 'Competition ranking/points',
    //             route: allPaths.COMPETITION_RANKING_POINT,
    //             // icon: <GiRank2 />
    //         },
    //     ]
    // },
    {
        title: 'Settings',
        titleNl: 'Instellingen',
        isSubMenu: true,
        icon: <FiSettings />,
        key: 5,
        children: [
            {
                title: 'Profile',
                titleNl: 'Profiel',
                route: allPaths?.PLAYER_PROFILE,
                // icon: <CgProfile />
            },
            {
                title: 'Club info',
                titleNl: 'Club info',
                route: allPaths?.CLUB_INFO,
                // icon: <CgProfile />
            },
            {
                title: 'Review',
                titleNl: 'Review',
                route: allPaths?.PLAYER_COURT_REVIEW,
                // icon: <CgProfile />
            }
        ]
    },
    // {
    //     title: 'Financial ',
    //     titleNl: 'Financial ',
    //     isSubMenu: true,
    //     icon: <TbFileInvoice />,
    //     key: 6,
    //     children: [
    //         {
    //             title: 'Subscription ',
    //             titleNl: 'Subscription',
    //             route: allPaths.PLAYER_SUBSCRIPTION,
    //             // icon: <MdAccountBalance />
    //         },
    //         {
    //             title: 'Wallet invoices',
    //             titleNl: 'Wallet invoices',
    //             route: allPaths.PLAYER_INVOIVE,
    //             // icon: <TbFileInvoice />
    //         },
    //         {
    //             title: 'competition subscription',
    //             titleNl: 'competition subscription',
    //             route: allPaths.COMPETITION_SUB_INVOICE,
    //             // icon: <TbFileInvoice />
    //         },
    //         {
    //             title: 'Cashback',
    //             titleNl: 'Cashback',
    //             route: allPaths.PLAYER_CASHBACK,
    //             // icon: <TbBrandCashapp />
    //         },
    //         {
    //             title: 'Wallet transactions',
    //             titleNl: 'Wallet transactions',
    //             route: allPaths.PLAYER_WALLET_TRANSACTION,
    //             // icon: <TbBrandCashapp />
    //         },
    //     ]
    // },
    {
        title: 'FAQ',
        titleNl: 'FAQ',
        route: allPaths.SIDE_MENU_FAQ,
        icon: <FaQuestionCircle />
    },
]

const provincieLanguage = [
    { name: 'Noord-Holland', nameNl: 'Noord-Holland' },
    { name: 'Zuid-Holland', nameNl: 'Zuid-Holland' },
    { name: 'Zeeland', nameNl: 'Zeeland' },
    { name: 'Noord-Brabant', nameNl: 'Noord-Brabant' },
    { name: 'Utrecht', nameNl: 'Utrecht' },
    { name: 'Flevoland', nameNl: 'Flevoland' },
    { name: 'Friesland', nameNl: 'Friesland' },
    { name: 'Groningen', nameNl: 'Groningen' },
    { name: 'Drenthe', nameNl: 'Drenthe' },
    { name: 'Overijssel', nameNl: 'Overijssel' },
    { name: 'Gelderland', nameNl: 'Gelderland' },
    { name: 'Limburg', nameNl: 'Limburg' }
]

const dateFormat = 'DD-MM-YYYY'

const gameTypes = ['tennis', 'padel']

const intervalOptions = [
    {
        value: '15',
        label: '15',
    },
    {
        value: '30',
        label: '30',
    },
    {
        value: '60',
        label: '60',
    }
]
const ageOptions = [
    '0-3',
    '4-12',
    '13-65',
    '66 and older'
]
const constraints = {
    // facingMode: { exact: 'environment' }
    facingMode: { exact: 'user' }
}
const productTimeRange = ['morning', 'afternoon', 'evening']


export {
    provincieLanguage,
    bgColor,
    drawerRoutesUser,
    drawerRoutesAdmin,
    allPaths,
    dateFormat,
    deployedURL,
    gameTypes,
    drawerRoutesClub,
    intervalOptions,
    ageOptions,
    constraints,
    productTimeRange
}