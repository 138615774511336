import { Button, Popconfirm, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ADMIN } from '../../../utils/apis'
import { convertTitle, errorMessage, successMessage } from '../../../utils/helpers'
const GameSuggestion = (props) => {
    const { selectedLanguage, isEnglish } = props
    const [allGameSuggestion, setAllGameSuggestion] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [approveLoading, setApproveLoading] = useState({})

    const getAllGameSuggestion = () => {
        setLoading(true)
        axios.get(ADMIN.GET_GAME_SUGGESTION)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setAllGameSuggestion(data?.data || [])
                }
                else {
                    setAllGameSuggestion([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const approveGameSuggestion = (obj, id) => {
        setApproveLoading({ [id]: true })
        axios.post(ADMIN.APPROVE_GAME_SUGGESTION, obj)
            .then((res) => {
                const { data } = res
                setApproveLoading({})
                if (data.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getAllGameSuggestion()
                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            }).catch((e) => {
                setApproveLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const deleteGameSuggestion = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = { _id: id }
        axios.post(ADMIN.DELETE_GAME_SUGGESTION, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getAllGameSuggestion()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }


    useEffect(() => {
        getAllGameSuggestion()
    }, [deleteLoading])


    const columns = [
        {
            title: <p className='p-admin-title-text'>{selectedLanguage?.title_ID}</p>,
            dataIndex: '_id',
            key: 'id',
            render: (e) => {
                let Number = e.substr(e.length - 5)
                return (
                    <p className='p_button_style'>{Number}</p>
                )
            }
        },
        {
            title: <p className='p-admin-title-text'>{selectedLanguage?.title_CreatedBy}</p>,
            dataIndex: 'userId',
            key: 'userId',
            render: (e) => {
                return (
                    <p className='p-admin-normal-text' style={{ width: '160px' }}>{e?.name || '---'}</p>
                )
            }
        },
        {
            title: <p className='p-admin-title-text'>{selectedLanguage?.title_Name}</p>,
            dataIndex: 'name',
            key: 'name',
            render: (e) => {
                return (
                    <p className='p-admin-normal-text' style={{ width: '100px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: <p className='p-admin-title-text'>{selectedLanguage?.text_gameType}</p>,
            dataIndex: 'gameType',
            key: 'gameType',
            render: (e) => {
                return (
                    <p className='p-admin-normal-text' style={{ width: '120px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: <p className='p-admin-title-text'>{selectedLanguage?.title_Description}</p>,
            dataIndex: 'description',
            key: 'description',
            render: (e) => {
                return (
                    <p className='p-admin-normal-text' style={{ width: '180px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: <p className='p-admin-title-text'>{selectedLanguage?.title_PlayingTimeAvgmin}</p>,
            dataIndex: 'playingTime',
            key: 'playingTime',
            render: (e) => {
                return (
                    <p className='p-admin-normal-text' style={{ width: '180px' }}>{e}{' '}{selectedLanguage?.Text_Min}</p>
                )
            }
        },
        {
            title: '',
            render: (e) => {
                let obj = {
                    playingTime: e?.playingTime.trim(),
                    name: e?.name.trim(),
                    description: e?.description.trim(),
                    gameType: e?.gameType.trim(),
                    id: e?._id
                }
                return (
                    <span style={{ display: 'flex', }}>
                        <Button
                            loading={approveLoading[e?._id]}
                            onClick={() => {
                                approveGameSuggestion(obj, e?._id)
                            }}
                            className='addproduct-text '
                            style={{ height: '30px', width: '160px', marginRight: '2px' }}
                        >{selectedLanguage?.buttontext_CreateGame}</Button>
                        <Popconfirm
                            title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.name} `)} ${selectedLanguage?.text_gameSuggestion} ?`}
                            okText={selectedLanguage?.textDelete}
                            cancelText={selectedLanguage?.textCancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => {
                                deleteGameSuggestion(e?._id)
                            }}
                        >
                            <Button
                                loading={deleteLoading[e?._id]}
                                className='addproduct-text '
                                style={{ height: '30px', width: '120px', marginLeft: '2px' }}
                            >{selectedLanguage?.buttonText_Delete}</Button>
                        </Popconfirm>
                    </span>
                )
            }
        },
    ]
    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <div>
                    <p className='admin-home-title'>
                        {selectedLanguage?.title_GameSuggestion}
                    </p>
                    <div style={{ marginTop: '20px' }}>
                        <Table
                            dataSource={allGameSuggestion || []}
                            columns={columns}
                            pagination={false}
                            loading={loading}
                            scroll={{ x: 800 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameSuggestion