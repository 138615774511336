import { Button, Modal, Popconfirm, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { POST } from '../../../utils/apis';
import { BTW_TEX, convertNumber, convertRoundNumber, convertTitle, errorMessage, getPointSetting, successMessage, validateTime } from '../../../utils/helpers';
import NoData from '../../../assets/noDataImae.png'

const RecivedMatchRequest = (props) => {
    const { selectedLanguage, isEnglish, getUser, pointSettingAction, receiveMatches, user } = props
    const AdminServiceFee = useSelector(state => state?.pointSettingReducer)
    const matchServiceFee = AdminServiceFee?.dollerMatchReqSendFee
    const matchServiceBTW = convertRoundNumber((matchServiceFee * BTW_TEX))
    const matchServiceFeeWithBTW = convertRoundNumber(matchServiceFee + (matchServiceFee * BTW_TEX))
    const [currentAccept, setCurrentAccept] = useState({})
    const [acceptloading, setAcceptLoading] = useState({})
    const [rejectloading, setRejectLoading] = useState({})
    const [confirmModal, setConfirmModal] = useState(false)
    // useEffect(() => {
    //     getPointSetting(pointSettingAction)
    // }, [])

    const AcceptMatchRequest = (data) => {
        if (!data?.courtId?.acceptNonMember) {

            if (!(user?.preferredClub?.isApproved && user?.preferredClub?.club === data?.club?._id)) {
                return errorMessage("You are not a Member of this club, This court is not for Non-Members")
            }

            if (data?.playerNumbers === 4) {
                let partner = data?.opponents?.[1]
                if (!(partner?.preferredClub?.isApproved && partner?.preferredClub?.club === data?.club?._id)) {
                    return errorMessage("Your partner is not a Member of this club, This court is not for Non-Members")
                }
            }

        }

        if (user?.walletBalance < getPrice()) {
            return errorMessage("You don't have enough balance to accept this match request")
        }
        setAcceptLoading({ [data?._id]: true })

        let obj = {
            _id: data?._id,
            userId: user?._id,
            senderId: data?.senderId?._id,
            partnerId: data?.opponents?.[1]?._id,
            clubId: data?.club?._id,
            playerNumbers: data?.playerNumbers,
            price: getPrice()
        }

        axios.post(POST?.ACCEPT_MATCH_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setAcceptLoading({})
                if (data?.success) {
                    getUser()
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setAcceptLoading({})
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }

    const RejectMatchRequest = (data) => {
        setRejectLoading({ [data?._id]: true })

        let obj = {
            _id: data?._id,
            price: data?.courtId?.rentPrice,
            reservation: data?.reservation,
            shiftId: data?.shiftId,
            dayId: data?.dayId,
            userId: user?._id,
            senderId: data?.senderId?._id,
            partnerId: data?.partner?._id,
            clubId: data?.club?._id,
            playerNumbers: data?.playerNumbers,
            preBooked: data?.preBooked,
            amountPaid: data?.amountPaid
        }
        // return true
        axios.post(POST?.REJECT_MATCH_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setRejectLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getUser()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setRejectLoading({})
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }

    const handleOk = () => {
        AcceptMatchRequest(currentAccept)
        setConfirmModal(false)
    }
    const handleCancel = () => {
        setCurrentAccept({})
        setConfirmModal(false)
    }

    const getPrice = () => {
        let totalMembers = []
        let members = 0
        let nonMembers = 0
        let price = currentAccept?.courtId?.rentPrice
        // console.log('currentAccept', currentAccept)

        if (currentAccept?.preBooked) {
            let calculatedPrice = (currentAccept.amountPaid / currentAccept?.playerNumbers)
            calculatedPrice = convertRoundNumber(calculatedPrice)

            return Number(calculatedPrice)
        }

        if (!currentAccept?.courtId?.memberDiscount) {
            let calculatedPrice = (price / currentAccept?.playerNumbers)
            calculatedPrice = convertRoundNumber(calculatedPrice)

            return Number(calculatedPrice)
        }

        totalMembers.push(user)

        if (currentAccept?.partner?._id) {
            totalMembers.push(currentAccept?.partner)
        }

        for (let v of (currentAccept?.opponents || [])) {
            totalMembers.push(v)
        }

        for (let v of totalMembers) {
            if (v?.preferredClub?.club === currentAccept?.club?._id && v?.preferredClub?.isApproved) {
                members += 1
            } else {
                nonMembers += 1
            }
        }

        let calculatedPrice = (price - (members * (price / totalMembers.length))) / totalMembers.length
        calculatedPrice = convertRoundNumber(calculatedPrice)

        return Number(calculatedPrice)

    }
    const columns = [
        {
            title: 'Club',
            dataIndex: ['club', 'name'],
            align: 'center'
        },
        {
            title: selectedLanguage?.title_GameType,
            dataIndex: 'gameType',
            align: 'center',
            render: (e) => convertTitle(e)
        },
        {
            title: 'Partner',
            // dataIndex: ['opponents'],
            align: 'center',
            render: (e) => e?.playerNumbers === 2 ? '-' : e?.opponents?.[0]?._id !== user?._id ? convertTitle(e?.opponents?.[0]?.name) : convertTitle(e?.opponents?.[1]?.name)
        },
        {
            title: 'Couple',
            dataIndex: ['opponentCouple', 'coupleName'],
            align: 'center'
        },
        {
            title: selectedLanguage?.title_DateTime,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p'>
                        {e?.matchDate || '-----'} | {validateTime(e?.matchTime) || '-----'}
                    </p>
                )
            }
        },
        {
            title: 'Players',
            dataIndex: 'playerNumbers',
            align: 'center'
        },
        {
            title: 'Opponent Couple',
            dataIndex: ['couple', 'coupleName'],
            align: 'center',
            width: 250,
        },
        {
            title: 'Opponents',
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' >{e?.senderId?.name} {',' && e?.partner?.name}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Message,
            dataIndex: 'message',
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' > {e || '----'}</p>
                )
            }
        },
        {
            title: 'Status',
            align: 'center',
            render: (e) => {
                return (
                    e?.isAccepted ?
                        <p className='accept-status'>{selectedLanguage?.sts_Accepted}</p>
                        : e?.isRejected ?
                            <p className='reject-status'>{selectedLanguage?.sts_rejected}</p>
                            :
                            <p className='pending-stats'>{selectedLanguage?.sts_pending}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            align: 'center',
            render: (e) => {
                return (
                    <>
                        {e?.opponents?.[0]?._id === user?._id && (
                            e?.isAccepted ?
                                <div style={{ display: 'flex' }} >
                                    <Button disabled={true} style={{ opacity: 0.2 }} className='accept_m_btn'>{selectedLanguage?.btn_Accept}</Button>
                                </div > :
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        className='accept_m_btn'
                                        loading={acceptloading[e?._id]}
                                        onClick={() => {
                                            setConfirmModal(true)
                                            setCurrentAccept(e)
                                        }}
                                    >{selectedLanguage?.btn_Accept}</Button>
                                    <Popconfirm
                                        title={`${selectedLanguage?.text_AreYouWantToRejectMatchRequestThatRecivedFrom} ${e?.senderId?.name}?`}
                                        okText={selectedLanguage?.textDelete}
                                        cancelText={selectedLanguage?.textCancel}
                                        okButtonProps={{
                                            type: 'primary',
                                            className: 'form-button'
                                        }}
                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                        onConfirm={() => {
                                            RejectMatchRequest(e)
                                        }}
                                    >
                                        <Button
                                            type='primary'
                                            danger
                                            loading={rejectloading[e?._id]}
                                            style={{ marginLeft: '10px' }}
                                        >{selectedLanguage?.btn_reject}</Button>
                                    </Popconfirm>
                                </div>)
                        }
                    </>
                )
            }
        }
    ]
    return (
        <div style={{ margin: '20px' }}>
            <p className='admin-home-title-white'>
                {selectedLanguage?.text_RecivedMatchRequest}
            </p>
            <div style={{ marginTop: '20px' }}>
                <Table
                    dataSource={receiveMatches}
                    columns={columns}
                    className='specific-css-table'
                    pagination={false}
                    scroll={{ x: 2000, y: 200 }}
                />
            </div>
            {/* Modal Confirm */}
            <Modal
                open={confirmModal}
                footer={null}
                className='pop-info-modal'
                style={{ top: '10px', }}
            >
                <div>
                    <p className='p-question'>{selectedLanguage?.text_AreYouSureYouWantToAcceptMacthRequest}</p>
                    <p className='p-opt'>By accepting match request € {getPrice()} will be deducted from your and your partner's wallet</p>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }}>
                    <button className='cancel-btn' onClick={handleCancel}>{selectedLanguage?.text_Cancel}</button>
                    <button className='confirm-btn' onClick={handleOk}>{selectedLanguage?.btn_Accept}</button>
                </div>
            </Modal>
            {/*  Modal Confirm*/}
        </div>
    )
}

export default RecivedMatchRequest