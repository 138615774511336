import React, { useEffect, useState } from 'react'
import NoData from '../../../assets/noDataImae.png'
import { Button, Popconfirm, Table } from 'antd'
import { POST } from '../../../utils/apis'
import { successMessage, errorMessage, getCompetitionMatches, convertTitle, validateTime } from '../../../utils/helpers'
import axios from 'axios'
const SentMatches = (props) => {
    const { selectedLanguage, user, matches, isEnglish, userActions } = props
    const [deleteLoading, setDeleteLoading] = useState({})

    const deleteMatchRequest = (data) => {
        setDeleteLoading({ [data?._id]: true })
        let obj = {
            _id: data?._id,
            userId: user?._id,
            code: data?.code,
            reservation: data?.reservation,
            shiftId: data?.shiftId,
            dayId: data?.dayId,
            senderId: data?.senderId?._id,
            partnerId: data?.partner?._id,
            clubId: data?.club?._id,
            playerNumbers: data?.playerNumbers,
            price: data?.courtId?.rentPrice

        }
        console.log('obj', obj)
        // return true
        axios.post(POST?.DELETE_COMPETITION_MATCH_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getCompetitionMatches(user?._id, userActions)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }
    const columns = [
        {
            title: selectedLanguage?.txt_Competition,
            dataIndex: ['competition', 'competitionName'],
            align: 'center'
        },
        {
            title: selectedLanguage?.txt_Club,
            dataIndex: ['club', 'name'],
            align: 'center'
        },
        {
            title: selectedLanguage?.title_GameType,
            dataIndex: 'gameType',
            align: 'center',
            render: (e) => convertTitle(e)
        },
        {
            title: selectedLanguage?.txt_Partner,
            // dataIndex: ['partner'],
            align: 'center',
            render: (e) => e?.playerNumbers === 2 ? '-' : e?.partner?._id !== user?._id ? convertTitle(e?.partner?.name) : convertTitle(e?.senderId?.name)
        },
        {
            title: selectedLanguage?.txt_Couple,
            dataIndex: ['couple', 'coupleName'],
            align: 'center'
        },
        {
            title: selectedLanguage?.title_DateTime,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' >
                        {e?.matchDate || '-----'} | {validateTime(e?.matchTime) || '-----'}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Players,
            dataIndex: 'playerNumbers',
            align: 'center'
        },
        {
            title: selectedLanguage?.txt_OpponentCouple,
            dataIndex: ['opponentCouple', 'coupleName'],
            align: 'center',
            width: 250
        },
        {
            title: selectedLanguage?.txt_Opponent,
            dataIndex: ['opponents'],
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' >{e?.[0]?.name} , {e?.[1]?.name}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Message,
            dataIndex: 'message',
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' > {e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Status,
            align: 'center',
            render: (e) => {
                return (
                    e?.isAccepted ?
                        <p className='accept-status'>{selectedLanguage?.sts_Accepted}</p>
                        : e?.isRejected ?
                            <p className='reject-status'>{selectedLanguage?.sts_rejected}</p>
                            :
                            <p className='pending-stats'>{selectedLanguage?.sts_pending}</p>
                    /* }
                  </> */
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            align: 'center',
            render: (e) => {
                return (<>
                    {user?._id === e?.senderId?._id &&
                        <Popconfirm
                            disabled={e?.isAccepted || e?.isRejected}
                            title={`${selectedLanguage?.text_AreyouwanttodeleteMatchrequestthatyousendto} ${e?.opponentCouple?.coupleName || e?.opponents?.[0]?.name}?`}
                            okText={selectedLanguage?.textDelete}
                            cancelText={selectedLanguage?.textCancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => {
                                deleteMatchRequest(e)
                            }}
                        >
                            <Button
                                disabled={e?.isAccepted || e?.isRejected}
                                type='primary'
                                loading={deleteLoading[e?._id]}
                                danger
                            >{selectedLanguage?.buttonText_Delete}</Button>
                        </Popconfirm>}
                </>
                )
            }
        }
    ]
    console.log("sent-matches", matches)
    return (
        // <div style={{ paddingBottom: 10, marginBottom: 30, borderBottom: `1px dashed white` }}>
        //     <div
        //         className='sidebar-scroll-class-black'
        //         style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
        //     >
        //         <div
        //             className='sidebar-scroll-class-black'
        //             style={{
        //                 overflow: 'scroll',
        //                 width: '98%',
        //                 margin: '0px auto'
        //             }}
        //         >
        //             <div style={{
        //                 marginTop: '10px',
        //                 marginBottom: '5px',
        //                 width: '1500px',
        //             }}>
        //                 <div style={{
        //                     width: '100%',
        //                     height: '60px',
        //                     borderTopRightRadius: '4px',
        //                     borderTopLeftRadius: '4px',
        //                     display: 'flex',
        //                     flexDirection: 'row',
        //                     justifyContent: 'space-between',
        //                     padding: '5px',
        //                     alignItems: 'center',
        //                 }}>
        //                     {[
        //                         { title: selectedLanguage?.label_CompetitionName },
        //                         { title: selectedLanguage?.text__Game },
        //                         { title: selectedLanguage?.text__Matchtype },
        //                         { title: selectedLanguage?.text__Matchdate },
        //                         { title: selectedLanguage?.text__Matchtime },
        //                         { title: selectedLanguage?.text__ReceiverNme },
        //                         { title: selectedLanguage?.text__Status },
        //                         { title: selectedLanguage?.title_Action },
        //                     ]?.map((v, i) => {
        //                         return (
        //                             <p className='tableValue'
        //                                 style={{
        //                                     margin: '0px',
        //                                     color: 'white',
        //                                     fontWeight: 'bolder',
        //                                     fontSize: '20px',
        //                                     width: '250px',
        //                                     marginLeft: '10px',
        //                                     marginRight: '10px'
        //                                 }}
        //                             >{v?.title}</p>
        //                         )
        //                     })}
        //                 </div>
        //                 <div
        //                     style={{
        //                         width: '100%',
        //                         borderBottomRightRadius: '4px',
        //                         borderBottomLeftRadius: '4px',
        //                         justifyContent: 'space-between',
        //                         padding: '5px',
        //                         alignItems: 'center',
        //                     }}>
        //                     {matches?.length ?
        //                         matches?.map((v, i) => {
        //                             return (
        //                                 <div
        //                                     style={{
        //                                         width: '100%',
        //                                         height: '50px',
        //                                         borderTopRightRadius: '4px',
        //                                         borderTopLeftRadius: '4px',
        //                                         display: 'flex',
        //                                         flexDirection: 'row',
        //                                         justifyContent: 'space-between',
        //                                         padding: '5px',
        //                                         alignItems: 'center',
        //                                         margin: '0px',
        //                                         padding: '0px'
        //                                     }}>
        //                                     <p className='tableValue table-p'>
        //                                         {v?.competition?.competitionName}
        //                                     </p>
        //                                     <p className='tableValue table-p'>
        //                                         {v?.gameId?.name}
        //                                     </p>
        //                                     <p className='tableValue table-p'>
        //                                         {v?.matchType}
        //                                     </p>
        //                                     <p className='tableValue table-p'>
        //                                         {v?.matchDate}
        //                                     </p>
        //                                     <p className='tableValue table-p'>
        //                                         {v?.matchTime}
        //                                     </p>
        //                                     <p className='tableValue table-p'>
        //                                         {v?.receiverId?.name}
        //                                     </p>
        //                                     <p className='tableValue table-p'>
        //                                         {v?.isAccepted ?
        //                                             <p className='accept-status'>{selectedLanguage?.sts_Accepted}</p>
        //                                             : v?.isRejected ?
        //                                                 <p className='reject-status'>{selectedLanguage?.sts_rejected}</p>
        //                                                 :
        //                                                 <p className='pending-stats'>{selectedLanguage?.sts_pending}</p>
        //                                         }
        //                                     </p>
        //                                     <p className='tableValue table-p'>
        //                                         {
        //                                             <Popconfirm
        //                                                 disabled={v?.isAccepted || v?.isRejected}
        //                                                 title={`${selectedLanguage?.text_AreyouwanttodeleteMatchrequestthatyousendto} ${v?.receiverId?.name}?`}
        //                                                 okText={selectedLanguage?.textDelete}
        //                                                 cancelText={selectedLanguage?.textCancel}
        //                                                 okButtonProps={{
        //                                                     type: 'primary',
        //                                                     className: 'form-button'
        //                                                 }}
        //                                                 cancelButtonProps={{ style: { borderRadius: 4 } }}
        //                                                 onConfirm={() => {
        //                                                     deleteMatchRequest(v)
        //                                                 }}
        //                                             >
        //                                                 <Button
        //                                                     disabled={v?.isAccepted || v?.isRejected}
        //                                                     type='primary'
        //                                                     loading={deleteLoading[v?._id]}
        //                                                     danger
        //                                                 >{selectedLanguage?.buttonText_Delete}</Button>
        //                                             </Popconfirm>}
        //                                     </p>
        //                                 </div>
        //                             )
        //                         })
        //                         :
        //                         <div style={{ height: '160px', textAlign: 'center' }}>
        //                             <img src={NoData} style={{ height: '150px', width: '150px' }} />
        //                         </div>
        //                     }
        //                 </div>
        //             </div>
        //         </div>
        //     </div >
        // </div >
        <div style={{ margin: '20px', paddingBottom: 10, borderBottom: `1px dashed white` }}>
            <div>
                <Table
                    dataSource={matches}
                    columns={columns}
                    className='specific-css-table'
                    pagination={false}
                    scroll={{ x: 2000, y: 200 }}
                />
            </div>
        </div>
    )
}

export default SentMatches