import { Button, Form, Select } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { useDispatch } from 'react-redux'
import voucher_codes from 'voucher-code-generator'
import { loginUser } from '../../Redux/actions/authActions'
import { POST } from '../../utils/apis'
import { errorMessage, successNotification } from '../../utils/helpers'

const { Option } = Select

const ChangeCode = (props) => {
    const { onCancel, user, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState(user?.productCode?.[0] || null)
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const onFinish = () => {
        setLoading(true)
        let obj = {
            _id: user?._id,
            productCode: [code]
        }
        axios.post(POST?.CHANGE_PRIVATE_CODE, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successNotification(data?.message)
                    data.data.productCode = [code]
                    dispatch(loginUser(data?.data))
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                } else {
                    return errorMessage(data?.message)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
                errorMessage()
            })
    }

    const generateCoupon = () => {
        let GenerateCoupon = voucher_codes.generate({
            count: 1,
            pattern: '###-###-###',
        })
        setCode(GenerateCoupon[0])
    }
    return (
        <div className='main-div-new-style'>
            <div className='div-flex-back-name'>
                <div className='screen-back-icion'>
                    <IoIosArrowBack onClick={onCancel} size={32} color='#ec671b'/>
                </div>
                <div className='height-35'>
                    <p className='screen-heading'>{user?.productCode?.length ? selectedLanguage?.UpdatePrivateCode : selectedLanguage?.CreatePrivateCode}</p>
                </div>
            </div>
            <div className='form-two-input-flex' style={{ marginTop: '20px' }}>
                <div className='form-two-input-flex-inner-input'>
                    <div className='form-input-new-style' style={{ padding: '15px',background:'white' }} >
                        {selectedLanguage?.Code}: {code}
                    </div>
                </div>
            </div>
            <div style={{ margin: '4px' }}>
                <Button
                    style={{ height: '40px', width: '160px', marginTop: '2px' }}
                    className='addproduct-text'
                    onClick={generateCoupon}
                >
                    {selectedLanguage?.Generate}
                </Button>
                <div style={{ marginTop: '20px' }}>
                    <Form
                        name='form'
                        form={form}
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <Form.Item>
                            <Button
                                style={{ height: '40px', width: '160px', marginTop: '2px' }}
                                className='addproduct-text'
                                htmlType='submit'
                                loading={loading}
                            >
                                {user?.productCode?.length ? selectedLanguage?.Update : selectedLanguage?.Create}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div >
    )
}

export default ChangeCode