import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, InputNumber, Radio, Select, Space, Switch, Upload } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FiPercent } from "react-icons/fi"
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { convertTitle, errorMessage, getGeneralData, successMessage } from "../../../utils/helpers"
import { gameTypes } from '../../../utils/constants'
const { RangePicker } = DatePicker;

const CreateCompetition = (props) => {
    const { Option } = Select
    const { generalActions, selectedLanguage, isEnglish, user, edit, editSuccess } = props

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])
    const [numPlayers, setNumPlayers] = useState(2)
    const allCompetitions = useSelector(state => state.generalReducer.allCompetitions)
    const allClubs = useSelector(state => state.generalReducer.allClubsUsers)
    const allPlayers = useSelector(state => state.generalReducer.allPlayersUsers)
    const [loading, setLoading] = useState(false)
    const [old, setOld] = useState(edit?.old ? edit?.old : false)
    const [selectedCompetition, setSelectedCompetition] = useState(null)
    const [form] = Form.useForm()
    const [type, setType] = useState(edit?.gameType || gameTypes?.[0])

    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: selectedLanguage?.txt_Pleaseselectrange,
            },
        ],
    };
    const onTypeChange = (e) => {
        setType(e)
        if (e === gameTypes[1]) {
            setNumPlayers(4)
            form.setFieldsValue({ playerNumbers: 4 })
        }
    }
    const onFinish = (fieldsValue) => {

        const rangeValue = fieldsValue['competitionDuration'];
        console.log('fieldsValue', fieldsValue)
        const values = {
            ...fieldsValue,
            'competitionDuration': JSON.stringify({ date1: rangeValue[0].format('YYYY-MM-DD'), date2: rangeValue[1].format('YYYY-MM-DD') }),
            file: fieldsValue?.file?.[0]?.url ? JSON.stringify(fieldsValue?.file?.[0]) : fieldsValue?.file?.[0]?.originFileObj,
            old: old,
            adminId: user?._id,
            rankRange: JSON.stringify(fieldsValue?.rankRange?.[0]),
            gameType:type
        }
        console.log('values', values)
        let formData = new FormData()

        for (var [k, v] of Object.entries(values)) {
            if (k === 'clubs' && v?.length) {
                v?.forEach((v) => {
                    formData.append('clubs[]', v)
                })
            } else if (k && v) {
                formData.append(k, v)
            }
        }

        formData.append('old', old ? true : false)

        setLoading(true)
        axios.post(ADMIN.CREATE_COMPETITION, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    form.resetFields()
                    edit?._id && editSuccess()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            }).catch((e) => {
                setLoading(false)
                console.log(e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const onChangeSwitchBox = (e) => {
        setOld(e)

        if (!e) {
            setSelectedCompetition(null)

            form.setFieldsValue({
                level: 1
            })
        }

        form.setFieldsValue({
            competitionName: null,
        })
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    }

    const competitionChange = (e) => {

        let competitionSelected = allCompetitions?.find((v) => v?.competitionName === e)
        setSelectedCompetition(competitionSelected)
        form.setFieldsValue({
            file: [competitionSelected?.imageObj],
            level: competitionSelected?.level + 1
        })
    }

    const disabledDate = (current) => {

        let competitionDate = moment(selectedCompetition?.competitionDuration?.date2, 'YYYY-MM-DD').add(1, 'days')

        if (!selectedCompetition) {
            return current && current.isBefore(moment(), 'day')
        } else {
            return current && current.isBefore(moment(), 'day') || current.isBefore(competitionDate, 'day')
        }
    }
    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <div>
                    {!edit?._id && <p className='admin-home-title'>
                        {selectedLanguage?.tittle_CreateCompetition}
                    </p>}
                    <div style={{ marginTop: '20px' }}>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >

                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input '>
                                    <Form.Item
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.CreatePrivateit}</p>}
                                        name='old'
                                        onChange={onChangeSwitchBox}
                                        // initialValue={old}
                                        valuePropName='checked'>
                                        <div
                                            className='form-input-new-style'
                                            style={{ display: 'flex', alignItems: 'center', background: 'white' }}
                                        >
                                            <p
                                                className='p-admin-title-text'
                                                style={{
                                                    margin: '0px 10px'
                                                }}>{selectedLanguage?.text_New}</p>
                                            <Switch
                                                onChange={onChangeSwitchBox}
                                                checked={old}
                                                checkedChildren={''}
                                                unCheckedChildren={''}
                                            />
                                            <p
                                                className='p-admin-title-text'
                                                style={{
                                                    margin: '0px 10px'
                                                }}>{selectedLanguage?.text_Old}</p>
                                        </div>
                                    </Form.Item>
                                </div>

                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='file'
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.text_IMG}</p>}
                                        valuePropName='fileList'
                                        getValueFromEvent={normFile}
                                    >
                                        <Upload disabled={old} className='upload_color' name='file' accept='image/*' beforeUpload={() => false} action='/upload.do' listType='text'>
                                            <Button disabled={old} icon={<UploadOutlined />}>{selectedLanguage?.text_ClickToUpload}</Button>
                                        </Upload>
                                    </Form.Item>
                                </div>

                            </div>

                            <div className='form-two-input-flex'>
                                {old ?
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='competitionName'
                                            hasFeedback
                                            // initialValue={edit?.tournamentName}
                                            label={<p className='p-admin-title-text'>{selectedLanguage?.label_CompetitionName}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.error_pleaseEnterCompetitionName
                                                }
                                            ]}
                                        >
                                            <Select
                                                onChange={competitionChange}
                                                placeholder={selectedLanguage?.text_SelectCompetition}
                                                className='form-input-new-style select-placeholder-orange'
                                                style={{ width: '100%', marginBottom: 20, marginTop: 5 }}
                                            >
                                                {allCompetitions?.map((v, i) => (<Option value={v?.competitionName}>{v?.competitionName}</Option>))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    : <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='competitionName'
                                            hasFeedback
                                            // initialValue={edit?.tournamentName}
                                            label={<p className='p-admin-title-text'>{selectedLanguage?.label_CompetitionName}</p>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.error_pleaseEnterCompetitionName
                                                }
                                            ]}
                                        >
                                            <Input

                                                className='form-input-new-style input-placeholder-orange'
                                                placeholder={selectedLanguage?.placeHolder_EnterCompetitionNameHere}
                                            />
                                        </Form.Item>
                                    </div>

                                }
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item name='competitionDuration' label={<p className='p-admin-title-text'>{selectedLanguage?.label_CompetitionRange}</p>} {...rangeConfig}>
                                        <RangePicker
                                            disabledDate={disabledDate}
                                            style={{ width: '100%' }}
                                            className='form-input-new-style date-placeholder-orange'
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <p style={{ padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px' }}
                                        className='p-admin-title-text'
                                    >{selectedLanguage?.text_SelectGameType}</p>
                                    <Select
                                        disabled={old}
                                        defaultValue={type}
                                        placeholder={selectedLanguage?.text_SelectGameType}
                                        className='form-input-new-style select-placeholder-orange'
                                        onChange={onTypeChange}
                                        style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                    >
                                        {gameTypes?.map((v, i) => (
                                            <Option value={v} >{convertTitle(v)}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className='form-two-input-flex-inner-input' >
                                    <Form.Item
                                        name='playerNumbers'
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.txt_SelectNumberofPlayers}</p>}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.textPleaseSelectWinnerOrDecidedToDrawIsMatch
                                            }
                                        ]}
                                        initialValue={numPlayers}
                                    >
                                        <Radio.Group
                                            value={numPlayers}
                                            // onChange={(e) => (setNumPlayers(e?.target?.value), form.getFieldValue('reservation') && handleCourtChange(form.getFieldValue('reservation')))}
                                            onChange={(e) => (setNumPlayers(e?.target?.value))}
                                            className='form-input-new-style'
                                            style={{ width: '100%', padding: '10px', display: 'flex', alignItems: 'center', backgroundColor: 'white' }}
                                            disabled={type === gameTypes[1]}
                                        >
                                            <Radio value={2}>{selectedLanguage?.txt_Two_player}</Radio>
                                            <Radio value={4}>{selectedLanguage?.txt_Four_Player}</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>

                            </div>

                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.Item
                                        name='level'
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_Levels}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.placeHolder_EnterLevels
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value < 1) {
                                                        return Promise.reject(selectedLanguage?.text_EnterLevelsmoreThen)
                                                    }
                                                    return Promise.resolve()
                                                }
                                            })
                                        ]}
                                        initialValue={1}
                                    >
                                        <InputNumber
                                            disabled={true}
                                            max={100}
                                            min={1}
                                            step={1}
                                            type='number'
                                            style={{ width: '100%' }}
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_EnterLevels}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='clubs'
                                        hasFeedback
                                        label={<p style={{ padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px' }}
                                            className='p-admin-title-text'
                                        >{selectedLanguage?.Selectclubs}</p>}
                                    >
                                        <Select
                                            mode='multiple'
                                            className='form-input-new-style-select-minhi'
                                            placeholder={selectedLanguage?.txt_Selectoneormoreclubshere}
                                            notFoundContent={<p className='custom-nodata-p'>{selectedLanguage?.clubsnotfound}</p>}
                                            optionFilterProp='children'
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {allClubs?.length && allClubs?.map((v, i) => {
                                                return (
                                                    <Option value={v?._id}>{`${v?.name}`}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>

                            </div>
                            <div className='form-two-input-flex'>

                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.Item
                                        name='participationMoney'
                                        initialValue={edit?.participationMoney}
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_ParticipationfeeMoney}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_PleaseEnterParticipationFeeMoney
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            step={0.5}
                                            type='number'
                                            style={{ width: '100%' }}
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_EnterParticipationFeeMoney}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.Item
                                        name='maximumMatches'
                                        hasFeedback
                                        initialValue={edit?.maximumMatches}
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.MaximumMatches}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.Pleaseenternumberofmaxmatches
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            // max={64}
                                            min={1}
                                            step={1}
                                            type='number'
                                            style={{ width: '100%' }}
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.Enternumberofmaxmatches}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.Item
                                        name='promoters'
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_Promoters}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.placeHolder_EnternumberofPromoters
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value < 1) {
                                                        return Promise.reject(`${selectedLanguage?.text_EnterPromotersmoreThen} ${1}`)
                                                    }
                                                    return Promise.resolve()

                                                }
                                            })
                                        ]}
                                    >
                                        <InputNumber
                                            // max={100}
                                            min={1}
                                            step={1}
                                            type='number'
                                            style={{ width: '100%' }}
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.Enternumberofpointsforpromoters}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.Item
                                        name='degraders'
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_Degraders}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.placeHolder_EnternumberofDegraders
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value < 1) {
                                                        return Promise.reject(`${selectedLanguage?.text_EnterDegradersmoreThen} ${1}`)
                                                    }
                                                    return Promise.resolve()
                                                }
                                            })
                                        ]}
                                    >
                                        <InputNumber
                                            // max={100}
                                            min={1}
                                            step={1}
                                            type='number'
                                            style={{ width: '100%' }}
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.Enternumberofpointsfordegraders}
                                        // placeholder={selectedLanguage?.placeHolder_EnternumberofDegraders}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.List name="rankRange" initialValue={[{ start: 0, end: 1 }]} >
                                        {(fields) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Space
                                                        key={key}
                                                        style={{
                                                            display: 'flex',
                                                            marginBottom: 8,
                                                        }}
                                                        align="baseline"
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            label={<p className='p-admin-title-text'>{selectedLanguage?.txt_RankRange}</p>}
                                                            // label={'abc'}
                                                            name={[name, 'start']}
                                                            rules={[
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        let newValue = getFieldValue('rankRange')?.[0]
                                                                        if (newValue?.end < newValue?.start) {
                                                                            return Promise.reject(selectedLanguage?.ttx_EndofrangeshouldbegreaterthanstartofRange)
                                                                        }
                                                                        return Promise.resolve()
                                                                    }
                                                                })
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                min={0.001}
                                                                step={0.001}
                                                                precision={3}
                                                                type='number'
                                                                style={{ width: '100%' }}
                                                                className='form-input-new-style input-placeholder-orange'
                                                            />
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...restField}
                                                            label={<p className='p-admin-title-text'>{' '}</p>}
                                                            name={[name, 'end']}
                                                            rules={[
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        let newValue = getFieldValue('rankRange')?.[0]
                                                                        if (newValue?.end < newValue?.start) {
                                                                            return Promise.reject(selectedLanguage?.ttx_EndofrangeshouldbegreaterthanstartofRange)
                                                                        }
                                                                        return Promise.resolve()
                                                                    }
                                                                })
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                min={0.001}
                                                                step={0.001}
                                                                precision={3}
                                                                type='number'
                                                                style={{ width: '100%' }}
                                                                className='form-input-new-style input-placeholder-orange'
                                                            />
                                                        </Form.Item>
                                                    </Space>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                </div>
                            </div>
                            <Form.Item>
                                <Button
                                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    htmlType='submit'
                                    loading={loading}
                                >
                                    {edit?._id ? selectedLanguage?.button_updateTournament : selectedLanguage?.btntext_Createtournament}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default CreateCompetition