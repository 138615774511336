import React, { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Tooltip } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { GET, POST } from '../../../utils/apis'
import { getUserData, successNotification, errorMessage, convertNumber } from '../../../utils/helpers'
import AddCashback from './AddCashback'
import moment from 'moment'
import NoData from '../../../assets/noDataImae.png'

const PlayerCashBack = (props) => {
  const { user, userActions, selectedLanguage, isEnglish } = props
  const [spin, setSpin] = useState({})
  const [form, setForm] = useState([])
  const [cashBackAmount, setCashBackAmount] = useState()
  const [loading, setLoading] = useState(false)
  const wallet = user?.walletBalance
  useEffect(() => {
    getUserData(user?._id, userActions);
    getCashBackForm()
  }, [])

  const getCashBackForm = () => {
    setLoading(true)
    axios.get(`${GET.GET_USER_CASH_BACK}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setForm(data?.data || [])
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })

    axios.get(`${GET.GET_USER_CASH_BACK_AMOUNT}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setCashBackAmount(data?.data || 0)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  const columns = [
    {
      title: selectedLanguage?.text_FormId,
      dataIndex: '_id',
      // sorter: true,
      ellipsis: true
    },
    {
      title: selectedLanguage?.text_Amount,
      dataIndex: 'amount',
      key: 'amount',
      // sorter: true,
      sorter: (a, b) => a.amount - b.amount,
      ellipsis: true,
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> € {e}</p>

      // render: (e) => <span>`${e }`</span>
    },
    {
      title: selectedLanguage?.txt_Status,
      key: 'isPaid',
      dataIndex: 'isPaid',
      // sorter: true,
      sorter: (a, b) => a.isPaid - b.isPaid,
      ellipsis: true,
      render: (e) => <span>{e ? selectedLanguage?.text_Approved : selectedLanguage?.text_Pending}</span>
    },
    {
      title: selectedLanguage?.text_Created_At,
      key: 'created',
      dataIndex: 'created',
      // sorter: true,
      ellipsis: true,
      render: (e) => <span>{moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</span>
    },

  ]

  return (
    <div className='cash-back-p-main-div'>
      <div
        className='sidebar-scroll-class-black'
        style={{
          height: '100%',
          overflowY: 'scroll',
          width: '98%',
          margin: '0px auto',
          marginLeft: '4px'
        }}
      >
        <p
          style={{
            marginTop: '10px',
          }}
          className='admin-home-title-white'>{selectedLanguage?.text_Cashback}</p>
        <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
          <AddCashback getCashBackForm={getCashBackForm}  {...props} />
        </span>
        <div style={{ marginTop: '20px' }}>
          <div
            className='sidebar-scroll-class-black'
            style={{
              overflow: 'scroll',
              width: '98%',
              margin: '0px auto'
            }}
          >
            <div style={{
              marginTop: '10px',
              marginBottom: '5px',
              // width: '100%',
              width: '650px',
            }}>
              <div style={{
                width: '100%',
                height: '60px',
                borderTopRightRadius: '4px',
                borderTopLeftRadius: '4px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '5px',
                alignItems: 'center',
              }}>
                {[
                  {
                    title: selectedLanguage?.text_FormId,
                    width: 240
                  },
                  {
                    title: selectedLanguage?.text_Amount,
                    width: 80,
                  },
                  {
                    title: selectedLanguage?.txt_Status,
                    width: 120,
                  },
                  {
                    title: selectedLanguage?.text_Created_At,
                    width: 120,
                  }
                ]?.map((v, i) => {
                  return (
                    <p className='tableValue'
                      style={{
                        margin: '0px',
                        color: 'white',
                        fontWeight: 'bolder',
                        fontSize: '20px',
                        width: v?.width,
                        marginLeft: '10px',
                        marginRight: '10px'
                      }}
                    >{v?.title}</p>
                  )
                })}
              </div>
              <div
                style={{
                  width: '100%',
                  borderBottomRightRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  justifyContent: 'space-between',
                  padding: '5px',
                  alignItems: 'center',
                }}>
                {form?.length ?
                  form?.map((v, i) => {
                    return (
                      <div
                        style={{
                          width: '100%',
                          height: '50px',
                          borderTopRightRadius: '4px',
                          borderTopLeftRadius: '4px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          padding: '5px',
                          alignItems: 'center',
                          margin: '0px',
                          padding: '0px'
                        }}>
                        <p className='tableValue table-p' style={{ width: '240px' }}>
                          {v?._id}
                        </p>
                        <p className='tableValue table-p' style={{ width: '80px' }}>
                          € {convertNumber(v?.amount)}
                        </p>
                        <p className='tableValue table-p' style={{ width: '120px' }}>
                          {v?.isPaid ?
                            <p className='accept-status' style={{ color: 'white' }}>{selectedLanguage?.text_Approved}</p>
                            :
                            <p className='pending-stats' style={{ color: 'white' }}>{selectedLanguage?.text_Pending}</p>
                          }
                        </p>
                        <p className='tableValue table-p' style={{ width: '120px' }}>
                          {moment(v?.created).utcOffset(0, true).format('DD-MM-YYYY')}
                        </p>
                      </div>
                    )
                  })
                  :
                  <div style={{ height: '160px', textAlign: 'center' }}>
                    <img src={NoData} style={{ height: '150px', width: '150px' }} />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        {/* <Table
           loading={loading}
           dataSource={form}
           columns={columns}
           pagination={{ hideOnSinglePage: true }}
           scroll={{ x: 800 }}
        /> */}
      </div>
    </div>
  )
}

export default PlayerCashBack