import React, { useState } from 'react'
import { Button, Form, Input, Select } from 'antd'
import axios from 'axios'
import { POST } from '../../../utils/apis'
import { errorMessage, successMessage } from '../../../utils/helpers'

const AddGameSuggestion = (props) => {
    const { Option } = Select

    const { selectedLanguage, isEnglish, user } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    let gameTypes = [
        { name: selectedLanguage?.text_OneByOneMatchGame, value: '1-1 Match Game' },
        { name: selectedLanguage?.text_TeamMatchGame, value: 'Team Match Game' },
    ]
    const onFinish = (values) => {
        let obj = {
            userId: user?._id,
            name: values?.name?.trim(),
            description: values?.description?.trim(),
            playingTime: values?.playingTime?.trim(),
            gameType: values?.gameType?.trim(),
        }

        axios.post(POST.CREATE_GAME_SUGGESTION, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(isEnglish ? data.message : data.messageNl)
                    form.resetFields()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    return (
        <div>
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='name'
                                hasFeedback
                                label={<p className='my-form-label'>{selectedLanguage?.title_Name}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_InputYourNameHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='gameType'
                                hasFeedback
                                label={<p className='my-form-label'>{selectedLanguage?.label_selectGameType}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_selectGameType
                                    }
                                ]}
                            >
                                <Select
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_selectGameType}>
                                    {gameTypes?.length ?
                                        gameTypes?.map((v, i) => {
                                            return (
                                                <Option value={v?.value}>{`${v?.name}`}</Option>
                                            )
                                        })
                                        : null}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='description'
                                hasFeedback
                                label={<p className='my-form-label'>{selectedLanguage?.title_Description}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_Description
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_Description}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='playingTime'
                                hasFeedback
                                label={<p className='my-form-label'>{selectedLanguage?.title_Playingtimeavg}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_Playingtimeavg
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeHolder_Playingtimeavg}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                        <Form.Item>
                            <Button
                             style={{
                                padding: '0px 20px 0px 20px',
                                background: 'rgba(92, 93, 93, 1)',
                                color: 'white',
                                border: 'none',
                                height: '45px',
                                marginTop: '10px',
                                borderRadius: 6
                            }}
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.textbtn_Addgamesuggestion}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>

    )
}

export default AddGameSuggestion