import { Button, Form, Input, InputNumber, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AUTH } from '../../../utils/apis'
import { convertTitle, errorMessage, getGeneralData, successNotification } from '../../../utils/helpers'
import { loginUser } from '../../../Redux/actions/authActions'
import countryCodes from '../../../utils/countryCodes'
import ChangePassword from './ChangePassword'
import ReactFlagsSelect from 'react-flags-select'
import { gameTypes } from '../../../utils/constants'

const PlayerProfile = (props) => {
    const { Option } = Select
    const { selectedLanguage, isEnglish, user, generalActions } = props
    const [selectedClub, setSelectedClub] = useState({})
    const [form] = Form.useForm()
    const formRef = useRef(null)
    const dispatch = useDispatch()
    const [country, setCountry] = useState(user?.country || 'Netherlands')
    const [countryCode, setCountryCode] = useState(countryCodes.filter(v => v?.code === user?.country)?.[0]?.dial_code || '+31')
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(user?.languageSetting === 'english' ? 'GB' : 'NL')
    const [userLanguage, setUserLanguage] = useState(user?.languageSetting)
    const [verified, setVerified] = useState(false)
    const clubUsers = useSelector(state => state.generalReducer.allClubsUsers)

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    const onSelectClub = (e) => {
        setSelectedClub(clubUsers.find((v) => v?._id === e))
        form.setFieldsValue({
            code: null
        })
        setVerified(false)
    }


    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter((v) => v?.code === e)[0].dial_code
        setCountryCode(selectedCode)
        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'GB') {
            setUserLanguage('english')
        }
        else {
            setUserLanguage('netherlands')
        }
    }

    console.log(user, '=====user')
    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    showSearch
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120,
                    }}
                    defaultValue={countryCode}
                    value={countryCode}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {countryCodes?.map((v, i) => (
                        <Option
                            value={v?.dial_code}
                            key={i}
                        >{`${v?.name} ${v?.dial_code}`}</Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }

    const onFinish = (values) => {
        values._id = user?._id
        values.languageSetting = userLanguage
        if (values.preferredClub !== user?.preferredClub?.club) {
            if (values.preferredClub && !verified) {
                return errorMessage('Enter code for preferred club!')
            }
            if (!verified) {
                delete values.preferredClub
            }
        }
        setLoading(true)
        axios.post(AUTH.UPDATE_SETTINGS, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(isEnglish ? data.message : data.messageNl)
                    dispatch(loginUser(data?.user))
                    return window.location.reload()
                }
                else {
                    setLoading(false)
                    errorMessage(isEnglish ? data.message : data.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }


    return (
        <div className='profile-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', padding: 20 }}
            >
                <p className='admin-home-title-white'>{selectedLanguage?.title_AccountSettings}</p>
                <div style={{ marginTop: '20px' }}>
                    <Form
                        name='form'
                        form={form}
                        onFinish={onFinish}
                        layout={'vertical'}
                        ref={formRef}
                    >
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    initialValue={user?.name}
                                    name='name'
                                    label={<p className='my-form-label'>{selectedLanguage?.label_FullName}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_PleaseEnterYourFullName
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_PleaseEnterYourFullNameHere}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='email'
                                    initialValue={user?.email}
                                    label={<p className='my-form-label'>{selectedLanguage?.label_Email}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_PleaseEnterYourEmail
                                        },
                                        {
                                            type: 'email',
                                            message: selectedLanguage?.error_EmailIsNotValid,
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_PleaseEnterYourEmail}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='country'
                                    label={<p className='my-form-label'>{selectedLanguage?.label_Country}</p>}
                                    hasFeedback
                                    initialValue={country}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_PleaseSelectYourCountry
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        showArrow
                                        allowClear={false}
                                        className='form-input-new-style input-none'
                                        placeholder={selectedLanguage?.placeHolder_SelectYourCountry}
                                        optionFilterProp='children'
                                        onChange={updateCountry}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{isEnglish ? v?.name : v?.nameNL}</Option>)}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='city'
                                    initialValue={user?.city}
                                    label={<p className='my-form-label'>{selectedLanguage?.label_City}</p>}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_PleaseInputYourCity
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_InputYourCityHere}
                                    />
                                </Form.Item>
                            </div >
                        </div >

                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='streetNumber'
                                    label={<p className='my-form-label'>{selectedLanguage?.StreetNumber}</p>}
                                    initialValue={user?.streetNumber}
                                    hasFeedback
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: selectedLanguage?.PleaseIputYourStreetNumber
                                //     }
                                // ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.InputYourStreetNumberHere}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='postalCodeCity'
                                    initialValue={user?.postalCodeCity}
                                    label={<p className='my-form-label'>{selectedLanguage?.PostalCode}</p>}
                                    hasFeedback
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: selectedLanguage?.PleaseInputYourPostalCode
                                //     }
                                // ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.InputYourPostalCodeHere}
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='houseNumber'
                                    initialValue={user?.houseNumber}
                                    label={<p className='my-form-label'>{selectedLanguage?.label_houseno}</p>}
                                    hasFeedback
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: selectedLanguage?.error_PleaseInputYourhouseno
                                //     }
                                // ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_InputYourhousenoHere}
                                    />
                                </Form.Item>
                            </div >
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='telNo'
                                    initialValue={user?.telNo}
                                    label={<p className='my-form-label'>{selectedLanguage?.TelNo}</p>}
                                    hasFeedback
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: selectedLanguage?.PleaseInputYourTelNo
                                //     }
                                // ]}
                                >
                                    <Input
                                        addonBefore={prefixSelector()}
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.TelNo}
                                    />
                                </Form.Item>
                            </div >
                        </div >

                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='bankAccount'
                                    initialValue={user?.bankAccount}
                                    label={<p className='my-form-label'>{selectedLanguage?.text_BankAccountName}</p>}
                                    hasFeedback
                                >
                                    <Input
                                        maxLength={36}
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_BankAccountName}
                                    />
                                </Form.Item>
                            </div >

                            <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                <Form.Item
                                    name='IBAN'
                                    initialValue={user?.IBAN}
                                    label={<p className='my-form-label'>{selectedLanguage?.text_IBAN}</p>}
                                    hasFeedback
                                >
                                    <Input
                                        maxLength={36}
                                        // type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.placeHolder_IBAN}
                                    />
                                </Form.Item>
                            </div >

                        </div >


                        <div className='form-two-input-flex'>
                            {user?.userType === 'player' && <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='preferredClub'
                                    hasFeedback
                                    initialValue={user?.preferredClub?.club}
                                    label={<p className='my-form-label'>{'Select Member Club'}</p>}
                                >
                                    <Select
                                        allowClear={true}
                                        onChange={onSelectClub}
                                        placeholder={'Select Club'}
                                        className='form-input-new-style'
                                        style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                    >
                                        {clubUsers?.map((v, i) => (<Option value={v?._id} key={i}>{v?.name} - {v?.email}</Option>))}
                                    </Select>
                                </Form.Item>
                            </div>}
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    label={<p className='my-form-label'>{selectedLanguage?.title_GameType}</p>}
                                    name='gameType'
                                    hasFeedback
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: selectedLanguage?.error_selectGameType
                                    //     }
                                    // ]}
                                    initialValue={user?.gameType}
                                >
                                    <Select
                                        mode='multiple'
                                        placeholder={selectedLanguage?.placeHolder_selectGameType}
                                        className='form-input-new-style'
                                        style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                    >
                                        {gameTypes?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        {selectedClub?._id &&
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='code'
                                        label={<p className='my-form-label'>{'Enter Code'}</p>}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'Please Enter Code!'
                                    //     }
                                    // ]}
                                    >
                                        <Input
                                            onChange={(e) => (setVerified(e?.target.value === selectedClub?.privateCode))}
                                            disabled={!selectedClub?._id}
                                            maxLength={11}
                                            className='form-input-new-style'
                                            placeholder={'Enter Code'}
                                        />
                                    </Form.Item>
                                </div>
                            </div>}

                        {user?.userType === 'player' && <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='actualRanking'
                                    initialValue={user?.actualRanking}
                                    label={<p className='my-form-label'>{'actual ranking'}</p>}
                                    hasFeedback
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'please enter actual ranking'
                                //     },
                                // ]}
                                >
                                    <InputNumber
                                        disabled={user?.actualRanking}
                                        precision={4}
                                        step={0.0001}
                                        style={{ width: '100%' }}
                                        className='form-input-new-style '
                                        placeholder={'Enter your actual ranking here...'}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='bondsnummer'
                                    initialValue={user?.bondsnummer}
                                    label={<p className='my-form-label'>{'Bonds Nummer'}</p>}
                                    hasFeedback
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'please enter bonds nummer'
                                //     },
                                // ]}
                                >
                                    <Input
                                        className='form-input-new-style '
                                        placeholder={'Enter your bonds nummer here...'}
                                    />
                                </Form.Item>
                            </div>
                        </div>}
                        <div style={{ marginBottom: '20px' }}>
                            <p style={{
                                margin: '0px',
                                color: 'white',
                                fontWeight: 'bold',
                                marginLeft: '5px',
                                marginBottom: '7px',
                            }}>
                                {selectedLanguage?.tedxt_LanguageSetting}
                            </p>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                background: 'white',
                                height: '45px',
                                paddingTop: '6px',
                                paddingLeft: '10px',
                                borderRadius: '6px',
                                boxShadow: 'rgb(0 0 0 / 24%) 0px 2.5px 8px'
                            }}>
                                <ReactFlagsSelect
                                    className='countries-select-grey'
                                    fullWidth={false}
                                    showOptionLabel={false}
                                    showSelectedLabel={false}
                                    countries={["GB", "NL"]}
                                    selected={selected}
                                    onSelect={(v) => onchangeLanguage(v)}
                                />
                            </div>
                        </div>

                        <Form.Item>
                            <Button
                                style={{
                                    padding: '0px 20px 0px 20px',
                                    background: 'rgba(92, 93, 93, 1)',
                                    color: 'white',
                                    border: 'none',
                                    height: '40px',
                                    marginTop: '10px',
                                    borderRadius: 6
                                }}
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.ButtonText_Submit}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div style={{ borderBottom: '1px dashed rgba(92, 93, 93, 1)' }} />
                <div style={{ marginTop: '40px' }}>
                    <ChangePassword {...props} />
                </div>
            </div>
        </div>
    )
}

export default PlayerProfile