import React, { useEffect, useState } from "react";
import NoData from '../../../assets/noDataImae.png'
import axios from 'axios'
import { GET } from "../../../utils/apis";
import { createCompetitionInvoice } from "../../../utils/helpers";
import moment from "moment";
import ViewCard from "../../Cards/ViewCard";

const CompetitionSubInvoives = (props) => {
    const { selectedLanguage, user } = props
    const [data, setData] = useState([])
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})


    const getAllData = () => {
        axios.get(`${GET.GET_PLAYER_COMPETITION_SUBSCRIPTION}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setData(data.data)
                }
                else {
                    setData([])
                }
            })
            .catch((e) => {
                setData([])
            })
    }

    useEffect(() => {
        getAllData()
    }, [])

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })
        setShowInvoice(true)
    }
    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    return (
        <div className='claim-p-main-div'>
            <div className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }} >
                <p
                    style={{
                        marginTop: '10px',
                    }}
                    className='admin-home-title-white'>
                    {selectedLanguage?.txt_CompetitionSubscriptionInvoices}
                </p>
                <div style={{ marginTop: '20px' }}>
                    <div
                        className='sidebar-scroll-class-black'
                        style={{
                            overflow: 'scroll',
                            width: '98%',
                            margin: '0px auto'
                        }}
                    >
                        <div style={{
                            marginTop: '10px',
                            marginBottom: '5px',
                            width: '1000px',
                        }}>
                            <div style={{
                                width: '100%',
                                height: '60px',
                                borderTopRightRadius: '4px',
                                borderTopLeftRadius: '4px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '5px',
                                alignItems: 'center',
                            }}>
                                {[
                                    { title: selectedLanguage?.text__Competitionname },
                                    { title: selectedLanguage?.text_SubscriptionDate },
                                    { title: selectedLanguage?.text__Couponused },
                                    { title: 'View' },
                                    { title: selectedLanguage?.text_DownloadInvoice },
                                ]?.map((v, i) => {
                                    return (
                                        <p className='tableValue'
                                            style={{
                                                margin: '0px',
                                                color: 'white',
                                                fontWeight: 'bolder',
                                                fontSize: '20px',
                                                width: '290px',
                                                marginLeft: '10px',
                                                marginRight: '10px'
                                            }}
                                        >{v?.title}</p>
                                    )
                                })}
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    borderBottomRightRadius: '4px',
                                    borderBottomLeftRadius: '4px',
                                    justifyContent: 'space-between',
                                    padding: '5px',
                                    alignItems: 'center',
                                }}>
                                {data?.length ?
                                    data?.map((v, i) => {
                                        return (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '50px',
                                                    borderTopRightRadius: '4px',
                                                    borderTopLeftRadius: '4px',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    padding: '5px',
                                                    alignItems: 'center',
                                                    margin: '0px',
                                                    padding: '0px'
                                                }}>
                                                <p className='tableValue table-p'>
                                                    {v?.competitionId?.competitionName}
                                                </p>
                                                <p className='tableValue table-p'>
                                                    {moment(v?.created).format('MM-DD-YYYY')}
                                                </p>
                                                <p className='tableValue table-p'>
                                                    {v?.isCouponUser ? 'true' : 'false'}
                                                </p>
                                                <p className='tableValue table-p'>
                                                    <a onClick={() => { createCompetitionInvoice(v, true, viewInvoice) }} >
                                                        {'View'}
                                                    </a>
                                                </p>
                                                <p className='tableValue table-p'>
                                                    <a onClick={() => { createCompetitionInvoice(v, false, viewInvoice) }} >
                                                        {selectedLanguage?.text_Download}
                                                    </a>
                                                </p>
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{ height: '160px', textAlign: 'center' }}>
                                        <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice} /> : null}
        </div>
    )
}

export default CompetitionSubInvoives