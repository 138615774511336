import { Image } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import NoData from '../../../assets/noDataImae.png'
import { errorMessage, getGeneralData } from "../../../utils/helpers"
import { GrContactInfo } from 'react-icons/gr'
import { allPaths } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'

const AdminCompetitionSelect = (props) => {
    const { selectedLanguage, user, generalActions, } = props
    const allCompetitions = useSelector(state => state.generalReducer.allCompetitions)
    const history = useHistory()

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    return (
        <div className='create-match-req-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
            >
                <p className='admin-home-title-white'>
                    {selectedLanguage?.text_CompetitionSelect}
                </p>
                <div
                    className='sidebar-scroll-class-black'
                    style={{
                        overflow: 'scroll',
                        width: '98%',
                        margin: '0px auto'
                    }}
                >
                    <div style={{
                        marginTop: '10px',
                        marginBottom: '5px',
                        width: '1500px',
                    }}>
                        <div style={{
                            width: '100%',
                            height: '60px',
                            borderTopRightRadius: '4px',
                            borderTopLeftRadius: '4px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '5px',
                            alignItems: 'center',
                        }}>
                            {[
                                { title: selectedLanguage?.text_Image },
                                { title: selectedLanguage?.text_Name },
                                { title: selectedLanguage?.text_StartDate },
                                { title: selectedLanguage?.text_EndDate },
                                { title: selectedLanguage?.text_Level },
                                { title: selectedLanguage?.MaximumMatches },
                                { title: selectedLanguage?.text_Registered },
                                { title: selectedLanguage?.title_Fee },
                                { title: selectedLanguage?.text_com_Result },
                            ]?.map((v, i) => {
                                return (
                                    <p className='tableValue'
                                        style={{
                                            margin: '0px',
                                            color: 'white',
                                            fontWeight: 'bolder',
                                            fontSize: '20px',
                                            width: '250px',
                                            marginLeft: '10px',
                                            marginRight: '10px'
                                        }}
                                    >{v?.title}</p>
                                )
                            })}
                        </div>
                        <div
                            style={{
                                width: '100%',
                                borderBottomRightRadius: '4px',
                                borderBottomLeftRadius: '4px',
                                justifyContent: 'space-between',
                                padding: '5px',
                                alignItems: 'center',
                            }}>
                            {allCompetitions?.length ?
                                allCompetitions?.map((v, i) => {
                                    return (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '50px',
                                                borderTopRightRadius: '4px',
                                                borderTopLeftRadius: '4px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                padding: '5px',
                                                alignItems: 'center',
                                                margin: '0px',
                                                padding: '0px',
                                                marginTop: '10px'
                                            }}>
                                            <p className='tableValue table-p'>
                                                <Image src={v?.imageObj?.url} style={{ marginRight: 10, width: 86, height: 50 }} />
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.competitionName}
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.competitionDuration?.date1}
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.competitionDuration?.date2}
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.level}
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.maximumMatches}
                                            </p>
                                            <p className='tableValue table-p'>
                                                {v?.competitionSubscribers?.length ? v?.competitionSubscribers?.length : 0}
                                            </p>
                                            <p className='tableValue table-p'>
                                                € {v?.participationMoney}
                                            </p>
                                            <p className='tableValue table-p'>
                                                <GrContactInfo
                                                    color='white'
                                                    size={30}
                                                    style={{
                                                        background: 'white',
                                                        borderRadius: '100px',
                                                        padding: '4px',
                                                        cursor: 'pointer'
                                                    }}
                                                    
                                                    onClick={()=>{
                                                        v?.isCompleted?
                                                            history?.push({ pathname: allPaths?.COMPETITION_RESULT, state: v }):
                                                            errorMessage(selectedLanguage?.text_competitionisNotCompeletedyet)
                                                    }}/>
                                            </p>
                                        </div>
                                    )
                                })
                                :
                                <div style={{ height: '160px', textAlign: 'center' }}>
                                    <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div >
        </div >
    )
}

export default AdminCompetitionSelect