import { Table } from "antd";
import moment from "moment";
import React from "react";
import NoData from '../../assets/noDataImae.png'
const ParticipationTable = (props) => {
    const { Tournament, selectedLanguage } = props
    let participater = Tournament?.tournamentParticipate
    let sortParticipater = participater?.sort((a, b) => Tournament?.isStake ? b?.playerPoint?.proPoint - a?.playerPoint?.proPoint : b?.playerPoint?.point - a?.playerPoint?.point);

    // console.log(Tournament?.isStake, sortParticipater)

    // const columns = [
    //     {
    //         title: 'player Name',
    //         dataIndex: 'userId',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '100px' }}>{e?.name || '----'}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: Tournament?.isStake ? 'Player Pro Point' : 'Player point',
    //         dataIndex: 'playerPoint',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '100px' }}>{Tournament?.isStake ? e?.proPoint || 0 : e?.point || 0}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: Tournament?.isStake ? 'Money Collected' : 'Point Collected',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '100px' }}>{Tournament?.isStake ? e?.moneyCollected : e?.pointCollected}</p>
    //             )
    //         }
    //     },
    //     {
    //         title: 'Participation date',
    //         render: (e) => {
    //             return (
    //                 <p style={{ width: '100px' }}>{moment(e?.created).format('DD-MM-YYYY')}</p>
    //             )
    //         }
    //     },

    // ]

    return (
        <div style={{ marginTop: '20px' }}>
            {/* // <Table
                //     dataSource={sortParticipater}
                //     columns={columns}
                //     pagination={false}
                //     scroll={{ x: 800 }}
                // /> */}
            <div
                className='sidebar-scroll-class-black'
                style={{
                    overflow: 'scroll',
                    width: '98%',
                    margin: '0px auto'
                }}
            >
                <div style={{
                    marginTop: '10px',
                    marginBottom: '5px',
                    // width: '100%',
                    width: '1000px',
                }}>
                    <div style={{
                        width: '100%',
                        height: '60px',
                        borderTopRightRadius: '4px',
                        borderTopLeftRadius: '4px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '5px',
                        alignItems: 'center',
                    }}>
                        {[
                            { title: 'player Name', },
                            { title: Tournament?.isStake ? 'Player Pro Point' : 'Player point', },
                            { title: Tournament?.isStake ? 'Money Collected' : 'Point Collected', },
                            { title: 'Participation date', }
                        ]?.map((v, i) => {
                            return (
                                <p className='tableValue'
                                    style={{
                                        margin: '0px',
                                        color: 'white',
                                        fontWeight: 'bolder',
                                        fontSize: '20px',
                                        width: '290px',
                                        marginLeft: '10px',
                                        marginRight: '10px'
                                    }}
                                >{v?.title}</p>
                            )
                        })}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            borderBottomRightRadius: '4px',
                            borderBottomLeftRadius: '4px',
                            justifyContent: 'space-between',
                            padding: '5px',
                            alignItems: 'center',
                        }}>
                        {sortParticipater?.length ?
                            sortParticipater?.map((v, i) => {
                                return (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '50px',
                                            borderTopRightRadius: '4px',
                                            borderTopLeftRadius: '4px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            padding: '5px',
                                            alignItems: 'center',
                                            margin: '0px',
                                            padding: '0px'
                                        }}>
                                        <p className='tableValue table-p'>
                                            {v?.userId?.name}
                                        </p>
                                        <p className='tableValue table-p'>
                                            {Tournament?.isStake ? v?.playerPoint?.proPoint || 0 : v?.playerPoint?.point || 0}
                                        </p>
                                        <p className='tableValue table-p'>
                                            {Tournament?.isStake ? v?.moneyCollected : v?.pointCollected}
                                        </p>
                                        <p className='tableValue table-p'>
                                            {moment(v?.created).format('DD-MM-YYYY')}
                                        </p>
                                    </div>
                                )
                            })
                            :
                            <div style={{ height: '160px', textAlign: 'center' }}>
                                <img src={NoData} style={{ height: '150px', width: '150px' }} />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
export default ParticipationTable