import { Button, Form, Input, Modal, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { POST } from '../../../utils/apis'
import { errorMessage, getUserData, successMessage } from '../../../utils/helpers'
const AddCouple = (props) => {
    const { Option } = Select
    const { selectedLanguage, isEnglish, user, playerContact, visible, handleOk, userActions } = props
    const playerContacts = useSelector(state => state?.userReducer?.playerContacts)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [disabledArray, setDisabledArray] = useState([])
    console.log('playerContacts', playerContacts)
    useEffect(() => {
        getUserData(user?._id, userActions)

        let arr = []
        playerContact?.length &&
            playerContact.map((v, i) => {
                arr.push(v?.partnerId?.email)
            })
        setDisabledArray(arr)
    }, [])

    const onFinish = (values) => {
        values.userId = user?._id
        values.postalCodeCity = user?.postalCodeCity
        axios.post(POST.CREATE_COUPLE, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(isEnglish ? data.message : data.messageNl)
                    form.resetFields()
                    handleOk()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    return (
        <div>
            <Modal
                open={visible}
                onOk={handleOk}
                onCancel={handleOk}
                footer={null}>
                <div style={{ marginTop: '10px' }}>
                    <span style={{ textAlign: 'center' }}>
                        <p className='admin-home-title' style={{ fontSize: '18px' }}>{
                            selectedLanguage?.title_AddNewCouple
                        }</p>
                    </span>
                </div>
                <div style={{ marginTop: '35px' }}>
                    <Form
                        name='form'
                        form={form}
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <Form.Item
                            name='partnerId'
                            hasFeedback
                            label={selectedLanguage?.SelectCoupleEmail}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseSelectCoupleEmail
                                }
                            ]}
                        >
                            <Select
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.SelectCoupleEmailHere}>
                                {playerContacts?.map((v, i) => <Option value={v?.contactId?._id}>{v?.contactId?.email}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='coupleName'
                            hasFeedback
                            label={selectedLanguage?.CoupleName}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseEnterCoupleName
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.EnterCoupleName}
                            />
                        </Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                            <Form.Item>
                                <Button
                                    className='addproduct-text '
                                    style={{ height: '45px', width: '180px', marginTop: '10px' }}
                                    htmlType='submit'
                                    loading={loading}
                                >
                                    {selectedLanguage?.AddNewCouple}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}
export default AddCouple