import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { POST } from '../../../utils/apis'
import { convertTitle, errorMessage, getUserData, successMessage } from '../../../utils/helpers'
import AddCouple from './AddCouple'
import NoData from '../../../assets/noDataImae.png'
import { CheckCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'


const PlayerCouple = (props) => {
    const { selectedLanguage, isEnglish, user, userActions } = props
    const allCouples = useSelector(state => state?.userReducer?.allCouples)
    const coupleRequests = useSelector(state => state?.userReducer?.coupleRequests)
    const [visible, setVisible] = useState(false)
    const [showRequests, setShowRequests] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [spin, setSpin] = useState({})
    useEffect(() => {
        getUserData(user?._id, userActions)
    }, [])

    const coupleTitles = [
        {
            title: selectedLanguage?.txt_Partner,
            width: 190
        },
        {
            title: selectedLanguage?.txt_CoupleName,
            width: 150
        },
        {
            title: selectedLanguage?.label_Country,
            width: 100
        },
        {
            title: selectedLanguage?.title_Action,
            width: 100
        },
    ]
    const requestTitles = [{
        title: selectedLanguage?.txt_CoupleName,
        width: 190
    },
    {
        title: selectedLanguage?.txt_Partner,
        width: 150
    },
    {
        title: selectedLanguage?.txt_Status,
        width: 100
    },
    {
        title: selectedLanguage?.title_Action,
        width: 100
    },]

    const deleteConatact = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = { _id: id }
        axios.post(POST.DELETE_COUPLE, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getUserData(user?._id, userActions)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    const deleteCoupleRequest = (id) => {
        setSpin({ [id]: true })
        let obj = { id }
        axios.post(POST.DELETE_COUPLE_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getUserData(user?._id, userActions)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    const acceptCoupleRequest = (id) => {
        setSpin({ [id]: true })
        let obj = { id }
        axios.post(POST.ACCEPT_COUPLE_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getUserData(user?._id, userActions)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const handleOk = () => {
        getUserData(user?._id, userActions)
        setVisible(false)
    }

    return (
        <div className='contact-p-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    width: '98%',
                    margin: '0px auto',
                    marginLeft: '4px'
                }}
            >
                <p
                    style={{
                        marginTop: '10px',
                    }}
                    className='admin-home-title-white'>
                    {selectedLanguage?.Couple}</p>
                <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    <Button
                        style={{
                            padding: '0px 20px 0px 20px',
                            background: 'rgba(92, 93, 93, 1)',
                            color: 'white',
                            border: 'none',
                            height: '45px',
                            marginTop: '10px',
                            borderRadius: 6
                        }}
                        onClick={() => setVisible(true)}>{selectedLanguage?.AddNewCouple}</Button>
                    {!showRequests ? <Button
                        style={{
                            padding: '0px 20px 0px 20px',
                            background: 'rgba(92, 93, 93, 1)',
                            color: 'white',
                            border: 'none',
                            height: '45px',
                            marginTop: '10px',
                            borderRadius: 6,
                            marginLeft: 6
                        }}
                        onClick={() => setShowRequests(true)}>{selectedLanguage?.Request}</Button>
                        :
                        <Button
                            style={{
                                padding: '0px 20px 0px 20px',
                                background: 'rgba(92, 93, 93, 1)',
                                color: 'white',
                                border: 'none',
                                height: '45px',
                                marginTop: '10px',
                                borderRadius: 6,
                                marginLeft: 6
                            }}
                            onClick={() => setShowRequests(false)}>{selectedLanguage?.Couples}</Button>
                    }
                </span>

                <div style={{ marginTop: '20px' }}>
                    <div
                        className='sidebar-scroll-class-black'
                        style={{
                            overflow: 'scroll',
                            width: '98%',
                            margin: '0px auto'
                        }}
                    >
                        <div style={{
                            marginTop: '10px',
                            marginBottom: '5px',
                            width: '900px',
                        }}>
                            <div style={{
                                width: '100%',
                                height: '60px',
                                borderTopRightRadius: '4px',
                                borderTopLeftRadius: '4px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '5px',
                                alignItems: 'center',
                            }}>
                                {(showRequests ? requestTitles : coupleTitles)?.map((v, i) => {
                                    return (
                                        <p className='tableValue'
                                            style={{
                                                margin: '0px',
                                                color: 'white',
                                                fontWeight: 'bolder',
                                                fontSize: '20px',
                                                width: v?.width,
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                            }}
                                        >{v?.title}</p>
                                    )
                                })}
                            </div>
                            {!showRequests ?
                                <div
                                    style={{
                                        width: '100%',
                                        borderBottomRightRadius: '4px',
                                        borderBottomLeftRadius: '4px',
                                        justifyContent: 'space-between',
                                        padding: '5px',
                                        alignItems: 'center',
                                    }}>
                                    {
                                        allCouples?.sort((a, b) => {
                                            if (a?.partnerId?.email === undefined || a?.partnerId?.email === null) {
                                                return 1
                                            }
                                            if (b?.partnerId?.email === undefined || b?.partnerId?.email === null) {
                                                return -1
                                            }
                                            return a?.partnerId?.email.localeCompare(b?.partnerId?.email);
                                        })?.length ?
                                            allCouples?.sort((a, b) => {
                                                if (a?.partnerId?.email === undefined || a?.partnerId?.email === null) {
                                                    return 1
                                                }
                                                if (b?.partnerId?.email === undefined || b?.partnerId?.email === null) {
                                                    return -1
                                                }
                                                return a?.partnerId?.email.localeCompare(b?.partnerId?.email);
                                            })?.map((v, i) => {
                                                return (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '50px',
                                                            borderTopRightRadius: '4px',
                                                            borderTopLeftRadius: '4px',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            padding: '5px',
                                                            alignItems: 'center',
                                                            margin: '0px',
                                                            padding: '0px'
                                                        }}>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '190px' }}>
                                                            {v?.userId?._id === user?._id ? v?.partnerId?.email : v?.userId?.email}
                                                        </p>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '150px' }}>
                                                            {v?.coupleName}
                                                        </p>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '100px' }}>
                                                            {v?.partnerId?.country}
                                                        </p>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '100px' }}>
                                                            <Popconfirm
                                                                title={`${selectedLanguage?.textDelete} ${convertTitle(`${v?.partnerId?.name} `)} ${selectedLanguage?.Couple} ?`}
                                                                okText={selectedLanguage?.textDelete}
                                                                cancelText={selectedLanguage?.textCancel}
                                                                okButtonProps={{
                                                                    type: 'primary',
                                                                    className: 'form-button'
                                                                }}
                                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                onConfirm={() => {
                                                                    deleteConatact(v?._id)
                                                                }}
                                                            >
                                                                <Button
                                                                    loading={deleteLoading[v?._id]}
                                                                    style={{
                                                                        padding: '0px 20px 0px 20px',
                                                                        background: 'rgba(92, 93, 93, 1)',
                                                                        color: 'white',
                                                                        border: 'none',
                                                                        height: '45px',
                                                                        marginTop: '10px',
                                                                        borderRadius: 6
                                                                    }}>
                                                                    {selectedLanguage?.DeleteCouple}
                                                                </Button>
                                                            </Popconfirm>
                                                        </p>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{ height: '160px', textAlign: 'center' }}>
                                                <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                            </div>
                                    }

                                </div>
                                :
                                <div
                                    style={{
                                        width: '100%',
                                        borderBottomRightRadius: '4px',
                                        borderBottomLeftRadius: '4px',
                                        justifyContent: 'space-between',
                                        padding: '5px',
                                        alignItems: 'center',
                                    }}>
                                    {
                                        coupleRequests?.length ?
                                            coupleRequests?.map((v, i) => {
                                                return (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '50px',
                                                            borderTopRightRadius: '4px',
                                                            borderTopLeftRadius: '4px',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            padding: '5px',
                                                            alignItems: 'center',
                                                            margin: '0px',
                                                            padding: '0px'
                                                        }}>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '190px' }}>
                                                            {v?.coupleName}
                                                        </p>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '150px' }}>
                                                            {v?.userId?._id === user?._id ? v?.partnerId?.email : v?.userId?.email}
                                                        </p>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '100px' }}>
                                                            {selectedLanguage?.text_Pending}
                                                        </p>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '100px' }}>
                                                            {v?.userId?._id === user?._id ? null : spin[v?._id] ? <Spin /> :
                                                                <>
                                                                    <Button shape='circle' type='primary' onClick={() => acceptCoupleRequest(v?._id)}><CheckOutlined /></Button>
                                                                    &nbsp;
                                                                    <Button onClick={() => deleteCoupleRequest(v?._id)} shape='circle' type='primary' danger><CloseOutlined /></Button>
                                                                </>
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{ height: '160px', textAlign: 'center' }}>
                                                <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                            </div>
                                    }
                                </div>
                            }
                        </div>

                    </div>
                </div>

            </div>
            {
                visible &&
                <AddCouple playerContact={allCouples} visible={visible} handleOk={handleOk} {...props} />

            }
        </div>
    )
}

export default PlayerCouple