import React from "react";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io"
import { Tabs } from 'antd'
import ParticipationChart from "./TournamentChart";
import ParticipationTable from "./TournamentParticipater";


const TournamentDetail = (props) => {
    const { TabPane } = Tabs
    const history = useHistory()
    const { user, selectedLanguage } = props
    let Tournament = history?.location?.state
    return (
        <div className='tournamnent-info-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
            >
                {/* <p className='admin-home-title-white' style={{ marginTop: '10px' }}> */}
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span style={{ marginTop: '2px', cursor: 'pointer' }}>
                        <IoIosArrowBack
                            style={{ marginRight: '5px', color: 'white' }}
                            size={28}
                            onClick={() => history?.goBack()} />
                    </span>
                    <p className='admin-home-title-white'>
                        {Tournament?.tournamentName || '----'}
                    </p>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Tabs defaultActiveKey='1' centered style={{color:'white'}}>
                        <TabPane tab={selectedLanguage?.text_ParticipaterTable} key='1'>
                            <ParticipationTable Tournament={Tournament} {...props} />
                        </TabPane>
                        <TabPane tab={selectedLanguage?.text_ParticipaterGraph} key='2'>
                            <ParticipationChart Tournament={Tournament} {...props} />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div >
    )
}

export default TournamentDetail