import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { GET } from '../../utils/apis'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { useSelector } from 'react-redux'

const VerifyEmail = (props) => {
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'
    const [state, setState] = useState({
        verify: false,
        message: '',
        success: false
    })

    const updateState = (obj) => {
        setState({ ...state, ...obj })
    }

    useEffect(() => {
        const { match } = props
        const { token } = match.params
        axios.get(`${GET.VERIFY_EMAIL}/${token}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    updateState({ message: isEnglish ? data.message : data.messageNl, success: data.success })
                }
                else {
                    updateState({ message: isEnglish ? data.message : data.messageNl, success: data.success })
                }
            })
            .catch(e => {
                updateState({ message: selectedLanguage?.backendMsg_SomethingWentWrongPleaseRefreshThisPage, success: false })
            })
    }, [])

    return (
        <div className='verify-div'>
            {state.message.length ? <div className='verify-child'>
                <p className='verify-head'>{selectedLanguage?.title_EmailVerification}</p>
                <p className='verify-text'>{state.message}</p>
                {state.success && <p className='verify-text'>{selectedLanguage?.title_NowYouCan} <Link className='linkStyle' to='/'>{selectedLanguage?.minTitle_Login}</Link>.</p>}
            </div> : <Skeleton paragraph={{ rows: 22 }} />}
        </div>
    )
}

export default VerifyEmail
