import React, { useEffect, useState } from "react";
import { Button, Card, Select, Tooltip } from "antd";
import { GET, POST } from "../../../utils/apis";
import { errorMessage, successNotification, convertRoundNumber } from "../../../utils/helpers";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../Redux/actions/authActions";
import allPaths from '../../../Config/paths'
import { useHistory } from "react-router-dom";

const { Option } = Select

const SubscriptionScreen = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { user, selectedLanguage, getUser, location } = props
    const [loading, setLoading] = useState(false)
    const [money, setMoney] = useState(5)
    const userId = new URLSearchParams(location.search).get('userId') || null
    let subscriptionMoney = [5, 10, 15, 20, 25, 50, 100]


    const handleChangeMoney = (value) => {
        setMoney(value)
    };
    useEffect(() => {
        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    getUser()
                    setLoading(false)
                    history?.replace(allPaths?.PLAYER_SUBSCRIPTION)
                    if (data.success) {
                        return successNotification(data.message)
                    }
                    errorMessage(data.message)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage(selectedLanguage?.backendMsg_OopsSomethingWentWrong)
                })
        }
    }, [])


    const easyLoad = (a) => {
        setLoading(true)
        let obj = {
            userId: user?._id,
            amount: a,
            subscriptionName: 'Wallet Recharge subscription'
        }

        axios.post(POST.WALLET_RECHARGE, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    window.location.href = data?.redirectUrl
                }
                else {
                    setLoading(false)
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(selectedLanguage?.backendMsg_OopsSomethingWentWrong)
            })
    }
    return (
        <div className='subscription-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', padding: 20 }}
            >
                <p className='admin-home-title-white' style={{color:'black', marginBottom: 10 }}>
                    {selectedLanguage?.title_Subscription}
                </p>
                <div className='sidebar-scroll_class'
                    style={{
                        overflow: 'scroll',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '4px',
                    }}
                >
                    <div
                        style={{
                            width: '220px',
                            maxWidth: '220px',
                            minWidth: '220px',
                            marginRight: '10px',
                            height: '280px',
                            boxShadow: '0px 0px 9px darkgrey',
                            borderRadius: '8px',

                        }}
                    >
                        <Card style={{ borderRadius: '6px', }}
                            bordered={false}>
                            <div className='subscription-title-new'>
                                {selectedLanguage?.text_EasyloadCard}
                            </div>

                            <br />
                            <div className='subscription-title-new'
                                style={{ height: '60px', marginTop: '10px', fontSize: '13px' }}
                            >
                                {selectedLanguage?.text_LoadYourWalletwith} € {convertRoundNumber(money)}
                            </div>
                            <Select
                                onChange={handleChangeMoney}
                                style={{ width: '100%' }}
                                defaultValue={money}
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.placeHolder_SelectGamefortournament}>
                                {subscriptionMoney?.length && subscriptionMoney?.map((v, i) => {
                                    return (
                                        <Option value={v}>{`€ ${convertRoundNumber(v)}`}</Option>
                                    )
                                })}
                            </Select>
                            <Button
                                // style={{ height: '40px', width: '100%', marginTop: '10px' }}
                                // className='addproduct-text'
                                style={{
                                    padding: '0px 20px 0px 20px',
                                    background: 'rgba(92, 93, 93, 1)',
                                    color: 'white',
                                    border: 'none',
                                    height: '40px',
                                    marginTop: '10px',
                                    borderRadius: 6
                                }}
                                block
                                htmlType='submit'
                                onClick={() => { easyLoad(money) }}
                                loading={loading}
                            >
                                {selectedLanguage?.text_PurchaceNow}
                            </Button>
                        </Card>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SubscriptionScreen