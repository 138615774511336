import React, { useState, useEffect } from 'react'
import { Button, Card, Progress, Select, Skeleton, Spin } from 'antd'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { ADMIN, GET } from '../../../utils/apis'
import { calculateAverageForCleanliness, calculateAverageForFacilities, calculateAverageForFreeWifi, calculateAverageForLocation, calculateAverageForStaff, calculateAverageForValueForMoney, calculateReviewAverage, convertTitle, getGeneralData, successNotification } from '../../../utils/helpers'
import noDataImage from "../../../assets/noDataImae.png"
import {BsFillReplyAllFill} from 'react-icons/bs'
import {  DotChartOutlined, WechatOutlined } from '@ant-design/icons'
const { Option } = Select

const AdminReview = (props) => {
    const { user, adminActions, history, generalActions, language, selectedLanguage } = props
    let filterTimeData = [
        { val: 'Newest first' },
        { val: 'Oldest first' },
        { val: 'Highest score' },
        { val: 'Lowest score' }
    ]
    const isEnglish = language?.language === 'english'
    const allClubs = useSelector(state => state.generalReducer.allClubsUsers || [])
    const [loading, setLoading] = useState(false)
    const [ProgressLoading, setProgressLoading] = useState(false)
    const [courts, setCourtsss] = useState([])
    const [selectedClub, setSelectedClub] = useState(allClubs?.[0]?._id || null)
    const [filterTime, setFilterTime] = useState(filterTimeData[0]?.val)
    const [selectedCourt, setSelectedCourt] = useState(null)
    const [readingIndex, setReadingIndex] = useState(null)
    const [commentReadingIndex, setCommentReadingIndex] = useState(null)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [GuestReview, setGuestReview] = useState(null)

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])


    useEffect(() => {
        setSelectedCourt(null)
        setCourtsss([])
        getCourts()
    }, [selectedClub])

    const getCourts = () => {
        setProgressLoading(true)
        setLoading(true)
        axios.get(`${GET?.GET_COURTS}/${selectedClub}`)
            .then((res) => {
                const { data } = res
                setCourtsss(data?.data || [])
                setSelectedCourt(data?.data?.[0] || null)
                setTimeout(() => {
                    setProgressLoading(false)
                }, 2000);
                setLoading(false)
            })
            .catch(() => {
                setProgressLoading(false)
                setLoading(false)
            })
    }

    const hideSingleReview = (_id) => {
        setButtonLoading(true)
        axios.post(`${ADMIN?.HIDE_SINGLE_REVIEW}`, { _id })
            .then((res) => {
                const { data } = res
                setButtonLoading(false)
                getCourts()
                if (data?.success) {
                    successNotification(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setButtonLoading(false)
            })
    }

    const ShowSingleReview = (_id) => {
        setButtonLoading(true)
        axios.post(`${ADMIN?.SHOW_SINGLE_REVIEW}`, { _id })
            .then((res) => {
                const { data } = res
                setButtonLoading(false)
                getCourts()
                if (data?.success) {
                    successNotification(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setButtonLoading(false)
            })
    }

    const getProductById = (Id) => {
        setProgressLoading(true)
        setLoading(true)
        axios.get(`${GET?.GET_COURT_BY_ID}/${Id}`)
            .then((res) => {
                const { data } = res
                setSelectedCourt(data?.data || {})
                setLoading(false)
                setTimeout(() => {
                    setProgressLoading(false)
                }, 2000);
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const calcSoldProducts = (arr) => {
        return arr?.reduce((sum, { soldProduct }) => sum + soldProduct, 0)
    }
    useEffect(() => {
        let filterReviewModal = selectedCourt?.review
        switch (filterTime) {
            case 'Newest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(b?.created) - new Date(a?.created) })
                break
            case 'Oldest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(a?.created) - new Date(b?.created) })
                break
            case 'Highest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return b?.average - a?.average })
                break
            case 'Lowest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return a?.average - b?.average })
                break
            default:
                break
        }
        setGuestReview(filterReviewModal || [])
    }, [selectedCourt])

    const setFilterValue = (e) => {
        setFilterTime(e)
        let filterReviewModal = selectedCourt?.review
        switch (e) {
            case 'Newest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(b?.created) - new Date(a?.created) })
                break
            case 'Oldest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(a?.created) - new Date(b?.created) })
                break
            case 'Highest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return b?.average - a?.average })
                break
            case 'Lowest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return a?.average - b?.average })
                break
            default:
                break
        }
        setGuestReview(filterReviewModal || [])
    }

    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <div className='card2'
                    style={{ height: 'auto', minHeight: '450px', marginBottom: 20 }}>
                    <div >
                        <div className='flex-mobile' style={{ marginTop: 10, margin: '10px auto' }}>
                            <Select
                                showSearch
                                showArrow
                                allowClear={false}
                                defaultValue={selectedClub}
                                onChange={(e) => setSelectedClub(e)}
                                className='form-input'
                                placeholder={selectedLanguage?.SelectClub}
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '250px' }}
                            >
                                {allClubs?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.name)} ${v?.email}`}</Option>)}
                            </Select>
                        </div>
                        <div className='flex-mobile' style={{ marginTop: 10, margin: '10px auto' }}>
                            <Select
                                showSearch
                                showArrow
                                allowClear={false}
                                value={selectedCourt?._id}
                                onChange={(e) => {
                                    setSelectedCourt(null);
                                    getProductById(e)
                                }}
                                className='form-input'
                                placeholder={selectedLanguage?.SelectCourt}
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '250px' }}
                            >
                                {courts?.length ?
                                    courts?.map((v, i) => {
                                        return (
                                            <Option value={v?._id} key={i}>{`${v?.name}- € ${v?.price}`}</Option>
                                        )
                                    })
                                    : null
                                }
                            </Select>
                        </div>
                    </div>
                    <br />
                    <div >
                        {loading ?
                            <div className='admin-review-div' style={{ marginTop: 50 }}>
                                <Spin size="large" />
                            </div>
                            :
                            !allClubs?.length ?
                                <div className='admin-review-div'>
                                    <img style={{ height: 200, width: 200 }} src={noDataImage} />
                                    <p className='review-text white-p' style={{ fontWeight: 700 }}>{selectedLanguage?.clubsNotFound}</p>
                                </div>
                                :
                                !courts?.length ?
                                    <div className='admin-review-div'>
                                        <img style={{ height: 200, width: 200 }} src={noDataImage} />
                                        <p className='review-text white-p' style={{ fontWeight: 700 }}>{selectedLanguage?.CourtNotCreatedBySelectedClub}</p>
                                    </div>
                                    :
                                    !selectedCourt?._id ?
                                        <div className='admin-review-div'>
                                            <p className='review-text white-p' style={{ fontWeight: 700 }}>{selectedLanguage?.courttsFound}</p>
                                        </div>
                                        :
                                        <div style={{ padding: 20, paddingTop: 0 }}>
                                            <div className='admin-r-cards'>
                                                <Card
                                                    hoverable
                                                    bordered={false}
                                                >
                                                    <span >
                                                        <div className='card_img' style={{ textAlign: 'center' }}>
                                                            <img style={{ height: '150px', maxHeight: 'auto', width: '100%', maxWidth: '300px' }} alt="example" src={selectedCourt?.imageUrl} />
                                                        </div>
                                                    </span>
                                                    <span style={{ marginLeft: 10 }}>
                                                        <div style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                                            <button
                                                                style={{
                                                                    height: 22,
                                                                    width: 22,
                                                                    padding: 0,
                                                                    background: '#007AFF',
                                                                    border: 'none',
                                                                    borderRadius: 4,
                                                                    fontSize: 11,
                                                                    fontWeight: 500,
                                                                    color: 'white',
                                                                }}>
                                                                {selectedCourt?.review?.length ? calculateReviewAverage(selectedCourt?.review) : 0}
                                                            </button>
                                                        </div>

                                                        <div style={{ textAlign: 'left', marginTop: 5 }}>
                                                            <span>
                                                                <span className='card_sold_price'
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                    }} >
                                                                    <p className='card_title'
                                                                    >{selectedCourt?.name}</p>
                                                                    <span >
                                                                        <p className='card_desc1'>
                                                                            {selectedCourt?.review?.length ?
                                                                                calculateReviewAverage(selectedCourt?.review) < 4.5 ?
                                                                                    selectedLanguage?.Bad + ',' :
                                                                                    calculateReviewAverage(selectedCourt?.review) < 5.9 ?
                                                                                        selectedLanguage?.Poor + ',' :
                                                                                        calculateReviewAverage(selectedCourt?.review) < 6.9 ?
                                                                                            selectedLanguage?.Adeqate + ',' :
                                                                                            calculateReviewAverage(selectedCourt?.review) < 8.9 ?
                                                                                                selectedLanguage?.Good + ',' :
                                                                                                selectedLanguage?.Excellent + ',' : null}
                                                                            {selectedCourt?.review?.length ?
                                                                                selectedCourt?.review?.length !== 1 ?
                                                                                    selectedCourt?.review?.length > 999 ?
                                                                                        ' ' +
                                                                                        `${selectedCourt?.review?.length?.slice}`(0, 1) +
                                                                                        '.' +
                                                                                        `${selectedCourt?.review?.length}`?.slice(1, 2) +
                                                                                        'k' + ' ' + selectedLanguage?.Reviews :
                                                                                        ' ' + selectedCourt?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                                    ' ' + selectedCourt?.review?.length + ' ' + selectedLanguage?.Review :
                                                                                selectedLanguage?.NoReviewesYet}
                                                                        </p>
                                                                    </span>
                                                                </span>
                                                                <span className='card_sold_price'
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                        height: '25px'
                                                                    }} >
                                                                    <p className='card_desc' >{selectedCourt?.description}</p>

                                                                </span>

                                                            </span>
                                                            <span >
                                                                <div className='card_sold_price'>
                                                                    <p className='card_sold' style={{ color: '#007AFF' }}>{selectedLanguage?.Sold}:{calcSoldProducts(selectedCourt?.reservation?.settings || [])}</p>
                                                                    <p className='card_sold' style={{ color: '#09af00' }}>€{' ' + selectedCourt?.price}</p>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </span>
                                                </Card>
                                            </div>
                                            <div>
                                                <div >
                                                    <h4 style={{ fontWeight: 600, color: 'white' }}>{selectedLanguage?.Categories}</h4>
                                                    <div className='progress-main-div'>
                                                        <div className='progress-inner-main-div'>
                                                            <div className='progress-text-point-div'>
                                                                <p className='review-text white-p'>{selectedLanguage?.Staff}</p>
                                                                <p className='review-text white-p'>
                                                                    {ProgressLoading ?
                                                                        <Skeleton.Node active={true} style={{ height: 10, width: 25 }}>
                                                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                                                        </Skeleton.Node> :
                                                                        selectedCourt?.review?.length
                                                                            ? calculateAverageForStaff(selectedCourt?.review) : 0}{ProgressLoading ? null : '/10'}</p>
                                                            </div>
                                                            <div>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Input
                                                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                        active={true}
                                                                        block={true}
                                                                    /> :
                                                                    <Progress percent={selectedCourt?.review?.length ?
                                                                        calculateAverageForStaff(selectedCourt?.review) * 10 : 0}
                                                                        showInfo={false} />}
                                                            </div>
                                                        </div>
                                                        <div className='progress-inner-main-div'>
                                                            <div className='progress-text-point-div'>
                                                                <p className='review-text white-p'>{selectedLanguage?.Facilities}</p>
                                                                <p className='review-text white-p'>{ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> : selectedCourt?.review?.length
                                                                        ? calculateAverageForFacilities(selectedCourt?.review) : 0}{ProgressLoading ? null : '/10'}</p>
                                                            </div>
                                                            <div>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Input
                                                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                        active={true}
                                                                        block={true}
                                                                    /> :
                                                                    <Progress percent={selectedCourt?.review?.length ?
                                                                        calculateAverageForFacilities(selectedCourt?.review) * 10 : 0}
                                                                        showInfo={false} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='progress-main-div'>
                                                        <div className='progress-inner-main-div'>
                                                            <div className='progress-text-point-div'>
                                                                <p className='review-text white-p'>{selectedLanguage?.Cleanliness}</p>
                                                                <p className='review-text white-p'>{ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> : selectedCourt?.review?.length
                                                                        ? calculateAverageForCleanliness(selectedCourt?.review) : 0}{ProgressLoading ? null : '/10'}</p>
                                                            </div>
                                                            <div>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Input
                                                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                        active={true}
                                                                        block={true}
                                                                    /> :
                                                                    <Progress percent={selectedCourt?.review?.length ?
                                                                        calculateAverageForCleanliness(selectedCourt?.review) * 10 : 0}
                                                                        showInfo={false} />}
                                                            </div>
                                                        </div>
                                                        <div className='progress-inner-main-div'>
                                                            <div className='progress-text-point-div'>
                                                                <p className='review-text white-p'>{selectedLanguage?.Comfort}</p>
                                                                <p className='review-text white-p'>{ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> : selectedCourt?.review?.length
                                                                        ? calculateAverageForCleanliness(selectedCourt?.review) : 0}{ProgressLoading ? null : '/10'}</p>
                                                            </div>
                                                            <div>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Input
                                                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                        active={true}
                                                                        block={true}
                                                                    /> :
                                                                    <Progress percent={selectedCourt?.review?.length ?
                                                                        calculateAverageForCleanliness(selectedCourt?.review) * 10 : 0}
                                                                        showInfo={false} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='progress-main-div'>
                                                        <div className='progress-inner-main-div'>
                                                            <div className='progress-text-point-div'>
                                                                <p className='review-text white-p'>{selectedLanguage?.ValueForMoney}</p>
                                                                <p className='review-text white-p'>{ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> : selectedCourt?.review?.length
                                                                        ? calculateAverageForValueForMoney(selectedCourt?.review) : 0}{ProgressLoading ? null : '/10'}</p>
                                                            </div>
                                                            <div>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Input
                                                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                        active={true}
                                                                        block={true}
                                                                    /> :
                                                                    <Progress percent={selectedCourt?.review?.length ?
                                                                        calculateAverageForValueForMoney(selectedCourt?.review) * 10 : 0}
                                                                        showInfo={false} />}
                                                            </div>
                                                        </div>
                                                        <div className='progress-inner-main-div'>
                                                            <div className='progress-text-point-div'>
                                                                <p className='review-text white-p'>{selectedLanguage?.Location}</p>
                                                                <p className='review-text white-p'>{ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> : selectedCourt?.review?.length
                                                                        ? calculateAverageForLocation(selectedCourt?.review) : 0}{ProgressLoading ? null : '/10'}</p>
                                                            </div>
                                                            <div>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Input
                                                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                        active={true}
                                                                        block={true}
                                                                    /> :
                                                                    <Progress percent={selectedCourt?.review?.length ?
                                                                        calculateAverageForLocation(selectedCourt?.review) * 10 : 0}
                                                                        showInfo={false} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='progress-main-div'>
                                                        <div className='progress-inner-main-div'>
                                                            <div className='progress-text-point-div'>
                                                                <p className='review-text white-p'>{selectedLanguage?.FreeWifi}</p>
                                                                <p className='review-text white-p'>{ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> : selectedCourt?.review?.length
                                                                        ? calculateAverageForFreeWifi(selectedCourt?.review) : 0}{ProgressLoading ? null : '/10'}</p>
                                                            </div>
                                                            <div>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Input
                                                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                        active={true}
                                                                        block={true}
                                                                    /> :
                                                                    <Progress percent={selectedCourt?.review?.length ?
                                                                        calculateAverageForFreeWifi(selectedCourt?.review) * 10 : 0}
                                                                        showInfo={false} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-end'
                                                }}>
                                                    <Select
                                                        showSearch
                                                        showArrow
                                                        allowClear={false}
                                                        value={filterTime}
                                                        onChange={setFilterValue}
                                                        className='form-input'
                                                        optionFilterProp='children'
                                                        style={{ width: '250px', marginTop: '10px' }}
                                                    >
                                                        {filterTimeData?.map((v, i) => {
                                                            return (
                                                                <Option value={v?.val} key={i}>{`${v?.val}`}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                                <div style={{ marginTop: 10 }}>
                                                    <h4 style={{ fontWeight: 600 }}>{selectedLanguage?.GuestReview}</h4>
                                                </div>
                                                {
                                                    GuestReview?.length ?
                                                        GuestReview?.map((v, i) => {
                                                            return (
                                                                <div>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', width: '80%', alignItems: 'center' }}>
                                                                            <div>
                                                                                {ProgressLoading ?
                                                                                    <div
                                                                                        style={{ height: 40, width: 40, borderRadius: 100 }}
                                                                                    >
                                                                                        <Skeleton.Avatar
                                                                                            style={{ margin: 5 }}
                                                                                            active={true} shape={'circle'} />
                                                                                    </div> :
                                                                                    <div
                                                                                        style={{ height: 40, width: 40, border: '2px solid #007AFF', borderRadius: 100 }}
                                                                                    >
                                                                                        <img src={v?.userId?.logoUrl ? v?.userId?.logoUrl : null}

                                                                                            style={{ height: '100%', width: '100%', borderRadius: 100 }}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {ProgressLoading ? <div style={{ margin: 3, marginLeft: 7 }}>
                                                                                <Skeleton.Node active={true} style={{ height: 10, width: 35 }}>
                                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                                </Skeleton.Node>
                                                                                <br />
                                                                                <Skeleton.Node active={true} style={{ height: 10, width: 35 }}>
                                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                                </Skeleton.Node>
                                                                            </div> :
                                                                                <div style={{ margin: 5 }}>
                                                                                    <p className='review-text white-p' style={{ fontWeight: 700 }}>{v?.userId?.name ? v?.userId?.name : '----'}</p>
                                                                                    <p className='review-text white-p' style={{ fontWeight: 400 }}>{v?.userId?.city ? v?.userId?.city : '----'}</p>
                                                                                </div>}
                                                                        </div>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', width: '20%', justifyContent: 'flex-end' }}>
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    alignItems: 'flex-end'
                                                                                }}
                                                                            >
                                                                                {ProgressLoading ?
                                                                                    <Skeleton.Input style={{ height: 10, width: 50 }} active={true} block={true} />
                                                                                    : <p className='review-text white-p' style={{ marginBottom: 2 }}>
                                                                                        {v?.average ?
                                                                                            v?.average < 4.5 ?
                                                                                                selectedLanguage?.Bad :
                                                                                                v?.average < 5.9 ?
                                                                                                    selectedLanguage?.Poor :
                                                                                                    v?.average < 6.9 ?
                                                                                                        selectedLanguage?.Adeqate :
                                                                                                        v?.average < 8.9 ?
                                                                                                            selectedLanguage?.Good :
                                                                                                            v?.average > 8.9 ?
                                                                                                                selectedLanguage?.Excellent :
                                                                                                                null :
                                                                                            null}
                                                                                    </p>}
                                                                                {ProgressLoading ?
                                                                                    <Skeleton.Node active={true} style={{ height: 34, width: 34 }}>
                                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                                    </Skeleton.Node> :
                                                                                    <button
                                                                                        style={{
                                                                                            height: 34,
                                                                                            width: 34,
                                                                                            padding: 0,
                                                                                            background: '#007AFF',
                                                                                            border: 'none',
                                                                                            borderRadius: 4,
                                                                                            fontSize: 12,
                                                                                            fontWeight: 500,
                                                                                            color: 'white',
                                                                                        }}>
                                                                                        {v?.average ?
                                                                                            v?.average === 10 ? 10 : v?.average.toFixed(1)
                                                                                            : 0}
                                                                                    </button>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ margin: 5, }}>
                                                                        <p className='review-text white-p' style={{ fontWeight: 700 }}>
                                                                            {ProgressLoading ?
                                                                                <Skeleton.Node active={true} style={{ height: 10, width: 35, marginBottom: 5 }}>
                                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                                </Skeleton.Node>
                                                                                :
                                                                                `${selectedLanguage?.Reviewed}:`}
                                                                            {ProgressLoading ?
                                                                                <Skeleton.Node active={true} style={{ marginLeft: 4, height: 10, width: 35, marginBottom: 5 }}>
                                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                                </Skeleton.Node>
                                                                                :
                                                                                new Date(v?.created).toLocaleDateString()}
                                                                        </p>
                                                                        {ProgressLoading ?
                                                                            <Skeleton.Node active={true} style={{ height: 40, marginBottom: 5 }}>
                                                                                <DotChartOutlined style={{ fontSize: 0 }} />
                                                                            </Skeleton.Node>
                                                                            :
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                                                <div style={{ background: '#E9EBEE', width: '80%', padding: 20, borderRadius: 8 }}>
                                                                                    <p className={'review-text'}   >{v?.comment ? <>
                                                                                        <WechatOutlined style={{ marginRight: 10 }} /> {
                                                                                            v?.comment?.length > 100 && commentReadingIndex !== i ?
                                                                                                v?.comment?.slice(0, 100) + '...'
                                                                                                :
                                                                                                v?.comment} </> : '-----'}
                                                                                        <p className='review-text'
                                                                                            style={{ color: '#007AFF', cursor: 'pointer' }}
                                                                                            onClick={() => { setCommentReadingIndex(commentReadingIndex !== i ? i : null) }}
                                                                                        >{v?.comment?.length > 100 ?
                                                                                            commentReadingIndex !== i ?
                                                                                                selectedLanguage?.ContinueReading : selectedLanguage?.ShowLess : null}</p>
                                                                                    </p>
                                                                                </div>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', paddingLeft: '10px', paddingBottom: '10px' }}>
                                                                                    {!v?.isHide ?
                                                                                        < div style={{ width: '100%' }}>
                                                                                            <Button
                                                                                                onClick={() => { hideSingleReview(v?._id) }}
                                                                                                type='primary'
                                                                                                // block
                                                                                                htmlType='submit'
                                                                                                style={{ width: '80px', }}
                                                                                                loading={buttonLoading}
                                                                                            >
                                                                                                {selectedLanguage?.Button_Hide}
                                                                                            </Button>
                                                                                        </div> :
                                                                                        <div style={{ width: '100%' }}>
                                                                                            <Button
                                                                                                onClick={() => { ShowSingleReview(v?._id) }}
                                                                                                type='primary'
                                                                                                // block
                                                                                                htmlType='submit'
                                                                                                style={{ width: '80px', }}
                                                                                                loading={buttonLoading}
                                                                                            >
                                                                                                {selectedLanguage?.Button_display}
                                                                                            </Button>
                                                                                        </div>}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {v?.answered === null ? null :
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <div style={{
                                                                                width: '50px',
                                                                                height: '50px',
                                                                                padding: '14px',
                                                                                rotate: '180deg',
                                                                            }}>
                                                                                {ProgressLoading ? <Skeleton.Node active={true} style={{ height: 20, width: 20 }}>
                                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                                </Skeleton.Node> : <BsFillReplyAllFill size={25} color='white' />}
                                                                            </div>
                                                                            {ProgressLoading ?
                                                                                <Skeleton.Node active={true} style={{ height: 40, }}>
                                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                                </Skeleton.Node> :
                                                                                <div style={{ background: '#E9EBEE', width: '80%', padding: 20, borderRadius: 8 }}>
                                                                                    <p className={'review-text'}   >{v?.answered ? <>
                                                                                        <WechatOutlined style={{ marginRight: 10 }} /> {
                                                                                            v?.answered?.length > 100 && readingIndex !== i ?
                                                                                                v?.answered?.slice(0, 100) + '...'
                                                                                                :
                                                                                                v?.answered} </> : '-----'}
                                                                                        <p className='review-text'
                                                                                            style={{ color: '#007AFF', cursor: 'pointer' }}
                                                                                            onClick={() => { setReadingIndex(readingIndex !== i ? i : null) }}
                                                                                        >{v?.answered?.length > 100 ?
                                                                                            readingIndex !== i ?
                                                                                                selectedLanguage?.ContinueReading : selectedLanguage?.ShowLess : null}</p>
                                                                                    </p>
                                                                                </div>}
                                                                        </div>}
                                                                    <hr />
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div>
                                                            <p className='review-text'>{selectedLanguage?.NoReviewesYet}</p>
                                                        </div>
                                                }
                                            </div>
                                        </div>}
                    </div>
                </div >
            </div >
        </div >
    )
}

export default AdminReview