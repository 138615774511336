import { Button, Popconfirm, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import AddNewGameSetting from './AddNewGameSetting'
import axios from 'axios'
import { GET, POST } from '../../../utils/apis'
import { convertTitle, errorMessage, getGeneralData, getUserData, successMessage } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import NoData from '../../../assets/noDataImae.png'


const PlayerGameSetting = (props) => {
    const { selectedLanguage, isEnglish, user, generalActions, userActions } = props
    const games = useSelector(state => state?.generalReducer?.games)
    const gameSettings = useSelector(state => state?.userReducer?.gameSettings)
    const [AddGame, setAddGame] = useState(false)
    const [edit, setEdit] = useState({})
    const [deleteLoading, setDeleteLoading] = useState({})
    useEffect(() => {
        getGeneralData(generalActions)
        getUserData(user?._id, userActions)
    }, [])

    const deletePlayerGameSetting = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = {
            id
        }
        axios.post(POST.DELETE_PLAYER_GAME_SETTING, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data.messageNl)
                    handleOk()
                }
                else {
                    console.log('run')
                }
            })
            .catch((e) => {
                errorMessage(isEnglish ? e?.message : e?.messageNl)
                setDeleteLoading({})
            })
    }

    const handleOk = () => {
        getGeneralData(generalActions)
        getUserData(user?._id, userActions)
        setAddGame(false)
        setEdit({})
    }

    const columns = [
        {
            title: selectedLanguage?.text_gameName,
            dataIndex: 'gameId',
            key: 'gameName',
            render: (e) => {
                return (
                    <p>{e?.name || '---'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_gameNickName,
            dataIndex: 'nickName',
            key: 'nickName',
        },
        {
            title: '',
            render: (e) => {
                return (
                    <span style={{ display: 'flex', }}>
                        <Button
                            onClick={() => {
                                setAddGame(true)
                                setEdit(e)
                            }}
                            className='addproduct-text '
                            style={{ height: '30px', width: '80px', marginRight: '2px' }}
                        >{selectedLanguage?.buttonText_Edit}</Button>
                        <Popconfirm
                            title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.gameId?.name} `)} ${selectedLanguage?.text_gameSuggestion}?`}
                            okText={selectedLanguage?.textDelete}
                            cancelText={selectedLanguage?.textCancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => {
                                deletePlayerGameSetting(e?._id)
                            }}
                        ><Button
                            loading={deleteLoading[e?._id]}
                            onClick={() => {
                            }}
                            className='addproduct-text '
                            style={{ height: '30px', width: '80px', marginLeft: '2px' }}
                        >{selectedLanguage?.buttonText_Delete}</Button>
                        </Popconfirm>
                    </span>
                )
            }
        },
    ]
    return (
        <>
            <div className='game-p-main-div'>
                <div
                    className='sidebar-scroll-class-black'
                    style={{
                        height: '100%',
                        overflowY: 'scroll',
                        width: '98%',
                        margin: '0px auto',
                        marginLeft: '4px'
                    }}
                >
                    <p
                        style={{
                            marginTop: '10px',
                        }}
                        className='admin-home-title-white'>
                        {selectedLanguage?.title_GameSetting}
                    </p>
                    <div className='button-div-flex-end'>
                        <button
                            style={{
                                padding: '0px 20px 0px 20px',
                                background: 'rgba(92, 93, 93, 1)',
                                color: 'white',
                                border: 'none',
                                height: '45px',
                                marginTop: '10px',
                                borderRadius: 6
                            }}
                            onClick={() => {
                                setAddGame(true)
                            }}
                        >{selectedLanguage?.button_AddNewGameSetting}</button>
                    </div>

                    {/* <div style={{ marginTop: '30px' }}>
                        <Table
                            dataSource={gameSettings}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: 400 }}
                        />
                    </div> */}

                    <div style={{ marginTop: '20px' }}>
                        <div
                            className='sidebar-scroll-class-black'
                            style={{
                                overflow: 'scroll',
                                width: '98%',
                                margin: '0px auto'
                            }}
                        >
                            <div style={{
                                marginTop: '10px',
                                marginBottom: '5px',
                                width: '800px',
                            }}>
                                <div style={{
                                    width: '100%',
                                    height: '60px',
                                    borderTopRightRadius: '4px',
                                    borderTopLeftRadius: '4px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: '5px',
                                    alignItems: 'center',
                                }}>
                                    {[
                                        {
                                            title: selectedLanguage?.text_gameName,
                                            width: 150
                                        },
                                        {
                                            title: selectedLanguage?.text_gameNickName,
                                            width: 150
                                        },
                                        {
                                            title: 'null',
                                            width: 200
                                        },
                                    ]?.map((v, i) => {
                                        return (
                                            <p className='tableValue'
                                                style={{
                                                    margin: '0px',
                                                    color: 'white',
                                                    fontWeight: 'bolder',
                                                    fontSize: '20px',
                                                    width: v?.width,
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                }}
                                            >{v?.title === 'null' ? '' : v?.title}</p>
                                        )
                                    })}
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        borderBottomRightRadius: '4px',
                                        borderBottomLeftRadius: '4px',
                                        justifyContent: 'space-between',
                                        padding: '5px',
                                        alignItems: 'center',
                                    }}>
                                    {
                                        gameSettings?.length ?
                                            gameSettings?.map((v, i) => {
                                                return (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '50px',
                                                            borderTopRightRadius: '4px',
                                                            borderTopLeftRadius: '4px',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            padding: '5px',
                                                            alignItems: 'center',
                                                            margin: '0px',
                                                            padding: '0px'
                                                        }}>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '150px' }}>
                                                            {v?.gameId?.name}
                                                        </p>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '150px' }}>
                                                            {v?.nickName}
                                                        </p>
                                                        <p className='tableValue table-p'
                                                            style={{ width: '200px' }}>
                                                            <span style={{ display: 'flex', }}>
                                                                <Button
                                                                    onClick={() => {
                                                                        setAddGame(true)
                                                                        setEdit(v)
                                                                    }}
                                                                    style={{
                                                                        padding: '0px 20px 0px 20px',
                                                                        background: 'rgba(92, 93, 93, 1)',
                                                                        color: 'white',
                                                                        border: 'none',
                                                                        height: '30px',
                                                                        marginTop: '10px',
                                                                        borderRadius: 6,
                                                                        marginRight:12
                                                                    }}
                                                                >{selectedLanguage?.buttonText_Edit}</Button>
                                                                <Popconfirm
                                                                    title={`${selectedLanguage?.textDelete} ${convertTitle(`${v?.gameId?.name} `)} ${selectedLanguage?.text_gameSuggestion}?`}
                                                                    okText={selectedLanguage?.textDelete}
                                                                    cancelText={selectedLanguage?.textCancel}
                                                                    okButtonProps={{
                                                                        type: 'primary',
                                                                        className: 'form-button'
                                                                    }}
                                                                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                    onConfirm={() => {
                                                                        deletePlayerGameSetting(v?._id)
                                                                    }}
                                                                ><Button
                                                                    loading={deleteLoading[v?._id]}
                                                                    style={{
                                                                        padding: '0px 20px 0px 20px',
                                                                        background: 'rgba(92, 93, 93, 1)',
                                                                        color: 'white',
                                                                        border: 'none',
                                                                        height: '30px',
                                                                        marginTop: '10px',
                                                                        borderRadius: 6
                                                                    }}
                                                                >{selectedLanguage?.buttonText_Delete}</Button>
                                                                </Popconfirm>
                                                            </span>
                                                        </p>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{ height: '160px', textAlign: 'center' }}>
                                                <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {AddGame &&
                <AddNewGameSetting myGameSetting={gameSettings} allGames={games} handleOk={handleOk} AddGame edit={edit}  {...props} />
            }
        </>
    )
}

export default PlayerGameSetting