import { Button, Image, Input, Popconfirm, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import AddPromoter from './AddPromoter'
import { ADMIN } from '../../../utils/apis'
import { convertTitle, errorMessage, getGeneralData, successMessage } from '../../../utils/helpers'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import axios from 'axios'
import { useSelector } from 'react-redux'

const Promoter = (props) => {
    const { selectedLanguage, isEnglish, user, generalActions } = props
    const [game, setGame] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [allGames, setAllGames] = useState([])
    const [edit, setEdit] = useState({})
    const promoter = useSelector(state => state.generalReducer)


    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    const getAllGames = () => {
        setLoading(true)
        console.log('run')
        axios.get(ADMIN.GET_ALL_PROMOTER)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    console.log('')
                    setAllGames(data?.data || [])
                    getGeneralData(generalActions)
                }
                else {
                    setAllGames([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }


    const deleteGame = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = { _id: id, adminId: user?._id }
        axios.post(ADMIN.DELETE_PROMOTER, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    setAllGames([])
                    getAllGames()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }


    useEffect(() => {
        getAllGames()
    }, [deleteLoading])


    const columns = [

        {
            title: <p className='p-admin-title-text'>{selectedLanguage?.title_AdFile}</p>,
            dataIndex: 'adFile',
            key: 'adFile',
            align: 'center',
            render: (e) => <Image src={e?.url} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: <p className='p-admin-title-text'>{selectedLanguage?.title_TournamentFile}</p>,
            dataIndex: 'tournamentFile',
            key: 'tournamentFile',
            align: 'center',
            render: (e) => <Image src={e?.url} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: <p className='p-admin-title-text'>{selectedLanguage?.title_Name}</p>,
            dataIndex: 'name',
            key: 'name',
            render: (e) => <p className='p-admin-normal-text'>{e}</p>
        },
        {
            title: <p className='p-admin-title-text'>{selectedLanguage?.label_Email}</p>,
            dataIndex: 'email',
            key: 'email',
            render: (e) => <p className='p-admin-normal-text'>{e}</p>
        },
        {
            title: '',
            render: (e) => {
                return (
                    <span style={{ display: 'flex', }}>
                        <Button
                            onClick={() => {
                                setEdit(e)
                                setGame(true)
                            }}
                            className='addproduct-text '
                            style={{ height: '30px', width: '90px', marginRight: '2px' }}
                        >{selectedLanguage?.buttonText_Edit}</Button>
                        <Popconfirm
                            title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.name} `)} ${selectedLanguage?.textGame} ?`}
                            okText={selectedLanguage?.textDelete}
                            cancelText={selectedLanguage?.textCancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => {
                                deleteGame(e?._id)
                            }}
                        >
                            <Button
                                loading={deleteLoading[e?._id]}
                                className='addproduct-text '
                                style={{ height: '30px', width: '90px', marginLeft: '2px' }}
                            >{selectedLanguage?.buttonText_Delete}</Button>
                        </Popconfirm>
                    </span>
                )
            }
        },
    ]

    const goBack = () => {
        setGame(false)
        getAllGames()
        setEdit({})
    }


    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px', }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {game && <span
                        onClick={() => {
                            goBack()
                        }}
                        style={{ marginTop: '3px', cursor: 'pointer' }}>
                        <IoIosArrowBack size={28} color=' #ec671b' />
                    </span>}
                    <p className='admin-home-title'>{selectedLanguage?.title_Promoters}</p>
                </div>



                <div>
                    {game ?
                        <AddPromoter onCancel={() => (setGame(false), getAllGames())} isHide={true} edit={edit} {...props} />
                        :
                        <>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <CopyToClipboard text={'https://wgp-plaza-2bogwjma7a-ez.a.run.app/promoting-form'}
                                    onCopy={() => successMessage(selectedLanguage?.SuccessfullyCopiedtoClipboard)}>
                                    <Button
                                        className='addproduct-text '
                                        style={{ height: '40px', width: '180px', marginTop: '10px' }}
                                    >{selectedLanguage?.txt_copyPromotingformUrl}</Button>
                                </CopyToClipboard>
                                <button
                                    className='addproduct-text '
                                    style={{ height: '40px', width: '130px', marginTop: '10px', marginLeft: '5px' }}
                                    onClick={() => {
                                        setGame(true)
                                    }}
                                >
                                    {selectedLanguage?.text_AddPromotion}
                                </button>
                            </div>
                            <div style={{ marginTop: '20px' }} >
                                <Table
                                    dataSource={allGames || []}
                                    columns={columns}
                                    pagination={false}
                                    loading={loading}
                                    scroll={{ x: 800 }}
                                />
                            </div>
                        </>
                        }
                </div>
            </div>
        </div>
    )
}

export default Promoter