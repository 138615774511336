import React, { useState } from 'react'
import { Button, Form, Input, InputNumber, Select, Upload, } from 'antd'
import { errorMessage, successMessage, warningMessage } from '../../../utils/helpers'
import axios from 'axios'
import { ADMIN, POST } from '../../../utils/apis'
import { UploadOutlined } from '@mui/icons-material'
import countryCodes from '../../../utils/countryCodes'

const AddPromoter = (props) => {
    const { Option } = Select
    const { selectedLanguage, isEnglish, edit, isHide, onCancel, user } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [tournamentFileList, settournamentFileList] = useState(edit?.tournamentFile?.url ? [edit?.tournamentFile] : [])
    const [adFileList, setadFileList] = useState(edit?.adFile?.url ? [edit?.adFile] : [])

    const onFinish = async (values) => {
        setLoading(true)
        values._id = edit?._id
        values.adminId = user?._id
        values.name = values.name

        if (tournamentFileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', tournamentFileList[0]?.originFileObj)
            await axios.post(POST.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.tournamentFile = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage()

                })
        } else if (tournamentFileList[0]?.url) {
            let fileObj = {
                mediaType: tournamentFileList[0]?.mediaType,
                name: tournamentFileList[0]?.name,
                url: tournamentFileList[0]?.url,
            }
            values.tournamentFile = fileObj
        }

        if (adFileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', adFileList[0]?.originFileObj)
            await axios.post(POST.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.adFile = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage()

                })
        } else if (adFileList[0]?.url) {
            let fileObj = {
                mediaType: adFileList[0]?.mediaType,
                name: adFileList[0]?.name,
                url: adFileList[0]?.url,
            }
            values.adFile = fileObj
        }

        if (!values.adFile?.url || !values.tournamentFile?.url) {
            setLoading(false)
            return warningMessage(selectedLanguage?.setting_FileIsRequired)
        }
        axios.post(edit?._id ? ADMIN.EDIT_PROMOTER : ADMIN.CREATE_PROMOTER, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(isEnglish ? data.message : data.messageNl)
                    (edit?._id ? null : form.resetFields())
                    onCancel()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const normFile = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return settournamentFileList(e?.fileList);
    }

    const normFile2 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return setadFileList(e?.fileList);
    }
    
    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <div>
                    {isHide ? null :
                        <p className='admin-home-title'>
                            {selectedLanguage?.text_AddPromotion}
                        </p>}
                    <div style={{ marginTop: '20px' }}>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='name'
                                        initialValue={edit?.name}
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.title_Name}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_PleaseInputYourName
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_InputYourNameHere}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='email'
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_Email}</p>}
                                        hasFeedback
                                        initialValue={edit?.email}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_PleaseEnterYourEmail
                                            },
                                            {
                                                type: 'email',
                                                message: selectedLanguage?.error_EmailIsNotValid
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_PleaseEnterYourEmail}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='houseNumber'
                                        initialValue={edit?.houseNumber}
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_houseno}</p>}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_PleaseInputYourhouseno
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_InputYourhousenoHere}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='streetNumber'
                                        initialValue={edit?.streetNumber}
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.StreetNumber}</p>}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.PleaseIputYourStreetNumber
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.InputYourStreetNumberHere}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='postalCodeCity'
                                    initialValue={edit?.postalCodeCity}
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.PostalCode}</p>}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.PleaseInputYourPostalCode
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style input-placeholder-orange'
                                        placeholder={selectedLanguage?.InputYourPostalCodeHere}
                                    />
                                </Form.Item>
                            </div>

                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='country'
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_Country}</p>}
                                        hasFeedback
                                        initialValue={edit?.country}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_PleaseSelectYourCountry
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            showArrow
                                            allowClear={false}
                                            className='form-input-new-style input-none select-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_SelectYourCountry}
                                            optionFilterProp='children'
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{isEnglish ? v?.name : v?.nameNL}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </div>
                                
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='city'
                                        initialValue={edit?.city}
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_City}</p>}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_PleaseInputYourCity
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style input-placeholder-orange'
                                            placeholder={selectedLanguage?.placeHolder_InputYourCityHere}
                                        />
                                    </Form.Item>
                                </div >
                            </div >

                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.title_TournamentAd}</p>}
                                        name='tournamentFile'
                                        style={{ width: '100%' }}
                                        valuePropName='file'
                                        getValueFromEvent={normFile}
                                    >
                                        <Upload
                                            className='questionFileUpload'
                                            multiple={false}
                                            maxCount={1}
                                            beforeUpload={() => false}
                                            fileList={tournamentFileList}
                                            accept='image/*'
                                            action='/upload.do'
                                            style={{ width: '100%' }}
                                        >
                                            <Button style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                            <br />
                                        </Upload>
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.title_ApplicationAd}</p>}
                                        name='adFile'
                                        style={{ width: '100%' }}
                                        valuePropName='file'
                                        getValueFromEvent={normFile2}
                                    >
                                        <Upload
                                            className='questionFileUpload'
                                            multiple={false}
                                            maxCount={1}
                                            beforeUpload={() => false}
                                            fileList={adFileList}
                                            accept='image/*'
                                            action='/upload.do'
                                            style={{ width: '100%' }}
                                        >
                                            <Button style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                            <br />
                                        </Upload>
                                    </Form.Item>
                                </div>

                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                <Form.Item>
                                    <Button
                                        className='addproduct-text'
                                        style={{ height: '45px', width: '150px', marginTop: '10px' }}
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        {edit?._id ? selectedLanguage?.text_UpdatePromotion : selectedLanguage?.text_AddPromotion}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AddPromoter