import { PLAYER_INVOCE } from "../types"

const addPlayerInvoice = (playerInvoice) => {
    return {
        type: PLAYER_INVOCE,
        playerInvoice
    }
}


export {
    addPlayerInvoice
 }