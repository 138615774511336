import React, { useState, useEffect } from 'react';
import { Bar } from '@ant-design/plots';

const ParticipationChart = (props) => {
    const { Tournament, selectedLanguage } = props
    let participater = Tournament?.tournamentParticipate
    const [data, setData] = useState([]);
    useEffect(() => {
        let myData = participater?.map(item => {
            return {
                proPoints: item?.playerPoint?.proPoint || 0,
                points: item?.playerPoint?.point||0,
                name: item?.userId?.name
            }
        })
        setData(myData)
    }, [])
    const config = {
        data,
        xField: Tournament?.isStake ? 'proPoints' : 'points',
        yField: 'name',
        color: 'white',
        legend: {
            position: 'top-left',
        },
        barBackground: {
            style: {
                fill: 'white',
            },
        },
        interactions: [
            {
                type: 'active-region',
                enable: false,
            },
        ],
    };
    return (
        data?.length ? <Bar {...config} /> : <p style={{ margin: '20px auto' }}> {'No Data Found'}</p>);
}


export default ParticipationChart
