import { Button, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { useSelector } from 'react-redux'
import { getGeneralData, getUserData } from '../../../utils/helpers'
import AllProRankingPiont from './AllProRankingPoint'
import NoData from '../../../assets/noDataImae.png'
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { TbEqual } from "react-icons/tb"


const PlayerProRankingPiont = (props) => {
    const { Option } = Select
    const { selectedLanguage, isEnglish, user, generalActions, userActions } = props
    const games = useSelector(state => state.generalReducer.games)
    const userProRanks = useSelector(state => state.userReducer.userProRanks)
    const [allPlayer, setAllPlayer] = useState(false)
    const [selectedGame, setSelectedGame] = useState(games?.[0]?._id || null)
    useEffect(() => {
        getGeneralData(generalActions)
        getUserData(user?._id, userActions)
    }, [])

    return (
        <>
            <div className='pro-ranking-main-div'>
                <div
                    className='sidebar-scroll-class-black'
                    style={{ height: '100%', overflowY: 'scroll' }}
                >
                    <div style={{ marginTop: '10px' }}>
                        {allPlayer ?
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}>
                                <IoIosArrowBack
                                    onClick={() => { setAllPlayer(false) }}
                                    style={{ marginRight: '5px', color: 'white' }}
                                    size={28} />
                                <p className='admin-home-title-white'>{selectedLanguage?.text_AllPlayerProRankingAndPointsDollerpoints}</p>
                            </div> :
                            <p className='admin-home-title-white'>{selectedLanguage?.text_MyProRankingAndPointDollerPoints}</p>
                        }
                    </div>
                    <span style={{ width: '98%', margin: '0px auto', marginTop: '20px', }} className='button-div-flex-end'>
                        {allPlayer ?
                            <Select
                                showSearch
                                showArrow
                                allowClear={false}
                                defaultValue={selectedGame}
                                onChange={(e) => setSelectedGame(e)}
                                style={{
                                    height: '35px',
                                    width: '130px'
                                }}
                                placeholder={selectedLanguage?.label_SelectGame}
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {games?.length && games?.map((v, i) => <Option value={v?._id} key={i}>{v?.name}</Option>)}
                            </Select>
                            :
                            <Button
                                style={{
                                    padding: '0px 20px 0px 20px',
                                    background: 'rgba(92, 93, 93, 1)',
                                    color: 'white',
                                    border: 'none',
                                    height: '45px'
                                }}
                                onClick={() => setAllPlayer(true)}>
                                {selectedLanguage?.btnAllplayerProRanks}
                            </Button>}
                    </span>
                    <div style={{ marginTop: '25px' }}>
                        {allPlayer ?
                            <AllProRankingPiont {...props} selectedGame={selectedGame} />
                            :
                            <div
                                className='sidebar-scroll-class-black'
                                style={{
                                    overflow: 'scroll',
                                    width: '98%',
                                    margin: '0px auto'
                                }}
                            >
                                <div style={{
                                    marginTop: '10px',
                                    marginBottom: '5px',
                                    width: '100%',
                                    minWidth: '450px',
                                }}>
                                    <div style={{
                                        width: '100%',
                                        height: '60px',
                                        borderTopRightRadius: '4px',
                                        borderTopLeftRadius: '4px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        padding: '5px',
                                        alignItems: 'center',
                                    }}>
                                        {[
                                            { title: selectedLanguage?.tableText_Game },
                                            { title: selectedLanguage?.tableText_Ranking },
                                            { title: `${selectedLanguage?.tableText_Points}-($)` },
                                            { title: selectedLanguage?.tableText_WeeklyResult },
                                        ]?.map((v, i) => {
                                            return (
                                                <p className='tableValue'
                                                    style={{
                                                        margin: '0px',
                                                        color: 'white',
                                                        fontWeight: 'bolder',
                                                        fontSize: '20px',
                                                        width: '20%'
                                                    }}
                                                >{v?.title}</p>
                                            )
                                        })}
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            borderBottomRightRadius: '4px',
                                            borderBottomLeftRadius: '4px',
                                            justifyContent: 'space-between',
                                            padding: '5px',
                                            alignItems: 'center',
                                        }}>
                                        {userProRanks?.length ?
                                            userProRanks?.map((v, i) => {
                                                return (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '50px',
                                                            borderTopRightRadius: '4px',
                                                            borderTopLeftRadius: '4px',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            padding: '5px',
                                                            alignItems: 'center',
                                                            margin: '0px',
                                                            padding: '0px'
                                                        }}>
                                                        <p className='tableValue'>{v?.gameId?.name}</p>
                                                        <p className='tableValue'>{v?.rank}</p>
                                                        <p className='tableValue'>{v?.proPoint}</p>
                                                        <p className='tableValue'>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                {!v?.weaklyProPointReport?.length || v?.weaklyProPointReport?.[0]?.difference === 0 ?
                                                                    <TbEqual color='black' size={22} style={{
                                                                        cursor: 'pointer',
                                                                        background: 'white',
                                                                        margin: '10px',
                                                                        height: '30px',
                                                                        width: '30px',
                                                                        borderRadius: '20px',
                                                                        padding: '4px'
                                                                    }} />
                                                                    :
                                                                    v?.proPointUp ?
                                                                        <AiOutlineArrowUp color='green' size={22} style={{
                                                                            cursor: 'pointer',
                                                                            background: 'white',
                                                                            margin: '10px',
                                                                            height: '30px',
                                                                            width: '30px',
                                                                            borderRadius: '20px',
                                                                            padding: '4px'
                                                                        }} /> :
                                                                        <AiOutlineArrowDown color='red' size={22} style={{
                                                                            cursor: 'pointer',
                                                                            background: 'white',
                                                                            margin: '10px',
                                                                            height: '30px',
                                                                            width: '30px',
                                                                            borderRadius: '20px',
                                                                            padding: '4px'
                                                                        }} />}
                                                                <p style={{ margin: '16px' }}>
                                                                    {v?.weaklyProPointReport?.[0]?.difference || 0}
                                                                </p>
                                                            </div>
                                                        </p>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{ height: '160px', textAlign: 'center' }}>
                                                <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default PlayerProRankingPiont