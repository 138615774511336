import { Button, Popconfirm, Spin, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { GET, POST } from '../../../utils/apis';
import { checkIfDateTimePassedForMatchResultBtns, convertNumber, convertTitle, errorMessage, get1_1_MatchData, successMessage, validateTime } from '../../../utils/helpers'
import ClaimMatchModal from './ClaimMatchModal';
import io from 'socket.io-client'
import moment from 'moment';
import DrawMatchProof from './DrawMatchProof';
import { useSelector } from 'react-redux';
import NoData from '../../../assets/noDataImae.png'

const MatchResult = (props) => {
    const { selectedLanguage, user, isEnglish, getUser, userActions } = props
    const matchResults = useSelector(state => state.userReducer.matchResults)
    const [data, setData] = useState(matchResults)
    const [claimData, setClaimData] = useState({})
    const [drawData, setDrawData] = useState({})
    const [spin, setSpin] = useState({})
    console.log('matchResults', matchResults)
    useEffect(() => {
        get1_1_MatchData(user?._id, userActions)
        getSocketInfo()
    }, [])
    let origin = `https://www.padeldater.com`
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    // console.log('origin', origin)
    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('matchRequestResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            get1_1_MatchData(user?._id, userActions)

        })
    }

    const userLostMatch = (e) => {
        let obj = {
            userId: user?._id,

            opponents: e?.playerNumbers === 2 ? (user?._id === e?.senderId?._id ? [e?.opponents?.[0]?._id] : [e?.senderId?._id]) : ((user?._id === e?.senderId?._id || user?._id === e?.partner?._id) ? e?.opponents?.map((v) => v?._id) : [e?.senderId?._id, e?.partner?._id]),

            looser: e?.playerNumbers === 2 ? (user?._id === e?.senderId?._id ? [e?.senderId?._id] : [e?.opponents?.[0]?._id]) : ((user?._id === e?.senderId?._id || user?._id === e?.partner?._id) ? [e?.senderId?._id, e?.partner?._id] : e?.opponents?.map((v) => v?._id)),

            _id: e?._id
        }
        setSpin({ [e?._id]: true })
        console.log(obj)

        axios.post(POST?.USER_LOST_MATCH, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    get1_1_MatchData(user?._id, userActions)
                    getUser()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(isEnglish ? e.message : e.messageNl)
            })

    }

    const userConfirmDrawMatch = (e) => {
        console.log('e', e)
        let obj = {
            gameId: e?.gameId?._id,
            points: e?.points,
            proPoints: e?.proPoints,
            money: e?.money,
            isStake: e?.isStake,
            userId: user?._id,
            opponentId: e?.receiverId?._id !== user?._id ? e?.receiverId?._id : e?.senderId?._id,
            _id: e?._id,


        }
        return true

        setSpin({ [e?._id]: true })
        // console.log(user?._id)

        axios.post(POST?.USER_CONFIRM_MATCH_DRAW, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(isEnglish ? e.message : e.messageNl)
            })

    }
    useEffect(() => {
        const intervalId = setInterval(() => {
            const newData = matchResults.map(item => {
                if (item?.updateTime) {
                    let diffMinutes = moment.utc().diff(moment.utc(item?.updateTime), 'minutes');
                    return { ...item, diffMinutes: diffMinutes }
                } else {
                    return { ...item, diffMinutes: -1 }
                }
            })
            setData(newData)
        }, 5000) // check every 5 seconds

        // return a cleanup function to clear the interval when the component is unmounted
        return () => clearInterval(intervalId)
    }, [])
    const handleOk = () => {
        setClaimData({})
        setDrawData({})
    }
    const findPartner = ({ senderId, opponents, partner }) => {

        if (user?._id === senderId?._id) {
            return partner?.name
        } else if (user?._id === partner?._id) {
            return senderId?.name
        } else if (user?._id === opponents?.[0]?._id) {
            return opponents?.[1]?.name
        } else {
            return opponents?.[0]?.name
        }

    }
    const findCouple = ({ senderId, partner, couple, opponentCouple }) => {

        if (user?._id === senderId?._id || user?._id === partner?._id) {
            return couple?.coupleName
        } else {
            return opponentCouple?.coupleName
        }
    }
    const findOpponentCouple = ({ senderId, partner, couple, opponentCouple }) => {

        if (user?._id === senderId?._id || user?._id === partner?._id) {
            return opponentCouple?.coupleName
        } else {
            return couple?.coupleName
        }
    }
    const findOpponents = ({ senderId, partner, opponents }) => {
        if (user?._id === senderId?._id || user?._id === partner?._id) {
            return <p className='table_p' >{opponents?.[0]?.name} {',' && opponents?.[1]?.name}</p>
        } else {
            return <p className='table_p' >{senderId?.name} {',' && partner?.name}</p>
        }
    }

    const columns = [
        {
            title: 'Club',
            dataIndex: ['club', 'name'],
            align: 'center'
        },
        {
            title: selectedLanguage?.title_GameType,
            dataIndex: 'gameType',
            align: 'center',
            render: (e) => convertTitle(e)
        },
        {
            title: 'Partner',
            align: 'center',
            render: (e) => e?.playerNumbers === 2 ? '-' : findPartner(e)
        },
        {
            title: 'Couple',
            align: 'center',
            render: (e) => e?.playerNumbers === 2 ? '-' : findCouple(e)

        },
        {
            title: selectedLanguage?.title_DateTime,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p'>
                        {e?.matchDate || '-----'} | {validateTime(e?.matchTime) || '-----'}
                    </p>
                )
            }
        },
        {
            title: 'Players',
            dataIndex: 'playerNumbers',
            align: 'center'
        },
        {
            title: 'Opponent Couple',
            // dataIndex: ['couple', 'coupleName'],
            align: 'center',
            width: 250,
            render: (e) => e?.playerNumbers === 2 ? '-' : findOpponentCouple(e)

        },
        {
            title: 'Opponents',
            align: 'center',
            render: (e) => findOpponents(e)
        },
        {
            title: selectedLanguage?.title_Message,
            dataIndex: 'message',
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' > {e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Status,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '120px' }}>
                        {e?.looser?.length || e?.winner?.length || e?.isDraw?.length ?
                            <p className='accept-status' style={{ width: '120px' }}>{selectedLanguage?.status_completed}</p>
                            :
                            <p className='pending-stats' style={{ width: '120px' }}>{selectedLanguage?.status_playing}</p>
                        }
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Result,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '120px' }}>
                        {((e?.winner?.length && !e?.looser?.length) || e?.isDraw?.length) ?
                            <p className='pending-stats' style={{ width: '180px' }}>{selectedLanguage?.status_ResultInProcess}</p>
                            :
                            e?.winner?.includes(user?._id) && e?.looser?.length ? <p className='accept-status'>{selectedLanguage?.status_Won}</p> :
                                e?.looser?.includes(user?._id) && e?.winner?.length ? <p className='reject-status'>{selectedLanguage?.status_Loss}</p> :
                                    !e?.winner?.length && !e?.looser?.length && !e?.isDraw?.length ?
                                        <p className='pending-stats' style={{ width: '140px' }}>{selectedLanguage?.status_MatchNotPlayed}</p> :
                                        <p className='pending-stats'>{selectedLanguage?.status_Argue}</p>
                        }
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            render: (e) => {
                let timeDiabledcondition = checkIfDateTimePassedForMatchResultBtns(e)

                return (
                    <>
                        {spin?.[e?._id] ?
                            <Spin /> :
                            (!e?.looser?.length && !e?.winner?.length && !e?.isDraw)?
                                <div style={{ display: 'flex' }}>
                                    <Popconfirm
                                        title={`${selectedLanguage?.areYouSureYouLostThe} ${selectedLanguage?.text_game}?`}
                                        okText={selectedLanguage?.text_yes}
                                        cancelText={selectedLanguage?.text_No}
                                        okButtonProps={{
                                            type: 'primary',
                                            className: 'form-button'
                                        }}
                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                        onConfirm={() => {
                                            userLostMatch(e)
                                        }}
                                    >
                                        <Button
                                            type='primary'
                                            danger
                                            disabled={e?.looser?.length || e?.winner?.length}
                                            loading={spin?.[e?._id]}
                                            style={{ marginLeft: '10px' }}
                                        >{selectedLanguage?.btn_ILost}</Button>
                                    </Popconfirm>

                                </div> :
                                e?.isDraw.length && !e?.draw?.includes(user?._id) ?
                                    <div style={{ display: 'flex' }}>
                                        {/* <Button
                                            disabled={e?.looser?.length || e?.winner?.length}
                                            onClick={() => setClaimData(e)}
                                            className={e?.looser?.length || e?.winner?.length ? '' : 'i-won-button'}
                                            style={{ marginLeft: '10px' }}
                                        >
                                            {selectedLanguage?.btn_IWon}
                                        </Button>
                                        <Button
                                            style={{ backgroundColor: 'yellow', marginLeft: 10, color: 'black' }}
                                            disabled={e?.looser?.length || e?.winner?.length}
                                            onClick={() => userConfirmDrawMatch(e)}
                                        >
                                            {selectedLanguage?.btn_Draw}
                                        </Button> */}
                                    </div >
                                    : !e?.looser?.length && e?.winner.includes(user?._id) !== user?._id && !e?.isDraw ?
                                        <Popconfirm
                                            title={`${selectedLanguage?.areYouSureYouLostThe}"${e?.gameId?.name}" ${selectedLanguage?.text_game}?`}
                                            okText={selectedLanguage?.text_yes}
                                            cancelText={selectedLanguage?.text_No}
                                            okButtonProps={{
                                                type: 'primary',
                                                className: 'form-button'
                                            }}
                                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                                            onConfirm={() => {
                                                userLostMatch(e)
                                            }}
                                        >
                                            <Button
                                                type='primary'
                                                danger
                                                loading={spin?.[e?._id]}
                                                style={{ marginLeft: '10px' }}
                                            >
                                                {/*  {e?.diffMinutes} */} {selectedLanguage?.button_ILostDeleteClaim}
                                            </Button>
                                        </Popconfirm>
                                        :
                                        null
                        }
                    </>
                )
            }
        },
    ]

    return (
        <div className='match-result-main-div'>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
            >
                <p className='admin-home-title-white' style={{ marginTop: '10px' }}>
                    {selectedLanguage?.title_OnetoOneMatchResults}
                </p>
                <Table
                    dataSource={data}
                    columns={columns}
                    className='specific-css-table'
                    pagination={false}
                    scroll={{ x: 2000, y: 200 }}
                />
            </div>
        </div>
    )
}

export default MatchResult