import { Button, Form, Input, Modal, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import io from 'socket.io-client'
import { POST } from '../../../utils/apis'
import { checkIfDateTimePassedForMatchResultBtns, convertTitle, errorMessage, getCompetitionMatches, successMessage, validateTime } from '../../../utils/helpers'
import ClaimMatchModal from '../MatchRequest/ClaimMatchModal'
import DrawMatchProof from '../MatchRequest/DrawMatchProof'

const CompetitionMatchesResult = (props) => {
    const { selectedLanguage, userActions, user, isEnglish, getUser } = props
    const competitionMatches = useSelector(state => state.userReducer.competitionMatches)
    const [form] = Form.useForm()
    const [deleteLoading, setDeleteLoading] = useState({})
    const [data, setData] = useState(competitionMatches)
    const [spin, setSpin] = useState({})
    const [claimData, setClaimData] = useState({})
    const [drawData, setDrawData] = useState({})
    const [lostData, setLostData] = useState({})
    const [detail, setDetail] = useState({})

    console.log('competitionMatches', competitionMatches)
    useEffect(() => {
        getCompetitionMatches(user?._id, userActions)
        getSocketInfo()
    }, [])

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         console.log('run')
    //         const newData = competitionMatches.map(item => {
    //             if (item?.updateTime) {
    //                 let diffMinutes = moment.utc().diff(moment.utc(item?.updateTime), 'minutes');
    //                 return { ...item, diffMinutes: diffMinutes }
    //             } else {
    //                 return { ...item, diffMinutes: -1 }
    //             }
    //         })
    //         console.log('newData',newData)
    //         setData(newData)
    //     }, 5000) // check every 5 seconds

    //     // return a cleanup function to clear the interval when the component is unmounted
    //     return () => clearInterval(intervalId)
    // }, [])
    const handleOk = () => {
        setClaimData({})
        setDrawData({})
    }
    let origin = `https://www.padeldater.com`
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    // console.log('origin', origin)
    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('matchRequestResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            console.log('d--->', d)
            getCompetitionMatches(user?._id, userActions)

        })
    }

    const userLostMatch = (e) => {
        let obj = {
            userId: user?._id,

            opponents: e?.playerNumbers === 2 ? (user?._id === e?.senderId?._id ? [e?.opponents?.[0]?._id] : [e?.senderId?._id]) : ((user?._id === e?.senderId?._id || user?._id === e?.partner?._id) ? e?.opponents?.map((v) => v?._id) : [e?.senderId?._id, e?.partner?._id]),

            looser: e?.playerNumbers === 2 ? (user?._id === e?.senderId?._id ? [e?.senderId?._id] : [e?.opponents?.[0]?._id]) : ((user?._id === e?.senderId?._id || user?._id === e?.partner?._id) ? [e?.senderId?._id, e?.partner?._id] : e?.opponents?.map((v) => v?._id)),

            winnerCouple: e?.couple?.userId === user?._id ? e?.opponentCouple?._id : e?.couple?._id,
            looserCouple: e?.couple?.userId === user?._id ? e?.couple?._id : e?.opponentCouple?._id,
            competition: e?.competition,
            playerNumbers: e?.playerNumbers,
            senderId: e?.senderId?._id,
            alreadyPaid: e?.alreadyPaid,
            _id: e?._id
        }
        e?.score && (obj.score = e?.score)

        console.log('e92', e)
        setLostData({})
        setSpin({ [e?._id]: true })

        axios.post(POST?.USER_COMPETITION_LOST_MATCH, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getCompetitionMatches(user?._id, userActions)
                    getUser()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(isEnglish ? e.message : e.messageNl)
            })

    }

    const userConfirmDrawMatch = (e) => {
        console.log('e', e)
        let obj = {
            claimer: e?.playerNumbers === 2 ? [e?.senderId?._id, e?.opponents?.[0]?._id] : [e?.senderId?._id, e?.opponents?.[0]?._id, e?.opponents?.[1]?._id, e?.partner?._id],

            allCouples: [e?.couple?._id, e?.opponentCouple?._id],
            userId: user?._id,
            competition: e?.competition,
            playerNumbers: e?.playerNumbers,
            _id: e?._id
        }
        setSpin({ [e?._id]: true })
        // console.log(user?._id)

        axios.post(POST?.USER_CONFIRM_MATCH_DRAW, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getUser()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }

    const findPartner = ({ senderId, opponents, partner }) => {
        if (user?._id === senderId?._id) {
            return partner?.name
        } else if (user?._id === partner?._id) {
            return senderId?.name
        } else if (user?._id === opponents?.[0]?._id) {
            return opponents?.[1]?.name
        } else {
            return opponents?.[0]?.name
        }

    }
    const findCouple = ({ senderId, partner, couple, opponentCouple }) => {

        if (user?._id === senderId?._id || user?._id === partner?._id) {
            return couple?.coupleName
        } else {
            return opponentCouple?.coupleName
        }
    }
    const findOpponentCouple = ({ senderId, partner, couple, opponentCouple }) => {

        if (user?._id === senderId?._id || user?._id === partner?._id) {
            return opponentCouple?.coupleName
        } else {
            return couple?.coupleName
        }
    }
    const findOpponents = ({ senderId, partner, opponents }) => {
        if (user?._id === senderId?._id || user?._id === partner?._id) {
            return <p className='table_p' >{opponents?.[0]?.name} {',' && opponents?.[1]?.name}</p>
        } else {
            return <p className='table_p' >{senderId?.name} {',' && partner?.name}</p>
        }
    }
    console.log('user', user?._id)

    const onFinish = (values) => {
        const score = values?.score
        let data = lostData
        data.score = score
        userLostMatch(data)

    }
    console.log('detail', detail)
    const columns = [
        {
            title: selectedLanguage?.txt_Club,
            dataIndex: ['club', 'name'],
            align: 'center'
        },
        {
            title: selectedLanguage?.title_GameType,
            dataIndex: 'gameType',
            align: 'center',
            render: (e) => convertTitle(e)
        },
        {
            title: selectedLanguage?.txt_Partner,
            align: 'center',
            render: (e) => e?.playerNumbers === 2 ? '-' : findPartner(e)
        },
        {
            title: 'Couple',
            align: 'center',
            render: (e) => e?.playerNumbers === 2 ? '-' : findCouple(e)

        },
        {
            title: selectedLanguage?.title_DateTime,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p'>
                        {e?.matchDate || '-----'} | {validateTime(e?.matchTime) || '-----'}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Players,
            dataIndex: 'playerNumbers',
            align: 'center'
        },
        {
            title: selectedLanguage?.txt_OpponentCouple,
            align: 'center',
            width: 250,
            render: (e) => e?.playerNumbers === 2 ? '-' : findOpponentCouple(e)
        },
        {
            title: selectedLanguage?.txt_Opponent,
            align: 'center',
            render: (e) => findOpponents(e)
        },
        {
            title: selectedLanguage?.txt_Score,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' > {e?.score || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Message,
            dataIndex: 'message',
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' > {e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Status,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '120px' }}>
                        {e?.looser?.length || e?.winner?.length || e?.isDraw ?
                            <p className='accept-status' style={{ width: '120px' }}>{selectedLanguage?.status_completed}</p>
                            :
                            <p className='pending-stats' style={{ width: '120px' }}>{selectedLanguage?.status_playing}</p>
                        }
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Result,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '120px' }}>
                        {((e?.winner?.length && !e?.looser?.length) || e?.isDraw && !e?.isCompleted) ?
                            <p className='pending-stats' style={{ width: '180px' }}>{selectedLanguage?.status_ResultInProcess}</p>
                            :
                            e?.isCompleted && e?.isDraw ? <p className='draw-status'>{'Draw'}</p> :
                                e?.winner?.includes(user?._id) && e?.looser?.length ? <p className='accept-status'>{selectedLanguage?.status_Won}</p> :
                                    e?.looser?.includes(user?._id) && e?.winner?.length ? <p className='reject-status'>{selectedLanguage?.status_Loss}</p> :
                                        !e?.winner?.length && !e?.looser?.length && !e?.isDraw ?
                                            <p className='pending-stats' style={{ width: '140px' }}>{selectedLanguage?.status_MatchNotPlayed}</p> :
                                            <p className='pending-stats'>{selectedLanguage?.status_Argue}</p>
                        }
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            render: (e) => {
                let timeDiabledcondition = checkIfDateTimePassedForMatchResultBtns(e)
                return (
                    <>
                        {spin?.[e?._id] ?
                            <Spin /> :
                            e?.isCompleted ?
                                <Button
                                    type='primary'
                                    style={{ marginLeft: 10 }}
                                    onClick={() => setDetail(e)}
                                >
                                    details
                                </Button> :
                                (!e?.looser?.length && !e?.winner?.length && !e?.isDraw) && (e?.senderId?._id === user?._id || e?.opponents?.[0]?._id === user?._id) ?
                                    <div style={{ display: 'flex' }}>

                                        <Button
                                            disabled={e?.looser?.length || e?.winner?.length}
                                            onClick={() => setClaimData(e)}
                                            className={e?.looser?.length || e?.winner?.length ? '' : 'i-won-button'}
                                            style={{ marginLeft: '10px' }}
                                        >
                                            {selectedLanguage?.btn_IWon}
                                        </Button>
                                        <Button
                                            style={{ backgroundColor: 'yellow', marginLeft: 10, color: 'black' }}
                                            disabled={e?.looser?.length || e?.winner?.length}
                                            onClick={() => setDrawData(e)}
                                        >
                                            {selectedLanguage?.btn_Draw}
                                        </Button>
                                        <Popconfirm
                                            title={`${selectedLanguage?.areYouSureYouLostThe} ${selectedLanguage?.text_game}?`}
                                            okText={selectedLanguage?.text_yes}
                                            cancelText={selectedLanguage?.text_No}
                                            okButtonProps={{
                                                type: 'primary',
                                                className: 'form-button'
                                            }}
                                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                                            onConfirm={() => {
                                                setLostData(e)
                                            }}
                                        >
                                            <Button
                                                type='primary'
                                                danger
                                                disabled={e?.looser?.length || e?.winner?.length}
                                                loading={spin?.[e?._id]}
                                                style={{ marginLeft: '10px' }}
                                            >{selectedLanguage?.btn_ILost}</Button>
                                        </Popconfirm>
                                    </div> :
                                    e?.isDraw && !e?.draw?.includes(user?._id) /* && (e?.senderId?._id === user?._id || e?.opponents?.[0]?._id === user?._id) */ ?
                                        <div style={{ display: 'flex' }}>
                                            <Button
                                                disabled={e?.looser?.length || e?.winner?.length}
                                                onClick={() => setClaimData(e)}
                                                className={e?.looser?.length || e?.winner?.length ? '' : 'i-won-button'}
                                                style={{ marginLeft: '10px' }}
                                            >
                                                {selectedLanguage?.btn_IWon}
                                            </Button>
                                            <Popconfirm
                                                title={`${'Are you sure you draw the match request...'}?`}
                                                okText={selectedLanguage?.text_yes}
                                                cancelText={selectedLanguage?.text_No}
                                                okButtonProps={{
                                                    type: 'primary',
                                                    className: 'form-button'
                                                }}
                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                onConfirm={() => {
                                                    userConfirmDrawMatch(e)
                                                }}
                                            >
                                                <Button
                                                    style={{ backgroundColor: 'yellow', marginLeft: 10, color: 'black' }}
                                                    disabled={e?.looser?.length || e?.winner?.length}
                                                >
                                                    {'Confirm Draw'}
                                                </Button>
                                            </Popconfirm>
                                        </div >
                                        : !e?.looser?.length && !e?.winner.includes(user?._id) && !e?.isDraw && (e?.senderId?._id === user?._id || e?.opponents?.[0]?._id === user?._id) ?
                                            <Popconfirm
                                                title={`${selectedLanguage?.areYouSureYouLostThe}"${e?.gameId?.name}" ${selectedLanguage?.text_game}?`}
                                                okText={selectedLanguage?.text_yes}
                                                cancelText={selectedLanguage?.text_No}
                                                okButtonProps={{
                                                    type: 'primary',
                                                    className: 'form-button'
                                                }}
                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                onConfirm={() => {
                                                    userLostMatch(e)
                                                }}
                                            >
                                                <Button
                                                    type='primary'
                                                    danger
                                                    loading={spin?.[e?._id]}
                                                    style={{ marginLeft: '10px' }}
                                                >
                                                    {selectedLanguage?.button_ILostDeleteClaim}
                                                </Button>
                                            </Popconfirm>
                                            :
                                            null
                        }
                    </>
                )
            }
        },
    ]

    return (
        <div style={{ paddingBottom: 10, marginBottom: 30, borderBottom: `1px dashed white` }}>
            <div
                className='sidebar-scroll-class-black'
                style={{ height: '100%', overflowY: 'scroll', width: '98%', margin: '0px auto' }}
            >
                <p className='admin-home-title-white'>
                    {selectedLanguage?.text_Competitionmatchesresult}
                </p>
                <Table
                    dataSource={competitionMatches}
                    columns={columns}
                    className='specific-css-table'
                    pagination={false}
                    scroll={{ x: 2000, y: 200 }}
                />

                {
                    claimData?._id &&
                    <ClaimMatchModal data={claimData} onCancel={() => setClaimData({})} visible={claimData._id ? true : false} handleOk={handleOk} {...props} />
                }
                {
                    drawData?._id &&
                    <DrawMatchProof data={drawData} onCancel={() => setDrawData({})} visible={drawData._id ? true : false} handleOk={handleOk} {...props} />
                }

                <Modal
                    open={detail?._id}
                    onCancel={() => setDetail({})}
                    footer={null}>
                    <div>
                        <p style={{ marginLeft: '15px' }}>Price: € {detail?.price}</p>
                        <ul>
                            {detail?.rankHistory?.map((v, i) => (
                                <li>
                                    {v?.userId?.name}
                                    <ul>
                                        <li>
                                            Bonds number :{v?.userId?.bondsnummer || '----'}
                                        </li>
                                        <li>
                                            Old Rank : {v?.oldRank}
                                        </li>
                                        <li>
                                            New Rank : {v?.newRank}
                                        </li>
                                        <li>
                                            Difference : {v?.difference}
                                        </li>
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Modal>
                <Modal
                    open={lostData?._id}
                    onCancel={() => setLostData({})}
                    footer={null}>
                    <div style={{ marginTop: '10px' }}>
                        <span style={{ textAlign: 'center' }}>
                            <p className='admin-home-title' style={{ fontSize: '18px' }}>{
                                'Enter Score Here...'
                            }</p>
                        </span>
                    </div>
                    <div>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                    <Form.Item
                                        name='score'
                                        label={<p className='my-form-label' style={{ color: '#ec671b' }}>{'Score'}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter score here...'
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style'
                                            placeholder={'Enter Score here"eg=6-6"'}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <Form.Item>
                                <Button
                                    className='login_button'
                                    htmlType='submit'
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>


            </div >
        </div >
    )
}

export default CompetitionMatchesResult